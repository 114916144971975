import { useEffect, useState } from "react"
import { cnpjMask, cpfMask, replaceEspecials } from "util/masks"
import { getEnterpriseUnit, getListEnterprise, getListEnterprise_temp, getUsuarioProprietario } from "../service"
import { LinkTable } from "./style"
import { config } from "api";
import { useEnterpriseContext } from "../Context/enterpriseContext";

const defaultEntepriseList = {
  page: [],
  count: 0,
  currentPage: 0,
}

export interface IEnterprise {
  idPlanoSelecionado: number | string | null,
  dadosDiretor: {
    id: number,
    nome: string,
    cpf: string,
    ddd: string | null,
    telefone: string | null,
    email: string | null,
    funcao: string | null,
    dataCadastro: string | null,
    idUnidade: number | null
  },
  dadosEmpresa: {
    id: number,
    cnpj: string,
    razaoSocial: string,
    codigoPlano: String,
    nomeFantasia: string,
    idPlanoSelecionado: string,
    logo: Blob | string | null
  },
  dadosUnidadePrincipal: number | null,
  unidades: number | null
}
interface IEnterpriseList {
  page: IEnterprise[],
  count: number,
  currentPage: number,
}
const useList = () => {
  const { setErrorGeral } = useEnterpriseContext()

  
  const listTypeSearch = ['CNPJ', 'CPF', 'EMPRESA', 'DIRETOR']

  const [success, setSuccess] = useState(false)
  const [values, setValues] = useState('')
  const [load, setLoad] = useState<boolean>(false)
  const [type, setType] = useState('')
  const [typeInput, setTypeInput] = useState('')
  const [filter, setFilter] = useState('')

  
  const [listEnterprise, setListEnterprise] = useState<IEnterpriseList>(defaultEntepriseList)
  const [pageCurrent, setPageCurrent] = useState<number>(0)

  const { data, isLoading } : { data : IEnterpriseList, isLoading: boolean } = getListEnterprise_temp({ 
    page: pageCurrent, 
    filter: filter 
  }) 

  useEffect(() => {
    setLoad(isLoading)
  },[isLoading])

  useEffect(() => {
    setListEnterprise(data)
  },[filter, data])

  const columns = [
    { nome: 'Empresa' },
    { nome: 'CNPJ' },
    { nome: 'Diretor' },
    { nome: 'CPF' },
    { nome: '' },
    { nome: '' }
  ]
  const rowsTable : any = listEnterprise?.page.length > 0
    ? listEnterprise?.page.map((i: IEnterprise) => {
      return {
        empresa: i.dadosEmpresa.nomeFantasia,
        cnpj: i.dadosEmpresa.cnpj,
        diretor: i.dadosDiretor.nome,
        cpf: i.dadosDiretor.cpf,
        dados: i
      }
    })
    : []

  const rowTableFilter = rowsTable.map((i: any) => ({
    empresa: i.empresa,
    cnpj: cnpjMask(i.cnpj),
    diretor: i.diretor,
    cpf: cpfMask(i.cpf),
    info: i.dados?.dadosEmpresa?.instanciaIsAtiva === "ATIVA" ? "Ativo" : "Inativo",
    link: <LinkTable onClick={() => handleView(i.dados)}>Ver dados</LinkTable>
  }))

  const handleFilter = () => {
    setPageCurrent(0)

    if(!typeInput){
      setErrorGeral(`Preencher o campo: ${type}`)
      return
    }

    const typeFilterDB = {
      'CNPJ': `&cnpjEmpresa=${replaceEspecials(typeInput)}`,
      'EMPRESA': `&nomeEmpresa=${typeInput}`,
      'CPF': `&cpfDiretor=${replaceEspecials(typeInput)}`,
      'DIRETOR': `&nomeDiretor=${typeInput}`,
    }
    
    type === 'CNPJ' && setFilter(typeFilterDB.CNPJ) 
    type === 'EMPRESA' && setFilter(typeFilterDB.EMPRESA) 
    type === 'CPF' && setFilter(typeFilterDB.CPF) 
    type === 'DIRETOR' && setFilter(typeFilterDB.DIRETOR) 
  }

  const handleEnterFilter = (e : string) => {
    e === 'Enter' && handleFilter()
  }

  const handleView = (e: any) => {

    const dadosDiretor = getUsuarioProprietario(e.dadosEmpresa.id, e.dadosDiretor.id);
    const dadosEmpresa = getEnterpriseUnit(e.dadosEmpresa.id);

    Promise.all([dadosDiretor, dadosEmpresa])
      .then(res => {
        setValues({
          ...e,
          dadosDiretor: {
            ...e.dadosDiretor,
            cpf: res[0].data.cpf,
            email: res[0].data.email,
            nome: res[0].data.nome,
            ddd: res[0].data.ddd,
            telefone: res[0].data.telefone
          },
          dadosEmpresa: {
            ...e.dadosEmpresa,
            cnpj: res[1].data.cnpj,
            nomeFantasia: res[1].data.nomeFantasia,
            razaoSocial: res[1].data.razaoSocial,
            logo: `${config.UrlS3}/galeria/instancia/logos/${res[1].data.logo}`,
            descricao: res[1].data.descricao,
            codigoPlano: res[1].data.codigoPlano
          }
        })
    })
    .catch(error => console.log(error))

    // setValues(e)
  }
  const handleCancel = () => {
    setPageCurrent(0)
    setValues('')
  }
  const handlePageSucess = (e: boolean) => {
    setValues('')
    setSuccess(true)
  }
  const handleConfirm = () => {
    setSuccess(false)
    setValues('')
  }

  const handlePage = (e: number) => setPageCurrent(e)

  return {
    listTypeSearch, listEnterprise, columns, rowTableFilter,
    success, values, type, typeInput, load, pageCurrent, handlePage,
    setSuccess, setValues, setType, setTypeInput, setLoad, setFilter,
    handleFilter, handleView, handleCancel, handlePageSucess, handleConfirm, handleEnterFilter
  }
}
export { useList }