import styled from "styled-components";
import theme from "styles/theme";

export const BoxVersion = styled.div`
  position: fixed;
  font-size: 0.70rem;
  font-weight: 400;
  bottom:15px;
  right:15px;
  color:${theme.COLORS.WHITE};
`