import styled from "styled-components";
import theme from "styles/theme";


export const FlexCards = styled.div`
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  gap:16px;
  padding:10px 0;
  position: relative;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
    max-width:380px;
    margin: auto;
  }
`
export const LoadCard = styled.div`
  position: absolute;
  inset:0 0 0 0;
  left:0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:5;

  &::after{
    content: '';
    position: absolute;
    inset: 0 0 0;
    backdrop-filter: blur(3px);
    z-index:-1;
  }
`

export const BoxCards = styled.div`
  display:flex;
  flex-direction: column;
  text-align: center;
  border-radius:6px;
  padding:16px;
  border:1px solid ${theme.COLORS.BLACK_300};
  color:${theme.COLORS.PRIM_800};
  font-size:16px;
  font-weight: 600;
  line-height: 120%;

  & > h3{
    font-size:14px;
    font-weight: 600;
  }

  small {
    display:block;
    font-weight: 400;
    line-height: 160%;
  }

  & > div + div{
    margin-top:20px;
  }

  & > button {
    font-size:18px;
    height:42px;
    padding-top:10px;

    &:disabled{
      opacity: 1;
      cursor:initial;
    }
  }
`
export const BoxValor = styled.div`
  padding:20px 0 0;
  font-size:30px;
  font-weight:600;
  line-height: 110%;
  height:80px;

  small{
    font-size:14px;
    font-weight: 600;
  }
`

export const BoxRelatorios = styled.div`
  min-height:80px;
  margin-bottom:25px;
`

export const BoxObs = styled.div`
  font-size:14px;
  margin-bottom:25px;

  & > p{
    margin-bottom:20px;
  }

  & > ul{
    margin: 5px 0 0;
    padding-left: 20px;
  }
`

export const BoxMock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border:1px solid ${theme.COLORS.BLACK_300};
  border-radius:6px;
`

export const BoxEditCard = styled.div`
  & #edit-card{
    display:none;
  }
`

export const BoxMigrationPlan = styled.div`
  padding: 20px;
  border:1px solid ${theme.COLORS.BLACK_300};
  border-radius:6px;
  margin-bottom: 24px;

  & h4{
    text-transform:uppercase;
  }

  & .box-btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:20px 0 0;
    gap:20px;
    
    & p{
      font-size:14px;
    }
    & div{
      display: flex;
      align-items: center;
      gap:20px;

      & button{
        width:130px;
      }
    }
  }
`

export const BoxMigrationInfo = styled.div`
  margin:20px 0 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & svg{
    fill: ${theme.COLORS.PRIM_700};
    width:24px;
    height:24px;
  }

  & table {
    width:45%;
    border-collapse:collapse;
    
    td{
      border:1px solid ${theme.COLORS.BLACK_200};
      padding: 12px 10px;
      text-align: center;
      vertical-align: middle;
      
      span{
        color: ${theme.COLORS.PRIM_700}
      }
    }

    thead{
      background: ${theme.COLORS.BLACK_100A};
      font-weight: 600;
    }
    tbody{
      font-size:24px;
      font-weight: 400;
    }
  }
` 