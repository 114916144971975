import { useContext, useEffect, useMemo, useState } from "react"
import { getUnits, getInstance } from "../services"
import moment from "moment";
import { IFilterReports, ReportsContext } from "../context";

export const dateInitial = moment(new Date()).format('YYYY-MM-DD')

export const defaultValuesFilter : IFilterReports = {
  idInstancia: '',
  idUnidade: '',
  periodo: 'Hoje',
  dataInicio: new Date(),
  dataFim: new Date(),
  tipo: "utilizados",
}

export const useBoxSearch : any = () => {
  const { nameUnity, setNameUnity } = useContext(ReportsContext)

  const [values, setValues] = useState<IFilterReports>(defaultValuesFilter)

  const periodo = ['Hoje', 'Ontem', 'Últimos 7 dias', 'Últimos 15 dias', 'Últimos 30 dias', 'Personalizado']

  const [listaPeriodo, setListaPeriodo] = useState<String[]>(periodo)

  const [nameUnit, setNameUnit] = useState<string>('')

  const [disabledUnits, setDisabledUnits] = useState(true)
  const [showDate, setShowDate] = useState(false)

  const idUser = localStorage.getItem('@mony:usuarioId') 
  const { data: instance, isLoading: loadInstance } = getInstance({ id: 'user'+ idUser })
  const { data: unidade, isLoading: loadUnidade } = getUnits({ idInstancia: values?.idInstancia, id: 'user'+ idUser })

  useEffect(() => {
    if(values.tipo === 'expirar'){
      setListaPeriodo(periodoAExpirar)
    }else{
      setListaPeriodo(periodo)
    }
  }, [values]);

  const singleInstance = useMemo(() => {
    if(instance != undefined && instance.length === 1){
      setValues((prevState) => ({ ...prevState, idInstancia: instance[0]?.idInstancia }))
    }
  }, [instance])
  singleInstance

  const singleUnits = useMemo(() => {
    
    unidade != undefined && unidade.length > 0
    ? setDisabledUnits(false)
    : setDisabledUnits(true)
    
    unidade != undefined && unidade.length === 1
      ? setValues((prevState) => ({ ...prevState, idUnidade: unidade[0].idUnidade }))
      : null

  }, [unidade, values.idInstancia])
  singleUnits
  

  const handleEmpresa = (e: any) => {
    const value = e.target.value

    if (value === 0) {
      setDisabledUnits(true)
    } else {
      setDisabledUnits(false)
    }

    setValues((prevState) => ({ ...prevState, idInstancia: value }))
  }

  const handleUnidade = (e: any) => {
    const value = e.target.value
    setValues((prevState) => ({ ...prevState, idUnidade: value }))
  }

  const calculateDate = (value: number, singleDay?: boolean) => {
    const now = new Date()
    const base = new Date()
    const date = new Date(now.setDate(now.getDate() - value))
    if (singleDay) {
      setValues((prevState) => ({ ...prevState, dataInicio: date, dataFim: date }))
    } else {
      setValues((prevState) => ({ ...prevState, dataInicio: date, dataFim: base }))
    }
  }

  const calculateDateAExpirar = (value: number) => {
    const now = new Date()
    const base = new Date()
    const date = new Date(now.setDate(now.getDate() + value))
    setValues((prevState) => ({ ...prevState, dataInicio: base, dataFim: date }))
  }
  
  const handlePeriodo = (e: any) => {
    const value = e.target.value
    if(values.tipo === 'expirar'){
      value === 'Próximos 15 dias' && calculateDateAExpirar(15)
    }else{
      value === 'Hoje' && calculateDate(0, true)
      value === 'Ontem' && calculateDate(1, true)
      value === 'Últimos 7 dias' && calculateDate(7)
      value === 'Últimos 15 dias' && calculateDate(15)
      value === 'Últimos 30 dias' && calculateDate(30)
      value === 'Personalizado' ? setShowDate(true) : setShowDate(false)
    }

    setValues((prevState) => ({ ...prevState, periodo: value }))
  }

  const handleSetStartDate = (value: any) => setValues((prevState) => ({ ...prevState, dataInicio: value }))
  const handleSetEndDate = (value: any) => setValues((prevState) => ({ ...prevState, dataFim: value }))

  const periodoAExpirar = ['Próximos 15 dias']

  const handleCancel = () => {
    setValues(defaultValuesFilter)
    setShowDate(false)
  }

  return {
    loadInstance, loadUnidade, values, setValues, setShowDate, nameUnit,
    disabledUnits, showDate, instance, unidade, periodo, listaPeriodo, periodoAExpirar, defaultValuesFilter,
    handleEmpresa, handleUnidade, handlePeriodo, handleSetStartDate, handleSetEndDate,
    handleCancel
  }
}