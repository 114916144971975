import styled from "styled-components";
import theme from "styles/theme";

export const InputTable = styled.input`
    border: none;
    background-color: ${theme.COLORS.WHITE};
    padding: 8px;
    font-size: 16px;
    font-family: "MontSerrat";
    color: ${theme.COLORS.PRIM_700};
    font-weight: bold;
    border-bottom: 1px solid ${theme.COLORS.PRIM_700};

    &:focus{
      outline: 0;
    }
`;

export const NudeButton = styled.button`
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
`

export const TermsContainer = styled.div`
    border: 1px solid ${theme.COLORS.BLACK_300};
    border-radius: 4px;
    height: 210px;
    padding: 8px;
    overflow: auto;
`;