import { createContext, useContext, useEffect, useState } from "react"
import api, { config } from "api"
import Button from "components/Button"
import FormControl, { FormControlMultLine } from "components/FormControl"
import { UploadFile } from "components/UploadFile"
import { Label, Row } from "styles/base"
import { GridDadosApp } from "../../Enterprise/style"
import { default as Modal } from 'components/modal/modal'
import { scrollToTop } from "util/utils"
import styled from "styled-components"
import theme from "styles/theme"
import { CircularProgress } from "@mui/material"

interface DataMonyProps { 
  value?: any, 
  setValue?: any, 
  hasEdit?: boolean, 
  hasControl?: boolean,
  data?: any,
}

const valuesDefault = {
  nomeFantasia:'',
  razaoSocial: '',
  descricao: '',
  logo: '',
  logoBlob: '',
}

interface DataMonyContextProps {
  imgValue: string,
  setImgValue: (e: string) => void,
}
export const DataMonyContext = createContext<DataMonyContextProps>({} as DataMonyContextProps)

export const DataMony = ({ ...rest }) => {
  const [imgValue, setImgValue] = useState('')
  
  return <DataMonyContext.Provider value={{ imgValue, setImgValue }}>
    <DataMonyComp {...rest} />
  </DataMonyContext.Provider>
}


export const DataMonyComp = ({ hasEdit = true, hasControl = true, data } : DataMonyProps) => {
  const { imgValue, setImgValue } = useContext(DataMonyContext)
  
  const [load, setLoad] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false);

  const [isEdit, setIsEdit] = useState(hasEdit);
  const [values, setValues] = useState(valuesDefault);
  const [open, setOpen] = useState(false);

  const imgUrl = (img: string) => `${config.UrlS3}/galeria/instancia/logos/${img}`;

  async function initData() {
    try {
      setLoad(true)
      const { data: dataEmpresa } = await api.get("/empresas/assinante")
      .finally(() => setLoad(false));
      // const { data: dataUser } = await api.get('/usuarios/atual');
      //setUserData(responseUserData.data);
      //return setFileImage(response.data.logo);
      // console.log('image api ', imgUrl(dataEmpresa.logo) )
      //setImgValue(imgUrl(dataEmpresa.logo))
      setValues({...dataEmpresa, logo: imgUrl(dataEmpresa.logo) })
    } catch (e) {
      //console.log(e);
    }
  }

  useEffect(() => {
    hasEdit && initData();
  }, []);

  useEffect(() => {
    if(data?.logo === ""){
      setImgValue('limpar')
      setValues(valuesDefault)
    }
  }, [data])

  const clearFile = () => {
     initData()
     setIsEdit(!isEdit)
  }

  const handleSave = async () => {   
    const formdata = new FormData();
    
    const data = new Blob([JSON.stringify({
      ...values,
      nomeFantasia: values.nomeFantasia,
      descricao: values.descricao
    })], {
      type: 'application/json',
    })
    
    if(values.logoBlob){
      formdata.append('logo', values.logoBlob);
    }
    formdata.append('dados', data);

    try {
      setLoad(true)
      await api.put('/empresas/assinante', formdata)
        .then(res => {
          if(res.status === 200){
            clearFile()
            setIsSuccess(true)
            return
          }

          scrollToTop({})
        })
        .finally(() => {
          setLoad(false)
        });
    } catch (error: any) {
      console.log(error);
    }
  }

  const handleModal = () => {
    setOpen(!open)
  }
  const resetValues = () => {
    clearFile()
  }

  return isSuccess ? <RowSuccess> 
    <Label size="16" bold>Atualização realizada com sucesso!</Label>
    <Button confirm text="ok" onClick={() => setIsSuccess(!isSuccess)} />
  </RowSuccess>
  :<>
    <Modal
      text="Deseja realmente sair? Os dados não salvos serão perdidos"
      open={open} 
      onClose={handleModal} 
      onYes={resetValues} />

    <Label size="16" bold>Atenção! As informações abaixo serão publicadas no App Mony para seus clientes!</Label>

    <GridDadosApp>
      <div className="desc">
        <div>
          <FormControl
            id="nome-app"
            text="Nome da empresa (App)"
            size={28}
            value={values.nomeFantasia}
            onChange={({ target } : any) => setValues((prevState) => ({
              ...prevState,
              nomeFantasia: target.value,
            }))}
            maxLength={40}
            disabled={isEdit}
          />
          <Label size="12" id="nome-app__erro" color={theme.COLORS.ERROR_800} />
        </div>
        <div>
          <FormControlMultLine
            id="descricao-app"
            text="Descrição da empresa (App)"
            row={4}
            value={values.descricao !== null ? values.descricao : ''}
            onChange={({ target } : any) => setValues((prevState) => ({
                ...prevState,
                descricao: target.value,
            }))}
            disabled={isEdit}
          />
          <Label size="12" id="descricao-app__erro" color={theme.COLORS.ERROR_800} />
        </div>
      </div>
      <div>
        {isEdit 
        ? <>
        <UploadFile
          values={values.logo}
          disabled={isEdit}
          />
        </>
        : <div> 
        <UploadFile
          // id="inputUpload"
          values={values.logo}
          setValues={(target: any) => {
            setValues((prevState) => ({...prevState, logoBlob: target }))
          }}
          // errorLabel={errorValues.dadosEmpresa.logo}
          hasLoadImg={false}
          />
        </div>
        }
      </div>
    </GridDadosApp>

    {hasControl
      &&  <RowButton>
      {isEdit ? <>
        <Button text="Editar" onClick={() => setIsEdit(!isEdit)} confirm loading={load}/>
      </> : <>
        <Button text="Cancelar" onClick={() => handleModal()} />
        <Button text="Salvar" onClick={() => handleSave()} confirm disabled={load} loading={load}/>
      </>
    }
    </RowButton> }
  </>
}

const RowSuccess = styled(Row)` 
  flex-direction: column;
  align-items: center;
  gap:20px;
` 
const RowButton = styled(Row)`
  padding:10px 0;
  gap:10px;
`