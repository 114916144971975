import { useEffect, useState } from "react";
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { getCep, getServiceType, putEnterprise } from "../service";
import { cepMask, replaceEspecials } from "util/masks";
import { isCnpjValid, isCpfValid, isEmailValid, isValidDDDPhone } from "util/validations";
import { IEnterprise } from "../List/useList";
import { string } from "yup/lib/locale";
import { useEnterpriseContext } from "../Context/enterpriseContext";
import api, { config } from "api";


export const defaultValues = {
  idPlanoSelecionado: 0,
  unidades: 0,
  dadosDiretor: {
    id: 0,
    nome: '',
    cpf: '',
    ddd: '',
    telefone: '',
    email: '',
    funcao: '',
    dataCadastro: '',
    idUnidade: ''
  },
  dadosEmpresa: {
    id: 0,
    cnpj: '',
    razaoSocial: '',
    nomeFantasia: '',
    logo: null,
    descricao: '',
    codigoPlano: '',
    instanciaIsAtiva: '',
  },
  dadosUnidadePrincipal: {
    nomeUnidade: '',
    cnpj: '',
    endereco: {
      cep: '',
      endereco: '',
      numero: '',
      bairro: '',
      complemento: '',
      cidade: '',
      uf: '',
    },
  },
};
const defaultValuesError = {
  idPlanoSelecionado: '',
  dadosDiretor: {
    id: '',
    nome: '',
    cpf: '',
    ddd: '',
    telefone: '',
    email: '',
    emailConfirmation: '',
  },
  dadosEmpresa: {
    id: '',
    cnpj: '',
    razaoSocial: '',
    nomeFantasia: '',
    logo: '',
    descricao: '',
    codigoPlano: '',
  },
  dadosUnidadePrincipal: {
    nomeUnidade: '',
    cnpj: '',
    endereco: {
      cep: '',
      endereco: '',
      numero: '',
      bairro: '',
      complemento: '',
      cidade: '',
      uf: '',
    },
  },
};
const defaultDisabledEnd = {
  endereco: false,
  bairro: false,
  cidade: false,
  uf: false,
}

const schema = yup.object({
  planos: yup.string()
    .test('Planos', 'Campo obrigatório', (value): any => (value !== '0' ? true : false)),
  nomeEmpresa: yup.string().required('Campo obrigatório'),
  cnpj: yup.string().required('Campo obrigatório')
    .test('Invalido', 'CNPJ inválido', (value): boolean => (value ? isCnpjValid(replaceEspecials(value)) : false)),
  nomeEmpresaAPP: yup.string().required('Campo obrigatório'),
  nomeDiretor: yup.string().required('Campo obrigatório'),
  cpfDiretor: yup.string().required('Campo obrigatório')
    .test('Invalido', 'CPF inválido', (value): boolean => (value ? isCpfValid(replaceEspecials(value)) : false)),
  telDiretor: yup.string().required('Campo obrigatório')
    .test('Invalido', 'Telefone inválido', (value): boolean => (value ? isValidDDDPhone(replaceEspecials(value)) : false)),
  emailDiretor: yup.string().required('Campo obrigatório').email()
    .test('Invalido', 'E-mail inválido', (value): boolean => (value ? isEmailValid(value) : false)),
  emailConfirmacao: yup.string().required('Campo obrigatório').email()
    .oneOf([yup.ref('emailDiretor')], 'E-mail de confirmção é diferente'),
  logo: yup.string().required('Campo obrigatório'),
  // logo: yup.mixed()
  //   .test("fileSize", "The file is too large", (value) => {
  //   if (!value.length) return true // attachment is optional
  //   return value[0].size <= 50
  // }).required('Campos obrigatório'),
  nomeUnidade: yup.string().required('Campo obrigatório'),
  cep: yup.string().required('Campo obrigatório'),
  logradouro: yup.string().required('Campo obrigatório'),
  numero: yup.string().required('Campo obrigatório'),
  bairro: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  estado: yup.string().required('Campo obrigatório'),
})

const usePlanos = () => {
  const { isLoading, error, data } = useQuery(['repoData'], () =>
    getServiceType()
      .then(res => res.data)
      .catch(error => console.log(error))
    , { staleTime: ((60 * 1000) * 60) * 24, cacheTime: 10 })

  const listPlanos = isLoading ? [{ nome: 'aguarde ...' }] : data

  return { isLoading, error, listPlanos }
}

const useFormEnterprise = () => {
  const { setErrorGeral } = useEnterpriseContext()

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    // mode: 'onChange',
    // defaultValues: values,
    resolver: yupResolver(schema)
  });

  const [values, setValues] = useState(defaultValues);
  const [cepInput, setCepInput] = useState('')

  const [errorValues, setErrorValues] = useState(defaultValuesError);
  const [open, setOpen] = useState(false);

  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [disabledEnd, setDisabledEnd] = useState(defaultDisabledEnd)

  const handleModal = () => setOpen(!open)

  const handleCancel = () => {
    reset()
    setValues(defaultValues)
    setErrorValues(defaultValuesError)
    setEmailConfirmation('')
    setCepInput('')
    resetEnd()
  }

  const handleSave = (data: any) => {
    console.log('passei', data, values)
  }

  const checkCep = async (e: string) => {
    getCep(e)
      .then(res => {
        const response = res.data
        
        setValues({
          ...values,
          dadosUnidadePrincipal: {
            ...values.dadosUnidadePrincipal,
            endereco: {
              ...values.dadosUnidadePrincipal.endereco,
              cep: cepMask(response.exploded.zip),
              endereco: response.exploded.fullAddress,
              cidade: response.exploded.city.name,
              uf: response.exploded.state.shortName,
              bairro: response.recovered.recoveredAddress[0].quarterA,
            },
          },
        });
      }).catch(error => {
        setErrorValues({
          ...errorValues,
          dadosUnidadePrincipal: {
            ...errorValues.dadosUnidadePrincipal,
            endereco: {
              ...errorValues.dadosUnidadePrincipal.endereco,
              cep: error.response.data.message,
            }
          }
        })
      })

    try {


      //   cep.style.borderColor = theme.COLORS.PRIM_800;
      //   cepErro.innerHTML = '';

      //   if (logradouro.value) {
      //     logradouro.setAttribute('disabled', 'true');
      //   } else {
      //     logradouro.removeAttribute('disabled')
      //   }

      //   if (bairro.value) {
      //     bairro.setAttribute('disabled', 'true');
      //   } else {
      //     bairro.removeAttribute('disabled')
      //   }

      //   if (cidade.value) {
      //     cidade.setAttribute('disabled', 'true');
      //   }

      //   if (estado.value) {
      //     estado.setAttribute('disabled', 'true');
      //   }

      //   numero.removeAttribute('disabled');
      //   complemento.removeAttribute('disabled');

      //   validationCancelEnd()
    } catch (error) {
      // cep.style.borderColor = theme.COLORS.ERROR_700;
      // cepErro.innerHTML = 'CEP inválido';

      // logradouro.setAttribute('disabled', 'true');
      // complemento.setAttribute('disabled', 'true');
      // bairro.setAttribute('disabled', 'true');
      // numero.setAttribute('disabled', 'true');
      // cidade.setAttribute('disabled', 'true');
      // estado.setAttribute('disabled', 'true');

    }
  };
  const resetEnd = () => {
    setValues({
      ...values,
      dadosUnidadePrincipal: {
        ...values.dadosUnidadePrincipal,
        endereco: {
          ...values.dadosUnidadePrincipal.endereco,
          cep: '',
          endereco: '',
          numero: '',
          bairro: '',
          complemento: '',
          cidade: '',
          uf: '',
        },
      },
    });
    setErrorValues({
      ...errorValues,
      dadosUnidadePrincipal: {
        ...errorValues.dadosUnidadePrincipal,
        endereco: {
          ...errorValues.dadosUnidadePrincipal.endereco,
          cep: '',
        },
      },
    })
  }

  return {
    register, handleSubmit, errors,
    values, setValues, defaultValues, defaultValuesError, open,
    errorValues, setErrorValues, cepInput, setCepInput,
    emailConfirmation, setEmailConfirmation, disabledEnd,
    handleModal, handleCancel, handleSave, checkCep, resetEnd
  }

}




const useFormUpdate = () => {
  const { setErrorGeral } = useEnterpriseContext()
  const [editEmail, setEditEmail] = useState(false)

  const [load, setLoad] = useState(false)
  const [errorValues, setErrorValues] = useState(defaultValuesError)
  const [values, setValues] = useState(defaultValues)
  const [open, setOpen] = useState(false)
  const [emailConfirmation, setEmailConfirmation] = useState<string>('');
  const [fullTel, setFullTel] = useState('');

  const isLabeError = (value: string) => {
    const label = document.querySelector(`${value}`)
    return label?.innerHTML
  }

  const isValidLogo: () => boolean = () => {
    if (isLabeError('label.error-upload')) {
      setErrorValues((prevState) => ({
        ...prevState,
        dadosEmpresa: { ...prevState.dadosEmpresa, logo: 'Campo obrigatório' }
      }))
      return true
    }

    setErrorValues((prevState) => ({
      ...prevState,
      dadosEmpresa: {
        ...prevState.dadosEmpresa,
        logo: ''
      }
    }))

    return false
  }
  const isValidEmail: (e: any) => boolean = (e) => {

    if (e.dadosDiretor.email === '') {
      setErrorValues((prevState) => ({
        ...prevState,
        dadosDiretor: { ...prevState.dadosDiretor, email: 'Campo obrigatório' }
      }))
      return true
    }

    if (!isEmailValid(e.dadosDiretor.email)) {
      setErrorValues((prevState) => ({
        ...prevState,
        dadosDiretor: { ...prevState.dadosDiretor, email: 'E-mail inválido', }
      }))
      return true
    }

    setErrorValues((prevState) => ({
      ...prevState,
      dadosDiretor: { ...prevState.dadosDiretor, email: '' }
    }))
    return false
  }

  const isValidEmailConfirmation: (e: any) => boolean = (e) => {
    if (!editEmail) {
      return false
    }
    if (emailConfirmation === '') {
      setErrorValues((prevState) => ({
        ...prevState,
        dadosDiretor: { ...prevState.dadosDiretor, emailConfirmation: 'Campo obrigatório' }
      }))
      return true
    }
    if (!isEmailValid(emailConfirmation)) {
      setErrorValues((prevState) => ({
        ...prevState,
        dadosDiretor: { ...prevState.dadosDiretor, emailConfirmation: 'E-mail inválido', }
      }))
      return true
    }
    if (values.dadosDiretor.email !== emailConfirmation) {
      setErrorValues((prevState) => ({
        ...prevState,
        dadosDiretor: { ...prevState.dadosDiretor, emailConfirmation: 'E-mails não são iguais, tente novamente' }
      }))
      return true
    }

   
    setErrorValues((prevState) => ({
      ...prevState,
      dadosDiretor: { ...prevState.dadosDiretor, emailConfirmation: '' }
    }))
    return false
  }


  const isValid = (e: any) => {
    const arrayIsValid = [isValidLogo(), isValidEmail(e), isValidEmailConfirmation(e)]
    const respIsValid = arrayIsValid.includes(true)

    return respIsValid //validLogo && validEmail && validConfirmEmail
  }

  const handleUpdate = async (e: any) => {
    const value: IEnterprise = e

    const dadosDiretor = new Blob([JSON.stringify({
      id: value.dadosDiretor.id,
      nome: value.dadosDiretor.nome,
      cpf: value.dadosDiretor.cpf,
      ddd: value.dadosDiretor.ddd,
      telefone: value.dadosDiretor.telefone,
      email: value.dadosDiretor.email,
    })], {
      type: 'application/json',
    });

    const dadosEmpresa = new Blob([JSON.stringify({
      id: (value.dadosEmpresa.id).toString(),
      cnpj: values.dadosEmpresa.cnpj,
      codigoPlano: values.dadosEmpresa.codigoPlano,
      idPlanoSelecionado: values.idPlanoSelecionado,
      descricao: values.dadosEmpresa.descricao,
      nomeFantasia: values.dadosEmpresa.nomeFantasia,
      razaoSocial: values.dadosEmpresa.razaoSocial
    })], {
      type: 'application/json',
    });

    const formdata = new FormData()

    formdata.append('dadosDiretor', dadosDiretor)
    formdata.append('dadosEmpresa', dadosEmpresa)
    if (typeof value.dadosEmpresa.logo !== 'string') {
      formdata.append('logo', value.dadosEmpresa.logo || '')
    }

    return api.put('/empresas', formdata)
  }

  const handlePaste = (e: any) => {
    e.preventDefault()

    setErrorValues((prevState) => ({
      ...prevState,
      dadosDiretor: { ...prevState.dadosDiretor, emailConfirmation: 'Necessário digitar o email de confirmação' }
    }))

    setTimeout(() => {
      setErrorValues((prevState) => ({
        ...prevState,
        dadosDiretor: { ...prevState.dadosDiretor, emailConfirmation: '' }
      }))
    }, 1000 * 10) // 10 seg 
  }

  return {
    values, open, errorValues, load,
    emailConfirmation, setEmailConfirmation,
    fullTel, setFullTel, 
    setValues, setOpen, setErrorValues, setLoad,
    isValid, handleUpdate, handlePaste,
    editEmail, setEditEmail
  }
}
export { useFormEnterprise, useFormUpdate, usePlanos } 