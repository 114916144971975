import { Accordion } from "components/Accordion"
import { AppBody } from "styles/base"
import { useQueryString } from "util/utils"
import TitlePage from "components/TitlePage"

import { IReports } from "./services/types"
import { Credits } from "./CreditsReports"

const Reports = ({ }: IReports) => {

  const query = useQueryString() 
  const tipo = query.get('tipo')

  return <AppBody>
    <TitlePage title="Relatórios" />

    <Accordion titulo='Cashback  utilizados' isActive={tipo === "utilizados" || tipo === null ? true : false}>
      <Credits tipo="utilizados" />
    </Accordion>


    <Accordion titulo='Cashback  distribuídos' isActive={tipo === "distribuidos" ? true : false}>
      <Credits tipo="distribuidos" />
    </Accordion>


    <Accordion titulo='Cashback  a expirar' isActive={tipo === "expirar" ? true : false}>
      <Credits tipo="expirar" />
    </Accordion> 

    <Accordion titulo='Cashback  expirados' isActive={tipo === "expirados" ? true : false}>
      <Credits tipo="expirados" />
    </Accordion> 
    
  </AppBody>
}
export { Reports }