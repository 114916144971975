import React from "react";

export const regex = {
  spaces: / +/gi,
  and: /&+/gi,
  slashes: /\/+/gi,
  not_digits: /[^0-9]+/gi,
  not_alpha: /[^-'a-zA-ZÀ-ÖØ-öø-ſ ]+/gi,
  not_alphanumeric: /[^-'0-9a-zA-ZÀ-ÖØ-öø-ſ ]+/gi,
  not_date_chars: /[^0-9/]/gi,
  name: /[^a-zA-ZÀ-ÖØ-öø-ſ ]+/gi,
  postal: /^(\d{5})-?(\d{1,3})/,
  tel: /^(\d{2})(\d{1,5})?(\d{4})?$/,
  rg: /^(\d{1,2})(\d{1,3})?(\d{1,3})?(\d)?$/,
  cnpj: /^(\d{2})(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d{1,2})?/,
  cpf: /^(\d{1,3})?(\d{1,3})?(\d{1,3})?(\d{1,2})?/,
  date: /^(\d\/|\d{2})\/*(\d\/|\d{2})?\/*(\d{1,4})?/,
  sus: /^(\d{0,3}) ?(\d{0,4}) ?(\d{0,4}) ?(\d{0,4})$/,
};

export const number = (val: string) => val.replace(regex.not_digits, "");

export const name = (val: string) => val.replace(regex.name, "");

export const letter = (val: string) => val.replace(regex.not_alpha, "");

export const alpha = (val: string) => val.replace(regex.not_alphanumeric, "");

export const cpfMask = (value: string) =>
  value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

export const cnpjMask = (value: string) =>
  value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2");

export const cnpjMaskNew =(value: string) => 
  value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d{2})\d+?$/, "$1-$2");

export const phoneMask = (value: string) =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");

export const cepMask = (value: string) =>
  value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2");

export const e = (value: string) => {
  const digits = value;

  if (digits.length < 3) {
    return digits;
  }

  const integer = digits.slice(0, digits.length - 2);
  const decimal = digits.slice(digits.length - 2);

  return `${integer},${decimal}`;
};

export const moneyMask = (value: string) => {
  value = value.replace(".", "").replace(",", "").replace(/\D/g, "");

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat("pt-BR", options).format(
    parseFloat(value) / 100
  );

  return "R$ " + result;
};

export const currencyMask = (data: string): string => {
  if (data) {
    let value = data;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    return value;
  }

  return "";
};
export const NormalizeMoney = (value : number) => ((value).toFixed(2)).toString()

export const replaceEspecials = (str: string) => {
  if (str === null) {
    return "";
  }

  const especials = [{ char: "", base: /[./_@+=() R$-]/g }];

  return especials.reduce(
    (str, letter) => str.replace(letter.base, letter.char),
    str
  );
};



export const cardMask = (value: string) => 
  value
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(-\d{4})(\d+?)$/, "$1 $2"); 

export const cardMaskDiners = (value: string) => 
  value
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{6})(\d)/, "$1 $2")
    .replace(/(-\d{4})(\d+?)$/, "$1 $2"); 

export const cardMaskAmex = (value: string) => 
    value
      .replace(/\D/g, "")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{6})(\d)/, "$1 $2")
      .replace(/(-\d{5})(\d+?)$/, "$1 $2"); 

export const monthYearMask = (value: string) => 
  value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1/$2")