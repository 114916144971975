import { useInfiniteQuery } from '@tanstack/react-query';
import api from 'api/index';

export function getServiceType(planosPagos?: boolean) {
  return planosPagos == undefined ? api.get('/planos?tipoInstancia=Pontos&somentePlanosPagos=true') : 
  api.get(`/planos?tipoInstancia=Pontos&somentePlanosPagos=${planosPagos}`); 
}

export function getListEnterprise(qtd: number, value: string) {
  return api.get(`/empresas?page=0&size=${qtd}${value && `&${value}`}`)
}

export const getListEnterprise_temp = ({ page = 0 , filter } : { page: number, filter: string }) => {
  const apiReports = async () => {
    return api.get(`/empresas?page=${page}&size=10${filter && `&${filter}`}`)
    .then(res => res.data)
  }

  const { data, error, fetchNextPage, hasNextPage, isFetching, 
    isFetchingNextPage, isFetchingPreviousPage, status, isLoading } = useInfiniteQuery({
    queryKey: ['listEnterprise', page, filter],
    queryFn: apiReports,
    refetchOnWindowFocus: false,
    getPreviousPageParam: (lastPage: any, pages: any) => lastPage.nextCursor,
    getNextPageParam: (lastPage: any, pages: any) => lastPage.nextCursor,
    cacheTime:(1000 * 60) * 10, // 10min
    staleTime:10, // 10 millesegundos
  })
  
  return { data: data?.pages[0], error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isFetchingPreviousPage, status, isLoading   }
}


export function getListEnterpriseUnits(value: number) {
  return api.get(`/unidades?page=0&size=10&idInstancia=${value}`)
}
export function getEnterpriseUnit(value: number) {
  return api.get(`/empresas/assinante?idInstancia=${value}`)
}

export function getUsuarioProprietario(idEmpresa: number, idDiretor: number){
  return api.get(`/usuarios/obter-dados-proprietario-por-instancia?idDadosEmpresa=${idEmpresa}&idDadosDiretor=${idDiretor}`)
}

//  DELETAR 
export function putEnterprise(formdata: any) {
  const token = localStorage.getItem("@mony:token");

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append('Content-Type', 'multipart/form-data')

  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow',
  };
  console.log(requestOptions)
  //@ts-ignore
  const respose = fetch(`${process.env.REACT_APP_API_URL}/empresas`, requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

  return respose //api.put(`/empresas`, value)
}
// DELETAR

export function getCep(data: any) {
  return api.get(`/endereco/${data}`)
}