import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import LogoPng from '../../assets/icons/logo.png';
import Button from '../../components/Button';
import { ErrorAlert } from '../../components/ErrorAlert';
import FormControl from '../../components/FormControl';
import { InputNumberFormat } from '../../components/PasswordFormat';
import { useAuth } from '../../hooks/auth';
import { Spacing } from '../../styles/base';
import { consoleStringify } from '../../util/console';
import { cpfMask, replaceEspecials } from '../../util/masks';
import NewPassword from './newPassword/NewPassword';
import { getInstancias } from './service';
import { LoginWrapper, ResetMessage, BoxInput, BoxBtnLogin, BoxLink, BoxForm } from './style';
import { Version } from 'components/Version';

const defaultValues = {
  cpf: '',
  password: '',
  empresa: '',
};

export const Logo = () => {
  return <img src={LogoPng} alt="Logo" style={{ width: '90%', margin: 'auto' }} />
}

const Login = () => {
  const { signIn } = useAuth();
  const history = useHistory();

  const [values, setValues] = useState(defaultValues);
  const [resetPassword, setResetPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [resetMessage, setResetMessage] = useState(false);
  const [listInstances, setListInstances] = useState([]);
  const [error, setError] = useState('');

  const [load, setLoad] = useState(false);

  const cpfInput = (document.querySelector('#input-cpf') as HTMLInputElement);
  const passwordInput = (document.querySelector('#input-password') as HTMLInputElement);

  const handleInstance = async () => {
    try {
      setLoad(true)
      setValues({
        ...values,
        empresa: '',
      });
      const response = await getInstancias(replaceEspecials(values.cpf));

      if (response.data.length === 1) {
        setValues({
          ...values,
          empresa: response.data[0].cdInstancia,
        });
      }
      setLoad(false)
      return setListInstances(response.data);
    } catch (err: any) {
      consoleStringify(err);
    }
  };
  const handleLogin = useCallback(async () => {
    try {
      if (listInstances.length > 1 && !values.empresa) {
        setError('A seleção da empresa é obrigatória');
        return;
      }

      if (!values.empresa) {
        setError('Login ou senha estão incorretos');
        return;
      }

      setLoad(true)
      const resp = await signIn({
        username: replaceEspecials(values.cpf) || replaceEspecials(cpfInput.value),
        password: values.password || passwordInput.value,
        'adm-pop': true,
        cdInstanciaSelecionado: values.empresa,
      });
      setLoad(false)      
      
      if (resp === 'Error') {
        setLoad(false)
        return setError('Login ou senha estão incorretos.');
      }
      
      history.push('/home');
    } catch (err: any) {
      if (!err.response) {
        return setError('Login ou senha estão incorretos.');
      }

      if (err.response.data.message === 'É necessário alterar a senha gerada para uma escolhida pelo usuário.') {
        setNewPassword(true);
      }
      
      //console.log(err.response.data.message);
      setError(err.response.data.message);
      setLoad(false)
    }
  }, [history, values]);
  
  const handleEnterLogin = (e : any) => e.key === 'Enter' && handleLogin()
  
  useEffect(() => {
    if (values.cpf.length === 14) {
      handleInstance();
    }

    if (values.cpf.length !== 14) {
      setListInstances([]);
    }

    return () => {
      setLoad(false)
      setListInstances([])
    }
  }, [values.cpf]);


  if (resetPassword) {
    return (
      <LoginWrapper>
        <Logo />

        <Spacing margin={20} />

        <FormControl
          constract
          text="CPF"
          value={values.cpf}
          onChange={(({ target }: any) => setValues({
            ...values,
            cpf: cpfMask(target.value),
          }))}
        />

        {resetMessage ? (
          <>
            <Spacing margin={14} />

            <ResetMessage>
              Redefinição de senha enviada para o e-mail #emailcadastrousuario#.
              Lembre-se de conferir no SPAM, pois o e-mail pode estar lá.
            </ResetMessage>
          </>
        ) : null}

        <Spacing margin={30} />

        {resetMessage
          ? (<Button loginSubmit text="Ok" onClick={() => console.log('oi')} />)
          : (<Button loginSubmit text="Redefenir senha" disabled={!values.cpf} onClick={() => setResetMessage(true)} />)}

      </LoginWrapper>
    );
  }

  if (newPassword) {
    return (
      <NewPassword username={values.cpf} password={values.password} empresa={values.empresa} />
    )
  }
  
  return (<>
    <Version />
    <LoginWrapper>
        <Logo />

        {error 
        ? <><br/><ErrorAlert message={error} isMarginTop={false} /><br/></> 
        : <Spacing margin={20} /> }

        <BoxForm onSubmit={e => e.preventDefault()}>
          <BoxInput onKeyDown={handleEnterLogin}>    
            <FormControl
              constract
              id="input-cpf"
              text="CPF"
              value={values.cpf}
              autoComplete="off"
              onChange={(({ target }: any) => {
                setValues({
                  ...values,
                  cpf: cpfMask(target.value),
                });
                setError('');
              })}
            />
          </BoxInput>
          
          {listInstances && listInstances.length > 1 ? (
            <FormControl
              constract
              select
              text="Selecione a empresa:"
              options={listInstances}
              value={values.empresa}
              onChange={({ target }) => setValues({
                ...values,
                empresa: target.value,
              })}
            />
          ) : null}
          
          <BoxInput onKeyDown={handleEnterLogin}>
            <InputNumberFormat
              id="input-password"
              label="Senha"
              format="######"
              lineInput
              type="password"
              value={values.password}
              onValueChange={(data: any) => {
                setValues({
                  ...values,
                  password: data,
                });
                setError('');
              }}
            />
          </BoxInput>
        </BoxForm>

        <BoxLink to={'/esqueci-minha-senha'}>
          Esqueci minha senha
        </BoxLink>

        <Spacing margin={15} />

        <BoxBtnLogin>
          <Button
            loginSubmit 
            text="Entrar" 
            disabled={!cpfInput?.value || !passwordInput?.value} 
            onClick={() => handleLogin()} 
            loading={load}
          />          
        </BoxBtnLogin>
        
    </LoginWrapper>
  </>
  );
};


export default Login;
