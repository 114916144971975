import styled from "styled-components";
import theme from "styles/theme";

export const BoxCkbSwitch = styled.div`
  cursor:pointer;
  display:inline-flex;
  align-items:center;
  width:74px;
  height:32px;
  padding:5px 12px;
  border-radius:50px;
  font-size:12px;
  font-weight: 600;
  font-weight:500;
  color:${theme.COLORS.PRIM_800};
  background:${theme.COLORS.SEC_800};
  border:3px solid ${theme.COLORS.SEC_800};
  position:relative;
  text-transform: uppercase;

  & > div{
    position: absolute;
    width:24px;
    height:24px;
    border-radius:50%;
    background:${theme.COLORS.SEC_800};
    border: 3px solid ${theme.COLORS.PRIM_800};
  }
  
  &.active-off{
    color:${theme.COLORS.BLACK_300};
    background:${theme.COLORS.WHITE};
    border:3px solid ${theme.COLORS.BLACK_300};
    justify-content:end;

    & > div{
      background:${theme.COLORS.BLACK_300};
      border:3px solid ${theme.COLORS.BLACK_400};
    }
  }
  &.active-on > div{
    right:1px;
  }
  &.active-off > div{
    left:1px;
  }
`