import styled from 'styled-components';
import theme from 'styles/theme';

export const ErrorAlert = styled.div`
    border: 1px solid  ${theme.COLORS.ERROR_100};
    border-radius: 6px;
    background-color: ${theme.COLORS.ERROR_100A} ;
    box-shadow: inset 0 0 4px 1px ${theme.COLORS.BLACK_800A};
    padding: 8px 30px;
    width: 95%;

    > span {
        letter-spacing: 0;
        line-height: 24px;
        font-weight: bold;
        color: ${theme.COLORS.WHITE};
        font-size: 1.1em;
    }
`;

export const CreditWrapper = styled.div`
    background-color: ${theme.COLORS.PRIM_800};
    display: flex;
    align-items: center;
    border-radius: 2px;
    padding: 4px 18px;


    .loading-style {
      margin-left: 8px;
    }
`;
