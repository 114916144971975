import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { BoxBtns, BoxDate, ButtonSearch, SearchBox, SelectBox } from "../styles";
import { useBoxSearch } from "./useBoxSearch";

import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import DatePicker from 'react-datepicker';
import ptBR from "date-fns/locale/pt-BR";
import { useEffect } from "react";
import theme from "styles/theme";

import { BarSearchProps } from "../services/types";
import Button from "components/Button";

export const BarSearch = ({ onClick, valueDefault, tipo }: BarSearchProps) => {
  const {
    loadInstance, loadUnidade, values, setValues, setShowDate, nameUnit,
    disabledUnits, showDate, instance, unidade, periodo, listaPeriodo, periodoAExpirar, defaultValuesFilter,
    handleEmpresa, handleUnidade, handlePeriodo, handlePeriodoAExpirar, handleSetStartDate, handleSetEndDate,
    handleCancel
  } = useBoxSearch()

  useEffect(() => {
    setValues({
      idInstancia: valueDefault.idInstancia || values.idInstancia, 
      idUnidade: valueDefault.idUnidade || values.idUnidade,
      periodo:  valueDefault.periodo || values.periodo,
      dataInicio: new Date(valueDefault.dataInicio) || values.dataInicio, 
      dataFim:  new Date(valueDefault.dataFim) || values.dataFim,
      tipo: tipo
    })  
    valueDefault?.periodo === 'Personalizado' ? setShowDate(true) : null;
  },[valueDefault])

  const handleClearSearch = () => {
    handleCancel()
    onClick(defaultValuesFilter)
  }

  const handleSearch = () => onClick(values)

  const styleInstance = instance?.length > 1 && showDate ? 'hasInstancia' : '' 

  return <SearchBox  className={styleInstance}>
    { instance?.length > 1 &&
    <SelectBox
      value={values?.idInstancia || ''}
      label="Filtrar empresa"
      style={{ width: 260 }}
      onChange={handleEmpresa}
      // disabled={disableEnterprise}
    >
      {instance === undefined && <MenuItem value=""></MenuItem>}
      {instance?.length > 1 && <MenuItem value="">Selecione</MenuItem>}
      {instance?.map((i : any, idx : number) => <MenuItem key={idx} 
        value={i.idInstancia} 
        selected={instance?.length === 0 ? true : false}>
          {i.nome}
        </MenuItem>)}
    </SelectBox>}

    <SelectBox
      value={values?.idUnidade || ''}
      label="Filtrar unidade"
      style={{ width: 190 }}
      onChange={handleUnidade}
      disabled={disabledUnits}
    >
      {unidade === undefined && <MenuItem value=""></MenuItem>}
      {unidade?.length > 1 && <MenuItem value="">Selecione</MenuItem>}
      {unidade?.map((i : any) => <MenuItem key={i.idUnidade +'-'+ i.nomeUnidade} 
        title={i.nomeUnidade} 
        value={i.idUnidade}>
          {i.nomeUnidade}
        </MenuItem>)}
    </SelectBox>

    <SelectBox
      value={values?.periodo}
      label="Filtrar período"
      style={{ width: 200 }}
      onChange={handlePeriodo}
    >
      {listaPeriodo.map((i : any) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
    </SelectBox>
 
    {showDate
      ? <BoxDate>
        <div>
          <DatePicker
            dateFormat={'dd/MM/yyyy'}
            placeholderText={'dd/MM/yyyy'}
            locale={ptBR}
            autoComplete='off'
            customInput={<TextField
              InputProps={{ endAdornment: <Calendar width={25} /> }}
              fullWidth
              label="Data Inicio"
              inputProps={{ style: { fontFamily: 'Montserrat' } }}
              InputLabelProps={{ shrink: true, style: { fontSize: 20, marginTop: "-0.8rem", fontFamily: "MontSerrat", color: theme.COLORS.PRIM_800 } }}
              variant="outlined"
            // error={!!selectErrorMsg.dateStart}
            // helperText={!!selectErrorMsg.dateStart && selectErrorMsg.dateStart}
            />}
            selected={values?.dataInicio}
            onChange={handleSetStartDate}
          />
        </div>
        <div>
          <DatePicker
            dateFormat={'dd/MM/yyyy'}
            placeholderText={'dd/MM/yyyy'}
            locale={ptBR}
            autoComplete='off'
            customInput={<TextField
              InputProps={{ endAdornment: <Calendar width={25} /> }}
              fullWidth
              label="Data Final"
              inputProps={{ style: { fontFamily: 'Montserrat' } }}
              InputLabelProps={{ shrink: true, style: { fontSize: 20, marginTop: "-0.8rem", fontFamily: "MontSerrat", color: theme.COLORS.PRIM_800 } }}
              variant="outlined"
            // error={!!selectErrorMsg.dateStart}
            // helperText={!!selectErrorMsg.dateStart && selectErrorMsg.dateStart}
            />}
            selected={values?.dataFim}
            onChange={handleSetEndDate}
          />
        </div>
      </BoxDate>
      : null}

    <BoxBtns className={styleInstance}>
      <ButtonSearch onClick={() => handleSearch()}>Buscar</ButtonSearch>
      <Button text="Limpar filtro" onClick={() => handleClearSearch()}></Button>
    </BoxBtns>
    {loadInstance || loadUnidade ? <CircularProgress /> : null}
  </SearchBox>
}
