import React, { InputHTMLAttributes, useCallback, useState } from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import { Column } from '../../styles/base';
import { FormInput } from '../FormControl/styles';
import { FormLabel } from '../Input/styles';
import {  cep, cpf, currency, numberOnly, telMask } from './mask';
import { ContaionerInputGroupPrefix } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  // eslint-disable-next-line react/require-default-props
  prefix?: string;
  label: string;
  registerField?: Path<any>;
  register?: UseFormRegister<any>;
  placeholder: string;
  mask: 'cep' | 'currency' | 'cpf' | 'cel' | "num" | "";
  onChange?: any;
  clearErrors(): void;
}
// eslint-disable-next-line react/function-component-definition
export function InputMask({
  label, registerField, register, mask, prefix, disabled, clearErrors, onChange, ...rest
}: Props) {
  const [isFocused, setIsFocused] = useState(false);

  function handleInputFocus() {
    setIsFocused(true);
    // clearErrors();
  }

  function handleInputBlur() {
    setIsFocused(false);
  }

  const handleKeyUp = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (mask === 'cep') {
        cep(e);
      }
      if (mask === 'currency') {
        currency(e, prefix);
      }
      if (mask === 'cel') {
        telMask(e);
      }
      if (mask === 'cpf') {
        cpf(e);
      }
      if (mask === ''){
        e;
      }
    },
    [mask],
  );

  return (
    <Column>
      <FormLabel disabled={disabled} isFocused={isFocused}>{label}</FormLabel>
      <ContaionerInputGroupPrefix>
        <FormInput
          {...rest}
          {...registerField && register && register(registerField)}
          onBlurCapture={() => handleInputBlur()}
          onChangeCapture={(e : any) => {
            clearErrors()
            onChange(e)
          }}
          onChange={(e) => numberOnly(e)}
          onFocus={() => handleInputFocus()}
          onKeyUp={handleKeyUp}
          disabled={disabled}
        />
      </ContaionerInputGroupPrefix>

    </Column>
  );
}
