import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactLoading from 'react-loading';
import Button from '../../../components/Button';
import { ErrorAlert } from '../../../components/ErrorAlert';
import FormControl from '../../../components/FormControl';
import { FormRow } from '../../../components/FormControl/styles';
import { InputNumberFormat } from '../../../components/NumberFormat';
import FormatInputValueFunction from '../../../components/NumberFormat/FormatInputValueFunction';
import {  AppBody, Bold, Column, DataWrapper, Label, Row, Spacing } from '../../../styles/base';
import { consoleStringify } from '../../../util/console';
import { FormatCurrency } from '../../../util/formatter';
import { letter, number, replaceEspecials } from '../../../util/masks';
import { ReactComponent as Check } from '../../../assets/icons/check.svg';
import { schema } from '../Components/FomValidation';
import { FormClient, IFormInput } from '../Components/FormClient';
import { fetchIP, getCashback, getUnidade, getUsuarios, getVendedores, launchPurchase, postRegister, sendPin, usuarioTel } from '../service';
import { CreditWrapper } from '../style';
import { getUserData } from 'components/AppHeader/service';
import { ResponseUsuarioAtual } from './Account/model/ResponseUsuarioAtual';
import { AuthorityEnum } from 'components/AppHeader/model/Authority';
import { useAuth } from 'hooks/auth';
import { StatefulPinInput } from 'react-input-pin-code';
import CountDown from 'components/CountDown/CountDown';
import TitlePage from 'components/TitlePage';
import { UsuarioModel } from '../Models';
import styled from 'styled-components';
import { getAccessionCurrent } from './Account/services';
import { IAccession } from './Account/services/types';
import theme from 'styles/theme';
import { default as Modal } from 'components/modal/modal'
import Success from './sucess/Success';

const defaultValues = {
  ddd: '',
  telefone: '',
  nome: '',
  nomeCadastro: '',
  vendedor: '',
  nomeVendedor: '',
  valorCompra: 0,
  pin: '',
  valorCashback: 0,
  loginUsuarioFavorecido: '',
  saldo: '',
};
interface IUsuario {
  idUsuario: number,
  codigoInscricaoFederal: null | number,
  nome: string,
  login: string,
  email: string,
  instanciaVO: null,
  ddd: string,
  telefone: string,
  ativo: boolean,
  termoAdesao: null | boolean,
  dataTermoAdesao: null | boolean,
  statusCadastro: string
}
const Home = () => {
  const navigate = useHistory()
  const [disabledPinBtn, setDisabledPinBtn] = useState(false)
  const [values, setValues] = useState(defaultValues);
  const [listSellers, setListSellers] = useState<IUsuario[]>([]);
  const { updateUser, signOut } = useAuth();

  const [boolLaunchPurchase, setBoolLaunchPurchase] = useState(false);
  const [boolSendPin, setBoolSendPin] = useState(false);
  const [boolLaunchPin, setBoolLaunchPin] = useState(false);
  const [boolResendPin, setBoolResendPin] = useState(false);

  const [showName, setShowName] = useState(false);
  const [disabelPin, setDisabelPin] = useState(false);
  const [errorPin, setErrorPin] = useState(false);
  const [returnError, setReturnError] = useState('');

  const [loadingFormClient, setLoadingFormClient] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [erroCalcularValor, setErroCalcularValor] = useState<string>('');

  const [disabledForm, setDisabledForm] = useState(false);

  const [countDownPin, setCountDownPin] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const userId = localStorage.getItem('@mony:usuarioId')
  const { data: dataPlanUser, isLoading: loadPlanUser } : 
  { data: IAccession, isLoading: boolean } = getAccessionCurrent({ userId: `${userId}` })
  
  
  useEffect(() => {
    if(dataPlanUser === undefined 
      || dataPlanUser?.status !== 'ATIVA'){
      setDisabledForm(true)
    }

    return () => {
      setCountDownPin(false)
      setDisabledForm(false)
    }
  }, [dataPlanUser])

  const {
    register: registerClient,
    formState: { errors: errosClient },
    clearErrors: clearErrorsClient,
    handleSubmit: handleSubmitClient,
    reset: resetClient,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const loadSellers = async () => {
    getUsuarios()
      .then(res => {
        const user: UsuarioModel[] = res.data.page
        const normalizarUser = user.map(i => ({
          idUsuario: i.id,
          codigoInscricaoFederal: null,
          nome: i.nome,
          login: i.cpf,
          email: i.email,
          instanciaVO: null,
          ddd: "",
          telefone: "",
          ativo: true,
          termoAdesao: null,
          dataTermoAdesao: null,
          statusCadastro: "ATIVO"
        }))
        setListSellers(normalizarUser);
      })
      .catch(error => console.log(error))
  };

  const registerUser = async () => {
    try {
      const resp = await postRegister(
        `0${values.ddd}${replaceEspecials(values.telefone)}`,
        values.ddd,
        replaceEspecials(values.telefone),
        values.nomeCadastro,
        values.pin,
      );

      if (resp.status === 400) {
        return setErrorPin(true);
      }

      setErrorPin(false);
      setBoolLaunchPin(true);

      setValues({
        ...values,
        loginUsuarioFavorecido: `0${values.ddd}${replaceEspecials(values.telefone)}`,
      });

      return loadSellers();
    } catch (error) {
      consoleStringify(error);
      return setErrorPin(true);
    }
  };

  const formatCash = (value: number) => {
    if (!value) {
      return null;
    }

    const pieces = value.toString().split('.');

    if (pieces[1] && pieces[1].length === 1) {
      return `${pieces[0]},${pieces[1]}0`;
    }

    if (pieces[1] && pieces[1].length === 2) {
      return `${pieces[0]},${pieces[1]}`;
    }

    return `${pieces[0]},00`;
  };

  const handleModal = () => setOpenModal(!openModal)
  const handleCancel = () => {
    handleModal()
  }

  const handleCancelClear = useCallback(() => {
    setValues(defaultValues);
    resetClient({
      ddd: '',
      telefone: '',
    });
    clearErrorsClient();
    setBoolLaunchPurchase(false);
    setBoolLaunchPin(false);
    setBoolSendPin(false);
    setShowName(false);
    setErrorPin(false);
    setDisabelPin(false);
    setReturnError('');
    setDisabledForm(false);
  }, []);

  const handleResendPin = () => {
    setCountDownPin(true)
    handleSendPin()
  }
  const handleSendPin = useCallback(async () => {
    try {
      if (!values.nomeCadastro) {
        setReturnError('Nome é obrigatório para o envio do PIN.');
        return;
      }
      await sendPin(values.ddd, replaceEspecials(values.telefone));
      setReturnError('');

      setBoolSendPin(true);
      setDisabelPin(true);
    } catch (error) {
      consoleStringify(error);
      setDisabelPin(false);
    } finally {
      setDisabledForm(true);
    }
  }, [values]);

  const handleConfirmPurchase = async () => {
    try {
      console.log(values.valorCompra);
      setErroCalcularValor('');
      setLoading(true);
      setBoolLaunchPurchase(true);

      const response = await getCashback(values.valorCompra);

      setValues({
        ...values,
        valorCashback: response.data.valorPrevia,
      });

      setLoading(false);
    } catch (error) {
      consoleStringify(`handleConfirmPurchase => ${error}`);
      setErroCalcularValor('Erro ao calcular valor.');
      setLoading(false);
    }
  };

  function formatDateTopApi() {
    const dateNow = new Date();

    const month = (`0${dateNow.getMonth() + 1}`).slice(-2);
    const day = (dateNow.getDate() < 10 ? `0${dateNow.getDate()}` : `${dateNow.getDate()}`);
    const hour = (dateNow.getHours() < 10 ? `0${dateNow.getHours()}` : `${dateNow.getHours()}`);
    const minute = (dateNow.getMinutes() < 10 ? `0${dateNow.getMinutes()}` : `${dateNow.getMinutes()}`);
    const second = (dateNow.getSeconds() < 10 ? `0${dateNow.getSeconds()}` : `${dateNow.getSeconds()}`);

    const date = `${dateNow.getFullYear()}-${month}-${day}`;
    const hours = `${hour}:${minute}:${second}`;
    console.log(hours);
    return `${date}T${hours}`;
  }

  const handleLaunchPurchase = useCallback(async () => {
    try {
      setReturnError('')
      setLoadingConfirm(true);

      const unidade = await getUnidade();
      const brazilTime = formatDateTopApi();
      const userIP = await fetchIP();

      await launchPurchase({
        idUnidade: unidade.data.idUnidade,
        valor: values.valorCompra,
        dataTransacao: brazilTime,
        loginVendedor: values.vendedor,
        ipAddress: userIP.data,
        loginUsuarioFavorecido: values.loginUsuarioFavorecido,
      });

      handleCancel();
      setLoadingConfirm(false);

      navigate.push('/home/lancar-compra/sucesso')

    } catch (err: any) {

      if (err?.response?.data?.erroCode === 22 || err?.erroCode === 22) {
        setReturnError('Olá, infelizmente os lançamentos deste mês se esgotaram, aguarde o próximo mês para novos lançamentos.'); 
        setLoadingConfirm(false);
        return;
      }

      if (err?.error || err?.erroCode != null) {
        setReturnError('Erro ao finalizar!');
        setLoadingConfirm(false);
        return;
      }

      setReturnError('Erro ao finalizar!');
    } finally {
      setLoadingConfirm(false);
    }
  }, [values]);

  // eslint-disable-next-line consistent-return
  const handleUser = async (data: IFormInput) => {
    try {
      setReturnError('');
      setLoadingFormClient(true);
      setDisabledForm(true);

      setValues({
        ...values,
        ...data,
        nome: '',
      });

      const response = await usuarioTel(data.ddd, replaceEspecials(data.telefone));
      const saldo = ((response.data.instanciaVO.saldoInstancia).toFixed(2)).toString()

      setValues({
        ...values,
        ...data,
        nome: response.data.nome,
        loginUsuarioFavorecido: response.data.login,
        saldo
      });

      loadSellers();
      setLoadingFormClient(false);
      setShowName(false);
    } catch (error) {
      setLoadingFormClient(false);
      setShowName(true);

      console.log(error)
      setReturnError('Celular não cadastrado. Informe o nome do cliente e envie o PIN para realizar o cadastro.')
    } finally {
      // setDisabledForm(true);
    }
  };

  const handleValorCompra = (value: any) => {
    if (value.floatValue) {
      setValues({
        ...values,
        valorCompra: value.floatValue / 100,
      });
    }
  };

  function handleClearErros() {
    setReturnError('');
  }

  function handleVerifyAuthorities(authority: ResponseUsuarioAtual.Authority[]) {
    if (authority?.some((item) => item.authority === AuthorityEnum.ADMINISTRADOR)) {
      signOut();
    }
  }
  useEffect(() => {
    async function initData() {
      try {
        const response = await getUserData();
        updateUser(response.data.body);
        handleVerifyAuthorities(response.data.body.authorities);
      } catch (error) {
        consoleStringify(error);
      }
    }

    initData();
    return () => { }
  }, []);

  const handleReenvioPin = (e: any) => {
    e && setCountDownPin(false)
  }

  return (
    <>
      <AppBody>
        <TitlePage title="Lançar compra" />

        <Modal
          text="Deseja realmente sair? Os dados não salvos serão perdidos"
          open={openModal}
          onClose={handleModal}
          onYes={handleCancelClear} />

        <Label size="24" bold color={theme.COLORS.WHITE}>1) Insira os dados do cliente</Label>

        {!!Object.values(errosClient).length && (
          <>
            {Object.values(errosClient).map((item) => (
              <ErrorAlert key={item.message} message={item.message} />
            ))}
          </>
        )}

        {returnError &&  <ErrorAlert message={returnError} /> }

        {errorPin && <ErrorAlert message="PIN inválido, tente novamente." />}

        <Spacing margin={20} />

        <DataWrapper>
          <FormRow>
            <FormClient
              onSubmit={handleUser}
              handleSubmit={handleSubmitClient}
              register={registerClient}
              loading={loadingFormClient}
              disabledForm={disabledForm}
              clearErrors={() => handleClearErros()}
            />
          </FormRow>

          <Spacing margin={12} />

          {showName ? (
            <FormRow>
              <FormControl
                text="Nome do cliente"
                value={values.nomeCadastro}
                maxLength={30}
                onChange={({ target }) => {
                  setValues({
                    ...values,
                    nomeCadastro: letter(target.value),
                  });
                }}
              />

              <Button
                text="Enviar PIN"
                confirm
                disabled={disabelPin || !values.nomeCadastro}
                onClick={handleSendPin}
              />

              <Button
                text="Cancelar"
                onClick={handleCancel}
              />
            </FormRow>
          ) : null}

          {values.nome
            ? <RowFlex>
              <FormControl
                text="Nome do cliente"
                disabled
                value={values.nome}
                onChange={() => null/*console.log('oi')*/}
              />
              <InputNumberFormat
                label="Saldo atual"
                type="text"
                placeholder="R$ 0,00"
                format={FormatInputValueFunction}
                onValueChange={(e) => null}
                value={values.saldo}
                maxLength={13}
                disabled
              />
            </RowFlex> : null}
        </DataWrapper>

        {boolSendPin && !boolLaunchPin ? (
          <>
            <Spacing margin={20} />

            <Label size="24" bold color={theme.COLORS.WHITE}>2) Informe o PIN de 6 dígitos recebido pelo cliente</Label>

            <Spacing margin={20} />

            <DataWrapper>
              <FormRow>
                <StatefulPinInput
                  length={6}
                  showState={false}
                  onChange={(value, index, values) => {
                    setDisabledPinBtn(false)
                    setValues((prevState) => ({ ...prevState, pin: number(values.join('')) }));
                  }}
                  validBorderColor="white"
                  containerStyle={{ marginTop: '10px' }}
                  inputStyle={{ fontFamily: 'Montserrat', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 'unset', borderBottom: `1px solid ${theme.COLORS.PRIM_800}`, color: `${theme.COLORS.PRIM_800}` }}
                />

                <Button
                  text="Confirmar"
                  confirm
                  disabled={(values.pin.length !== 6 || disabledPinBtn)}
                  onClick={() => {
                    console.log('passei', disabledPinBtn)
                    setDisabledPinBtn(true)
                    registerUser()
                  }}
                />
              </FormRow>

              <Spacing margin={20} />

              <Column>
                <Row>
                  <Button
                    link
                    text="Cliente não recebe o PIN"
                    onClick={() => setBoolResendPin(!boolResendPin)}
                  />
                </Row>

                <Spacing margin={4} />

                {boolResendPin ? (
                  <Bold>
                    Confirme o número do celular, se necessário faça o
                    reenvio do PIN ou faça uma nova consulta para corrigir.
                  </Bold>
                ) : null}
              </Column>

              <div style={{ display: boolResendPin ? 'block' : 'none' }}>
                <Spacing margin={10} />
                <Row>
                  <Button
                    text="Reenviar PIN"
                    onClick={() => handleResendPin()}
                    disabled={countDownPin}
                  />

                  {countDownPin
                    && <>
                      <Spacing margin={4} />
                      <CountDown time="0:30" hasFinished={handleReenvioPin} />
                    </>}

                  <Spacing margin={4} />

                  <Button
                    text="Nova consulta"
                    confirm
                    onClick={handleCancel}
                  />
                </Row>
              </div>


            </DataWrapper>
          </>
        ) : null}

        {boolSendPin && boolLaunchPin ? (
          <>
            <Spacing margin={40} />
            <Label size="24" bold color={theme.COLORS.WHITE}> 2) Informe o PIN de 6 dígitos recebido pelo cliente</Label>
            <Spacing margin={20} />

            <Success onSuccess={() => null} label="PIN válido" hasBtnSucesso={false} />
          </>
        ) : null}

        {boolLaunchPin ? (
          <>
            <Spacing margin={20} />

            <Label size="24" bold color={theme.COLORS.WHITE}>3) Lançar compra</Label>

            <Spacing margin={20} />

            <DataWrapper>

              {boolLaunchPurchase
                ? (
                  <>
                    <Column>
                      <Bold>CASHBACK GERADO COM SUCESSO!</Bold>

                      <Spacing margin={2} />

                      <Label>{`Valor da compra: ${FormatCurrency(values.valorCompra)}`}</Label>
                      <Label>{`Vendedor: ${values.nomeVendedor}`}</Label>
                    </Column>

                    <Spacing margin={4} />

                    <Row>
                      <CreditWrapper>
                        <Bold constract> Valor do Cashback: </Bold>
                        {loading ? (
                          <ReactLoading className="loading-style" color={theme.COLORS.WHITE} type="spinningBubbles" width="1em" height="1em" />
                        ) : (
                          <Bold constract className="loading-style"> {erroCalcularValor
                            || FormatCurrency(values.valorCashback || 0)}
                          </Bold>
                        )}
                      </CreditWrapper>

                      <Spacing margin={6} />

                      <Button
                        text="Cancelar"
                        onClick={handleCancel}
                      />

                      <Spacing margin={4} />

                      <Button
                        text="Confirmar"
                        confirm
                        onClick={() => handleLaunchPurchase()}
                        loading={loadingConfirm}
                      />
                    </Row>
                  </>
                )
                : (
                  <>
                    <Bold>
                      Informe o valor da compra e selecione seu nome no combo de vendedores,
                      para geração de Cashback.
                    </Bold>

                    <Spacing margin={10} />

                    <FormRow>
                      <InputNumberFormat
                        label="Valor da compra"
                        type="text"
                        placeholder="R$ 0,00"
                        format={FormatInputValueFunction}
                        onValueChange={handleValorCompra}
                        maxLength={13}
                      />

                      <FormControl
                        selectSeller
                        text="Vendedor"
                        options={listSellers}
                        value={values.vendedor}
                        placeholder="Buscar vendedor..."
                        onChange={({ target }) => setValues({
                          ...values,
                          vendedor: target.value,
                          nomeVendedor: target.options[target.selectedIndex].text,
                        })}
                      />

                      <Button text="Cancelar" onClick={handleCancel} />

                      <Button
                        text="Avançar"
                        confirm
                        disabled={!(values.valorCompra && values.vendedor)}
                        onClick={() => handleConfirmPurchase()}
                      />
                    </FormRow>
                  </>
                )}
            </DataWrapper>

            <Spacing margin={20} />
          </>
        ) : null}

        {values.nome ? (
          <>
            <Spacing margin={20} />

            <Label size="24" bold color={theme.COLORS.WHITE}>2) Lançar compra</Label>

            <Spacing margin={20} />

            <DataWrapper>
              {boolLaunchPurchase
                ? (
                  <>
                    <Column>
                      <Bold>CASHBACK GERADO COM SUCESSO!</Bold>

                      <Spacing margin={2} />

                      <Label>{`Valor da compra: ${FormatCurrency(values.valorCompra)}`}</Label>
                      <Label>{`Vendedor: ${values.nomeVendedor}`}</Label>
                    </Column>

                    <Spacing margin={4} />

                    <Row>
                      <CreditWrapper>
                        <Bold constract> Valor do Cashback: </Bold>
                        {loading ? (
                          <ReactLoading className="loading-style" color={theme.COLORS.WHITE} type="spinningBubbles" width="1em" height="1em" />
                        ) : (
                          <Bold constract className="loading-style"> {erroCalcularValor
                            || FormatCurrency(values.valorCashback || 0)}
                          </Bold>
                        )}
                      </CreditWrapper>

                      <Spacing margin={6} />

                      <Button
                        text="Voltar"
                        onClick={() => setBoolLaunchPurchase(false)}
                      />

                      <Spacing margin={4} />

                      <Button
                        text="Cancelar"
                        onClick={handleCancel}
                      />

                      <Spacing margin={4} />

                      <Button
                        text="Confirmar"
                        confirm
                        onClick={() => handleLaunchPurchase()}
                        disabled={loadingConfirm}
                        loading={loadingConfirm}
                      />
                    </Row>
                  </>
                )
                : (
                  <>
                    <Bold>
                      Informe o valor da compra e selecione seu nome no combo de vendedores,
                      para geração de Cashback.
                    </Bold>

                    <Spacing margin={10} />

                    <FormRow>
                      <InputNumberFormat
                        label="Valor da compra"
                        type="text"
                        placeholder="R$ 0,00"
                        onValueChange={handleValorCompra}
                        format={FormatInputValueFunction}
                        maxLength={13}
                      />

                      <FormControl
                        selectSeller
                        text="Vendedor"
                        options={listSellers}
                        value={values.vendedor}
                        placeholder="Buscar vendedor..."
                        onChange={({ target }) => setValues({
                          ...values,
                          vendedor: target.value,
                          nomeVendedor: target.options[target.selectedIndex].text,
                        })}
                      />

                      <Button text="Cancelar" onClick={handleCancel} />

                      <Button
                        text="Avançar"
                        confirm
                        disabled={!(values.valorCompra && values.vendedor)}
                        onClick={() => handleConfirmPurchase()}
                      />
                    </FormRow>
                  </>
                )}

            </DataWrapper>
          </>
        ) : null}
      </AppBody>
    </>
  );
};

const RowFlex = styled(Row)`
  display:flex;
  gap:25px;

  span{
    font-weight:500;
    opacity:.5;
  }
  & > div > div {
    opacity:.5;
  }
`

export default Home;
function updateUser(body: any) {
  throw new Error('Function not implemented.');
}

