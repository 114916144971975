import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { isCpfValid, isEmailValid, isValidDDDPhone } from "util/validations";
import { cpfMask, phoneMask, replaceEspecials } from "util/masks";
import { getServiceUnid, postServiceUser, putServiceUser } from "../service/ServiceUser";
import { isOnlyString, scrollToTop } from "util/utils";

export interface IUnidSelect{
  id: number,
  nome: string,
}
export interface IUnid{
  idUnidade: number,
  nomeUnidade: string,
}

function useQueryString() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export interface IFormUser {
  id: number | null,
  funcao: string,
  nome: string,
  cpf: string,
  email: string,
  ddd: string,
  telefone: string,
  idUnidade: number | null,
}
const valuesDefault = {
  id: null,
  funcao: '',
  nome: '',
  cpf: '',
  email: '',
  ddd: '',
  telefone: '',
  idUnidade: null
}

const schema = yup.object({
  nome: yup.string().required('Campo obrigatório')
    .test('Carecteres especiais', 'Número não é permitido', (value) : boolean => (value ? !isOnlyString(value) : false)),
  cpf: yup.string().required('Campo obrigatório')
    .test('CPF invalid', 'CPF inválido', (value): boolean => (value ? isCpfValid(replaceEspecials(value)) : false)),
  email: yup.string().required('Campo obrigatório')
    .test('E-mail invalid', 'E-mail inválido', (value): boolean => (value ? isEmailValid(value) : false)),
  telefone: yup.string().required('Campo obrigatório')
    .test('Telefone/celular invalid', 'Telefone/celular inválido', (value): boolean => (value ? isValidDDDPhone(value) : false)),
  unidade: yup.string().required('Campo obrigatório'),
}).required();

const useFormUser = () => {
  const navigate = useHistory();
  const [editUser, setEditUser] = useState<IFormUser | null>();
  const [funcao, setFuncao] = useState('');

  const [load, setLoad] = useState(false);
  const [errorForm, setErrorForm] = useState({msg : '', error: []});
  
  const [values, setValues] = useState<IFormUser>(valuesDefault);
  const [modal, setModal] = useState({ open: false, msg: '' });

  const [unid, setUnid] = useState(""); // unid é do select
  const [unidades, setUnidades] = useState([]);
  
  useEffect(() => {
    setEditUser(null)
    getlistUnid()
    //@ts-ignore corrige erro no compontente que precisa inicaar com vazio
    setUnid(values?.idUnidade || editUser?.idUnidade || "")
  },[])

  const getlistUnid =  async () => getServiceUnid()?.then((resp : any) => {
    const list = resp.data.page.map((i : IUnid) => { 
      return { id: i.idUnidade, nome: i.nomeUnidade }
    })
    setUnidades(list)
  })

  useEffect(() => {
    // carregor os campos e formato o CPF e Telefone
    editUser != undefined
      && setValues({
        ...editUser,
        cpf: cpfMask(editUser.cpf),
        telefone: phoneMask(editUser.ddd +''+ editUser.telefone)
      })

      // esse código resolveu o problema da validação da edição
      editUser != undefined && reset({ data: {}})
  }, [editUser])
  
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    // mode: 'onChange',
    // defaultValues: values,
    resolver: yupResolver(schema)
  });

  const handleUnidade = (e: any) => {
    setValues({...values, idUnidade: e.target.value})
    setUnid(e.target.value)
  }

  const handleModal = () => {
    setModal({ open: !modal.open, msg: 'Deseja realmente sair? Os dados não salvos serão perdidos' })
  }
  const handleCancel = () => {
    setEditUser(valuesDefault)
    setValues(valuesDefault)
    navigate.push('/home/usuarios/')
  }

  
  const handleSave = () => {
    setErrorForm({msg: '', error: []})
    const telClear = replaceEspecials(values.telefone)
    const cpfClear = replaceEspecials(values.cpf)

    const user = {  
      id: values.id, 
      funcao: funcao || values.funcao,
      nome: values.nome,
      cpf: cpfClear,
      email: values.email,
      ddd: (telClear).slice(0,2),
      telefone: (telClear).slice(2),
      idUnidade: values.idUnidade
    }
    const userUpdate = {  
      id: values.id, 
      nome: values.nome,
      email: values.email,
      ddd: (telClear).slice(0,2),
      telefone: (telClear).slice(2),
      idUnidade: values.idUnidade
    }


    if (!!errors) {
      if(values.id !== null){
        setLoad(true)
        putServiceUser(userUpdate)
          .then((res) => {
            isSuccess()
          })
          .catch(error => {
            setErrorForm({msg: error.response.data.message || 'Erro inesperado!', error: error.response.data})
          })
          .finally(() => setLoad(false))
      }else{
        if(user != undefined || user != ""){
          setLoad(true)
          //@ts-ignore
          postServiceUser(user)
            .then(() => {
              isSuccess()
            })
            .catch(error => {
              const resp = error?.response?.data
              const erros = resp.message !== undefined ? resp.message : error?.response?.data.map((e : any) => e.defaultMessage).join(',')
              setErrorForm({ msg: erros, error: erros })
            })
            .finally(() => setLoad(false))
        }
      }
    }
    scrollToTop({})
  };

  const isSuccess = () => {
    const update = values.id !== null ? '&update=1' : ''
    navigate.push(`/home/usuarios/sucesso?perfil=${funcao}${update}`)
  }

  return {
    unidades, unid, values, setValues, modal, setUnid, setEditUser, setFuncao,
    handleUnidade, handleModal, handleCancel, handleSave,
    register, handleSubmit, errors, load, errorForm
  }
}
export { useFormUser, useQueryString }