import api from "api";
import { useState } from "react";
import { moneyMask } from "util/masks";
import { encodeJsonBase64, normalizeMoneyNumber } from "util/utils";


const defaultValues = {
  valor: 0,
  numeroCaixa: "",
  valorAmostra: ''
};

const defaultDataQrCode = {
  numeroIdentificacaoTransacaoTEF: null,
  codigoEstabelecimento: "",
  valorTransacao: "",
  dataGeracaoQrCode: "",
  nomeInstancia: "",
  nomeUnidade: "",
  logo: "",
  nsuQrCode: null,
  idPedido: 0,
  lido: false,
  tipoIntegracao: "",
};



export default function usePay() {
  const [values, setValues] = useState(defaultValues);
  const [dataQrCode, setDataQrCode] = useState<any>(defaultDataQrCode);
  const [payQr, setPayQr] = useState(false);
  const [load, setLoad] = useState(false)
  const [open, setOpen] = useState(false)

  const [dataQrCodeDecode, setDataQrCodeDecode] = useState('')
  const handleModal = () => setOpen(!open);


  const clearQrCode = async (id: string) => {
    try {
      await api.delete(`/autorizador/qr-code/${id}`);
    } catch (error) {
      console.log(error);
    }

    setPayQr(false);
    setValues(defaultValues);
  };

  const finishQrCode = () => {
    setPayQr(false);
    setValues(defaultValues);
  };

  const handleValorCompra = (e: any) => {
    const value = moneyMask(e.target.value)
    const valorNumber = normalizeMoneyNumber(e.target.value)
    
    if (!isNaN(valorNumber)){
      setValues({ 
        ...values,
        valor: valorNumber,
        valorAmostra: value
      })
    }
  };

  const handleQrCode = async () => {
    try {
      setLoad(true);
      setPayQr(true);
      const resp = await api.post("/autorizador/qr-code", values);
      const encodeJson = encodeJsonBase64(resp.data)

      setDataQrCodeDecode(encodeJson)
      setDataQrCode(resp.data);
      setLoad(false);
    } catch (error) {
      setLoad(false)
      console.log(error);
    }
  };

  const handleClear = () => {
    setValues(defaultValues);
    setLoad(false)
  };

  const handleSubmit = () => {
    handleQrCode();
  };

  return {
    open,
    load,
    clearQrCode,
    handleQrCode,
    finishQrCode,
    handleSubmit,
    handleClear,
    handleValorCompra,
    payQr,
    dataQrCode,
    dataQrCodeDecode,
    values,
    setValues,
    handleModal,
  };
}
