import api from "api";
import Button from "components/Button";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Label, Spacing } from "styles/base";
import { TermsContainer } from "../style";
import { TermDigital } from "./TermMony";


const DigitalTerms = ({ terms } : { terms: boolean }) => {
    const [show, setShow] = useState(terms ? false : true);

    const navigate = useHistory();

    const handleSubmit = async () => {
        try {
            await api.post("/empresas/aceitar-termos");
            navigate.push('/conta-validada');
        } catch (error) {
            return console.log(error);
        }

        navigate.push('/conta-validada');
    }

    return (
        <>       
            <TermsContainer>
                <TermDigital />
            </TermsContainer>

            {!terms && (<>
                <Spacing margin={24} />
                <Button
                    confirm
                    text="Aceitar os termos e ativar conta"
                    onClick={() => handleSubmit()}
                />
            </>)}
        </>
    )
};

export default DigitalTerms;
