import imagPlanos from 'assets/images/planos.png';
import { useHistory } from 'react-router-dom';
import { Spacing } from 'styles/base';

export const BannerExchangeOfPlan = () => {
    const navigate = useHistory();
    const handleClick = () => navigate.push('/home/conta/planos')
    return <>
      <img onClick={() => handleClick()} 
        src={imagPlanos} 
        alt="Planos" 
        style={{width: '100%', height:'auto', cursor: 'pointer'}} />
      <Spacing margin={24} />
    </>
  }