export const IconInfo = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <g clipPath="url(#clip0_6684_79106)">
            <path d="M21.21 7.98L16.53 3.3C16.34 3.11 16.08 3 15.82 3H9.18C8.92 3 8.66 3.11 8.48 3.29L3.79 7.98C3.61 8.16 3.5 8.42 3.5 8.68V15.31C3.5 15.58 3.61 15.83 3.79 16.02L8.47 20.7C8.66 20.89 8.92 21 9.18 21H15.81C16.08 21 16.33 20.89 16.52 20.71L21.2 16.03C21.39 15.84 21.49 15.59 21.49 15.32V8.68C21.5 8.42 21.39 8.16 21.21 7.98ZM19.5 14.9L15.4 19H9.6L5.5 14.9V9.1L9.6 5H15.4L19.5 9.1V14.9Z" fill="#240032" />
            <path d="M12.5 17C13.0523 17 13.5 16.5523 13.5 16C13.5 15.4477 13.0523 15 12.5 15C11.9477 15 11.5 15.4477 11.5 16C11.5 16.5523 11.9477 17 12.5 17Z" fill="#240032" />
            <path d="M12.5 7C11.95 7 11.5 7.45 11.5 8V13C11.5 13.55 11.95 14 12.5 14C13.05 14 13.5 13.55 13.5 13V8C13.5 7.45 13.05 7 12.5 7Z" fill="#240032" />
        </g>
        <defs>
            <clipPath id="clip0_6684_79106">
                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
}