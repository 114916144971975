import { useMemo, useState } from "react";
import { CircularProgress } from '@mui/material';
import { LoginWrapper } from "../style";
import LogoPng from '../../../assets/icons/logo.png';
import FormControl from "components/FormControl";
import { Spacing } from "styles/base";
import { cpfMask, replaceEspecials } from "util/masks";
import Button from "components/Button";
import { isCpfValid } from "util/validations";
import styled from "styled-components";
import { BoxResetPassord } from "./BoxResetPassord/BoxResetPassord";
import { InfoPassword } from "./InfoPassword/InfoPassword";
import { useHistory } from "react-router-dom";
import { getInstancias, postResetPassword } from "../service";
import { ErrorAlert } from "components/ErrorAlert";
import { useQueryString } from "util/utils";

const defaultValues = {
  login: "",
  senha: "",
  novaSenha: "",
  cdInstanciaSelecionado: "",
  admPop: false,
}

const rememberPassword = () => {
  const navigate = useHistory();
  const query = useQueryString()
  const step = +`${query.get('step')}`

  const [load, setLoad] = useState(false)
  const [values, setValues] = useState(defaultValues);
  const [errorApi, setErrorApi] = useState('')
  const [errors, setErrors] = useState('');
  const [instance, setIntance] = useState([])
  const [disabledBtn, setDisabledBtn] = useState(false)

  const [resetMessage, setResetMessage] = useState({ 
    steps: step || 1, 
    email: '',   
    ddd: "",
    celular: "" 
  })

  const handleCpf = (e: any) => {
    setErrors('')
    loadInstance(e.target.value)
    setValues((prevState) => ({ ...prevState, login: cpfMask(e.target.value) }))
  }
  
  const loadInstance = (value: string) => {
    setErrorApi('')
    const cpf = replaceEspecials(value)
    
    if (cpf.length === 11) {
      setLoad(true)
      setDisabledBtn(true)

      getInstancias(replaceEspecials(value))
      .then((res: any) => {
          if(res.data.length === 1){
            setDisabledBtn(false)
          }
          setIntance(res.data)
        })
        .catch(error => {
          console.log(error)
          setErrorApi('CPF inválido ou não cadastrado')
        })
        .finally(() => setLoad(false))
    } else {
      setIntance([])
    }
  }

  const resetPassword = () => {
    const cpfClear = replaceEspecials(values.login)

    if (isCpfValid(cpfClear)) {
      setErrors('')
      setLoad(true)

      getInstancias(cpfClear)
        .then(resp => {

          const user = {
            login: cpfClear,
            cdInstanciaSelecionado: values.cdInstanciaSelecionado || resp.data[0].cdInstancia,
            admPop: true,
          }

          postResetPassword(user).then(res => {
            const { email, ddd, celular } = res.data.body
            setResetMessage((prevState) => ({ ...prevState, 
              steps: 2, 
              ddd, 
              celular,
              email 
            }))
          }).finally(() => setLoad(false))
        })
        .catch(error => {
          if(error.response.status === 404){
            setErrorApi('CPF inválido ou não cadastrado')
          }
        })
        .finally(() => setLoad(false))


    } else {
      setErrors('CPF inválido')
    }
  }

  const nextPassword = () => {
    navigate.push('/')
  }

  const sendPassword = (e: any) => {
    console.log('resetar e enviar para login')
    navigate.push('/')
  }
  return (
    <LoginWrapper>
      <img src={LogoPng} alt="Logo" style={{ width: '100%' }} />
      <Spacing margin={20} />
      
      {errorApi && <><ErrorAlert message={errorApi} isMarginTop={false} /><br/></>}

      {(resetMessage.steps === 1 || resetMessage.steps === 2)
        && <>
          <FormControl
            constract
            text="CPF"
            value={values.login}
            onChange={handleCpf}
          />
          <LabelError>{errors}</LabelError>

          {(instance.length > 1 && resetMessage.steps === 1)
            && <>
              <Spacing margin={7} />
              <FormControl
                constract
                select
                text="Selecione a empresa:"
                options={instance}
                value={values.cdInstanciaSelecionado}
                onChange={({ target }) => {
                  setDisabledBtn(false)

                  setValues({
                  ...values,
                  cdInstanciaSelecionado: target.value,
                })}}
                disabled={instance.length === 1}
                // disabled={!values.cdInstanciaSelecionado}
              />
            </>
          }
          <Spacing margin={14} />
        </>}

      {resetMessage.steps === 1
        && <Button
          loginSubmit
          text="Redefenir senha"
          disabled={(!values.login && !values.cdInstanciaSelecionado) 
            || (instance.length > 1 && !values.cdInstanciaSelecionado) 
            || load || disabledBtn}
          onClick={() => resetPassword()}
        />}

      {resetMessage.steps === 2
        && <InfoPassword
          cpf={values.login}
          ddd={resetMessage.ddd}
          tel={resetMessage.celular}
          email={resetMessage.email}
          onClick={() => nextPassword()}
        />}

      {resetMessage.steps === 3
        && <BoxResetPassord onClick={sendPassword} />}

      {load && <BoxProgress><CircularProgress style={{ width: 30, height: 30 }} /></BoxProgress>}
    </LoginWrapper>
  )
}

const BoxProgress = styled.div`
  position:fixed;
  bottom:6px;
  right:15px;
`
export const LabelError = styled.label`
  display:block;
  color:#ff3e3e;
  font-size:0.8rem;
  text-align:right;
  padding-top:10px;
`

export { rememberPassword }