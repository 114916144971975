import React from 'react'
import styled from 'styled-components'
import { Label } from 'styles/base'
import CheckIcon from '@mui/icons-material/Check';
import theme from 'styles/theme';


interface LabelProps {
  label?: string,
  iconSuccess?: boolean,
  onSuccess(): void,
  hasBtnSucesso?: boolean,
}

const Success: React.FC<LabelProps> = ({ label, onSuccess, iconSuccess = true, hasBtnSucesso = true }) => {
  return (
    <Container>
      <TextContainer>
        <Label>{label && <div dangerouslySetInnerHTML={{ __html: label }}></div>}</Label>
        {iconSuccess && <CheckIcon />}
      </TextContainer>
      {hasBtnSucesso && <Button onClick={onSuccess}>Ok!</Button>}
    </Container>
  );
};

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
  width: 100%;
  height: 89px;
  background-color:${theme.COLORS.WHITE};
  border-radius:8px;

  span{
    font-weight: 400;
    text-align: center;
    font-weight: 600;
  }
  svg{
    display: flex;
    color:${theme.COLORS.SEC_800};
  }
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;


const Button = styled.button`
    background: transparent;
    outline: none;
    width: 180px;
    color: ${theme.COLORS.PRIM_800};
    font-weight: 600;
    font-size: 16px;
    border: 1px solid ${theme.COLORS.PRIM_800};
    border-radius: 6px;
    padding: 8px 25px;
    cursor: pointer;
    background-color: ${theme.COLORS.SEC_800};
    display: flex;
    justify-content: center;
    align-items: center;
    font: inherit;

    > span {
        line-height: 19px;
        font-size: .8em;
        text-align: center;
        color: ${theme.COLORS.PRIM_800};
        font-weight: bold;
    }
`;

export default Success;