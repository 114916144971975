import styled from "styled-components";
import theme from "styles/theme";

export const Container = styled.div`
    padding:24px;
    width: 100%;
    background-color:  ${theme.COLORS.WHITE};
    border-radius: 8px;

    & + div{
      margin-top:20px;
    }

    & > h3{
      display:flex;
      align-items: center;
      justify-content: space-between;
      
      gap:10px;
      font-size:20px;
      color:${theme.COLORS.PRIM_800};
      margin-bottom:16px;     
    }
`;
export const BoxLabelText = styled.div`
  & + div{
    padding-top:24px;
  }

  & > span{
    display:flex;
    font-weight:600;
    padding-bottom:16px;
  }
  & > div{
    height:auto;
  }
  
  & > div.boxObs{
    min-width:100%;
  }
`