import { Select } from "@mui/material";
import { Button } from "../../campaings/styles"
import styled from "styled-components";

import theme from "styles/theme";
import { Link } from "react-router-dom";

export const SearchBox = styled.div`
  display:flex;
  flex-wrap:nowrap;
  flex-direction:row;
  gap:16px;
  padding:35px 0 35px;
  max-width: 1024px;

  &.hasInstancia{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr auto;
  }
`
export const BoxDate = styled.div`
  display: flex;
  gap:16px;

  label{
    font-size: 16px;
    font-weight:700;
  }
  fieldset span{
    display:none;
  }
`

export const SelectBox = styled(Select)({
  fieldset: {
    "& span": {
      fontFamily: theme.FONTS.MONTSERRAT,
      fontWeight:700,
      opacity: 1,
      fontSize: "16px",
      color: `${theme.COLORS.PRIM_800}`,
      position: "absolute",
      top: '-20px',
      zIndex: 50,
    }
  }
})

export const ButtonSearch = styled(Button)`
  font-weight: 700;
`

export const BoxBtns = styled.div`
  display: flex;
  gap: 16px;
  
  &.hasInstancia{
    justify-content: center;
    grid-column: 1/5;
  }
  & > button{
    height: 56px;
  }
`
export const BoxInfo = styled.p`
  font-size:17px;
  font-weight:500;
  color:${theme.COLORS.PRIM_800};
  padding:15px 0 0;
`
interface PropsLink{
  count?: number
}
export const LinksBack = styled(Link)<PropsLink>`
  display:inline-flex;
  align-items:center;
  font-weight:700;
  text-decoration: none;
  height:42px;
  padding:0 40px;
  color:${theme.COLORS.PRIM_800};
  border:1px solid ${theme.COLORS.PRIM_800};
  border-radius:5px;

  &:hover{
    box-shadow:0 0 15px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1);
  }
`

export const BoxBtnsRelatorios = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top:20px;

  & > button{
    font-size:18px;
  }
`