import api from "api";
import { useQueryFetch } from "../../Reports/services";

export const getGeneralRulesQuery = ({ id } : { id?: string }) => {
  const { isFetching, isLoading, error, data } = useQueryFetch({ 
    key: ['gerenciarRegrasGerais','gerenciarRegrasGerais', id?.toString() || ""], 
    url: '/gerenciarRegrasGerais', 
    cache: 0,
    revalidate: 0,
  })
  
  return { isFetching, isLoading, error, data }
}

export const getGeneralCampaings = ({ id } : { id?: string }) => {
  const { isFetching, isLoading, error, data } = useQueryFetch({ 
    key: ['gerenciarCampanhas','generalRules', id?.toString() || ""], 
    url: '/gerenciarCampanhas',
    cache:0,
    revalidate:0,
  })

  return { isFetching, isLoading, error, data }
}

export const getGeneralRulesCampaings = () => {
  const rules = api.get('/gerenciarRegrasGerais')
  const campaing = api.get('/gerenciarCampanhas')

  return Promise.all([rules, campaing]).then(res => res)
}


export const getMasterPinQuery = ({ id } : { id?: string }) => {
    
  const { isFetching, isLoading, error, data } = useQueryFetch({ 
    key: ['pinMestre', id?.toString() || ""], 
    url: '/gerenciarRegrasGerais/pinMestre', 
    cache: (60*1000) * 60 * 24, // 24h
    revalidate: (60*1000) * 60 * 24, // 24h -- 1000*15, // 15s
  })

  return { isFetching, isLoading, error, data }
}


export const updateActiveGeneralRulesQuery = async (e : any) => {

  const objSave = {
    campanhas: {
      "id": e.idCampaingActive,
      "observacoes":e.observacao,
      "multiplicador": parseFloat(e.percentualCreditos),
      "ativo": e.isActiveInstance
    },
  }
  const respCampaing = api.put('gerenciarCampanhas', objSave.campanhas)

  return Promise.all([respCampaing]).then(res => res)
}

export const updateGeneralRulesQuery = async (e : any) => {

  const objSave = {
    campanhas: {
      "id": e.idCampaingActive,
      "observacoes":e.observacao,
      "multiplicador": parseFloat(e.percentualCreditos),
      "ativo": e.isActiveInstance
    },
    generalRules: {
      "valorMaximoDia": parseFloat(e.valorMaximoDia),
      "quantidadeVigencia": parseInt(e.quantidadeVigencia)
    }
  }
  const respGeneralRules = api.post('/gerenciarRegrasGerais', objSave.generalRules)
  const respCampaing = api.put('gerenciarCampanhas', objSave.campanhas)

  return Promise.all([respGeneralRules, respCampaing]).then(res => res)
}