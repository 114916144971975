import styled from "styled-components";
import theme from "./theme";

export interface Props {
  margin?: number;
  constract?: boolean;
  bold?: boolean;
  size?: string;
  color?: string;
  upper?: boolean;
  justify?: string;
  center?: boolean;
  backgroundColor?: string;
  marginBottom?: string;
}

export const Spacing = styled.div<Props>`
  margin: ${(props) => (props.margin ? `${props.margin}px` : "8px")};
`;

export const Label = styled.span<Props>`
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: ${(props) => (props.size ? `${props.size}px` : "16px")};
  color: ${(props) => (props.color ? props.color : theme.COLORS.PRIM_800)};
  font-family: "MontSerrat";
  text-transform: ${(props) => (props.upper ? "uppercase" : "none")};
  line-height: 20px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "0")};
`;

export const Bold = styled.span<Props>`
  color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800)};
  font-weight: bold;
  line-height: 20px;
`;

export const Title = styled.h1<Props>`
  font-size: 2em;
  line-height: 40px;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800)};
  font-family: "Source Sans Pro";
`;

export const Subtitle = styled.h2<Props>`
  font-size: 1.5em;
  line-height: 31px;
  font-weight: bold;
  color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800)};
  font-family: "Source Sans Pro";
`;

export const GridHome = styled.div`
  display:grid;
  grid-template-columns: auto 1fr;
  min-height:100vh;
`
export const AppBody = styled.div`
  padding: 40px 45px;
  transform: translate3d(0, 0, 0);
`;

export const Row = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justify || "initial"};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataWrapper = styled.div<Props>`
  background-color: ${theme.COLORS.WHITE};
  border: 0 solid ${theme.COLORS.PRIM_700};
  border-radius: 6px;
  /* width: 95%; */
  padding: 24px;
  text-align: ${({ center }) => (center ? "center" : "none")};
  background-color: ${({ backgroundColor }) => backgroundColor || "none"};
`;

export const FileContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid ${theme.COLORS.BLACK_300};
    height: 160px;
    width: 160px;
    margin-right: 30px;

    img {
        width: 110px;
        height: 110px;
        margin-bottom: 5px;
    }

    span {
        display: block;
    }
`;

export const TableLine = styled.tr`
    border: 1px solid ${theme.COLORS.BLACK_300};
    padding: 8px 16px;

    th {
      border-left: 1px solid ${theme.COLORS.BLACK_300};
      font-family: "MontSerrat";
      font-size: 16;

      button{
          all: unset;

          :hover{
              cursor: pointer;
          }
      }
    }
 
`

export const TableContainer = styled.table`
    border-collapse: collapse;
    font-size: 16px;
    width: 100%;
    color: ${theme.COLORS.PRIM_800};
    border-spacing:2px;

    th {
        font-size: 16;
        height: 54px ;
        font-family: "MontSerrat";
    }
`



export const TableLineGrid = styled.div`
  color:${theme.COLORS.PRIM_800};
  font-weight: bold;
  font-size:16px;

  & > div + div{
    margin-top:10px;
  }
  & > div{
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    gap:10px;

    & > div{
      display: flex;
      align-items:center;
      justify-content: center;
      height:54px;
    }
  } 
`
export const GridHead = styled.div`
  height:45px;
`
export const GridLine = styled.div`
  & > div{
    gap:10px;
    display: grid;
    border:1px solid ${theme.COLORS.BLACK_300};
  }
`

export const TituloH2 = styled.h2`
  font-size:1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  color:${theme.COLORS.PRIM_800};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BoxIcon = styled.span`
  color:${theme.COLORS.PRIM_200};
`