import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ReactInputMask = styled(InputMask)`
  background: transparent;
  outline: none;
  border: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #2C2A59;
  color: #2C2A59;
  padding: 14px 14px 4px 14px;
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
  font: inherit;
  font-weight: bold;

  ::placeholder {
    color: #2C2A59;
    opacity: 0.25;
  }
`;

export const ContaionerInputGroupPrefix = styled.div`
  display: flex;
  align-items: center;
`;

// .prefix-span {
//   padding: 6px 12px;
//   font-size: 14px;
//   color: #555;
//   background-color: #eee;
//   border: 1px solid #ccc;
// }

// input {
//   flex: 1;
//   padding: 6px 12px;
//   border: 1px solid #ccc;
// }

// .prefix input {
//   border-radius: 2px 4px 4px 0px;
// }

// .prefix .prefix-span {
//   border-right: 0;
//   border-radius: 4px 0px 0px 4px;
// }

// .input-group + .input-group {
//   margin-top: 10px;
// }
