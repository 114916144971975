import { useEffect } from 'react';
import { ContainerErrorAlert, Spacing } from './style';

type Props = {
  message?: string;
  error?: any;
  isMarginTop?: boolean;
}

// eslint-disable-next-line react/function-component-definition
export function ErrorAlert({ message, error, isMarginTop = true }: Props) {

  useEffect(() => {
    error && console.error('Erro Inesperado', error)
  }, [error])
  

  return (
    <>
      {isMarginTop && <Spacing margin={34} />}
      <ContainerErrorAlert>
        <span>{message || ''}</span>
      </ContainerErrorAlert>
    </>
  );
}
