import styled from "styled-components";

export const BoxModalLoad = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding:20px;
  `
export const BoxModalCancel = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
  line-height: 140%;
`
interface HeadModalProps{
  notIcon?: boolean
}
export const HeadModalCancel = styled.header<HeadModalProps>`
  display: grid;
  grid-template-columns:  ${p => !p.notIcon ? '1fr auto' : '1fr' };
  align-items: start;
  gap:10px;
  margin-bottom: 20px;
  
  & h3{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap:5px;
    font-weight: 600;
    line-height: 120%;
  }

  & > div{
    display: flex;
    justify-content: center;
    align-items: center;
    width:24px;
    height: 24px;
    cursor: pointer;

    & > svg{
      width: 14px;
      height: 14px;
    }
  }
`
export const BoxModalBtns = styled.section`
  display: flex;
  gap:10px;
  margin-top: 20px;

  & > button{
    flex:1;
  }
`

export const BoxModalCard = styled.section`
  text-align: left;
  
  & > div {
    flex-wrap: wrap;
    padding:0;

    & > div:nth-child(1),
    & > div:nth-child(2){
      input{
        width: 215px;
      }
    }
  }
`