import { ErrorAlert } from 'components/ErrorAlert';
import TitlePage from 'components/TitlePage'
import { AppBody, DataWrapper } from 'styles/base'
import { EnterpriseProvider, useEnterpriseContext } from './Context/enterpriseContext';
import { EnterpriseList } from './List';
import { TabEnterprise } from './TabEnterprise';

interface IEnterprise {

}
const Enterprise = ({ }: IEnterprise) => {
  const { errorGeral } = useEnterpriseContext()
  
  return <AppBody id="app-body">
    <TitlePage title="Empresas" />
    
    {errorGeral && <ErrorAlert message={errorGeral} />}

    <TabEnterprise activeIndexTab={1} />
    <DataWrapper>
      <EnterpriseList />
    </DataWrapper>
  </AppBody>
}

const EnterprisePage = () => {
  return <EnterpriseProvider>
    <Enterprise />
  </EnterpriseProvider>
}

export { EnterprisePage }