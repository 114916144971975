import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { ManageRules } from '.';
import { sucessManageRules } from './sucesso';


export const IndexManegeRules = () => {
  const { path } = useRouteMatch();
  return <Switch>
    <Route exact path={`${path}/`} component={ManageRules} />
    <Route exact path={`${path}/sucesso`} component={sucessManageRules} />
  </Switch>
}