import api from "api";
import { updateActiveGeneralRulesQuery } from "layout/Home/screens/ManageRules/service";
import { deleteAccession } from "../../services";

export interface cancelarPlanProps{
    codigoErro?: number
    dataExpiracaoCredito?: string
    erroMsg?:string
    hasImpeditivo?: boolean
    saldoTransacaoDisponivel?: number | undefined
    valorTotalTransacoesAdicionais?: number | undefined
    transacoesAdicionaisUtilizadas?: number | undefined
    dataInicio?: string | null
    dataFim?: string | null
    acao?: string | null
}

export const assinanteGetApi = async () => {
    try {
        const response = await api.get("/empresas/assinante")
        return response.data
    } catch (e) {
        console.log(e);
    }
}

export const downgradePostApi = ({ sucesso = true }) => {
    return new Promise((resolve,reject) => {
        if(sucesso){
            setTimeout(() => {
                resolve({ status: 200, msg: 'Downgrade realizado com sucesso'})
            }, 1000);
        }else{
            setTimeout(() => {
                reject({ status: 400, msg: 'Não foi possivel realizar o downgrade' })
            }, 300);
        }
    })
}

export const apiPromisseTeste = ({ success, error } : { success: any, error: any }) => {
    return new Promise((resolve,reject) => {
        if(success !== ''){
            setTimeout(() => {
                resolve({ status: 200, msg: success})
            }, 1000);
        }else{
            setTimeout(() => {
                reject({ status: 400, msg: error })
            }, 300);
        }
    })  
}

export const excluirContaPostApi = async () => {
    try {
        const response = await api.post('/empresas/solicitar-exclusao-conta');
        return response.data
    } catch (error: any) {
        console.log(error);
    }
}

export const getVerificationAccession = async ( isPlanFree : boolean ) => {

    // Trecho para teste
    // const testResp : cancelarPlanProps = {
    //     // codigoErro: 1,
    //     // dataExpiracaoCredito: "2023-11-15",
    //     // erroMsg: "Cliente com cashback disponível.",
    //     // hasImpeditivo: true,
    //     // saldoTransacaoDisponivel: 36,
    //     // valorTotalTransacoesAdicionais: 15.00,
    //     // transacoesAdicionaisUtilizadas: 75,
    //     // dataInicio: "2023-11-15",
    //     // dataFim: "2023-12-10",
    // }
    // return new Promise((resolve,reject) => {
    //     setTimeout(() => {
    //         // resolve({ status: 200, data: testResp })
    //         resolve(testResp)
    //     }, 1000);
    // })

    const response = await api.get(`/adesao/verificaCancelamentoAdesao?isMigracaoGratuito=${ isPlanFree ? 'true' : 'false' }`)
    return response.data
    
}

export const deleteDisableInstance = async (idCampaing : number) => {
    const response = await updateActiveGeneralRulesQuery({
        "idCampaingActive": idCampaing,
        "observacao": '',
        "percentualCreditos": 5,
        "isActiveInstance": false
    })
    return response
}

export const deleteMigrateOrCancel = async (isMigrateFree : boolean = false) => {
    // true = migração | false = exclusão
    const response : { data: cancelarPlanProps } = await deleteAccession(isMigrateFree)
    return response.data
}