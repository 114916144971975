export default {
  COLORS: {
    BG_001: `linear-gradient(134.94deg, #F30063 29.85%, #960264 71.92%), #FFF7EE`,
    BG_002: `linear-gradient(0deg, rgba(36, 0, 50, 0.25), rgba(36, 0, 50, 0.25)), #FFF7EE`,
    BG_003: `linear-gradient(0deg, rgba(36, 0, 50, 0.1), rgba(36, 0, 50, 0.1)), #FFF7EE`,
    BG_004: `linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFF7EE`,
    BG_005: `linear-gradient(180deg, rgba(190, 9, 95, 0.375) 0%, rgba(235, 27, 101, 0.5) 100%)`,
    PRIM_800A: '#2C2A59BF',
    PRIM_800: '#240032',
    PRIM_700: '#6768AA',
    PRIM_200: '#BE095F',
    PRIM_100: '#EB1B65', 
    SEC_800: '#FAAB53',
    WHITE: '#FFF7EE',
    BLACK_800A: 'rgba(0,0,0,0.5)',
    BLACK_800: '#000',
    BLACK_600: '#616161',
    BLACK_500: '#828282',
    BLACK_400: '#BBD5',
    BLACK_300: '#CACAD5',
    BLACK_200: '#d5d4d4',
    BLACK_150: '#EDEDED',
    BLACK_100: '#f7f8fa',
    BLACK_100A: "#2400321A",
    ERROR_800: '#d32f2f',
    ERROR_700: '#FF0000',
    ERROR_100: '#FF5757',
    ERROR_100A: 'rgba(255,87,87,0.1)',
  },
  FONTS: {
    SOURCE_SANS_PRO: 'Source Sans Pro',
    MONTSERRAT: 'Montserrat',
  },
};
