import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as Refresh } from 'assets/icons/refresh.svg'
import theme from "styles/theme";

interface ICountDown {
  time: string;
  hasFinished?: any;
  isReset?: Boolean;
  labelReset?: string;
  outline?: boolean
}

const CountDown = ({ time = "0:05", hasFinished, isReset, labelReset, outline = false }: ICountDown) => {
  const tm = time.split(":");
  const min = parseFloat(tm[0]);
  const sec = parseFloat(tm[1]);

  const [minutes, setMinutes] = useState(min);
  const [seconds, setSeconds] = useState(sec);

  useEffect(() => {
    if (minutes >= 0 && seconds === 0) {
      setMinutes(minutes - 1);
      setSeconds(59);
    }

    if (minutes >= 0 || seconds >= 0) {
      const interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      if (minutes === 0 && seconds === 0) {
        setSeconds(0);
        hasFinished(true);
      }
      return () => clearInterval(interval);
    }
  }, [minutes, seconds]);

  const handleReset = () => {
    setMinutes(min);
    setSeconds(sec);
  };

  return minutes > 0 || seconds > 0 ? (
    <Number>
      <BoxRefresh className={outline ? 'out' : ''}><Refresh /></BoxRefresh>
      <NumberBox className={outline ? 'out' : ''}>
        {minutes < 10 ? ("0" + minutes).slice(-2) : minutes}
      </NumberBox>
      <div>:</div>
      <NumberBox className={outline ? 'out' : ''}>
        {seconds < 10 ? ("0" + seconds).slice(-2) : seconds}
      </NumberBox>
    </Number>
  ) : <>
    {isReset && (
      <button onClick={handleReset}>{labelReset || "Reiniciar"}</button>
    )}
  </>;
};
const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-size: 1.4rem;

  & > small {
    display: flex;
    font-size: 1rem;
    padding: 0 0 2px;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const BoxRefresh = styled.small`
  position:relative;
  color:${theme.COLORS.PRIM_800};

  &.out{
    color:${theme.COLORS.BLACK_200}
  }

  svg,
  svg path{
    fill:currentColor;
  }
  svg{
    width:30px;
    height:30px;
    animation: ${rotate} .8s linear infinite;
  }
`

const NumberBox = styled.div`
  display:flex;
  min-width:50px;
  min-height:37px;
  text-align:center;
  align-items: center;
  justify-content: center;
  background:${theme.COLORS.WHITE};
  border-radius:5px;
  
  &.out{
   background:transparent;
   border:1px solid ${theme.COLORS.BLACK_200}
  }
`

export default CountDown;
