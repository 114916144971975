import Button from "components/Button"
import { CheckboxSwitch } from "components/CheckboxSwitch"
import { TextFieldInput } from "layout/Home/screens/Pay"
import { useEffect, useState } from "react"
import { Label } from "styles/base"
import { moneyMask } from "util/masks"
import { BoxLabelText, H3 } from "./style"

const initialValueGeneralRules = {
  valorMaximoDia: '',
  valorMaximoDiaMostrar: `R$ 0.00`,
  percentualCreditos: '',
  percentualCreditosMostra: '',
  quantidadeVigencia: '',
  observacao: '',
}

interface IError {
  percentualCreditos: string,
  observacao?: string,
  valorMaximoDiaMostrar?: string,
  quantidadeVigencia?: string,
}
interface IInstanceManageRules {
  values?: any,
  isCkdSwitch?: boolean, // isso ativa o visualização do CheckboxSwitch
  valueInstanceActive?: any,
  handleActiveInstance?: any,
  onClick?: any,
  errors?: IError
}
const InstanceManageRules = ({
  values,
  valueInstanceActive,
  handleActiveInstance,
  isCkdSwitch,
  onClick,
  errors }: IInstanceManageRules) => {

  const [generalRules, setGeneralRules] = useState<any>(initialValueGeneralRules)
  
  useEffect(() => {
    setGeneralRules(values)
  },[values])

  const handlePercentualCreditos = (e: any) => {
    const value = moneyMask(e.target.value)
    const valorNumberMostra = value.replace('R$ ', '').replace('.', '')
    const valorNumber = valorNumberMostra.replace(',', '.')
    
    setGeneralRules({ ...generalRules, percentualCreditos: valorNumber, percentualCreditosMostra: valorNumberMostra })
  }

  const handleObservacao = (e: any) => setGeneralRules({ ...generalRules, observacao: e.target.value })

  const handleValorCompra = (e: any) => {
    const value = moneyMask(e.target.value)
    const valorNumber = value.replace('R$ ', '').replace('.', '').replace(',', '.')
    
    if (!isNaN(parseFloat(valorNumber))) {
      setGeneralRules({
        ...generalRules,
        valorMaximoDia: valorNumber,
        valorMaximoDiaMostrar: value,
      })
    }
  }
  
  const handleActiveInstanceGeneralRules = (e : boolean) => {
    handleActiveInstance(e)
    setGeneralRules({ ...generalRules, instanceActive: e })
  }

  const handleQuantidadeVigencia = (e: any) => {
    const value = !isNaN(e.target.value) ? e.target.value : '';
    setGeneralRules({ ...generalRules, quantidadeVigencia: value })
  }

  const handleClick = () => onClick(generalRules)

  return <>
    <div id="topo"></div>
    <H3>
      Regra para gerar cashback
      {isCkdSwitch && <CheckboxSwitch
        value={valueInstanceActive}
        onClick={handleActiveInstanceGeneralRules}
      />}
    </H3>
    <BoxLabelText id="percentual" >
      <Label>Defina o percentual de cashback distribuído por compra no "Lançar Compra”.</Label>
      <TextFieldInput
        type={'tel'}
        inputProps={{ maxLength: 5 }}
        InputLabelProps={{ shrink: true }}
        variant="standard"
        fullWidth
        label="Percentual"
        value={generalRules?.percentualCreditosMostra ? generalRules?.percentualCreditosMostra : ''}
        onChange={handlePercentualCreditos}
        disabled={!valueInstanceActive}
        error={!!errors?.percentualCreditos}
      />
      {!!errors?.percentualCreditos ? <small>{errors?.percentualCreditos}</small> : <small></small>}
    </BoxLabelText>

    <BoxLabelText id="observacao" >
      <TextFieldInput
        type={'text'}
        inputProps={{ maxLength: 50 }}
        className="boxObs"
        InputLabelProps={{ shrink: true }}
        variant="standard"
        fullWidth
        label="Observação sobre o benefício"
        value={generalRules?.observacao ? generalRules?.observacao : ""}
        onChange={handleObservacao}
        disabled={!valueInstanceActive}
        error={!!errors?.observacao}
      />
      {!!errors?.observacao ? <small>{errors?.observacao}</small> : <small></small>}
    </BoxLabelText>
    <br />
    <H3>Regras para receber cashback</H3>
    <BoxLabelText id="valorMaximoPorDia" >
      <Label>Defina o valor máximo por dia, em reais, para a utilização de cashback no pagamento com o Mony</Label>
      <TextFieldInput
        type={'tel'}
        inputProps={{ maxLength: 10 }}
        InputLabelProps={{ shrink: true }}
        variant="standard"
        fullWidth
        label="Valor máximo por dia"
        value={generalRules?.valorMaximoDiaMostrar ? generalRules?.valorMaximoDiaMostrar : ''}
        onChange={handleValorCompra}
        error={!!errors?.valorMaximoDiaMostrar}
      />
      {!!errors?.valorMaximoDiaMostrar ? <small>{errors?.valorMaximoDiaMostrar}</small> : <small></small>}
    </BoxLabelText>

    <BoxLabelText id="validadeEmDia" >
      <Label>Defina a validade do cashback gerado, em dias</Label>
      <TextFieldInput
        type={'tel'}
        inputProps={{ maxLength: 3 }}
        InputLabelProps={{ shrink: true }}
        variant="standard"
        fullWidth
        label="Validade em dias"
        value={generalRules?.quantidadeVigencia ? generalRules?.quantidadeVigencia : '' }
        onChange={handleQuantidadeVigencia}
        error={!!errors?.quantidadeVigencia}
      />
      {!!errors?.quantidadeVigencia ? <small>{errors?.quantidadeVigencia}</small> : <small></small>}
    </BoxLabelText>

    {onClick && <>
      <br/>
      <Button confirm text="Salvar" onClick={handleClick} />
    </>}
  </>
}
export { InstanceManageRules }