import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const MenuButton = styled.button`
    background: transparent;
    outline: none;
    border: 1px solid ${theme.COLORS.PRIM_700};
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font: inherit;
    width: 100%;
    
    :disabled {
        opacity: 0.4;
    }

    > span {
        line-height: 19px;
        font-size: .8em;
        text-align: center;
        color: ${theme.COLORS.WHITE};
        font-weight: bold;
    }
`;

export const FormButton = styled.button`
    background: transparent;
    outline: none;
    border: 1px solid ${theme.COLORS.PRIM_800};

    ${(props) => props.disabled && css`
      color: ${theme.COLORS.SEC_800};
      opacity: 0.5;
    `}

    border-radius: 6px;
    padding: 8px 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font: inherit;

    > span {
        line-height: 19px;
        font-size: .8em;
        text-align: center;
        color: ${theme.COLORS.PRIM_800};
        font-weight: bold;
    }
`;

export const ConfirmButton = styled(FormButton)`
    background-color: ${({ disabled }) => (disabled ? theme.COLORS.BLACK_150 : theme.COLORS.SEC_800)};
    border: none;
`;

export const LoginButton = styled.button`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 14px;
    background-color: ${({ disabled }) => (disabled ? theme.COLORS.BLACK_150 : theme.COLORS.SEC_800)};
    cursor: pointer;
    border: 1px solid ${theme.COLORS.BLACK_300};
    border-radius: 8px;
    font: inherit;

    > span {
        line-height: 19px;
        font-size: 1em;
        text-align: center;
        color: ${theme.COLORS.PRIM_800};
        font-weight: bold;
    }
`;

export const ButtonLink = styled.button`
    background: transparent;
    cursor: pointer;
    color: ${theme.COLORS.WHITE};
    margin-top: 4px;
    border: transparent;
    display: flex;
    justify-content: flex-end;
    opacity: 75%;
    font: inherit;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const ShowPasswordButton = styled.button`
    margin-left: -40px;
    right: 10px;
    position: absolute;
    margin-top: 10px;
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: 0;
`;

export const LinkButton = styled.button`
    background: transparent;
    cursor: pointer;
    border: transparent;
    font: inherit;

    span {
        color: ${theme.COLORS.SEC_800};
        font-weight: bold;
        margin-right: 5px;
    }
`;
