import * as yup from 'yup';
import { validationDDD, isPhoneDDDValid, isPhoneValid } from '../../../../util/validations';

export const schema = yup.object({
  ddd: yup.string()
    .required('O preenchimento do Campo DDD é obrigatório')
    .test('validation DDD', 'DDD inválido', (value): boolean => (
      value ? validationDDD(value) : false
    )),
  telefone: yup.string()
    .required('Celular é obrigatório')
    .min(10, 'Número de celular inválido. O campo Celular deve ter 9 dígitos.')
    .test('validation DDD', 'Celular inválido', (value): boolean => (
      value ? isPhoneValid(value) : false
    )),
}).required();
