import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { ReportsProvider } from "./context"

import { Reports } from './indexReports';
// import { ReportsDetails } from './CreditsUsed/details';
import { ReportsDetails } from './CreditsReports/details';

export const IndexReports = () => {
  const { path } = useRouteMatch();
  return <ReportsProvider>
    <Switch>
      <Route exact path={`${path}/`} component={Reports} />
      <Route exact path={`${path}/:slug/detalhe`} component={ReportsDetails} />
    </Switch>
  </ReportsProvider>
}