import Button from "components/Button"
import { ButtonSave } from "../List/style"
import { default as Modal } from 'components/modal/modal';
import { BoxButtons, BoxPayment, LabelError, TableBox } from "./style";
import { useFormUpdate } from "./useForm";
import { Planos } from ".";
import { GridForm, TitleH2 } from './style';
import FormControl, { FormControlMultLine } from "components/FormControl";
import { cnpjMask, cpfMask, name, phoneMask, replaceEspecials } from "util/masks";
import { Label, Row, TableLine } from "styles/base";
import { UploadFile } from "components/UploadFile";
import { useEffect, useState } from "react";
import { getListEnterpriseUnits } from "../service";
import { CircularProgress } from "@mui/material";
import { useEnterpriseContext } from "../Context/enterpriseContext";
import { GridDadosApp, RowDadosApp } from "../style";
import { scrollToTop } from "util/utils";
import theme from "styles/theme";
import { removeAccents } from "util/validations";

interface IUnit {
  idUnidade: number,
  nomeUnidade: string,
  endereco: {
    cep: string,
    endereco: string,
    numero: string,
    complemento: string | null,
    bairro: string,
    cidade: string,
    uf: string
  },
  codigoEstabelecimento: string,
  principal: true
}
const ListUnid = ({ idInstancia }: { idInstancia: number }) => {
  const [load, setLoad] = useState(false)
  const [openIndex, setOpenIndex] = useState<number>()
  const [unid, setUnid] = useState<IUnit[]>([])

  useEffect(() => {
    if (idInstancia !== 0) {
      setLoad(true)
      getListEnterpriseUnits(idInstancia)
        .then(res => setUnid(res.data.page))
        .catch(error => console.log(error))
        .finally(() => setLoad(false))
    }
  }, [idInstancia])

  const listaInstancia = unid.length > 0 ? unid.map(i => {
    return {
      codEstabelecimento: i.codigoEstabelecimento,
      unidade: i.nomeUnidade,
      dados: i
    }
  }) : []

  const openDetail = (id: number) => openIndex !== id ? setOpenIndex(id) : setOpenIndex(-1)

  if (load) {
    return <>Loading .... </>
  }
  return listaInstancia.length > 0
    ? <>
      <TableBox>
        <thead>
          <tr>
            <th>Código do estabelecimento</th>
            <th>Unidade</th>
          </tr>
        </thead>
        {listaInstancia.map((i, idx) => {
          return <tbody key={idx}><TableLine className="info"
            onClick={() => openDetail(idx)}
            style={{ backgroundColor: theme.COLORS.BLACK_400 }}>
            <th>{i.codEstabelecimento}</th>
            <th>{i.unidade}</th>
          </TableLine>
            <TableLine
              className="more"
              style={{ display: (openIndex === (idx)) ? 'contents' : 'none', }}>
              <th colSpan={2}>
                <p>{i.dados.endereco.endereco}, {i.dados.endereco.numero}</p>
                <p>{i.dados.endereco.bairro}</p>
                <p>{i.dados.endereco.cidade}, {i.dados.endereco.uf} - CEP: {i.dados.endereco.cep}</p>
                {i.dados.endereco.complemento && <p>{i.dados.endereco.complemento}</p>}
                <a href={`https://www.google.com.br/maps/place/${i.dados.endereco.endereco}+${i.dados.endereco.numero}+${i.dados.endereco.bairro}+${i.dados.endereco.cidade}+${i.dados.endereco.uf}`} target="_blank">
                  veja no mapa</a>
              </th>
            </TableLine>
          </tbody>
        })}
      </TableBox>
    </>
    : <>Nenhuma unidade cadastrada</>
}
const PaymentData = () => {
  const dados = {
    nome: 'João Magalhaes',
    numeroCartao: '1234123412349988'
  }
  const hideCardPay = (e: any) => "****.****.****." + e.slice(e.length - 4)
  return <BoxPayment>
    <p><strong>Nome impresso no cartão:</strong> {dados.nome}</p>
    <p><strong>Número do cartão:</strong> <span>{hideCardPay(dados.numeroCartao)}</span></p>
  </BoxPayment>
}
interface IFormUpdate {
  onSuccess: any
  onCancel: any,
  dadosUpdate: any
}
const FormUpdate = ({ onSuccess, onCancel, dadosUpdate }: IFormUpdate) => {
  const { setErrorGeral } = useEnterpriseContext()

  const { values, setValues, open, load,
    emailConfirmation, setEmailConfirmation,
    fullTel, setFullTel,
    setOpen, errorValues, setErrorValues, setLoad,
    isValid, handleUpdate, handlePaste,
    editEmail, setEditEmail } = useFormUpdate()

  const isEditDisabled = values.dadosEmpresa.instanciaIsAtiva === 'ATIVA' ? true : false;

  const handleModal = () => setOpen(!open)
  const handleCancel = () => onCancel(true)

  useEffect(() => {
     // Carrega os dadosUpdate
    setValues(dadosUpdate)
    setFullTel(dadosUpdate.dadosDiretor.ddd + dadosUpdate.dadosDiretor.telefone);

    return () => setValues(values)
  }, [dadosUpdate])

  const handleSave = () => {
    
    if (isValid(values)) {
      setErrorGeral('Ops... verifique os dados sinalizados para prosseguir com a atualização da empresa')
      scrollToTop({})
      return
    }

    setLoad(true)
    handleUpdate(values)
      .then((res) => {
        onSuccess(true)
      })
      .catch((error) => {
        const msg = error.response.data
        setErrorGeral(`Erro: ${msg.status} - ${msg.message}`)
      })
      .finally(() => setLoad(false))
  }

  return <>
    <Modal
      text="Deseja realmente sair? Os dados não salvos serão perdidos"
      open={open}
      onClose={handleModal}
      onYes={handleCancel}
    />

    <Planos
      values={values.idPlanoSelecionado || values.dadosEmpresa.codigoPlano}
      setValues={(value: any) => setValues((prevState: any) => ({
        ...prevState,
        idPlanoSelecionado: value
      }))
      }
      errorLabel={''}
      disabled={isEditDisabled}
    />

    <TitleH2>DADOS DA EMPRESA</TitleH2>
    <GridForm columns={3}>
      <div data-name={values.dadosDiretor.nome}>
        <FormControl
          id="nome-empresa"
          text="Nome da empresa"
          value={values.dadosEmpresa.razaoSocial}
          size={28}
          onChange={({ target }) => setValues({
            ...values,
            dadosEmpresa: {
              ...values.dadosEmpresa,
              razaoSocial: target.value,
            },
          })}
          maxLength={40}
          disabled={isEditDisabled}
        />
      </div>

      <div>
        <FormControl
          id="cnpj-empresa"
          text="CNPJ da empresa"
          value={cnpjMask(values.dadosEmpresa.cnpj)}
          size={18}
          onChange={({ target }) => setValues({
            ...values,
            dadosEmpresa: {
              ...values.dadosEmpresa,
              cnpj: target.value,
            },
          })}
          maxLength={18}
          disabled={isEditDisabled}
        />
      </div>

      <div>
        <FormControl
          id="nome-app"
          text="Nome da empresa (App)"
          value={values.dadosEmpresa.nomeFantasia}
          size={28}
          onChange={({ target }) => setValues({
            ...values,
            dadosEmpresa: {
              ...values.dadosEmpresa,
              nomeFantasia: target.value,
            },
          })}
          maxLength={40}
          disabled={isEditDisabled}
        />
      </div>
    </GridForm>

    <RowDadosApp>
      <TitleH2 start="true">DADOS PARA O APP MONY</TitleH2>
      <Label size="16" bold>Atenção! As informações abaixo serão publicadas no App Mony para seus clientes!</Label>
      <GridDadosApp>
        <div className="desc">
          <div>
            <FormControl
              id="nome-app"
              text="Nome da empresa (App)"
              value={values.dadosEmpresa.nomeFantasia}
              size={28}
              onChange={({ target }) => setValues({
                ...values,
                dadosEmpresa: {
                  ...values.dadosEmpresa,
                  nomeFantasia: target.value,
                },
              })}
              maxLength={40}
              disabled={isEditDisabled}
            />
          </div>
          <div>
            <FormControlMultLine
              id="descricao-app"
              text="Descrição da empresa (App)"
              row={4}
              value={values.dadosEmpresa.descricao}
              onChange={({ target }) => setValues({
                ...values,
                dadosEmpresa: {
                  ...values.dadosEmpresa,
                  descricao: target.value,
                },
              })}
              disabled={isEditDisabled}
            />
          </div>
        </div>
        <div>
          <TitleH2 start="true">LOGO PARA O APLICATIVO</TitleH2>
          <Row>
            <UploadFile
              values={values.dadosEmpresa.logo}
              setValues={(target: any) => setValues({
                ...values,
                dadosEmpresa: {
                  ...values.dadosEmpresa,
                  logo: target,
                },
              })}
              errorLabel={errorValues.dadosEmpresa.logo}
            />
          </Row>
        </div>
      </GridDadosApp>
    </RowDadosApp>

    <TitleH2>DADOS DO RESPONSÁVEL</TitleH2>
    <GridForm columns={3}>
      <div>
        <FormControl
          id="nome"
          text="Nome"
          value={values.dadosDiretor.nome}
          onChange={({ target }) => setValues({
            ...values,
            dadosDiretor: {
              ...values.dadosDiretor,
              nome: name(target.value),
            },
          })}
          maxLength={40}
          disabled={isEditDisabled}
        />
      </div>

      <div>
        <FormControl
          id="cpf"
          text="CPF"
          value={cpfMask(values.dadosDiretor.cpf)}
          size={14}
          onChange={({ target }) => setValues({
            ...values,
            dadosDiretor: {
              ...values.dadosDiretor,
              cpf: target.value,
            },
          })}
          disabled={isEditDisabled}
        />
      </div>

      <div>
        <FormControl
          id="celular"
          text="Telefone/Celular"
          value={phoneMask(fullTel)}
          size={12}
          onChange={({ target }) => {
            setFullTel(target.value)

            const telCleared = replaceEspecials(target.value);

            setValues({
            ...values,
            dadosDiretor: {
              ...values.dadosDiretor,
              ddd: telCleared.substring(0, 2),
              telefone: telCleared.substring(2),
            },
          })}
        }
          maxLength={15}
        />
      </div>
    </GridForm>
    <GridForm columns={2}>
      <div>
        <FormControl
          id="email"
          text="E-mail"
          value={values.dadosDiretor.email}
          size={32}
          onChange={({ target }) => {
            setValues({
              ...values,
              dadosDiretor: {
                ...values.dadosDiretor,
                email: removeAccents(target.value),
              },
            })
            setEditEmail(true)
          }}
          maxLength={50}
        />
        <LabelError className="error">{errorValues.dadosDiretor.email}</LabelError>
      </div>
      {editEmail
        ? <div>
          <FormControl
            id="emailConfirmar"
            text="Confirmar o e-mail"
            value={emailConfirmation}
            size={32}
            onChange={({ target }) => setEmailConfirmation(removeAccents(target.value))}
            maxLength={50}
            autoComplete="off"
            onPaste={handlePaste}
          />
          <LabelError className="error">{errorValues.dadosDiretor.emailConfirmation}</LabelError>
        </div>
        : null}
    </GridForm>

    <TitleH2>Unidades participantes</TitleH2>
    <ListUnid idInstancia={values.dadosEmpresa.id} />

    <BoxButtons>
      <Button onClick={() => handleModal()} text="Voltar" />
      <ButtonSave>
        <Button onClick={() => handleSave()} text="Editar informações" disabled={load} />
      </ButtonSave>
      {load && <CircularProgress />}
    </BoxButtons>
  </>
}
export { FormUpdate }