import { useContext, useEffect, useState } from "react"
import { FileContainer, Label } from "styles/base"
import { CircularProgress } from "@mui/material"

import styled from "styled-components"
import theme from "styles/theme"

import Compress from "browser-image-compression";
import { LabelError } from "layout/Home/screens/Enterprise/Form/style"
import { IconFilePicker } from "components/Icons/iconFilePicker"
import { DataMonyContext } from "layout/Home/screens/Account/dataMony"
import { BoxImagemDefault } from "./styles"

interface IUploadFile {
  id?:string,
  values?: any,
  setValues?: any,
  errorLabel?: any,
  disabled?: boolean,
  hasLoadImg?: boolean,
  reset?: any
}

export const UploadFile = ({ id, values, setValues, errorLabel, disabled, hasLoadImg = true, reset}: IUploadFile) => {
  const { imgValue, setImgValue } = useContext(DataMonyContext)

  const [error, setError] = useState(errorLabel)
  const [imageLoad, setImageLoad] = useState<boolean>(hasLoadImg)
  const [load, setLoad] = useState<boolean>(false)
  const [fileImage, setFileImage] = useState<Blob | null>()
  const [fileImageName, setFileImageName] = useState<string>()

  const clearError = ({ arq } : { arq?: string }) => {
    setError('')
  }
  const isLogoNull = (logo: string) => {
    if(logo){
      const urlLogo = logo?.split('/')
      const urlLast = urlLogo.length - 1
      return urlLogo[urlLast]  
    }
  }
  useEffect(() => {
    if(imgValue === "limpar"){
      setFileImage(null)
      setFileImageName('')
      setImgValue('')
    }
  },[imgValue])
  
  const updateFile = (e: any) => {
    const file = { src: e.files[0], name: e.files[0].name }
    const extensao = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG']

    clearError({ arq: file.name })

    if (e.files?.length > 0) {
      const extFile = e.files[0].name.split('.').pop() || ''

      if (!extensao.includes(extFile)) {
        // console.log(extFile)
        setError(`A extensão "*.${e.files[0].name.split('.').pop()}" do arquivo é inválida`)
        setFileImage(null)
        return
      }
    }

    const maxMb = 50
    const options = {
      maxSizeMB: 0.05,
      useWebWorker: true
    }

    const sizeMb = (arq: any) => arq && parseFloat( (arq.size / 1024).toFixed(1) )
    // console.log('compress', maxMb, sizeMb(file.src), file.src)

    if (sizeMb(file.src) >= maxMb) {
      setLoad(true)

      Compress(file.src, options)
        .then(compressedBlob => {
          if (sizeMb(compressedBlob) < maxMb) {

            const fileBlob = new File([compressedBlob], compressedBlob.name, { type: 'contentType', lastModified: Date.now()});
            setValues(fileBlob)
            setFileImage(fileBlob)
            setFileImageName(file.name)
          } else {
            setFileImage(null)
            setFileImageName('')
            setError('Tamanho máximo é de 250kb')
            return;
          }
        })
        .catch(error => console.log(error))
        .finally(() => setLoad(false))
    } else {
      setFileImage(file.src)
      setFileImageName(file.name)
      setValues(file.src)
    }
  }


  return <BoxUpload>
    <FileContainer>
      {fileImage
        ? <>
          <img src={URL.createObjectURL(fileImage)} alt="Logo" style={{ 
              width: '120px',
              height: 'auto',
              objectFit: 'cover', }} />
          <Label style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: 140, }} bold>
            <small>{fileImageName}</small>
          </Label>
        </>
        : <>
          {isLogoNull(values) === 'null' || isLogoNull(values) === null
          ? <BoxImagemDefault> 
            Sem <br/> imagem
          </BoxImagemDefault>
          : <>
            {imageLoad && <CircularProgress />}
            
            {(fileImage === null && values === '') 
            || (fileImage === undefined && values === '') 
            || (fileImage !== undefined && error !== "") 
            ? <IconDefault><IconFilePicker /></IconDefault>
            : <img 
                src={ values }
                onLoad={() => setImageLoad(false)} 
                alt="Logo"
                style={{
                  display: imageLoad ? 'none' : 'flex',
                  width: '120px',
                  height: 'auto',
                  objectFit: 'cover',
                }} />
            }
          </> }
        </>}
    </FileContainer>

    <div>
      <Label bold>O arquivo do seu logo deve:</Label>
      <ul style={{ display: 'grid', gap: 5, marginLeft: 20, padding: '12px 0', }}>
        <li><Label>Estar no formato .png ou .jpg</Label></li>
        <li><Label>Ter no mínimo 264x264 pixels</Label></li>
        <li><Label>Ter no máximo 250kb</Label></li>
      </ul>
      
      <LabelButtonFile className={(load || disabled) ? "disabled" : ""}>
          {load
          ? <CircularProgress style={{ width: 18, height: 18 }} />
          : <>Selecione</>}
          
          <input
            data-id={id}
            id={"file-picker"} 
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            style={{ opacity: 0, display: 'none' }}
            onChange={({ target }) => updateFile(target)}
            disabled={(load || disabled)}
            // {...register(label, { required })}
          />
        </LabelButtonFile>
      
      <p>
        <LabelError id="file-picker__erro" className="error error-upload">{error}</LabelError>
      </p>
    </div>
  </BoxUpload>
}

const IconDefault = styled.div`
 color:${theme.COLORS.PRIM_100}
`
const BoxUpload = styled.div`
  display:flex;
  padding:20px 0;
`
const LabelButtonFile = styled.label`
  display:inline-flex;
  font-weight:bold;
  padding:12px 25px;
  color:${theme.COLORS.PRIM_800};
  border:1px solid ${theme.COLORS.PRIM_800};
  border-radius:8px;
  background:${theme.COLORS.SEC_800};

  &.disabled{
    opacity: .2;
  }
`