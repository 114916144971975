import { useState } from 'react';
import theme from 'styles/theme';
import { ReactComponent as EyeCrossed } from '../../assets/icons/eye-crossed.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { Column } from '../../styles/base';
import { ButtonLink, ButtonWrapper, ShowPasswordButton } from '../Button/styles';
import { FormInput, FormLabel, FormLabelSelect, FormSelect, FormTextarea, LabelError, MoneyInput } from './styles';

interface Props2 {
  text: string,
  maxLength: number,
  value: any,
  onChange: any,
  disabled?: boolean
}

interface Props {
  id?: string,
  ref?: any,
  text: string,
  password?: boolean,
  money?: boolean,
  select?: boolean,
  selectSeller?: boolean,
  selectServiceType?: boolean,
  placeholder?: string,
  maxLength?: number,
  autoComplete?: string,
  options?: any,
  value?: any,
  moneyValue?: number,
  onClick?(): any,
  onChange: ({ target }: { target: any }) => any,
  constract?: boolean,
  size?: number,
  disabled?: boolean;
  style?: {};
  className?: string,
  title?: string,
  onPaste: any,
  onCopy: any,
}

const defaultProps = {
  password: false,
  select: false,
  selectSeller: false,
  money: false,
  value: '',
  placeholder: '',
  moneyValue: 0,
  maxLength: 14,
  onClick: () => { },
  options: [],
  constract: false,
  size: 20,
  className: '',
  title: '',
  onPaste: () => { },
  onCopy: () => { },
};

const FormControl = ({
  text, password, money, id, ref, select, maxLength, value, moneyValue, autoComplete, onChange, onClick,
  constract, size, options, selectSeller, selectServiceType, placeholder, disabled, style, className, title,
  onPaste, onCopy
}: Props & typeof defaultProps) => {
  const [showPassword, setShowPassword] = useState(false);

  if (select) {
    return (
      <Column>
        <FormLabelSelect constract={constract}>{text}</FormLabelSelect>
        <FormSelect
          ref={ref}
          id={id}
          style={style}
          value={value}
          onChange={onChange}
          className={className}
          title={title}
          disabled={disabled}
          onPaste={onPaste}
          onCopy={onCopy}
        >
          <option value="">{placeholder}</option>
          {options && options.map(({ cdInstancia, nome }:
            { cdInstancia: string, nome: string }) => (
            <option key={cdInstancia} value={cdInstancia}>{nome}</option>
          ))}
        </FormSelect>
        <LabelError className="error"></LabelError>
      </Column>
    );
  }

  if (selectServiceType) {
    return (
      <Column>
        <FormLabelSelect constract={constract}>{text}</FormLabelSelect>
        <FormSelect
          ref={ref}
          id={id}
          style={style}
          value={value}
          onChange={onChange}
          className={className}
          title={title}
          disabled={disabled}
          onPaste={onPaste}
          onCopy={onCopy}
        >
          <option value="">{placeholder}</option>
          {options && options.map(({ cdPlano, nome }:
            { cdPlano: string, nome: string }) =>  (
              <option key={cdPlano} value={cdPlano}>{nome}</option>
            ))}
        </FormSelect>
        <LabelError className="error"></LabelError>
      </Column>
    );
  }

  if (selectSeller) {
    return (
      <Column>
        <FormLabelSelect constract={constract}>{text}</FormLabelSelect>
        <FormSelect
          ref={ref}
          value={value}
          onChange={onChange}
          className={className}
          title={title}
          disabled={disabled}
          onPaste={onPaste}
          onCopy={onCopy}
        >
          <option value="">{placeholder}</option>
          {options && options.map(({ login, nome }:
            { login: string, nome: string }) => (
            <option key={login} value={login}>{nome}</option>
          ))}
        </FormSelect>
        <LabelError className="error"></LabelError>
      </Column>
    );
  }

  if (password) {
    return (
      <Column>
        <FormLabel constract={constract}>{text}</FormLabel>
        <div style={{ display: 'flex' }}>
          <FormInput
            ref={ref}
            style={{ flex: 1 }}
            constract={constract}
            maxLength={maxLength}
            value={value}
            onChange={onChange}
            type={showPassword ? 'text' : 'password'}
            size={size}
            className={className}
            title={title}
            onPaste={onPaste}
            onCopy={onCopy}
          />
          <ShowPasswordButton onClick={() => setShowPassword(!showPassword)}>
            {showPassword
              ? (<EyeCrossed style={{ width: 22, height: 22, fill: theme.COLORS.WHITE }} />)
              : (<Eye style={{ width: 22, height: 22, fill: theme.COLORS.WHITE }} />)}
          </ShowPasswordButton>
          <LabelError className="error"></LabelError>
        </div>
        <ButtonWrapper>
          <ButtonLink onClick={onClick}>Esqueceu a senha?</ButtonLink>
        </ButtonWrapper>
      </Column>
    );
  }

  return (
    <Column>
      <FormLabel constract={constract} disabled={disabled}>{text}</FormLabel>
      <FormInput
        ref={ref}
        id={id}
        autoComplete={autoComplete}
        disabled={disabled}
        constract={constract}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        size={size}
        type="text"
        className={className}
        title={title}
        onPaste={onPaste}
        onCopy={onCopy}
      />
      <LabelError className="error"></LabelError>
    </Column>
  );
};

export const CurrencyInputt = ({
  text, maxLength, value, onChange, disabled
}: Props2) => (
  <Column>
    <FormLabel>{text}</FormLabel>
    <MoneyInput
      allowNegativeValue={false}
      allowDecimals
      decimalsLimit={2}
      decimalSeparator="."
      disableGroupSeparators
      fixedDecimalLength={2}
      prefix="R$ "
      maxLength={maxLength}
      value={value}
      onValueChange={onChange}
      disabled={disabled}
    />
    <LabelError className="error"></LabelError>
  </Column>
);

interface MultLineProps {
  id?: string,
  text?: string,
  value?: any,
  defaultValue?: string,
  row?: number,
  onChange?: ({ target }: { target: any }) => any,
  disabled?: boolean
}
export const FormControlMultLine = ({ id = "standard-multiline-static", text, value, defaultValue, row = 4, onChange, disabled }: MultLineProps) => {
  return <Column>
    <FormLabel  disabled={disabled}>{text}</FormLabel>
    {!defaultValue ?
      <FormTextarea
        id={id}
        data-teste='aqui'
        rows={row}
        value={value}
        onChange={onChange}
        disabled={disabled}
      ></FormTextarea>
      : <FormTextarea id={id} rows={row} disabled={disabled} defaultValue={defaultValue} ></FormTextarea>
    }
    </Column>
}

FormControl.defaultProps = defaultProps;

export default FormControl;
