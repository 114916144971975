import Button from "components/Button";
import { AppBody, DataWrapper, GridHead, GridLine, Label, Row, Spacing, TableLineGrid } from "styles/base";
import theme from "styles/theme";
import { moneyMask, number, replaceEspecials } from "util/masks";
import QrCode from "./qrCode/QrCode";
import usePay from "./usePay";
import { default as Modal } from 'components/modal/modal'
import { CircularProgress, Grid, TextField } from "@mui/material";
import TitlePage from "components/TitlePage";
import { getUsuarios, postFinalizarTransacao, usuarioTel } from "layout/Home/service";
import { FormClient, IFormInput } from "layout/Home/Components/FormClient";
import { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { UsuarioModel } from "layout/Home/Models";
import { useForm } from 'react-hook-form';
import { schema } from "layout/Home/Components/FomValidation";
import FormatInputValueFunction from 'components/NumberFormat/FormatInputValueFunction';
import { InputNumberFormat } from "components/NumberFormat";
import FormControl from "components/FormControl";
import { ErrorAlert } from "components/ErrorAlert";
import moment from "moment";
import { scrollToTop } from "util/utils";
import styled from 'styled-components'

const defaultValues = {
  ddd: '',
  telefone: '',
  nome: '',
  nomeCadastro: '',
  vendedor: '',
  nomeVendedor: '',
  valorCompra: 0,
  pin: '',
  valorCashback: 0,
  valorCashbackExibicao: '',
  loginUsuarioFavorecido: '',
  saldo: '',
  dataFimValidade: '',
  proximosPontosExpirar: '',
  labelProximosPontosExpirar: '',
  valorMaxDia: ''
};

interface IUsuario {
  idUsuario: number,
  codigoInscricaoFederal: null | number,
  nome: string,
  login: string,
  email: string,
  instanciaVO: null,
  ddd: string,
  telefone: string,
  ativo: boolean,
  termoAdesao: null | boolean,
  dataTermoAdesao: null | boolean,
  statusCadastro: string
}

const Pay = () => {
  const { clearQrCode,
    finishQrCode,
    handleSubmit,
    handleValorCompra,
    payQr,
    dataQrCode,
    dataQrCodeDecode,
    open,
    load
  } = usePay();

  
  /*if (payQr) {
    return load 
      ? <AppBody>
          <TitlePage title="Usar cashback"/>
          <DataWrapper><CircularProgress /></DataWrapper>
        </AppBody>
      : <QrCode clear={clearQrCode} finish={finishQrCode} data={dataQrCode} decode={dataQrCodeDecode} />    
  }*/

  const valorNumeroCaixa = 1;

  const [values, setValues] = useState(defaultValues);
  const [openModalCancelar, setOpenModalCancelar] = useState(false);
  const [cashbackValues, setCashbackValues] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [showName, setShowName] = useState(false);
  const [returnError, setReturnError] = useState('');
  const [disabledForm, setDisabledForm] = useState(false);

  const [boolCashback, setBoolCashback] = useState(false);
  const [boolCashbackUtilizado, setBoolCashbackUtilizado] = useState(false);

  const [loadingFormClient, setLoadingFormClient] = useState(false);
  const [loadingCashback, setLoadingCashback] = useState(false);
  const [disabledButtonCashback, setDisabledButtonCashback] = useState(false);
  const [listSellers, setListSellers] = useState<IUsuario[]>([]);


  const {
    register: registerClient,
    formState: { errors: errosClient },
    clearErrors: clearErrorsClient,
    handleSubmit: handleSubmitClient,
    reset: resetClient,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const handleModal = () => setOpenModal(!openModal)
  const handleModalCancelar = () => setOpenModalCancelar(!openModalCancelar)
  const handleCancel = () => {
    handleModal()
  }

  const loadSellers = async () => {
    getUsuarios()
      .then(res => {
        const user: UsuarioModel[] = res.data.page
        const normalizarUser = user.map(i => ({
          idUsuario: i.id,
          codigoInscricaoFederal: null,
          nome: i.nome,
          login: i.cpf,
          email: i.email,
          instanciaVO: null,
          ddd: "",
          telefone: "",
          ativo: true,
          termoAdesao: null,
          dataTermoAdesao: null,
          statusCadastro: "ATIVO"
        }))
        setListSellers(normalizarUser);
      })
      .catch(error => console.log(error))
  };

  const handleCashback = async () => {
    setLoadingCashback(true);
    setDisabledButtonCashback(true);
    setReturnError('');
    try{
      await postFinalizarTransacao({
        valor: values.valorCashback == 0 ? Number(values.valorCashbackExibicao) : values.valorCashback,
        numeroCaixa: valorNumeroCaixa,
        login: values.loginUsuarioFavorecido
      });
      scrollToTop({elemento: "#app-home"});
      setBoolCashbackUtilizado(true);
      setLoadingCashback(false);
      setDisabledButtonCashback(false);
    } catch(error: any){
      console.log(error);
      scrollToTop({elemento: "#app-home"});
      setLoadingCashback(false);
      setDisabledButtonCashback(false);
      setReturnError(values.valorCashback > Number(values.saldo) ? 
        'Saldo insuficiente' : 
        values.valorCashback > Number(values.valorMaxDia) ? 
          'Valor máximo por dia excedido' : error.response.data.message);
    }
  }

  const handleValorCashback = (value: any) => {
    if (value.floatValue) {
      setValues({
        ...values,
        valorCashback: value.floatValue / 100,
        valorCashbackExibicao: value.formatedValue,
      });
    }
  };

  const handleUser = async (data: IFormInput) => {
    try {
      setReturnError('');
      setLoadingFormClient(true);
      setDisabledForm(true);

      setValues({
        ...values,
        ...data,
        nome: '',
      });
      const response = await usuarioTel(data.ddd, replaceEspecials(data.telefone));
      const saldo: number = response.data.instanciaVO.saldoInstancia;
      const valorMaxDia: number = response.data.instanciaVO.valorMaxDia;
      const dataFimValidade = response.data.instanciaVO.dataFimValidade != null ? moment(response.data.instanciaVO.dataFimValidade).format('DD/MM/YYYY') : '';
      const proximosPontosExpirar = response.data.instanciaVO.proximosPontosExpirar != null ? response.data.instanciaVO.proximosPontosExpirar : 0;
      let result: number = saldo > valorMaxDia ? valorMaxDia : saldo;

      setValues({
        ...values,
        ...data,
        nome: response.data.nome,
        loginUsuarioFavorecido: response.data.login,
        saldo: (response.data.instanciaVO.saldoInstancia).toFixed(2),
        valorMaxDia: (response.data.instanciaVO.valorMaxDia).toFixed(2),
        dataFimValidade,
        proximosPontosExpirar,
        labelProximosPontosExpirar : proximosPontosExpirar > 0 ? moneyMask(`${FormatInputValueFunction(proximosPontosExpirar * 100)}`) + " em " + dataFimValidade : 'Sem saldo a expirar',
        valorCashbackExibicao: (result).toFixed(2).toString(),
      });

      loadSellers();
      setBoolCashback(true);
      setLoadingFormClient(false);
      setShowName(false);
    } catch (error) {
      setLoadingFormClient(false);
      setShowName(true);

      console.log(error)
      setDisabledForm(false);
      setReturnError('Celular não cadastrado.')
    } finally {
      // setDisabledForm(true);
    }
  };

  const handleFinalizar = useCallback(() => {
    setValues(defaultValues);
    resetClient({
      ddd: '',
      telefone: '',
    });
    clearErrorsClient();
    setBoolCashback(false);
    setShowName(false);
    setReturnError('');
    setDisabledForm(false);
    setBoolCashbackUtilizado(false);
  }, []);

  const handleClear = useCallback(() => {
    setValues(defaultValues);
    resetClient({
      ddd: '',
      telefone: '',
    });
    clearErrorsClient();
    setBoolCashback(false);
    setShowName(false);
    setReturnError('');
    setDisabledForm(false);
    handleModalCancelar();
    setBoolCashbackUtilizado(false);
  }, []);

  function handleClearErros() {
    setReturnError('');
  }

  return boolCashbackUtilizado
    ?(
    <AppBody>
          <Spacing margin={20} />
          <DataWrapper>
            <Container>
              <TextContainer>
                <h3>Cashback utilizado com sucesso!</h3>
              </TextContainer>
              <Button
                confirm
                text="Ok"
                onClick={() => handleFinalizar()}
                style={{ alignSelf: 'center', height:'40px', width:'150px' }}
              />
            </Container>
          </DataWrapper>
    </AppBody>
  )
  : (
    <AppBody>
      <Modal
        text="Tem certeza que deseja cancelar? Todos os dados serão perdidos."
        open={openModalCancelar}
        onClose={handleModalCancelar}
        onYes={handleClear}
        textClose="voltar"
        textConfirm="cancelar" />

      <TitlePage title="Usar cashback"/>

      <Label size="24" bold color={theme.COLORS.WHITE}>1) Insira os dados do cliente</Label>

      {!!Object.values(errosClient).length && (
          <>
            {Object.values(errosClient).map((item) => (
              <ErrorAlert key={item.message} message={item.message} />
            ))}
          </>
        )}

        {returnError &&  <ErrorAlert message={returnError} /> }

      <Spacing margin={20} />

      <DataWrapper>
            <RowLine>
              <FormClient
                onSubmit={handleUser}
                handleSubmit={handleSubmitClient}
                register={registerClient}
                loading={loadingFormClient}
                disabledForm={disabledForm}
                clearErrors={() => handleClearErros()}
              />
            </RowLine>

            {values.nome
              ?
              <RowLine>
                  <FormControl
                    text="Nome do cliente"
                    disabled
                    value={values.nome}
                    onChange={() => null/*console.log('oi')*/}
                  />
                  <InputNumberFormat
                    label="Saldo disponível"
                    type="text"
                    placeholder="R$ 0,00"
                    format={FormatInputValueFunction}
                    onValueChange={(e) => null}
                    value={values.saldo}
                    maxLength={13}
                    disabled
                  />
                
                    <FormControl
                    text="Saldo a expirar"
                    onChange={() => null}
                    value={values.labelProximosPontosExpirar}
                    maxLength={13}
                    disabled
                    />

                    <InputNumberFormat
                      label="Valor máximo por dia"
                      type="text"
                      placeholder="R$ 0,00"
                      format={FormatInputValueFunction}
                      onValueChange={(e) => null}
                      value={values.valorMaxDia}
                      maxLength={13}
                      disabled
                    />
              </RowLine> : null}
        </DataWrapper>

        {boolCashback && values.saldo != '0.00' ? (
          <>

          <Spacing margin={20} />

          <Label size="24" bold color={theme.COLORS.WHITE}>2) Informe o valor a ser utilizado</Label>

          <Spacing margin={20} />

          <DataWrapper>

            <RowLine>
              
              <InputNumberFormat
                label="Valor cashback"
                //type="text"
                placeholder="R$ 0,00"
                format={FormatInputValueFunction}
                value={values.valorCashbackExibicao}
                onValueChange={handleValorCashback}
                maxLength={13}
              />

              <Button
                text="Cancelar"
                onClick={() => handleModalCancelar()}
                style={{ alignSelf: 'flex-end', height:'40px' }}
              />

              <Button
                confirm
                text="Avançar"
                onClick={() => handleCashback()}
                style={{ alignSelf: 'flex-end', height:'40px', width:'103px' }}
                disabled={disabledButtonCashback}
                loading={loadingCashback}
              />

              {load && <CircularProgressBox />}
            </RowLine>
          </DataWrapper>
          </>
        ) : null }
    </AppBody>
  )
}

const CircularProgressBox = styled(CircularProgress)({
  alignSelf: 'end',
  width:'30px',
  height:'30px'
})
const RowLine = styled(Row)({
  gap:'15px',
  padding:'20px 0 0',
})

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const colorBlue = theme.COLORS.PRIM_800
export const TextFieldInput = styled(TextField)(({ theme }) => ({
  justifyContent:'end',
  maxWidth: '250px',
  height:'57px',

  "& > label":{
    top:'0px',
    marginTop:'0 !important',
    letterSpacing: '0.1px',
    fontFamily: "MontSerrat",
    fontSize:'22px',
    fontWeight:'500',
    lineHeight: '19px',
    color: colorBlue,
    opacity:'1',
  },
  "& input":{
    fontFamily: "MontSerrat",
    fontWeight:'600',
    color: colorBlue,
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));


export default Pay;
