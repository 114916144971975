import CurrencyInput from 'react-currency-input-field';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import DropSvg from '../../assets/icons/drop.svg';
import UpSvg from '../../assets/icons/up.svg';

interface Props {
    constract?: boolean;
    disabled?: boolean;
}

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-end;

    > div {
        margin-right: 25px;
    }

    > button {
        margin-right: 10px;
    }

    > div:last-child {
        margin-right: 0;
    }

    > button:last-child {
        margin-right: 0;
    }

`;

export const FormLabel = styled.span<Props>`
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 19px;
    
    color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800 )};
    opacity: ${(props) => (props.constract ? '0.25' : '1')};

    ${(props) => props.disabled && css`
      color: ${theme.COLORS.PRIM_800};
      opacity: 0.5;
    `};
`;

export const FormLabelSelect = styled.span<Props>`
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 19px;
    color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800 )};
    padding-bottom: 8px;
`;

export const FormInput = styled.input<Props>`
    background: transparent;
    outline: none;
    border: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800 )};
    color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800 )};

    ${(props) => props.disabled && css`
      color: ${theme.COLORS.PRIM_800};
      opacity: 0.50;
    `}

    padding: 14px 14px 4px 14px;
    font-size: 1.2em;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
    font: inherit;
    font-weight: bold;

    ::placeholder {
     color: #2C2A59;
     opacity: 0.25;
   }
`;

export const MoneyInput = styled(CurrencyInput)<Props>`
    background: transparent;
    outline: none;
    border: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800 )};
    color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800 )};
    padding: 14px 14px 4px 14px;
    font-size: 1.2em;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
    font: inherit;
    font-weight: bold;
`;

export const FormSelect = styled.select`
    flex: 1;
    letter-spacing: 1px;
    font: inherit;
    padding: 8px 40px 8px 18px;
    border: 1px solid ${theme.COLORS.BLACK_800};
    border-radius: 2px;
    backface-visibility: hidden;
    appearance: none;
    background: url(${DropSvg}) ${theme.COLORS.WHITE} no-repeat 95%;
    background-size: 6px;
    font-size: .95em;

    :focus {
        background-image: url(${UpSvg});
    }
`;

export const LabelError = styled.label`
    display:inline-flex;
    padding: 5px 0 0;
    font-size:14px;
    color:${theme.COLORS.ERROR_700}
`

export const FormTextarea = styled.textarea<Props>`
  height:90px;
  resize: none;
  padding: 8px 18px 8px 18px;
  margin:5px 0 3px;
  background: transparent;
  
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
  font: inherit;
  font-weight: bold;

  border: 0;
  border-bottom: 1px solid ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800 )};
  color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800 )};

  &:focus{
    outline:0;
  }

  &:disabled{
    background:${theme.COLORS.WHITE};
    opacity: 0.50;
  }
`