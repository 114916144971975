import { LoginWrapper } from "../style";
import { ErrorAlert } from 'components/ErrorAlert';
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "hooks/auth";
import { replaceEspecials } from "util/masks";
import { BoxResetPassord } from "../rememberPassword/BoxResetPassord/BoxResetPassord";
import { Logo } from "..";

const defaultValues = {
  novaSenha: '',
  novaSenhaConfirmacao: '',
}

const NewPassword = ({ username, password, empresa }: { username: string, password: string, empresa: string }) => {
  const [values, setValues] = useState(defaultValues);
  const [error, setError] = useState('');
  const [load, setLoad] = useState(false);

  const history = useHistory();
  const { signInNewPassword, signIn } = useAuth();

  const handleResetPassword = async (e: any) => {
    try {
      if (values.novaSenha !== values.novaSenhaConfirmacao) {
        setError('As senhas são divergentes');
        return;
      }

      setLoad(true)
      await signInNewPassword({
        login: replaceEspecials(username),
        senha: password,
        novaSenha: e.newPassword,
        cdInstanciaSelecionado: empresa,
        admPop: true,
      });
      setLoad(false)

      handleLogin(e.newPassword)
    } catch (err: any) {
      if (!err.response) {
        return setError('Erro inesperado');
      }

      setError(err.response.data.message);
      setLoad(false)
    }
  }

  const handleLogin = useCallback(async (newPassword : any) => {
    try {
      setLoad(true)
      const resp = await signIn({
        username: replaceEspecials(username),
        password: newPassword,
        cdInstanciaSelecionado: empresa,
        'adm-pop': true,
      });
      setLoad(false)
      
      if (resp === 'Error') {
        setLoad(false)
        return setError('Login ou senha estão incorretos.');
      }
      
      history.push('/home');
    } catch (err: any) {
      console.log(err)
      setLoad(false)
    }
  }, [history, values]);

  return (
    <LoginWrapper>
      <Logo />

      {error && <><ErrorAlert message={error} /><br/></>}

      <BoxResetPassord onClick={(e: any) => handleResetPassword(e)} load={load} />
    </LoginWrapper>
  )
}

export default NewPassword;
