import React, {
  createContext, useCallback, useContext, useState,
} from 'react';

import api from '../api';
import { UserData } from './model/UserModel';

interface AuthState {
  token: string;
  usuarioId: object;
  user?: UserData.RootObject;
}

interface SignInCredentials {
  username: string;
  password: string;
  'adm-pop': boolean;
  cdInstanciaSelecionado: string;
}

interface SignInNewPasswordCredentials {
  login: string;
  senha: string;
  novaSenha: string;
  cdInstanciaSelecionado: string;
  admPop: boolean;
}

interface AuthContextData {
  usuarioId: object;
  signIn(credentials: SignInCredentials): Promise<any>;
  signInNewPassword(credentials: SignInNewPasswordCredentials): Promise<any>;
  signOut(): void;
  updateUser(newUser: UserData.RootObject): void;
  user?: UserData.RootObject;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@mony:token');
    const usuarioId = localStorage.getItem('@mony:usuarioId');

    if (token && usuarioId) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      return { token, usuarioId: JSON.parse(usuarioId) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async (values: object) => {
    const response = await api.post('/login', values);
    
    if (response.data.message) {
       return 'Error'
    }

    const { token, usuarioId } = response.data;

    localStorage.setItem('@mony:token', token);
    localStorage.setItem('@mony:usuarioId', usuarioId);

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    setData({ token, usuarioId });
  }, []);

  const signInNewPassword = useCallback(async (values: object) => {
    const response = await api.put('/usuarios/redefinirsenha', values)

    if (response.data.message) {
      return 'Error'
    }

    const { token, usuarioId } = response.data;

    localStorage.setItem('@mony:token', token);
    localStorage.setItem('@mony:usuarioId', usuarioId);

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    setData({ token, usuarioId });
  }, [])

  const signOut = useCallback(() => {
    localStorage.removeItem('@mony:token');
    localStorage.removeItem('@mony:usuarioId');
    localStorage.removeItem('@mony:usuarioPerfil');

    delete api.defaults.headers.common.Authorization;

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback((newUser: UserData.RootObject) => {
    if (newUser) {
      setData({
        ...data,
        user: newUser,
      });
    }
  }, [data]);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthContext.Provider value={{
      usuarioId: data.usuarioId, signIn, signInNewPassword, signOut, user: data.user, updateUser,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
