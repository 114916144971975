/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
/* eslint-disable operator-assignment */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */

import { Photo } from "@mui/icons-material";
import { replaceEspecials } from "./masks";

/* eslint-disable no-plusplus */
export function validationDDD(value: string) {
  const dddValidos = [
    61,
    62,
    64,
    65,
    66,
    67,
    82,
    71,
    73,
    74,
    75,
    77,
    85,
    88,
    98,
    99,
    83,
    81,
    87,
    86,
    89,
    84,
    79,
    68,
    96,
    92,
    97,
    91,
    93,
    94,
    69,
    95,
    63,
    27,
    28,
    31,
    32,
    33,
    34,
    35,
    37,
    38,
    21,
    22,
    24,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    41,
    42,
    43,
    44,
    45,
    46,
    51,
    53,
    54,
    55,
    47,
    48,
    49,
  ];

  return dddValidos.some((ddd) => ddd === Number(value));
}

export function isPhoneValid(value: string): boolean {
  // -Para o DDD 11 são permitidos celulares iniciando com o 9 e o 5.
  // -Números fixos NÃO podem começar com 9 para todos os DDD.
  // -Números fixos, com DDD 11, NÃO podem começar com 5.

  if (!value) {
    return false;
  }
  
  const phone = replaceEspecials(value);

  if (phone.length < 8 || phone.length > 9) {
    return false;
  }

  if (phone.length === 8) {
    if (phone[0] === '9') {
      return false;
    }
    return true;
  }
 
  const numberCel = ['9', '5']
  if(!numberCel.includes(phone[0])){
    return false
  }else{
    if (phone[1] === '0') {
      return false;
    }
  }

  const numbers = phone.split('');
  let isValidCount: number = 0;
  numbers.forEach((item: string, index: number) => {
    if (index > 1 && item === numbers[index - 1]) {
      isValidCount += 1;
    }
  });

  if (phone.length === 9 && isValidCount === 7) {
    return false;
  }

  return true;
}

export function isCellPhoneDDDValid(value: string): boolean {
  const ddd = value?.substring(0, 2);
  const phone = value.substring(2);

  if (!value) {
    return false;
  }
  if(phone.length < 9){
    return false
  }
  if(!isValidDDDPhone([ddd, phone].join(''))){
    return false
  }

  return true
}

export function isPhoneDDDValid(value: string): boolean {
  if (!value) {
    return false;
  }

  const ddd = value?.substring(0, 2);
  const phone = value.substring(2);

  if (!phone) {
    return false;
  }

  if (phone.length < 8 || phone.length > 9) {
    return false;
  }

  const numbers = phone.split('');
  let isValidCount: number = 0;
  numbers.forEach((item: string, index: number) => {
    if (index > 1 && item === numbers[index - 1]) {
      isValidCount += 1;
    }
  });

  if (phone.length === 9 && isValidCount === 7) {
    return false;
  }

  return true;
}

// Código : https://gist.github.com/jonathangoncalves/7bdec924e9bd2bdf353d6b7520820b62
export function isValidDDDPhone(phone : any) {
  phone = phone.replace(/\D/g, '');

  if (!(phone.length >= 10 && phone.length <= 11)) return false;

  if (phone.length == 11 && parseInt(phone.substring(2, 3)) != 9) return false;

  for (var n = 0; n < 10; n++) {
      if (phone == new Array(11).join(n.toString()) || phone == new Array(12).join(n.toString())) return false;
  }
  var codigosDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19,
      21, 22, 24, 27, 28, 31, 32, 33, 34,
      35, 37, 38, 41, 42, 43, 44, 45, 46,
      47, 48, 49, 51, 53, 54, 55, 61, 62,
      64, 63, 65, 66, 67, 68, 69, 71, 73,
      74, 75, 77, 79, 81, 82, 83, 84, 85,
      86, 87, 88, 89, 91, 92, 93, 94, 95,
      96, 97, 98, 99];
  if (codigosDDD.indexOf(parseInt(phone.substring(0, 2))) == -1) return false;

  if (new Date().getFullYear() < 2017) return true;
  if (phone.length == 10 && [2, 3, 4, 5, 7].indexOf(parseInt(phone.substring(2, 3))) == -1) return false;

  return true;
}

export function isCpfValid(value: string) {
  let Soma;
  let Resto;
  Soma = 0;
  if (value === '00000000000') return false;

  for (let i = 1; i <= 9; i++) Soma += parseInt(value.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(value.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma += parseInt(value.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(value.substring(10, 11))) return false;
  return true;
}

export function isCnpjValid(value: string) {
  if (value == '') return false;

  if (value.length != 14) { return false; }

  if (value == '00000000000000'
      || value == '11111111111111'
      || value == '22222222222222'
      || value == '33333333333333'
      || value == '44444444444444'
      || value == '55555555555555'
      || value == '66666666666666'
      || value == '77777777777777'
      || value == '88888888888888'
      || value == '99999999999999') { return false; }

  let tamanho = value.length - 2;
  let numeros = value.substring(0, tamanho);
  const digitos = value.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) { pos = 9; }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != Number(digitos.charAt(0))) return false;
  tamanho = tamanho + 1;
  numeros = value.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) { pos = 9; }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != Number(digitos.charAt(1))) { return false; }

  return true;
}

export function isEmailValid(value: string) {
  // const regexEmail = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  // expression.test(value.toLowerCase());
  const regexEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i
  return regexEmail.test(value)
}

export function removeAccents(value: string){
  const map : any = {"â":"a","Â":"A","à":"a","À":"A","á":"a","Á":"A","ã":"a","Ã":"A","ê":"e","Ê":"E","è":"e","È":"E","é":"e","É":"E","î":"i","Î":"I","ì":"i","Ì":"I","í":"i","Í":"I","õ":"o","Õ":"O","ô":"o","Ô":"O","ò":"o","Ò":"O","ó":"o","Ó":"O","ü":"u","Ü":"U","û":"u","Û":"U","ú":"u","Ú":"U","ù":"u","Ù":"U","ç":"c","Ç":"C"};
  const val = value.replace(/[`~!#$%^&*()|+\-=?;:'"´,¨<>\{\}\[\]\\\/]/gi, '');
  return val.replace(/[\W\[\] ]/g, a => map[a]||a )
}