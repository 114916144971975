import styled from "styled-components"

export const TermDigital = () => {
    return <Box>
      <h1>CONTRATO DE PRESTAÇÃO DE SERVIÇOS PLATAFORMA <b>Mony</b></h1>
      <p>Pelo presente instrumento particular e na melhor forma de direito: </p>
      
      <h2>AS PARTES</h2>
      <p>
      Na aceitação deste <b>Mony</b> Serviços LTDA., sociedade empresária de 
      responsabilidade limitada, regularmente constituída no país, inscrita no 
      CNPJ/MF  sob  o  nº49.617.861/0001-02,  com  sede  na  Rua  dos  Três 
      Irmãos,  201,  4º  andar,  Jardim  Guedala,  São  Paulo – SP,  neste  ato 
      representada na forma do seu Contrato Social, doravante denominada 
      simplesmente <b>CONTRATADA</b>;  e  o <b>CONTRATANTE</b>, conforme  dados 
      informados na plataforma. 
      </p>
      <p>
      As partes acima qualificadas (“Partes”) têm entre si, certo e ajustado, 
      celebrar    o    presente    Contrato    de    Prestação    de    Serviços    de 
      Administração  da  Plataforma  de  Incentivo <b>Mony</b> (“Contrato”), que se 
      regerá pelas seguintes cláusulas e condições.
      </p>

      <h2>
      CLÁUSULA PRIMEIRA: DO OBJETO. 
      </h2>
      
      <p>
      1.1. Este  Contrato  tem  por  objeto  a  prestação  de  serviços,  pela 
      <b>CONTRATADA</b>, de desenvolvimento e operação de um sistema 
      eletrônico virtual na rede mundial de computadores (“Serviços” 
      e “Sistema”, respectivamente) que terá as funções de administrar 
      o  programa  de fidelidade ou  incentivos para  os  clientes ou 
      colaboradores/parceiros da <b>CONTRATANTE</b>,  
      </p>
      
      <p>
      1.1.1. As  condições  detalhadas  dos  serviços  disponibilizados 
      pela <b>CONTRATADA</b> à <b>CONTRATANTE</b> estão descritas na 
      plataforma administrativa, em  “Minha Conta“ que para 
      todos os fins e efeitos é parte indissociável e inseparável 
      do presente contrato. 
      </p>

      
      <p>
      1.2. O     portal     do     Sistema,     denominada     plataforma <b>Mony</b> 
      Administrador, terá   acesso por   meio   de   um   link   do   site 
      www.Mony.com.br ou  aplicativo  <b>Mony</b>  Empresas,  disponíveis 
      nas lojas de aplicativos. O Programa de geração de créditos será 
      parametrizado pelo <b>CONTRATANTE</b> na plataforma <b>Mony</b>. Para 
      utilização   dos   créditos   concedidos pelo <b>CONTRATANTE</b>,   os 
      <b>Participantes</b> deverão utilizar  o aplicativo <b>Mony</b>,  que  será 
      disponibilizado gratuitamente, nas lojas da Apple Store e Google 
      Play.     O pré-cadastro dos    Participantes    será    realizado 
      diretamente no estabelecimento comercial, solicitando o nome 
      e o DDD + Celular, no momento da distribuição do crédito após 
      a   compra.   No aplicativo será solicitado   ao   Participante   o 
      preenchimento  de  um  cadastro  completo e  a  criação  de  um 
      usuário  e  senha,  que  serão  utilizados  no  acesso ao  aplicativo 
      para utilização dos créditos. 
      </p>
      
      <p>
      1.3.  A <b>CONTRATANTE</b> terá    acesso    on-line, à plataforma 
      administrativa e  ao App  Empresas. O acesso será por  Login  e 
      Senha.   Na   plataforma   serão realizados os lançamentos de 
      créditos aos participantes,  parametrização  dos  %  de  crédito 
      sobre   as   compras   realizadas, vigência para   utilização dos 
      créditos, relatórios* de   distribuição de   créditos, créditos 
      utilizados e créditos a expirar. (*) Valido para planos pagos. 
      </p>
      
      <p>
      1.4. É  de  responsabilidade  da <b>CONTRATADA</b> viabilizar a  utilização 
      dos   créditos concedidos   pela <b>CONTRATANTE</b> de maneira 
      correta  e  exata,  nos  termos  informados  e  autorizados  pela 
      <b>CONTRATANTE</b>. 
      </p>
      
      <p>
      1.5. A <b>CONTRATADA</b> garante que os Participantes terão acesso ao 
      leitor de QR Code com disponibilidade de 99,5% (noventa e nove 
      virgula cinco por cento) do tempo, 24 (vinte e quatro) horas por 
      dia,  365  (trezentos  e  sessenta  e  cinco)  dias  por  ano,  em 
      condições normais de operação. 
      </p>
      
      <p>
      1.6. Fica  facultado  à <b>CONTRATADA</b>,  sob  sua  única  e  exclusiva 
      responsabilidade,  subcontratar  terceiros  ou  realizar  parcerias 
      para a prestação de parte do objeto deste contrato. 
      </p>
      
      <p>
      1.6.1. A <b>CONTRATADA</b> declara   e   garante   que   somente 
      envolverá subcontratados e/ou parceiros que 
      expressamente   se   sujeitarem   a   todos   os   termos   e 
      condições deste Contrato. 
      </p>
      
      <h2>
      CLÁUSULA SEGUNDA: DAS OBRIGAÇÕES DA <b>CONTRATADA</b>.
      </h2>
      
      <p>
      2.1. Para    o    cumprimento    das    obrigações    deste    contrato    a 
      <b>CONTRATADA</b> obriga-se:   
      </p>
      
      <p>
      a) Desenvolvimento  e  execução  do  Sistema e Aplicativos 
      para   acesso à <b>CONTRATANTE</b> das   funcionalidades 
      administrativas das Plataformas <b>Mony</b>,    bem    como 
      disponibilização    de acesso aos <b>Participantes</b> as 
      funcionalidades do aplicativo. 
      </p>
      
      <p>
      b) Fornecer  atendimento, via  canais de  atendimento,  tais 
      como  e-mail  e/  ou  whatsapp, em  horário  comercial  para 
      os   casos   de   problemas   ocorridos na   utilização   dos 
      créditos  via  aplicativo <b>Mony</b> e  realizar  ações  corretivas 
      necessárias. 
      </p>        
      
      <p>
      c) Hospedar,  manter  e  realizar  backups  do  Sistema  e  do 
      banco   de   dados   de   Participantes   e   de   transações 
      realizadas via aplicativo do programa. 
      </p>
        
      <p>
      d) Manter em   segurança os   dados   de   Participantes   e 
      transações   por   eles   efetuadas,   responsabilizando-se 
      inteiramente    no    caso    de    descumprimento    dessa 
      obrigação, desde que comprovado sua culpa ou dolo. 
      </p>
      
      <p>
      e) Prestar   os   Serviços   por   intermédio   de   profissionais 
      qualificados  e  tecnicamente  habilitados,  provendo  todos 
      os materiais e equipamentos necessários à prestação dos 
      Serviços bem como os demais recursos necessários para 
      sua perfeita e integral execução e arcando com os ônus e 
      encargos  respectivos,  sem  direito  a  qualquer  tipo  de 
      ressarcimento, reembolso ou remuneração adicional. 
      </p>

      <p>
      f) Disponibilizar  acesso  ao  Sistema a  gestores  e outros 
      usuários, de acordo com o perfil. Para os fins do presente 
      instrumento,  gestores e  usuários são pessoas  indicadas 
      pela <b>CONTRATANTE</b> que  terão  acesso  ao  Sistema,  bem 
      como funcionalidades  atribuídas  a cada perfil. O  registro 
      desses    gestores    e    usuários    será    realizado    pela 
      <b>CONTRATANTE</b> via plataforma administrativa. 
      </p>
      
      <p>
      g) Assumir   plena   responsabilidade   pela   qualidade   dos 
      Serviços  prestados,  respondendo  por  qualquer  prejuízo 
      decorrente da falta de capacidade técnica ou descuido no 
      cumprimento de suas obrigações. 
      </p>
      
      <p>
      h) Possuir infraestrutura adequada  para  a  prestação  dos 
      Serviços, nos termos deste Contrato. 
      </p>
      
      <p>
      i) Obter   e   manter   em   vigor   durante   a   vigência   deste 
      Contrato,  às  suas  expensas,  as  devidas  licenças,  alvarás, 
      permissões e/ou autorizações e demais documentos que 
      se  façam  necessários  para  a  prestação  dos  Serviços, 
      mantendo-os  à  disposição  da <b>CONTRATANTE</b>,  sempre 
      que lhe for solicitado. 
      </p>
      
      <h2>
      CLÁUSULA TERCEIRA: DAS OBRIGAÇÕES DA <b>CONTRATANTE</b>. 
      </h2>
      
      <p>
      3.1.  Para    o    cumprimento    das    obrigações    deste    contrato    a 
      <b>CONTRATANTE</b> obriga-se a:   
      </p>

      <p>
      a) Prover,   no momento   do   pagamento,   as   informações 
      transacionais necessárias para geração e  exibição  do 
      QRcode para pagamento do  valor  em  créditos  com  o 
      aplicativo <b>Mony</b>, necessários para a correta execução dos 
      Serviços pela <b>CONTRATADA</b>. 
      </p>
      
      <p>
      b) Acompanhar,   comunicar   internamente   e   promover   o 
      Programa de  Fidelidade ou  Incentivo,  viabilizado  pela 
      Plataforma <b>Mony</b>, entre   os   envolvidos, clientes e 
      colaboradores da <b>CONTRATANTE</b>. 
      </p>
      
      <p>
      c)  Responsabilizar-se pelos  créditos  gerados  e  concedidos 
      aos seus clientes, dentro do prazo de vigência estipulados 
      e divulgados na plataforma. 
      </p>
      
      <p>
      d) Efetuar  os  pagamentos  nas  datas  aprazadas  na  cláusula 
      quarta abaixo. 
      </p>
      
      <p>
      e) A <b>CONTRATANTE</b> concorda   que a distribuição dos 
      créditos aos  seus  clientes, participantes do  programa, 
      ocorrerá utilizando o saldo de  transações do  pacote 
      contratado. A <b>CONTRATANTE</b> concorda em   utilizar 
      transações adicionais,  quando o saldo  de  transações, 
      oriundos da mensalidade não forem suficientes. (*) 
      </p>
      
      <p>
      (*) Não se aplica em caso de planos gratuitos que eventualmente 
      façam parte da oferta promocional da administradora. 
      </p>

      <p>
      f) A <b>CONTRATANTE</b> concorda em  pagar  a  assinatura  de 
      forma  recorrente, em  seu  cartão  de crédito,  bem  como 
      pelos créditos adicionais utilizados. 
      </p>

      <p>
      g)       Para    os    casos    de encerramento/cancelamento    do 
      programa por parte da <b>CONTRATANTE</b>, ela deve informar 
      a <b>CONTRATADA</b>,  30  dias  antes  do  encerramento  e 
      manter o  programa  ativo durante  o  período  de  vigência 
      dos créditos  distribuídos. 
      </p>
      
      <h2>          
      CLÁUSULA QUARTA: DO PAGAMENTO DOS SERVIÇOS E TRANSAÇÕES  
      </h2>
      
      <p>
      4.1. A <b>CONTRATANTE</b> deverá  pagar  à <b>CONTRATADA</b> o valor da 
      mensalidade é   destinado   a manutenção   da   plataforma, do 
      Aplicativo e Atendimento. 
      </p>
      
      <p>
      4.2.  A <b>CONTRATANTE</b> poderá optar por um dos planos de 
      contratação disponibilizados pela <b>CONTRATADA</b>. 
      </p>
      
      <p>
      4.2.1  <u>Plano Mony 100</u>: Mensalidade R$ 49,90 – 100 transações/mês;  transação adicional R$ 0,50 <br/>
      <u>Plano Mony 250</u>: Mensalidade R$ 99,90 – 250 transações/mês;  transação adicional R$ 0,40 <br/>
      <u>Plano Mony 450</u>: Mensalidade – R$ 149,90 – 450 transações/mês; transação adicional  R$ 0,30 <b>Mony</b>. <br/>
      <u>Plano Promocional Gratuito:</u> Eventualmente a Administradora 
      poderá oferecer, a título promocional uma quantidade de 
      transações por mês de forma gratuita por período determinado. 
      </p>
      
      <p>
      Essa oferta será de acordo com a estratégia promocional da 
      <b>CONTRATADA</b>. Informada ao <b>CONTRATANTE</b> nos canais comerciais. 
      No plano gratuito promocional, não haverá a possibilidade de 
      comprar transações adicionais. 
      </p>
      
      <p>
      Nos demais planos, o valor de transações adicionais utilizados 
      será cobrado juntamente com a mensalidade no mês 
      subsequente a utilização. 
      </p>
      
      <p>
      4.3.  Os valores  devidos  pela <b>CONTRATANTE</b> à <b>CONTRATADA</b> no 
      âmbito  deste  Contrato  serão  pagos diretamente  na  plataforma 
      administrativa  por  meio  de  cartão  de  crédito  cadastrado  e 
      autorizado pela <b>CONTRATANTE</b>. 
      </p>
      
      <p>
      4.4.  Os valores cobrados neste Contrato serão reajustados de acordo 
      com o IPCA (Índice Nacional de Preços ao Consumidor Amplo - 
      IBGE)  anualmente,  ou,  em  sua  falta,  por  qualquer  outro  índice 
      que venha a substituí-lo. 
      </p>
      
      <h2>
      CLÁUSULA QUINTA:    DAS    OBRIGAÇÕES    E    RESPONSABILIDADES 
      TRABALHISTAS, FISCAIS E PREVIDENCIÁRIAS 
      </h2>
      
      <p>
      5.1.   A  prestação  dos  Serviços  objeto  deste  Contrato  será  realizada 
      com     total     responsabilidade     e     independência     técnico-
      operacional, sem exclusividade entre as Partes, ou qualquer tipo 
      de subordinação e/ou pessoalidade entre a <b>CONTRATANTE</b> e os 
      empregados e/ou prepostos da <b>CONTRATADA</b>.  
      </p>
      
      <p>
      5.1.1  A <b>CONTRATANTE</b> concorda   expressamente   com   todas   as 
      alterações  que  a <b>CONTRATADA</b> venha  a  fazer  na  plataforma, 
      cabendo  a <b>CONTRATADA</b> o  direito  de  modificar  a  Plataforma 
      conforme  sua  conveniência  e  independentemente  de  aviso 
      prévio à <b>CONTRATANTE</b>. 
      </p>
      
      <p>
      5.2.  Este contrato não estabelece qualquer relação de emprego entre 
      a <b>CONTRATANTE</b> e   os   empregados   e/ou   prepostos   da 
      <b>CONTRATADA</b>, sendo essa a única e exclusiva responsável pelo 
      recrutamento, seleção, contratação, administração e 
      gerenciamento de seu pessoal. 
      </p>
      
      <p>
      5.3.  A <b>CONTRATADA</b> compromete-se   a   cumprir   fielmente   a 
      legislação  trabalhista,  previdenciária,  cível  e  tributária,  bem 
      como  as  normas  relativas  à  segurança  e  medicina  do  trabalho, 
      em  relação  aos  seus  empregados  e/ou  prepostos,  isentando  a 
      <b>CONTRATANTE</b> de  quaisquer  responsabilidades  e  assumindo 
      com   exclusividade   todas   as   consequências   por   eventuais 
      descumprimentos das referidas disposições legais. 
      </p>
      
      <p>
      5.4.  Todos os impostos, taxas e contribuições, fiscais, 
      previdenciárias,  trabalhistas,   acidentárias   ou   parafiscais   que 
      incidam ou venham a incidir sobre os Serviços são de exclusiva 
      responsabilidade da <b>CONTRATADA</b>. 
      </p>
      
      <h2>
      CLÁUSULA SEXTA: DO DEVER DE CONFIDENCIALIDADE  
      </h2>
      
      <p>
      6.1.  A <b>CONTRATADA</b> tem pleno conhecimento de que, em razão da 
      assinatura deste Contrato, a <b>CONTRATANTE</b> lhe disponibilizará 
      informações e materiais de caráter confidencial e estratégico. 
      </p>
      
      <p>
      6.2.   São  consideradas  “Informações  Confidenciais”  todas  aquelas 
      reveladas,  diretas  ou indiretamente,  pela <b>CONTRATANTE</b> à 
      <b>CONTRATADA</b> ou às pessoas autorizadas por essa, bem como 
      todas  as  demais  obtidas  pela <b>CONTRATADA</b> em  virtude  deste 
      Contrato,  independentemente  de  estar  ou  não  expressamente 
      classificada  como  “CONFIDENCIAL”,  tais  como,  mas  não  se 
      limitando, a modelos e estratégias do negócio, características de 
      produtos   (pré-existentes,   novos   ou   em   desenvolvimento), 
      informações   sobre   softwares   e/ou   hardwares,   informações 
      sobre   negociações   em   andamento,   planos   de   marketing  e 
      comerciais, informações contábeis, financeiras, estatísticas e/ou 
      de  natureza  publicitária,  informações  cadastrais  de  clientes, 
      fornecedores  e/ou  parceiros  comerciais,  projeções  financeiras, 
      informações  envolvendo  direito  de  propriedade  industrial  ou 
      direitos  autorais,  informações  sobre  projetos,  técnicas  e/ou 
      métodos,  fórmulas,  demonstrações,  contratos,  apresentações, 
      relatórios,  listas,  preços,  estudos,  pesquisas  de  mercado  e/ou 
      decisões gerenciais, dentre outras. 
      </p>
      
      <p>
      6.3.   Não é considerada Informação Confidencial aquela que:  
      </p>
      
      <p>
      6.3.1. Estiver  em  domínio  público  antes  de  sua  obtenção  pela 
      <b>CONTRATADA</b> ou  cair  em  domínio  em  decorrência  de 
      publicação  ou  de  qualquer  outra  forma  autorizada  pela 
      <b>CONTRATANTE</b>; 
      </p>
      
      <p>
      6.3.2. Já tinha sido obtida legitimamente pela <b>CONTRATADA</b> de 
      um terceiro, desde que o terceiro tenha obtido anuência 
      prévia  da <b>CONTRATANTE</b> para  divulgar  tal  informação, 
      sendo que, nessa hipótese, a <b>CONTRATADA</b> e o terceiro 
      responderão solidariamente em caso de danos causados 
      à <b>CONTRATANTE</b> por culpa ou dolo;  
      </p>
  
      <p>
      6.3.3. Foi independentemente desenvolvida pela 
      <b>CONTRATADA</b> sem acesso a qualquer outra Informação 
      Confidencial revelada pela <b>CONTRATANTE</b> ou em virtude 
      dos Serviços. 
      </p>
      
      <p>
      6.4.  A <b>CONTRATADA</b> compromete-se  a  manter,  por  si  e  pelas 
      pessoas  por  ela  autorizadas,  incluindo  seus  administradores, 
      empregados,  prepostos,  a  qualquer  título,  e  comitentes,  estrita 
      confidencialidade quanto a todas as Informações Confidenciais 
      que    a <b>CONTRATADA</b> receber    da <b>CONTRATANTE</b> em 
      decorrência deste Contrato. 
      </p>
      
      <h2>
      CLÁUSULA SÉTIMA: MARCAS E PROPRIEDADE INTELECTUAL  
      </h2>

      <p>
      7.1.  A <b>CONTRATANTE</b> reconhece   que   a   plataforma <b>Mony</b>, o 
      respectivo   código fonte   e   aplicativos   desenvolvidos   pela 
      <b>CONTRATADA</b> são de propriedade exclusiva da <b>CONTRATADA</b>, 
      cabendo  à <b>CONTRATANTE</b> o  direito  à  sua  utilização,  pelo 
      período de vigência deste Contrato. 
      </p>        
      
      <p>
      7.2. A marca <b>Mony</b>, forma,  cor,  dizeres  e  demais  características 
      pertencem  exclusivamente  à <b>CONTRATADA</b>,  cabendo-lhe  o 
      direito    de    modificá-los    e    substituí-los    conforme    sua 
      conveniência    e    independentemente    de    aviso    prévio    à 
      <b>CONTRATANTE</b>, sendo  possível  a <b>CONTRATANTE</b> a inserção 
      do seu LOGOTIPO, no aplicativo <b>Mony</b>. 
      </p>
      
      <p>
      7.3.  Toda e qualquer publicidade envolvendo o nome das Partes em 
      relação   ao   Contrato   ora   firmado,   deverá   ser   previamente 
      autorizada por escrito pela outra Parte. 
      </p>
      
      <h2>
      CLÁUSULA OITAVA:  BANCO DE DADOS E PRIVACIDADE DE DADOS 
      </h2>
      
      <p>
      8.1  A <b>CONTRATANTE</b> não  terá  acesso  aos  dados de participantes 
      cadastrados na loja ou via aplicativo .  A <b>CONTRATADA</b> é a única com acesso 
      a  base total de  participantes <b>Mony</b> e  poderá usar para  ações  relevantes  ao 
      programa, de  acordo  com  as  leis  vigentes.  Os  dados  fornecidos  pelo 
      participante via aplicativo e via plataforma administrativa serão armazenados 
      pela <b>CONTRATADA</b>,  durante  a  vigência  do  contrato, para  geração  de 
      relatórios, informações    analíticas e disponibilização de créditos. A 
      <b>CONTRATADA</b> não será responsável por qualquer utilização/tratamento de 
      dados   inseridos   no   pré-cadastro   e   cadastro   dos   participantes quando 
      realizada  diretamente  pela <b>CONTRATANTE</b> cuja  finalidade  não  seja  ao 
      atendimento ao objeto do presente Contrato. 
      </p>

      <p>
      8.2. As  PARTES,  por  si  e  por  seus  sócios,  administradores,  representantes, 
      colaboradores, prestadores de serviços e/ou terceiros, no desenvolvimento 
      de  quaisquer  das  atividades  relacionadas  com  a  execução  deste Contrato, 
      observarão a Lei Federal nº 13.709, de 14 de agosto de 2018 (“LGPD”),  e se 
      informarão e conhecerão eventuais normativos e regulamentos que venham 
      a  ser  editados  sobre  o  tema,  que  se  obrigam  a,  desde  já,  cumprirem 
      integralmente    referida    legislação    e    regulamentação,    atuando    em 
      conformidade  com  a legislação  vigente  sobre  a  proteção  de  dados  e  as 
      determinações de órgãos reguladores sobre a matéria, em especial a Agência 
      Nacional  de  Proteção  de  Dados  (ANPD),  sempre  com  vistas  a  proteger  os 
      Dados Pessoais. 
      </p>
      
      <p>
      8.3. As estipulações e obrigações constantes do presente instrumento serão 
      aplicadas  a  todo  e  qualquer  Tratamento  de  Dados  Pessoais  e/ou  Dados 
      Pessoais  Sensíveis  de  usuários,  colaboradores,  clientes  e  consumidores  na 
      forma  escrita,  verbal  ou  de  qualquer  outro  modo  apresentada  que  sejam 
      revelados entre as PARTES ou que venha a tomar conhecimento no curso das 
      atividades executadas ou por outros meios. 
      </p>
      
      <h2>
      CLÁUSULA NONA: DA VIGÊNCIA E DO TÉRMINO  
      </h2>
      
      <p>
      9.1.  Este Contrato vigerá por prazo indeterminado. 
      </p>
      
      <p>
      9.2.   Este  Contrato  poderá  ser  denunciado  por  qualquer  uma  das 
      Partes,  a  qualquer  momento,  mediante  aviso  prévio  de 90 
      (noventa) dias, sem incorrer as Partes, neste caso, em quaisquer 
      despesas, seja a título de indenização, multa ou qualquer outro 
      título, sendo devido, somente, o pagamento dos valores relativos 
      ao  período  da  prestação  dos  Serviços  efetuados,  inclusive 
      aqueles compreendidos pelo aviso prévio.  
      </p>
      
      <p>
      9.2.1. Durante  o  prazo  do  aviso  prévio,  todos  os  direitos  e 
      obrigações das Partes permanecerão em pleno vigor, até 
      sua efetiva resilição. 
      </p>        
      
      <p>
      9.3.  São  causas  de  rescisão  imediata  e  independente  de  qualquer 
      outra formalidade: 
      </p>
      
      <p>
      9.3.1.  Inadimplemento  de  quaisquer  cláusulas  ou  condições 
      previstas  neste  Contrato  que  não  tenha  sido  sanado  no 
      prazo de até 20 (vinte) dias contados do recebimento da 
      respectiva  notificação  enviada  pela  Parte  prejudicada  à 
      Parte infratora; 
      </p>
      
      <p>
      9.3.2. Descumprimento por qualquer das Partes das obrigações 
      estabelecidas nesse instrumento;  
      </p>
      
      <p>
      9.3.3. Decretação de falência, pedido de recuperação judicial ou 
      extrajudicial de qualquer das Partes; 
      </p>
      
      <p>
      9.3.4.  Cessão ou transferência total ou parcial deste Contrato a 
      terceiros sem a prévia autorização escrita da outra Parte;  
      </p>
      
      <p>
      9.3.5. Cassação de qualquer licença expedida por órgão oficial, 
      que seja obrigatória para a prestação ou continuidade dos 
      Serviços ora contratados; 
      </p>
      
      <p>
      9.3.6. Evento   de   caso   fortuito   ou   de   força   maior   que 
      impossibilite    a    prestação    dos    Serviços,    total    ou 
      parcialmente, por mais de 30 (trinta) dias consecutivos. 
      </p>
      
      <h2>
      CLÁUSULA DÉCIMA: DAS DISPOSIÇÕES FINAIS. 
      </h2>
      
      <p>
      10.1.  A  celebração  deste  Contrato  não  gera,  sob  nenhuma  hipótese, 
      vínculo  empregatício  entre  os  profissionais,  empregados  e/ou 
      subcontratados,   ligados   à <b>CONTRATADA</b>,   em   relação   à 
      <b>CONTRATANTE</b>,  sendo  a <b>CONTRATADA</b> considerada  como 
      única responsável por esses profissionais. 
      </p>
      
      <p>
      10.2.  Na ocorrência de subcontratação dos Serviços, a 
      <b>CONTRATADA</b>,     desde     já,     assume     total     e     exclusiva 
      responsabilidade  pelas  ações  e  trabalhos  executados  por  sua 
      sub<b>CONTRATADA</b>. 
      </p>
      
      <p>
      10.3.  Este contrato não gera para qualquer das partes nenhum outro 
      direito   ou   obrigação   diverso   daqueles   aqui   expressamente 
      previstos,   ficando   afastada   qualquer   relação,   ostensiva   ou 
      remota,  de  sociedade,  joint-venture  ou  associação  entre  as 
      partes,   não   estando   nenhuma   delas   autorizada   a   assumir 
      quaisquer obrigações ou compromissos em nome da outra. 
      </p>

      <p>
      10.4.  A eventual tolerância por qualquer das partes quanto a qualquer 
      violação    dos    termos    e    condições    deste    contrato    será 
      considerada  mera  liberalidade  e  não  será  interpretada  como 
      novação,  precedente  invocável,  renúncia  a  direitos,  alteração 
      tácita  dos  termos  contratuais,  direito  adquirido  ou  alteração 
      contratual.  
      </p>        
      
      <p>
      10.5.  A <b>CONTRATADA</b> declara  que  não  emprega  e/ou  utiliza,  e  se 
      obriga a não empregar e/ou utilizar, durante o prazo de vigência 
      deste  contrato,  mão-de-obra infantil  na  consecução  do  objeto 
      contratual, assim como não contrata e/ou mantém relações com 
      quaisquer outras empresas que lhe prestem serviços (parceiros, 
      fornecedores e/ou subcontratados) que utilizem, explorem e/ou 
      por qualquer meio ou forma empreguem o trabalho infantil, nos 
      termos previstos no Estatuto da Criança e do Adolescente (ECA), 
      Lei  n.º  8.069/90  e  demais  normas  legais  e/ou  regulamentares 
      em vigor. 
      </p>
      
      <p>
      10.6. A  nulidade  ou  invalidade  de  qualquer  das  disposições  deste 
      contrato  não  implicará  na  nulidade  ou  invalidade  das  demais, 
      sendo   que   as   disposições   consideradas   nulas   ou   inválidas 
      deverão ser reescritas, de modo a refletir a intenção inicial das 
      partes em conformidade com a legislação aplicável.  
      </p>
      
      <p>
      10.7. Este Contrato não poderá ser cedido, total ou parcialmente, por 
      nenhuma das Partes, sem prévio e expresso consentimento por escrito da outra Parte, ressalvada a cessão pela <b>CONTRATANTE</b> 
      às empresas controladas, controladoras ou sob controle comum. 
      </p>
      
      <p>
      10.8. Este  instrumento  constitui  o entendimento  integral  entre  as 
      Partes e revoga expressamente todas e quaisquer tratativas ou 
      discussões entre elas em relação ao objeto deste Contrato. 
      </p>
      
      <p>
      10.9. Este Contrato obriga entre si as Partes e os seus sucessores, a 
      qualquer  título,  sendo  as  Partes responsáveis  pelos  atos  e 
      omissões   de   seus   respectivos   profissionais,   funcionários, 
      administradores    ou    gerentes,    prestadores    de    serviços, 
      contratados ou prepostos, a qualquer título. 
      </p>
      
      <p>
      10.10. Nenhuma  alteração  deste instrumento será  considerada  válida, 
      exceto   se   acordada   expressamente   por   meio   de   aditivo 
      contratual escrito e assinado pelas Partes ou se constar de novo 
      contrato  firmado  em  substituição  a  este,  o  que  deverá  estar 
      expresso.  
      </p>

      <p>
      10.11. Os  signatários  deste  Contrato  declaram,  sob  as  penas  da  lei, 
      estarem devidamente investidos de poderes para celebrá-lo na 
      forma como está redigido, com a assunção das obrigações aqui 
      contraídas.  
      </p>
      
      <h2>
      CLÁUSULA DÉCIMA PRIMEIRA: DO FORO. 
      </h2>
              
      <p>
      11.1  Fica  eleito  o  Foro  Central  da  Comarca  de  São  Paulo - SP,  que 
      será competente para julgar as questões oriundas da execução 
      deste Contrato, renunciando a qualquer outro. 
      </p>

      <p>
      <b>Mony Serviços LTDA.</b>
      </p>
    </Box>
}

const Box = styled.div`
    word-break: normal;
    text-align:justify;
    line-height: 160%;

    h1{
        font-size: 1.8rem;
    }    
    h2{
        font-size:1.2rem;
        line-height:140%;
        margin:10px 0 20px;
        text-align: left;
    }
    b{
        display: inline-block;
        padding:0 2px;
    }
    P{
        margin:15px 0;
    }
`