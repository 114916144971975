import Button from "components/Button"
import { Spacing } from "styles/base"
import styled from "styled-components"
import theme from "styles/theme"
import { hideEmail, hideTel } from "util/utils"

interface IInfoPassword {
  cpf?: string,
  email?: string,
  ddd?: string,
  tel?: string,
  onClick?: () => void,
}
const InfoPassword = ({ cpf, ddd, tel, email, onClick }: IInfoPassword) => {
  return <>
    <ResetMessage>
      Redefinição de senha enviada para o celular<br/>
      {tel && <>({ddd}) {hideTel(tel)}</>}. Caso não tenha recebido, tente novamente.
    </ResetMessage>

    <Spacing margin={14} />

    <Button
      loginSubmit
      text="Ok"
      disabled={!cpf}
      onClick={onClick}
    />
  </>
}

const ResetMessage = styled.div`
  text-align: center;
  line-height:150%;
  font-size:16px;
  color:${theme.COLORS.WHITE}
`

export { InfoPassword }