import styled from "styled-components";
import theme from "styles/theme";


export const H3 = styled.h3`
  display:flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;

  gap:10px;
  font-size:18px;
  color:${theme.COLORS.PRIM_800};
  margin-bottom:16px;  
`

export const BoxLabelText = styled.div`
  & + div{
    padding-top:24px;
  }

  & > span{
    display:flex;
    font-weight:600;
    padding-bottom:16px;
  }
  & > div{
    height:auto;
  }
  
  & > div.boxObs{
    min-width:100%;
  }

  small{
    padding:3px 0 0;
    display:flex;
    color:${theme.COLORS.ERROR_800};
  }
`