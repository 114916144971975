import api from 'api';
import { useQueryFetch } from '../../Reports/services';

export const getPlans = ({ userId = '' } : { userId?: string }) => {
  const planosPagos = true

  const { isFetching, isLoading, error, data } = useQueryFetch({ 
    key: ['plans', userId ], 
    url: `/planos?tipoInstancia=Pontos&somentePlanosPagos=${planosPagos}`, 
    cache: 0,
    revalidate: 0,
  })
  
  return { isFetching, isLoading, error, data }
}
export const getPlanSeleced = ({ userId = '' } : { userId?: string }) => {
  const { isFetching, isLoading, error, data } = useQueryFetch({ 
    key: ['plans', userId ], 
    url: '/planos/selecionado', 
    cache: 0,
    revalidate: 0,
  })
  
  return { isFetching, isLoading, error, data }
}
export const getAccessionCurrent = ({ userId = '' } : { userId?: string }) => {
  const { isFetching, isLoading, error, data } = useQueryFetch({ 
    key: ['planUser', userId], 
    url: '/adesao', 
    cache: 0,
    revalidate: 1,
  })  
  return { isFetching, isLoading, error, data }
}

export const getPlansData = () => {
  return api.get('/planos?tipoInstancia=Pontos')
}
export const getPlanSelecedData = () => {
  return api.get('/planos/selecionado')  
}
export const getAccessionCurrentData = () => {
  return api.get('/adesao')
}


export const postAccessionMake = async (e : FormData) => {
  return await api.post("/adesao", e);
}
export const deleteAccession = async (isMigrateFree : boolean, e?: any | null) => {
  // true = migração | false = exclusão
  // return { data: { 'acao' : isMigrateFree ? 'Migrar' : 'Excluir' }}
  return await api.put(`/adesao/cancela?isMigracaoGratuito=${isMigrateFree}`, e)
}

export const postValidateCard = async (e : any) => {
  return await api.post("/adesao/validaNumeroCartaoCredito", e);
}

export const updateCard = async (e: any) => {
  return await api.put("/adesao/atualiza-cartao", e);
}

export const putMigratePlans = async (idPlano : number | undefined, body : any | null) => {
  const url = "/adesao/migrar-plano/"
  return body !== null 
  ? await api.put(`${url}${idPlano}`, body)
  : await api.put(`${url}${idPlano}`)
}