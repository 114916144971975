import api from 'api'
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import moment from 'moment';
import { format } from 'date-fns';

export const useQueryFetch = ({ key, url, revalidate, cache } : 
  {key: string[], url: string, revalidate?: number, cache?: number }) => {
  const { isFetching, isLoading, error, data } = useQuery([...key], async () => {
    const response = await api.get(url || '')
    return response.data
  }, {
    staleTime: revalidate || (1000 * 60), //1 min
    cacheTime: cache || (1000 * 60) * 10, // 10 minutos
    retry: cache === 0 ? false : true,
    refetchOnWindowFocus: false   
  }) 

  return { isFetching, isLoading, error, data }
}

export const getInstance = ({ id } : {id: string}) => {
  const { isFetching, isLoading, error, data } = useQueryFetch({ 
    key: ['instance', id], 
    url: '/instancias/subs', 
    revalidate: (60*1000) * 30 // 30 min
  })

  return { isFetching, isLoading, error, data }
}

export const getUnits = ({ idInstancia, id } : {idInstancia: string, id: string}) => {
  const { isLoading, isFetching, error, data } = useQueryFetch({ 
    key: ['unidade', idInstancia, id], 
    url: `/unidades?page=0&size=100&idInstancia=${idInstancia}&ativasAndInativas=true`, 
    revalidate: (60*1000) * 5 // 5 min
  })

  if(parseInt(idInstancia) !== 0){
    return { data: data?.page, isLoading, isFetching, error }
  }
  return { data: [], isLoading: false, isFetching: false, error:false }
}


const consulta = {
  utilizados: "pedidos/creditos-utilizados",
  distribuidos: "relatorios/creditos-distribuidos",
  expirados: 'relatorios/creditos-expirados',
  expirar: 'relatorios/creditos-expirar',
}

export interface getRepostsProps{
  idInstancia?:string, 
  idUnidade?:string,
  dataInicio?:Date, 
  dataFim?:Date, 
  page?:number, 
  size?:number,
  tipo?: "utilizados" | "distribuidos" | "expirados" | "expirar"
}

/* CRÉDITOS UTILIZADOS  */
export const getReports = ({ idInstancia, idUnidade, dataInicio, dataFim, page, size, tipo } : getRepostsProps) => {
  const url_base = consulta[tipo || "utilizados"]

  const apiReports = async () => {
    //pedidos/creditos-utilizados
    return api.get(`${url_base}?idInstancia=${idInstancia}&idUnidade=${idUnidade}&dataInicio=${moment(dataInicio).format('YYYY-MM-DD')}&dataFim=${moment(dataFim).format('YYYY-MM-DD')}&page=${page || 0}&size=${size || 10}&tpIntegracao=MONY`)
    .then(res => res.data)
  }

  const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isFetchingPreviousPage, status, isLoading } = useInfiniteQuery({
    queryKey: ['Reports', url_base, idInstancia, idUnidade, dataInicio?.toString(), dataFim?.toString(), page, size],
    queryFn: apiReports,
    refetchOnWindowFocus: false,
    getPreviousPageParam: (lastPage: any, pages: any) => lastPage.nextCursor,
    getNextPageParam: (lastPage: any, pages: any) => lastPage.nextCursor,
    cacheTime:(1000 * 60) * 10, // 10min
    staleTime:10, // 10 millesegundos
  })

  return { data: data?.pages[0], error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isFetchingPreviousPage, status, isLoading   }
}

export const getReportsDetails = ({ idInstancia, idUnidade, dataInicio, dataFim, page, size, tipo } : getRepostsProps) => {
  const url_base = consulta[tipo || "utilizados"]

  const apiReports = async () => { 
    //pedidos/creditos-utilizados
    return api.get(`${url_base}?idInstancia=${idInstancia || ''}&idUnidade=${idUnidade || ''}&dataInicio=${moment(dataInicio).format('YYYY-MM-DD')}&dataFim=${moment(dataFim).format('YYYY-MM-DD')}&page=${page || 0}&size=${size || 10}&tpIntegracao=MONY`)
    .then(res => res.data)
  }
  
  const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isFetchingPreviousPage, status, isLoading } = useInfiniteQuery({
    queryKey: ['ReportsDetails', url_base, idInstancia, idUnidade, dataInicio?.toString(), dataFim?.toString(), page, size],
    queryFn: apiReports,
    refetchOnWindowFocus: false,
    getPreviousPageParam: (lastPage: any, pages: any) => lastPage.nextCursor,
    getNextPageParam: (lastPage: any, pages: any) => lastPage.nextCursor,
    cacheTime:(1000 * 60) * 10, // 10 min
    staleTime:(1000 * 60) * 3, // 3 min
  })

  return { data: data?.pages, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isFetchingPreviousPage, status, isLoading }
}

export const getReportsDetailsFile = ({ idInstancia, idUnidade, dataInicio, dataFim, page, size, tipo } : getRepostsProps) => {
  const url_base = '/relatorios/arquivo-credito-'+ tipo;
  const url = `${url_base}?idInstancia=${idInstancia}&idUnidade=${idUnidade}&dataInicio=${dataInicio}&dataFim=${dataFim}&tpIntegracao=MONY`;

  return api.get( url ).then(res => {
        const urlArquivo = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = urlArquivo;
        link.setAttribute('download', 'relatorio de cashback ' + tipo + " - " + format(new Date(), 'dd/MM/yyyy HH:mm') + '.csv');
        document.body.appendChild(link);
        link.click();
    res.data});
}