import styled, { css } from 'styled-components';
import { NumberFormatProps } from 'react-number-format';
import theme from 'styles/theme';

interface Props extends NumberFormatProps {
  constract?: boolean;
  isFocused?: boolean;
  lineInput?: boolean;
}

export const ReactNumberFormat = styled.input<any>`
    background: transparent;
    outline: none;
    border: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: ${(props) => (props.constract ? theme.COLORS.WHITE :  theme.COLORS.PRIM_800 )};
    color: ${(props) => (props.constract ? theme.COLORS.WHITE :  theme.COLORS.PRIM_800 )};
    padding: 14px 14px 4px 14px;
    font-size: 1.2em;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
    font: inherit;
    font-weight: bold;

   ::placeholder {
     color: ${theme.COLORS.PRIM_800};
     opacity: 0.25;
   }

   ${({ lineInput }) => lineInput && css`
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-color: ${theme.COLORS.WHITE};
      color: ${theme.COLORS.WHITE};
    `}
`;

export const FormLabel = styled.span<Props>`
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 19px;
    color: #2C2A59;
    opacity: ${(props) => (props.isFocused ? '0.25' : '1')};
    color: ${(props) => (props.constract ? theme.COLORS.WHITE : theme.COLORS.PRIM_800 )};

    ${({ lineInput }) => lineInput && css`
      color: ${theme.COLORS.WHITE};
      opacity: 0.25;
    `}
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-end;
`;

export const ConatinerEye = styled.div`
  flex: 1;
  border-bottom: 1px solid ${theme.COLORS.WHITE};
  text-align: right;

  padding-right: 5px;

  > button {
    position: relative;
  }
`;
