import { useState } from 'react';
import { NumberFormatProps } from 'react-number-format';

import styled from 'styled-components';
import theme from 'styles/theme';
import { ReactComponent as EyeCrossed } from '../../assets/icons/eye-crossed.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';

import { Column } from '../../styles/base';
import { ShowPasswordButton } from '../Button/styles';

import { Box, FormLabel, ConatinerEye, ReactNumberFormat } from './styles';

interface Props extends NumberFormatProps {
  label: string;
  onValueChange: any
  placeholder?: string;
  constract?: boolean;
  lineInput?: boolean;
  ref?: any;
  id?: string;
}

export const InputNumberFormat = ({
  label, ref, id, onValueChange, placeholder, format, constract, lineInput, type, maxLength, ...rest
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  function handleInputFocus() {
    setIsFocused(true);
  }

  function handleInputBlur() {
    setIsFocused(false);
  }

  if (type === 'password') {
    return (
      <Column>
        <FormLabel isFocused={isFocused} lineInput={lineInput}>{label}</FormLabel>
        <Box>
          <ReactNumberFormat
            id={id}
            ref={ref}
            lineInput={lineInput}
            onKeyPress={(event: { key: string; preventDefault: () => void; }) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder={placeholder}
            format={format}
            maxLength={6}
            onFocus={() => handleInputFocus()}
            onBlurCapture={() => handleInputBlur()}
            type={showPassword ? 'text' : 'password'}
            onChange={(e: any) => {
              onValueChange(e.target.value);
            }}
            autoComplete="current-password"
          />

          {type === 'password' && (
            <ConatinerEye>
              <ShowPasswordButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword
                  ? (<EyeCrossed style={{ width: 22, height: 22, fill: theme.COLORS.WHITE }} />)
                  : (<Eye style={{ width: 22, height: 22, fill: theme.COLORS.WHITE }} />)}
              </ShowPasswordButton>
            </ConatinerEye>
          )}
        </Box>
      </Column>

    );
  }

  return (
    <Column>
      <FormLabel isFocused={isFocused} lineInput={lineInput}>{label}</FormLabel>
      <Box>
        <ReactNumberFormat
          ref={ref}
          id={id}
          lineInput={lineInput}
          onKeyPress={(event: { key: string; preventDefault: () => void; }) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          placeholder={placeholder}
          format={format}
          maxLength={6}
          onFocus={() => handleInputFocus()}
          onBlurCapture={() => handleInputBlur()}
          onValueChange={onValueChange}
          type={showPassword ? 'text' : 'password'}
          onChange={(e: any) => {
            onValueChange(e.target.value);
          }}
        />
      </Box>
    </Column>

  );
};

const Input = styled.input`
  all: unset;
  height: 100% ;
`;
