import { useHistory } from "react-router-dom";
import { Label, Spacing, TableLineGrid, GridLine, GridHead } from "styles/base";
import theme from "styles/theme";
import { moneyMask } from "util/masks";
import { IAccession } from "../services/types";
import Button from "components/Button";

const DataSignature = ({ planCurrent } : { planCurrent?: IAccession }) => {

  const navigate = useHistory();
  const handleClick = () => navigate.push('/home/conta/planos')

  return (
    <>
      <TableLineGrid>
        <GridHead>
          <div>Transações mensais*</div>
          <div>Mensalidade</div>
          <div>Transações adicionais</div>
        </GridHead>
        <GridLine>
          { planCurrent?.plano.codigoPlano !== undefined
          ? <>
            <div>{planCurrent?.plano.pontosMensal}</div>
            <div>{moneyMask(`${planCurrent?.plano.valor * 100}`)}</div>
            <div>{moneyMask(`${planCurrent?.plano.valorTransacaoAdicional * 100}`)} </div>
          </> : <>
            <div>{20}</div>
            <div>{moneyMask(`0.00`)}</div>
            <div>{moneyMask(`0.50`)} (fixo) </div>
          </> }
        </GridLine>
      </TableLineGrid>

      <Spacing margin={8} />
      <Label size="14" color={theme.COLORS.PRIM_800A}>*oferta promocional de créditos não cumulativos</Label>

      <Spacing margin={16} />
      <Button onClick={() => handleClick()} text="Alterar Planos" confirm />
    </>
  )
}

export default DataSignature;
