import { Tab } from "components/Tab/Tab"

interface ITabEnterprise {
  activeIndexTab?: number
}
const TabEnterprise = ({ activeIndexTab }: ITabEnterprise) => {
  const tabs = [
    { name: 'Cadastrar Empresas',url: '/home/empresas', isActive: activeIndexTab === 0 ? true : false },
    { name: 'Consultar Empresas',url: '/home/consultar-empresas', isActive: activeIndexTab === 1 ? true : false },
  ]

  return <Tab labels={tabs} />
}
export { TabEnterprise }