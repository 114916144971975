import LaunchPurchase from './screens/launchPurchase';
import AppHeader from 'components/AppHeader';
import { Route, useRouteMatch, Switch, useLocation } from 'react-router-dom';
import Enterprise from 'layout/Home/screens/Enterprise';
import Pay from './screens/Pay';
import User from './screens/User/User';
import FormUser from './screens/User/FormUser/FormUser';
import { PageSuccess } from './screens/User/Success';
import { LaunchPurchaseSuccess } from './screens/LaunchPurchaseSuccess';
import { EnterprisePage } from './screens/Enterprise/Enterprise';
import { RouteAccount } from './screens/Account/routerAccount';
import { scrollToTop } from 'util/utils';
import { useEffect } from 'react';
import { GridHome } from 'styles/base';
import { IndexManegeRules } from './screens/ManageRules/IndexManegeRules';
import { IndexReports } from './screens/Reports';

const Home = () => {
  const { path } = useRouteMatch();
  
  const { pathname } = useLocation();
  useEffect(() => scrollToTop({ elemento: "#app-home" }), [pathname])

  return (
    <GridHome id="app-home">
      <AppHeader />
      <div>
        <Switch>
          <Route exact path={`${path}/empresas`} component={Enterprise} />
            <Route exact path={`${path}/consultar-empresas`} component={EnterprisePage} />

          <Route exact path={`${path}/`} component={LaunchPurchase} />
            <Route exact path={`${path}/lancar-compra/sucesso`} component={LaunchPurchaseSuccess} />

          <Route exact path={`${path}/pagar`} component={Pay} />

          <Route path={`${path}/relatorios`} component={IndexReports} />

          <Route path={`${path}/regras-gerais`} component={IndexManegeRules} />

          <Route exact path={`${path}/usuarios`} component={User} />
            <Route exact path={`${path}/usuarios/salvar`} component={FormUser} />
            <Route exact path={`${path}/usuarios/salvar/:id`} component={FormUser} />
            <Route exact path={`${path}/usuarios/sucesso`} component={PageSuccess} />

          <Route path={`${path}/conta`} component={RouteAccount} />

        </Switch>
      </div>
    </GridHome>
  );
};

export default Home;
