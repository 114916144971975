import styled from "styled-components"
import theme from "styles/theme"
import { TableContainer } from "styles/base";

export const TableBox = styled(TableContainer)`
  tr.info{
    th{
      width:50%;
    }
  }
  tr.more{
    th{
      line-height:160%;
      padding:15px 10px;
      border:0;
      background:${theme.COLORS.BLACK_100};

      a{
        display:inline-flex;
        text-transform:uppercase;
        text-decoration: none;
        font-size:12px;
        font-weight:700;
        color:${theme.COLORS.PRIM_800};
        border:1px solid ${theme.COLORS.PRIM_800};
        padding:5px 20px;
        margin:15px 0 0;
        border-radius:4px;
        transition:.2s;

        &:hover{
          color:${theme.COLORS.WHITE};
          background: ${theme.COLORS.PRIM_800};
        }
      }
    }
  }
`

export const BoxPayment = styled.div`
  font-weight:500;
  line-height:160%;
  padding:15px 0;
  color:${theme.COLORS.PRIM_800};
  & > p > span{
    letter-spacing:.05rem;
  }
`

interface IGridForm {
    end?: boolean | string,
    buttons?: boolean,
    columns?: number
  }
export const GridForm = styled.div<IGridForm>`
    display:grid;
    grid-template-columns:${props => props?.buttons ? 'auto auto 1fr' : `repeat(${props.columns}, 1fr)`};
    gap:15px;
    padding:${props => props?.end ? '25px 0 0' : '15px 0'};
    justify-content: start;
  
    label,
    input, 
    select{
      font:18px 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
      font-weight:500 !important;
    }

    @media(max-width:1174px){
      grid-template-columns: 1fr 1fr;
    }
    @media(max-width:960px){
      grid-template-columns: 1fr;
    }
  `
  interface ITitleH2 {
    start?: boolean | string,
  }
  export const TitleH2 = styled.h2<ITitleH2>`
    font-weight: bold;
    font-size: 18px;
    color: ${theme.COLORS.PRIM_800};
    text-transform: none;
    line-height: 20px;
    margin: ${p => p.start ? '0 0 5px 0' : '30px 0 5px 0'};
    text-transform: uppercase;
  `

  export const LabelError = styled.label`
    &.error{
      font-weight: normal;
      font-size: 12.8px !important;
      color: ${theme.COLORS.ERROR_700};
      /* font-family: "MontSerrat"; */
      text-transform: none;
      line-height: 20px;
      margin-bottom: 0;
    }
  `

  export const BoxButtons = styled.div`
    display: flex;
    gap:10px;
    padding:20px 0 0;

    & > button{
      height:45px;
    }
  `

  export const ButtonCancel = styled.button`
    display:inline-flex;
    align-items:center;
    height:49px;

    font-family:${theme.FONTS.MONTSERRAT};
    font-size: .8em;
    font-weight: bold;
    line-height: 19px;
    text-align: center;
    color: ${theme.COLORS.PRIM_800};
    
    padding:0 25px;
    border-radius:6px;
    border:1px solid ${theme.COLORS.PRIM_800};
    background:${theme.COLORS.WHITE};
  `