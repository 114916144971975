import mony from '../../../package.json'
import { BoxVersion } from './styles'

export const Version = () => {

  const prodOrUat = () => {
    const host = document.location.host 
    const prod = ['adm.mony.com.br']
    const uat = ['adm.uat.mony.com.br', 'localhost:3000']

    if(prod.includes(host)) return 
    if(uat.includes(host)) return "(UAT)"
  }

  return <BoxVersion>v{mony.version} {prodOrUat()}</BoxVersion>
}