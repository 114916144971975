import styled from "styled-components"
import theme from "styles/theme"
import { TextField } from '@mui/material'

export const BoxSearch = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  h2{
    font-size:22px;
    text-transform: uppercase;

    span{
      font-weight:500;
    }
  }

  button{
    height:48px;
  }
`

export const TextFieldBox = styled(TextField)`
  width: 250px;

  & > div > div{
    color:${theme.COLORS.PRIM_800};
    padding:12px 15px; 
  }

  & > label{
    color:${theme.COLORS.PRIM_800};
    font-size:18px;
    top:-3px;
  }
`

export const Search = styled.div`
  display: flex;
  gap:10px;
  align-items: center;
`
export const ButtonSave = styled.div`
  display:flex;

  & > button{
    background: ${theme.COLORS.SEC_800};
    border:1px solid ${theme.COLORS.SEC_800};
  }
`
export const LinkTable = styled.a`
  cursor:pointer;
  font-size:14px;
  font-weight:700;
  text-decoration: underline;
`

export const ButtonClose = styled.button`
  border:0;
  background:transparent;
  position:relative;
  z-index: 2;
  width:30px;
  margin-left: -30px;
`

export const BoxSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  gap:20px;

  h2,
  svg{
    display:flex;
    gap:10px;
  }
  h2{
    font-size:20px;
    align-items: center;
    color:${theme.COLORS.PRIM_800};
  }
`