import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { cardMask, cardMaskAmex, cardMaskDiners, monthYearMask, replaceEspecials } from "util/masks"
import { deleteAccession, postAccessionMake, postValidateCard, putMigratePlans, updateCard } from "../services"
import moment from "moment";
import { scrollToTop } from "util/utils";
import { IAccession, IPlansList } from "../services/types";

interface IValuePayment{
  nome: string,
  numero: string,
  vencimento: string,
  cvv: string,
  bandeira: string,
}
const paymentDefault : IValuePayment = {
  nome: '',
  numero: '',
  vencimento: '',
  cvv: '',
  bandeira: '',
}
interface IPayment{
  numeroCartao: string,
  codigoSeguranca: string,
  mesValidade: string,
  anoValidade: string,
  bandeira: string,
  nomeCartao: string,
}

const regexFlags = {
  mastercard: /^5[1-5][0-9]{14}/,
  amex: /^3[47][0-9]{13}/,
  discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
  jcb: /^(?:2131|1800|35\d{3})\d{11}/,
  aura: /^(5078\d{2})(\d{2})(\d{11})$/,
  elo: /^((401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636369|(506699|5067[0-6]\d|50677[0-8])|(50900\d|5090[1-9]\d|509[1-9]\d{2})|65003[1-3]|(65003[5-9]|65004\d|65005[0-1])|(65040[5-9]|6504[1-3]\d)|(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|(65054[1-9]|6505[5-8]\d|65059[0-8])|(65070\d|65071[0-8])|65072[0-7]|(65090[1-9]|65091\d|650920)|(65165[2-9]|6516[6-7]\d)|(65500\d|65501\d)|(65502[1-9]|6550[3-4]\d|65505[0-8]))[0-9]{10,12})/gmi,
  //  elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
  diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
  hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
  visa: /^4[0-9]{12}(?:[0-9]{3})/
}
function cardFlegs(e : any, cartoes : any) {
  const numero = e.replace(/\D/g, '')

  for (var cartao in regexFlags){
    if (numero.match(cartoes[cartao])){
      return cartao;
    }
  }
  return false;
}

const modalDefault = {
  msg: '',
  open: false,
}
export const usePayment = () => {
  const [isBoxSuccess, setIsBoxSuccess] = useState(false)

  const [limit, setLimit] = useState({ numero:19, cvv: 3})
  const [load, setLoad] = useState(false)
  const [loadCard, setLoadCard] = useState(false)
  const [modal, setModal] = useState(modalDefault)
  const [edit, setEdit] = useState(false)

  const [values, setValues] = useState<IValuePayment>(paymentDefault)
  const [valuesSelect, setValuesSelect] = useState<any>() //IAccession | undefined
  const [valuesData, setvaluesData] = useState<any>() //IAccession | undefined
  
  const [styleFlag, setStyleFlag] = useState('')
  const [error, setError] = useState({...paymentDefault, geral: ''})

  const navigate = useHistory();

  const handleModal = () => setModal( (prevState) => ({...prevState, open: false}) )

  const handleCancel = () => {
    resetDataAll()
    handleEdit()
    handleModal()
    setError({...paymentDefault, geral: ''})
  }

  const handleEdit = () => setEdit(!edit)


  const handleNomeCartao = (e: string) => {
    var regexp = /[^a-zA-Z ]/g;
    if(!e.match(regexp))
      setValues( (prevState) => ({...prevState, nome: e.toUpperCase() }) );    
  }

  const validateCard = async (e : string) => {
    if(e.length >= 16){ //17
      setLoadCard(true)
      const { data, response } = await postValidateCard(replaceEspecials(e))
        .then(resp => resp)
        .catch(error => error)
        .finally(() => setLoadCard(false))        

      if(!data){
        if(data === undefined && response === undefined || response.data.erroCode === 21 || response.data.erroCode === 23){
          setError((prevState) => ({ ...prevState, numero: response.data.message }))
          return false
        }
      }

      setError((prevState) => ({ ...prevState, numero: '' }))
      return true
    }
    return true
  }

  const flagCard = (type : string, e :string) => {
    const flagCard : any = {
      amex: { num: 17, cvv: 4, mask: cardMaskAmex(e) },
      diners: { num: 16, cvv: 3, mask: cardMaskDiners(e) },
      default: { num: 19, cvv: 3, mask: cardMask(e) }
    }

    return flagCard[type] || flagCard.default
  }
  const handleNumeroCartao = (e: string) => {
    const flag : string = cardFlegs(e, regexFlags).toString()

    const instrution = flagCard(flag, e) 
    const num = instrution.num
    const numberMask = instrution.mask
    setLimit({ numero: instrution.num, cvv: instrution.cvv })

    if(e.length >= num){
      validateCard(e)
    }
    
    setStyleFlag(flag)
    setValues( (prevState) => ({...prevState, numero: numberMask, bandeira: flag.toUpperCase() }) )
  }

  const handleValidadeCartao = (e: string) => {
    setValues( (prevState) => ({...prevState, vencimento: monthYearMask(e) }) )
  }

  const handleCvvCartao = (e: string) => {
    const value = e.replace(/\D/g, '')
    setValues( (prevState) => ({...prevState, cvv: value }) )
  }

  const resetDataAll = () => {
    setStyleFlag('')
    setValues(paymentDefault)
  }

  const handleReset = () => {
    setModal((prevState) => ({...prevState, msg: 'Deseja realmente sair? Os dados não salvos serão perdidos', open: true}))
  }
  
  const isValidInput = {
    nome: () => {
      if(values.nome === ''){
        setError((prevState) => ({ ...prevState, nome: 'Nome é obrigatório' }))
        return true
      }else if(values.nome !== '' && values.nome.length < 4){
        setError((prevState) => ({ ...prevState, nome: 'Necessário ter no mínimo 4 letras' }))
        return true
      }

      setError((prevState) => ({ ...prevState, nome: '' }))
      return false
    },
    cvv: () => {
      if(values.cvv === ''){
        setError((prevState) => ({ ...prevState, cvv: 'Código é obrigatório' }))
        return true
      }
      const limite = limit.cvv - 1
      if(values.cvv !== '' && values.cvv.length <= limite){
        setError((prevState) => ({ ...prevState, cvv: 'CVV inválido' }))
        return true
      }

      setError((prevState) => ({ ...prevState, cvv: '' }))
      return false
    },
    numeroCartao: () => {
      if(values.numero === ''){
        setError((prevState) => ({ ...prevState, numero: 'Número é obrigatório' }))
        return true
      }else if((values.numero).length <= (limit.numero - 1)){
        setError((prevState) => ({ ...prevState, numero: 'Número incorreto' }))
        return true
      }else if(!validateCard(values.numero)){
        return true
      }

      setError((prevState) => ({ ...prevState, numero: '' }))
      return false
    },
    vencimento: () => {
      const dateNow = new Date()
      const monthCurrent = moment( (dateNow.getMonth() ).toString() ).format('MM');
      const yearCurrent = moment( (dateNow.getFullYear() ).toString() ).format('YYYY');
      const yearMax = moment( (dateNow.getFullYear() + 50 ).toString() ).format('YYYY'); // máximo 50 anos para frente
      const _vMonth = values.vencimento.slice(0,2);
      const _vYear = values.vencimento.slice(3,7);

      const validade = {
        vazio: values.vencimento === '' || values.vencimento === null,
        lenght: (values.vencimento).length <= 6,
        maxMes: parseInt( _vMonth ) < 1 || parseInt( _vMonth ) > 12,
        mes: ( parseInt( _vMonth ) <= parseInt( monthCurrent ) || parseInt( _vMonth ) > 12 ) && ( parseInt( _vYear ) <= parseInt( yearCurrent ) ), // 12 mese
        ano: parseInt( _vYear ) < parseInt( yearCurrent ) || parseInt( _vYear ) > parseInt( yearMax ), 
      }

      if(validade.vazio){
        setError((prevState) => ({ ...prevState, vencimento: 'Vencimento é obrigatório' }))
        return true
      }else if(!validade.vazio && validade.lenght){
        setError((prevState) => ({ ...prevState, vencimento: 'Data incorreta - ex: 01/2022' }))
        return true
      }else if(validade.mes || validade.maxMes){
        setError((prevState) => ({ ...prevState, vencimento: 'Mês incorreto' }))
        return true
      }else if(validade.ano){
        setError((prevState) => ({ ...prevState, vencimento: 'Ano incorreto' }))
        return true
      }

      setError((prevState) => ({ ...prevState, vencimento: '' }))
      return false
    },
  }

  const handleSubmit = async () => {
    setLoad(true)
    setError((prevState) => ({...prevState, geral: '' }))

    const dados : IPayment = {
      "numeroCartao": replaceEspecials(values.numero),
      "codigoSeguranca": values.cvv,
      "mesValidade": values.vencimento.slice(0,2),
      "anoValidade": values.vencimento.slice(3,7),
      "bandeira": values.bandeira,
      "nomeCartao": values.nome
    }
    
    const isValid : boolean[] = [
      isValidInput.nome(),
      isValidInput.numeroCartao(),
      isValidInput.vencimento(),
      isValidInput.cvv(),
    ]

    if(isValid.includes(true)){
      scrollToTop({})
      setLoad(false)
      setError((prevState) => ({...prevState, geral: 'Ops... verifique os dados sinalizados para prosseguir' }))
      return;
    }    
    
    const data = new Blob(
      [JSON.stringify(dados)],
      {type: "application/json"}
    );

    const formdata = new FormData();
	
    formdata.append("cartao", data);
    formdata.append("aceiteTermos", 'true');
    
    try{
      const resp = await postAccessionMake(formdata)
      navigate.push('/conta-validada');
      handleEdit()
      resetDataAll()
      setError((prevState) => ({...prevState, geral: "" }))
    }catch(error : any){
      scrollToTop({})

      if (error?.response?.data?.erroCode === 20) {
        return setError((prevState) => ({...prevState, geral: "Ops... Não foi possível realizar o pagamento."}));
      }

      setError((prevState) => ({...prevState, geral: "Ops... "+ error?.response?.data?.message}));
    }
    setLoad(false)
  }

  const handleUpdate = async () => {
    setLoad(true)
    setError((prevState) => ({...prevState, geral: '' }))

    const dados : IPayment = {
      "numeroCartao": replaceEspecials(values.numero),
      "codigoSeguranca": values.cvv,
      "mesValidade": values.vencimento.slice(0,2),
      "anoValidade": values.vencimento.slice(3,7),
      "bandeira": values.bandeira,
      "nomeCartao": values.nome
    }
    
    const isValid : boolean[] = [
      isValidInput.nome(),
      isValidInput.numeroCartao(),
      isValidInput.vencimento(),
      isValidInput.cvv(),
    ]

    if(isValid.includes(true)){
      scrollToTop({})
      setLoad(false)
      setError((prevState) => ({...prevState, geral: 'Ops... verifique os dados sinalizados para prosseguir' }))
      return
    }    
   
    
    try{
      // const resp = await updateCard(formdata)  //JSON.stringify(dados)
      const resp = await updateCard(JSON.stringify(dados))
      .finally(() => {
        setLoad(false)
        setvaluesData({
          ...valuesData,
          cartao: {
            ...valuesData?.cartao,
            numeroCartao: dados.numeroCartao,
            bandeira: dados.bandeira,
            mesValidade: dados.mesValidade,
            anoValidade: dados.anoValidade,
          }
        })
      })
      //console.log(resp)
      // navigate.push('/conta-validada');
      setIsBoxSuccess(true)
      handleEdit()
      resetDataAll()
      setError((prevState) => ({...prevState, geral:"" }))
    }catch(error : any){
      scrollToTop({})
      setError((prevState) => ({...prevState, geral:"Ops... "+ error?.response?.data?.message}))
    }
    setLoad(false)
  }

  const handleMigratePlans = async ({ planCurrent, planSelected } : 
    { planCurrent : IAccession | undefined, planSelected: IPlansList | undefined }) => {
    setLoad(true)
    setError((prevState) => ({...prevState, geral: '' }))

    const dados : IPayment = {
      "numeroCartao": replaceEspecials(values.numero),
      "codigoSeguranca": values.cvv,
      "mesValidade": values.vencimento.slice(0,2),
      "anoValidade": values.vencimento.slice(3,7),
      "bandeira": values.bandeira,
      "nomeCartao": values.nome
    }

    const isValid : boolean[] = [
      isValidInput.nome(),
      isValidInput.numeroCartao(),
      isValidInput.vencimento(),
      isValidInput.cvv(),
    ]
   
    if(planCurrent?.plano.codigoPlano === 'PL00' && isValid.includes(true)){
      scrollToTop({})
      setLoad(false)
      setError((prevState) => ({...prevState, geral: 'Ops... verifique os dados sinalizados para prosseguir' }))
      return
    }
    
    putMigratePlans(planSelected?.id, planCurrent?.plano.codigoPlano === 'PL00' ? dados : null )
      .then((e) => {
        navigate.push('/home/conta/planos/sucesso');
        setIsBoxSuccess(true)
        handleEdit()
        resetDataAll()
        setError((prevState) => ({...prevState, geral:"" }))
      })
      .catch((error) => {
        scrollToTop({})
        setError((prevState) => ({...prevState, geral:"Ops... "+ error?.response?.data?.message}))
      })
      .finally(() => setLoad(false))
  }

  const numberCard = (num : string) => {
    const finish = num?.length
    return num.slice(finish - 4, finish)
  }

  const singlePayment = async (isMigrateFree : boolean) => {
    setLoad(true)
    setError((prevState) => ({...prevState, geral: '' }))
    
    const dados : IPayment = {
      "numeroCartao": replaceEspecials(values.numero),
      "codigoSeguranca": values.cvv,
      "mesValidade": values.vencimento.slice(0,2),
      "anoValidade": values.vencimento.slice(3,7),
      "bandeira": values.bandeira,
      "nomeCartao": values.nome
    }

    const isCardValid = await validateCard(dados.numeroCartao)
    
    const isValid : boolean[] = [
      isValidInput.nome(),
      isValidInput.numeroCartao(),
      isValidInput.vencimento(),
      isValidInput.cvv()
    ]

    if(isValid.includes(true) || !isCardValid){
      scrollToTop({})
      setLoad(false)
      setError((prevState) => ({...prevState, geral: 'Ops... verifique os dados sinalizados para prosseguir' }))
      return
    }
        
    try{
      const resp = await deleteAccession(isMigrateFree, JSON.stringify(dados))
      // console.log(resp)
      //navigate.push('/conta-validada');
      handleEdit()
      resetDataAll()
      setError((prevState) => ({...prevState, geral: "" }))

      return { isSuccess: true, dados: resp.data }
    }catch(error : any){
      scrollToTop({})
      setError((prevState) => ({...prevState, geral: "Ops... "+ error?.response?.data?.message}))
    }
    setLoad(false)
  }

  return { values, setValues, load, loadCard, edit, setEdit, error, styleFlag,
    handleEdit, handleSubmit, handleReset, handleNomeCartao, handleNumeroCartao, 
    handleValidadeCartao, handleCvvCartao, modal, handleModal, handleCancel, 
    numberCard, limit, handleUpdate, isBoxSuccess, setIsBoxSuccess,
    valuesData, setvaluesData, handleMigratePlans, setValuesSelect, singlePayment }

}