import { ReactChildren, ReactChild, useState } from 'react';
import { DataWrapper, TituloH2 } from "styles/base"
import { ReactComponent as Drop } from 'assets/icons/drop-arrow.svg';
import styled from '@emotion/styled';
import theme from 'styles/theme';

interface IAccordion {
  titulo: string,
  children?: ReactChild | ReactChildren,
  isActive?: boolean,
  isControl?: boolean,
}
export const Accordion = ({ titulo, children, isActive, isControl = true }: IAccordion) => {
  const [active, setActive] = useState(isActive)

  const handleActive = () => setActive(!active)

  return <AcoordionBox>
    {isControl ?
    <AcoordionTitle onClick={handleActive}>
      {titulo} <Drop className={active ? 'ativo' : ''} />
    </AcoordionTitle>
    : <AcoordionTitle className="not">{titulo}</AcoordionTitle> }

    <AcoordionMain className={active ? 'ativo' : ''} >{children}</AcoordionMain>
    {/* {active ? <AcoordionMain className={active ? 'ativo' : ''} >{children}</AcoordionMain> : null} */}
  </AcoordionBox>
}
const AcoordionBox = styled(DataWrapper)`
    & + div{
      margin-top: 24px;
    }
  `
const AcoordionTitle = styled(TituloH2)`
    cursor:pointer;
  
    &.not{
      cursor: default;
    }
  
    svg{
      fill:currentColor;
      width:20px;
      height:20px;
      transition:.2s;
  
      &.ativo{
        transform: rotate(180deg);
      }
    }
  `
const AcoordionMain = styled.div`
  display: none;
  padding: 33px 0 0;

  &.ativo{
    display:block;
  }
`
