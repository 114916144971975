import { CircularProgress, TextField } from "@mui/material";
import { getUserData } from "components/AppHeader/service";
import Button from "components/Button";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Close } from 'assets/icons/close.svg';

const BarSearch = ({ value, onChange, clear } : { value?: string, onChange?: any, clear?: any}) => {
    const navigate = useHistory();

    const [load, setLoad] = useState(false)
    const [perfil, setPerfil] = useState('')
    const perfilLocal = localStorage.getItem("@mony:usuarioPerfil")

    // const checkPerfil = (e : string) => {
    //   if(e === 'GERENTE'){
    //     setPerfil(true)
    //   }else{
    //     setPerfil(false)
    //   }
    // }
    const getPerfil = async () => {
      setLoad(true)
      const resp = await getUserData();
      const authority = resp.data.body.authorities[0].authority
      setPerfil(authority)
      setLoad(false)
    }
    const init =  () => {
      if(perfilLocal !== ''){
        setPerfil(perfilLocal || '')
      }else{
        getPerfil()
      }
    }
    
    useEffect(() => {
      init()
    },[])
  
    return <BarSeachBox> 
      {load ? <CircularProgress /> : <>
        {!(perfil === "GERENTE" || perfil === "VENDEDOR") && <>
          <Button onClick={() => navigate.push('/home/usuarios/salvar/?funcao=diretor')} text="Cadastrar Diretor"/>
          <Button onClick={() => navigate.push('/home/usuarios/salvar/?funcao=gerente')} text="Cadastrar Gerente"/>
        </>}
        {!(perfil === "VENDEDOR") && <Button onClick={() => navigate.push('/home/usuarios/salvar/?funcao=vendedor')} text="Cadastrar Vendedor" /> }

        <div>
          <TextField 
            // label="CPF" 
            placeholder="CPF"
            inputProps={{ maxLength: 14 }}
            variant="standard" 
            value={value} 
            // InputLabelProps={{shrink: true}}
            autoComplete="off"
            onChange={onChange} />
            {!!value && value?.length > 10 
              ? <ButtonClear onClick={clear}><Close /></ButtonClear> 
              :  null}
        </div>
      </>}
    </BarSeachBox>
  }
  const BarSeachBox = styled.div`
    display:flex;
    flex:1;
    gap:10px;
    padding-bottom:24px;
    
    & > button{
      height:40px;
    }
  
    & > div{
      display:flex;
      padding: 10px 25px 0;
      position:relative;
      max-width:350px;

      & > div{
        display:flex;
        flex:1;
      }
    }
  `
  const ButtonClear = styled.button`
    cursor:pointer;
    display:flex;
    align-items: center;
    background:transparent;
    border:0px;
    position:absolute;
    right:25px;
    bottom:2px;
    padding:5px;

    svg{
      width:20px;
      height:20px;
    }
  `

  export { BarSearch }