import TitlePage from "components/TitlePage"
import { useHistory } from "react-router-dom"
import { AppBody } from "styles/base"
import Success from "../sucess/Success"
import { useQueryString } from "./FormUser/useFormUser"

const PageSuccess = () => {
    const navigate = useHistory()

    const query = useQueryString()
    const qs_perfil = query.get('perfil')
    const qs_update = query.get('update')
    
    const msg = qs_update === '1' 
        ? 'Dados Salvos com sucesso!' 
        : `Dados Salvos com sucesso. <br/> Um e-mail será enviado ao ${qs_perfil?.toLowerCase()}, com uma senha para o primeiro acesso.`

    const onSuccess = () =>  navigate.push('/home/usuarios/')
    
    return <AppBody>
        <TitlePage title="Gerenciar Usuários" />

        <Success onSuccess={onSuccess} label={msg} iconSuccess={false} />
    </AppBody> 
}
export { PageSuccess }