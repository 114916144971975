import styled from "styled-components";
import theme from "styles/theme";

import { ReactComponent as LeftArrow } from 'assets/icons/arrowLeft.svg';
import { ReactComponent as RigthArrow } from 'assets/icons/arrowRight.svg';
import { useCallback, useState } from "react";
import { scrollToTop } from "util/utils";

interface IPaginationAPI{
  count: number,
  perPage?: number, 
  onPageChange:(arg: number) => void,
  currentPage?:number
}
export const PaginationAPI = ({ count, perPage = 10, onPageChange, currentPage = 0 } : IPaginationAPI) => {
  const [current, setCurrent] = useState(currentPage)
  const totalPage = Math.ceil(count / perPage)

  const handleGoToPage = (e : any) => {
    setCurrent(e)
    onPageChange(e)
    scrollToTop({ elemento: "#app-home" })
  }

  const prev = () => current > 0 ? current - 1 : 0;
  const next = () => current + 1 < totalPage ? current + 1 : current;
  
  const itemPaginate = useCallback(() => {
    const linksSide = Math.ceil(perPage / 2);
    const start = current - linksSide
    const limit = current + linksSide

    const listArray = []

    for(let i = start; i <= limit; i++){
      if(i == current){
        listArray.push(i)
      }else if(i >= 0 && i < totalPage){
        listArray.push(i)
      }
    }
    return listArray
  }, [current, count])
  
  const totalListPage = totalPage < 10 ? [...Array(totalPage)] : itemPaginate()

  return totalListPage.length > 1 ? <BoxPaginate>
    <BtnPag onClick={() => handleGoToPage( prev() )}><LeftArrow /></BtnPag>
    <BoxPg>
      {totalListPage.map((id,idx) => {
        
        const i = totalPage < 10 ? idx : id

        return <BtnPag
          key={i}
          onClick={() => handleGoToPage(i)}
          className={current === i ? 'active' : ''} 
          disabled={current === i ? true : false} 
          >
            {i + 1}
          </BtnPag>
      })}
    </BoxPg>
    <BtnPag onClick={() => handleGoToPage( next() )}><RigthArrow /></BtnPag>

    {/* <p>{count} - {totalPage}</p> */}
  </BoxPaginate> : null
}

const BoxPaginate = styled.div`
  padding:20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:20px;
`
const BoxPg = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 110px);
  align-items: center;
  justify-content: center;
  gap:2px;
  background:${theme.COLORS.BLACK_100A};
  border-radius:20px;
  padding: 2px;

  button{
    background:transparent;
  }
`
const BtnPag = styled.button`
  font-family: ${theme.FONTS.MONTSERRAT};
  width:36px;
  height:36px;
  border-radius:50px;
  border:0;
  cursor:pointer;

  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color:${theme.COLORS.BLACK_800};
  background:${theme.COLORS.BLACK_100A};

  &.active{
    background:${theme.COLORS.SEC_800};
  }
  
  & > svg{
    width: 12px;
    height: 12px;
    display:flex;
  }
  & > svg,
  & > svg path{
    fill:currentColor;
  }
  
  &:disabled{
    cursor: initial;
  }
`