import styled from "styled-components";
import { Props } from "styles/base";
import theme from "styles/theme";

interface ITitlePage {
  title?: string,
}

const TitlePage = ({ title }: ITitlePage) => {
  return <Label bold>{title}</Label>
}

export const Label = styled.h1<Props>`
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: ${(props) => (props.size ? `${props.size}px` : "32px")};
  color: ${(props) => (props.color ? props.color : theme.COLORS.WHITE)};
  font-family: "MontSerrat";
  line-height: 20px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "30px")};
  text-transform:uppercase;
`;

export default TitlePage


