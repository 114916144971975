import { useState } from "react";
import { TableLine } from "styles/base";
import { default as Modal } from 'components/modal/modal'
import { ReactComponent as PencilSvg } from 'assets/icons/pencilIcon.svg';
import { ReactComponent as RemoveSvg } from 'assets/icons/remove.svg';
import theme from "styles/theme";

const UnitContent = (
    { unit, remove, dataUnits, setUnitSelected } : 
    { unit: any, remove: Function, dataUnits: any, setUnitSelected: Function } ) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Modal
                text="A remoção é irreversível, tem certeza que deseja remover esta unidade da sua franquia?"
                open={showModal} 
                onClose={() => setShowModal(false)} 
                onYes={() => remove(unit.idUnidade)}
            />
            <TableLine style={{ backgroundColor: theme.COLORS.BLACK_400 }}>
                <th>{unit.codigoEstabelecimento}</th>
                <th>{unit.nomeUnidade}</th>
                <th>
                    <button onClick={() => setUnitSelected(unit)}>
                        <PencilSvg style={{
                            width: 24, height: 24, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
                        }}
                        />
                    </button>
                </th>
                <th>
                    <button onClick={() => setShowModal(true)} disabled={dataUnits?.page.length === 1}>
                        <RemoveSvg style={dataUnits?.page.length === 1 ? {
                            width: 24, height: 24, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
                            opacity: 0.5,
                        } : {
                            width: 24, height: 24, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
                        }}
                        />
                    </button>
                </th>
            </TableLine>
        </>
    )
}

export default UnitContent;
