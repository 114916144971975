import styled from 'styled-components';
import theme from '../../styles/theme';
import { MenuButton } from '../Button/styles';

export const MenuBtn = styled(MenuButton)`
    height:44px;
    gap:15px;
    margin-bottom:10px;
    color:${theme.COLORS.WHITE};
    border: 1px solid ${theme.COLORS.SEC_800};
    transition: .2s;
    
    & > span{
        color:currentColor;
        font-size: 0.74em;
    }
    & > svg,
    & > svg path{
        fill:currentColor;
    }
    &.ativo,
    &:hover{
        background: ${theme.COLORS.PRIM_200};
        border:1px solid ${theme.COLORS.PRIM_200};
    }

    :disabled {
        opacity: 0.6;
    }
`

export const Header = styled.div`
    color: ${theme.COLORS.BLACK_500}; 
    background:${theme.COLORS.PRIM_800};
    width: 300px;
    height: 100%;
    border-right: 4px solid ${theme.COLORS.PRIM_100};
    padding: 0 40px 40px;

    & > nav{
      position: sticky;
      top:50px;
    }
`;

export const Menu = styled.div`
    padding-inline-start: 0;
    margin: 0;
    flex-direction: row;
`;

export const Divisor = styled.hr`
    border-color: ${theme.COLORS.PRIM_700};
`;

export const BoxLoadPage = styled.div`
    display:flex;
    gap:20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position:fixed;
    top:0px;
    left:0px;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background: ${theme.COLORS.PRIM_800};
    color:${theme.COLORS.WHITE};
`;