import { useEffect, useState } from "react";
import api from "../../../../api";
import Button from "../../../../components/Button";
import { ReactComponent as FilePicker } from "assets/icons/file-picker.svg";
import FormControl, { FormControlMultLine, } from "../../../../components/FormControl";
import { AppBody, BoxIcon, DataWrapper, Label, Row, Spacing } from "../../../../styles/base";
import theme from "../../../../styles/theme";
import { alpha, cepMask, cnpjMask, cpfMask, name, number, phoneMask, replaceEspecials,} from "../../../../util/masks";
import { getServiceType } from "./service";
import { consoleStringify } from "../../../../util/console";
import { DataWrapperEnterprise, FileContainer, RowLine1, RowLine2, RowLine2a, RowLine3, RowLine4, RowDadosApp, GridDadosApp } from "./style";
import { ErrorAlert } from "../../../../components/ErrorAlert";
import { isCpfValid, isCnpjValid, validationDDD, isEmailValid, removeAccents, isCellPhoneDDDValid,} from "../../../../util/validations";
import { default as Modal } from "components/modal/modal";
import TitlePage from "components/TitlePage";


import Compress from "browser-image-compression";
import { TabEnterprise } from "./TabEnterprise";
import { InstanceManageRules } from "components/InstanceManageRules";
import { scrollToTop } from "util/utils";
import UnitForm from "components/UnitForm/UnitForm";
import { DataMony } from "../Account/dataMony";

const defaultValues = {
  cdPlanoSelecionado: 0,
  dadosDiretor: {
    nome: "",
    cpf: "",
    ddd: "",
    telefone: "",
    email: "",
  },
  dadosEmpresa: {
    cnpj: "",
    razaoSocial: "",
    nomeFantasia: "",
    descricao: "",
  },
  dadosUnidadePrincipal: {
    nomeUnidade: "",
    cnpj: "",
    endereco: {
      cep: "",
      endereco: "",
      numero: "",
      bairro: "",
      complemento: "",
      cidade: "",
      uf: "",
    },
  },
};

const valueAppMony = {
  nomeFantasia:'',
  razaoSocial: '',
  descricao: '',
  logo: '',
  logoBlob: '',
}

const Enterprise = () => {
  const [values, setValues] = useState(defaultValues);
  const [emailConfirmation, setEmailConfirmation] = useState("");

  const [loadfileImg, setLoadfileImg] = useState(false);
  const [fileImageArquive, setFileImageArquive] = useState<Blob>();
  const [fileImage, setFileImage] = useState("");
  const [fileName, setFileName] = useState("");

  const [listServiceType, setListServiceType] = useState([]);

  const [errorForm, setErrorForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [acceptedForm, setAcceptedForm] = useState(false);

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);


  const [dataApp, setDataApp] = useState(valueAppMony);
  const [endereco, setEndereco] = useState(defaultValues.dadosUnidadePrincipal);
  const [resetGeneralRules, setResetGeneralRules] = useState<any>();

  const handleModal = () => setOpen(!open);

  const initData = async () => {
    try {
      const response = await getServiceType(false); 
      const respOrdBy = (response.data).sort((a : any, b : any) =>{
        return a.idPlanoMensalidade - b.idPlanoMensalidade 
      })
      setListServiceType(respOrdBy);
    } catch (error) {
      consoleStringify(error);
    }
  }

  useEffect(() => {
    initData();
  }, []);

  if (!listServiceType) {
    return <AppBody><TitlePage title="Cadastrar Empresa" />
      <DataWrapper><b>Ops! Ocoreu erro ao carregar a lista de planos</b></DataWrapper></AppBody>;
  }

  // Campos Formuário
  const tipoPlano = document.querySelector("#tipo-plano") as HTMLInputElement;
  const nomeEmpresa = document.querySelector("#nome-empresa") as HTMLInputElement;
  const cnpjEmpresa = document.querySelector("#cnpj-empresa") as HTMLInputElement;
  const nome = document.querySelector("#nome") as HTMLInputElement;
  const cpf = document.querySelector("#cpf") as HTMLInputElement;
  const celular = document.querySelector("#celular") as HTMLInputElement;
  const email = document.querySelector("#email") as HTMLInputElement;
  const emailConfirmar = document.querySelector("#emailConfirmar") as HTMLInputElement;
  
  const nomeApp = document.querySelector("#nome-app") as HTMLInputElement;
  const fileInput = document.querySelector("#file-picker") as HTMLInputElement;
  
  const nomeUnidade = document.querySelector("#nomeUnidade") as HTMLInputElement;
  const cep = document.querySelector("#cep") as HTMLInputElement;
  const logradouro = document.querySelector("#logradouro") as HTMLInputElement;
  const complemento = document.querySelector("#complemento") as HTMLInputElement;
  const cidade = document.querySelector("#cidade") as HTMLInputElement;
  const numero = document.querySelector("#numero") as HTMLInputElement;
  const estado = document.querySelector("#estado") as HTMLInputElement;
  const bairro = document.querySelector("#bairro") as HTMLInputElement;

  // Erros Formulário
  const tipoPlanoErro = document.querySelector("#tipo-plano__erro") as HTMLInputElement;
  const nomeEmpresaErro = document.querySelector("#nome-empresa__erro") as HTMLInputElement;
  const cnpjEmpresaErro = document.querySelector("#cnpj-empresa__erro") as HTMLInputElement;
  const nomeErro = document.querySelector("#nome__erro") as HTMLInputElement;
  const cpfErro = document.querySelector("#cpf__erro") as HTMLInputElement;
  const celularErro = document.querySelector("#celular__erro") as HTMLInputElement;
  const emailErro = document.querySelector("#email__erro") as HTMLInputElement;
  const emailConfirmarErro = document.querySelector("#emailConfirmar__erro") as HTMLInputElement;
  
  const nomeAppErro = document.querySelector("#nome-app__erro") as HTMLInputElement;
  const fileInputErro = document.querySelector("#file-picker__erro") as HTMLInputElement;
  
  const nomeUnidadeErro = document.querySelector("#nomeUnidade__erro") as HTMLInputElement;
  const cepErro = document.querySelector("#cep__erro") as HTMLInputElement;
  const logradouroErro = document.querySelector("#logradouro__erro") as HTMLInputElement;
  const cidadeErro = document.querySelector("#cidade__erro") as HTMLInputElement;
  const numeroErro = document.querySelector("#numero__erro") as HTMLInputElement;
  const estadoErro = document.querySelector("#estado__erro") as HTMLInputElement;
  const bairroErro = document.querySelector("#bairro__erro") as HTMLInputElement;

  // DESCRIÇÃO APP
  const descricao = document.querySelector( "#descricao-app") as HTMLInputElement;
  const descricaoErro = document.querySelector( "#descricao-app__erro") as HTMLInputElement;

  // REGRAS GERAIS
  const percentual = document.querySelector("#percentual") as HTMLInputElement;
  const percentualInput = percentual?.getElementsByTagName("input")[0] as HTMLInputElement;
  const percentualError = percentual?.getElementsByTagName("small")[0] as HTMLInputElement;

  const observacao = document.querySelector("#observacao") as HTMLInputElement;
  const observacaoInput = observacao?.getElementsByTagName("input")[0] as HTMLInputElement;
  const observacaoError = observacao?.getElementsByTagName("small")[0] as HTMLInputElement;

  const valorMaximoPorDia = document.querySelector( "#valorMaximoPorDia") as HTMLInputElement;
  const valorMaximoPorDiaInput = valorMaximoPorDia?.getElementsByTagName("input")[0] as HTMLInputElement;
  const valorMaximoPorDiaError = valorMaximoPorDia?.getElementsByTagName("small")[0] as HTMLInputElement;

  const validadeEmDia = document.querySelector( "#validadeEmDia") as HTMLInputElement;
  const validadeEmDiaInput = validadeEmDia?.getElementsByTagName("input")[0] as HTMLInputElement;
  const validadeEmDiaError = validadeEmDia?.getElementsByTagName("small")[0] as HTMLInputElement;

  const updateFile = (file: any) => {
    setFileName(file.files[0].name);
    setFileImage(URL.createObjectURL(file.files[0]));
  };

  const addDisabeledEndFull = () => {
    logradouro.setAttribute("disabled", "true");
    complemento.setAttribute("disabled", "true");
    bairro.setAttribute("disabled", "true");
    numero.setAttribute("disabled", "true");
    cidade.setAttribute("disabled", "true");
    estado.setAttribute("disabled", "true");
  };
  const removerDisabledEnd = () => {
    logradouro.removeAttribute("disabled");
    cidade.removeAttribute("disabled");
    estado.removeAttribute("disabled");
    bairro.removeAttribute("disabled");
  };

 
  const validationCancel = () => {
    tipoPlano.style.borderColor = theme.COLORS.PRIM_800;
    nomeEmpresa.style.borderColor = theme.COLORS.PRIM_800;
    cnpjEmpresa.style.borderColor = theme.COLORS.PRIM_800;

    nomeApp.style.borderColor = theme.COLORS.PRIM_800;
    descricao.style.borderColor = theme.COLORS.PRIM_800;

    nome.style.borderColor = theme.COLORS.PRIM_800;
    cpf.style.borderColor = theme.COLORS.PRIM_800;
    celular.style.borderColor = theme.COLORS.PRIM_800;
    email.style.borderColor = theme.COLORS.PRIM_800;
    emailConfirmar.style.borderColor = theme.COLORS.PRIM_800;

    nomeUnidade.style.borderColor = theme.COLORS.PRIM_800;

    tipoPlanoErro.innerHTML = "";
    nomeEmpresaErro.innerHTML = "";
    cnpjEmpresaErro.innerHTML = "";

    nomeAppErro.innerHTML = "";
    descricaoErro.innerHTML = "";
    // fileInputErro.innerHTML = "";

    nomeErro.innerHTML = "";
    cpfErro.innerHTML = "";
    celularErro.innerHTML = "";
    emailErro.innerHTML = "";
    emailConfirmarErro.innerHTML = "";

    nomeUnidadeErro.innerHTML = "";

    percentualError.innerHTML = "";
    valorMaximoPorDiaError.innerHTML = "";
    validadeEmDiaError.innerHTML = "";
  };

  const validationCancelEnd = () => {
    cep.style.borderColor = theme.COLORS.PRIM_800;
    logradouro.style.borderColor = theme.COLORS.PRIM_800;
    cidade.style.borderColor = theme.COLORS.PRIM_800;
    estado.style.borderColor = theme.COLORS.PRIM_800;
    bairro.style.borderColor = theme.COLORS.PRIM_800;

    cepErro.innerHTML = "";
    logradouroErro.innerHTML = "";
    cidadeErro.innerHTML = "";
    estadoErro.innerHTML = "";
    bairroErro.innerHTML = "";
  };
  const validationCancelEndNum = () => {
    numero.style.borderColor = theme.COLORS.PRIM_800;
    numeroErro.innerHTML = "";
  };

  const handleCancel = () => {
    setValues(() => (defaultValues));
  
    nomeApp.value= "";
    descricao.value = "";

    setFileName("");
    setFileImage("");
    fileInput.value = "";

    removerDisabledEnd();
    setEmailConfirmation("");

    setAcceptedForm(false);
    setErrorForm(false);
    setErrorMessage("");

    validationCancel();

    validationCancelEnd();
    validationCancelEndNum();

    
    setEndereco({
      nomeUnidade: "",
      cnpj: "",
      endereco: {
        cep: "",
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cidade: "",
        uf: "",
      }
    });

    setDataApp({
      nomeFantasia:'',
      razaoSocial: '',
      descricao: '',
      logo: '',
      logoBlob: '',
    });

    setResetGeneralRules({
      valorMaximoDia: 0,
      valorMaximoDiaMostrar: `R$ 0.00`,
      percentualCreditos: 0,
      quantidadeVigencia: 0,
      observacao: "",
    });


    scrollToTop({});
  };


  const handleFinish = () => {
    handleCancel();
    setAcceptedForm(false);
  };

  const isError = () => {
    let value = false;
    if (
      nomeUnidadeErro.innerHTML ||
      tipoPlanoErro.innerHTML ||
      nomeEmpresaErro.innerHTML ||
      cnpjEmpresaErro.innerHTML ||
      nomeAppErro.innerHTML ||
      nomeErro.innerHTML ||
      cpfErro.innerHTML ||
      celularErro.innerHTML ||
      emailErro.innerHTML ||
      emailConfirmarErro.innerHTML ||
      fileInputErro.innerHTML ||
      cepErro.innerHTML ||
      logradouroErro.innerHTML ||
      cidadeErro.innerHTML ||
      numeroErro.innerHTML ||
      estadoErro.innerHTML ||
      bairroErro.innerHTML ||
      percentualError.innerHTML ||
      valorMaximoPorDiaError.innerHTML ||
      validadeEmDiaError.innerHTML ||
      descricaoErro.innerHTML
    ) {
      value = true;
    }
    return value;
  };

  const showError = ({ input, label, msg, }: { input: any; label: any; msg: string; }) => {
    input.style.borderColor = theme.COLORS.ERROR_800;
    label.innerHTML = msg;
  };
  const hideError = ({ input, label }: { input: any; label: any }) => {
    input.style.borderColor = theme.COLORS.PRIM_800;
    label.innerHTML = "";
  };

  // PLANO
  const isValidTipoPlano: () => boolean = () => {
    const input = tipoPlano;
    const label = tipoPlanoErro;

    if (!input.value) {
      showError({ input, label, msg: "Seleção do plano é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };

  // DADOS EMPRESA
  const isValidNomeEmpresa: () => boolean = () => {
    const input = nomeEmpresa;
    const label = nomeEmpresaErro;

    if (!input.value) {
      showError({ input, label, msg: "Nome da empresa é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  const isValidCNPJ: () => boolean = () => {
    const input = cnpjEmpresa;
    const label = cnpjEmpresaErro;

    if (!input.value) {
      showError({ input, label, msg: "CNPJ é obrigatório" });
      return true;
    }
    if (input.value && !isCnpjValid(replaceEspecials(input.value))) {
      showError({ input, label, msg: "CNPJ inválido" });
      return true;
    }

    hideError({ input, label });
    return false;
  };

  // DADOS RESOPNSAVEL
  const isValidNome: () => boolean = () => {
    const input = nome;
    const label = nomeErro;

    if (!input.value) {
      showError({ input, label, msg: "Nome do responsável é obrigatório" });
      return true;
    }
    if (input.value && input.value.length < 4) {
      showError({
        input,
        label,
        msg: "Nome deve conter ao menos 3 caracteres",
      });
      return true;
    }
    hideError({ input, label });
    return false;
  };
  const isValidCPF: () => boolean = () => {
    const input = cpf;
    const label = cpfErro;

    if (!input.value) {
      showError({ input, label, msg: "CPF é obrigatório" });
      return true;
    }
    if (input.value && !isCpfValid(replaceEspecials(input.value))) {
      showError({ input, label, msg: "CPF inválido" });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  const isValidCelular: () => boolean = () => {
    const input = celular;
    const label = celularErro;

    if (!input.value) {
      showError({ input, label, msg: "Celular é obrigatório" });
      return true;
    }
    if (
      input.value &&
      !validationDDD(
        replaceEspecials(values.dadosDiretor.telefone).substring(0, 2)
      )
    ) {
      showError({ input, label, msg: "Celular inválido" });
      return true;
    }
    if (
      input.value &&
      !isCellPhoneDDDValid(replaceEspecials(values.dadosDiretor.telefone)) 
    ) {
      showError({ input, label, msg: "Celular inválido" });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  const isValidEmail: () => boolean = () => {
    const input = email;
    const label = emailErro;

    if (!input.value) {
      showError({ input, label, msg: "E-mail é obrigatório" });
      return true;
    }
    if (input.value && !isEmailValid(input.value)) {
      showError({ input, label, msg: "E-mail inválido" });
      return true;
    }
    hideError({ input, label });
    return false;
  };
  const isValidEmailConfirm: () => boolean = () => {
    const input = emailConfirmar;
    const label = emailConfirmarErro;

    if (!input.value) {
      showError({ input, label, msg: "E-mail é obrigatório" });
      return true;
    }
    if (input.value && !isEmailValid(input.value)) {
      showError({ input, label, msg: "E-mail inválido" });
      return true;
    }
    if (email.value !== input.value) {
      showError({
        input,
        label,
        msg: "E-mails não são iguais, tente novamente",
      });
      return true;
    }

    hideError({ input, label });
    return false;
  };

  // DADOS APP
  const isValidNomeApp: () => boolean = () => {
    const input = nomeApp;
    const label = nomeAppErro;

    if (!input.value) {
      showError({ input, label, msg: "Nome da empresa no app é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };

  /*
  const isValidDescricao: () => boolean = () => {
    const input = descricao;
    const label = descricaoErro;

    if (input.value === "") {
      showError({ input, label, msg: "Descrição do app é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  */

  // DADOS APP - LOGO
  const isValidLogo: () => boolean = () => {
    const input = fileInput;
    const label = fileInputErro;

   if (label.innerHTML !== "") {
      showError({ input, label, msg: label.innerHTML });
      return true;
    }

    return false;
  };

  // UNIDADE - ENDEREÇO
  const isValidNomeUnidade: () => boolean = () => {
    const input = nomeUnidade;
    const label = nomeUnidadeErro;

    if (!input.value) {
      showError({ input, label, msg: "Nome da unidade é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  const isValidCep: () => boolean = () => {
    const input = cep;
    const label = cepErro;

    if (!input.value) {
      showError({ input, label, msg: "CEP é obrigatório" });
      return true;
    }
    if (input.value.length !== 9) {
      showError({ input, label, msg: "CEP inválido" });
      return true;
    }
    // if (input.value.length === 9) {
    //   checkCep(replaceEspecials(input.value));
    // }

    hideError({ input, label });
    return false;
  };
  const isValidLogradouro: () => boolean = () => {
    const input = logradouro;
    const label = logradouroErro;

    if (!input.value) {
      showError({ input, label, msg: "Logradouro é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  const isValidCidade: () => boolean = () => {
    const input = cidade;
    const label = cidadeErro;

    if (!input.value) {
      showError({ input, label, msg: "Cidade é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  const isValidNumero: () => boolean = () => {
    const input = numero;
    const label = numeroErro;

    if (!input.value) {
      showError({ input, label, msg: "Número é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  const isValidEstado: () => boolean = () => {
    const input = estado;
    const label = estadoErro;

    if (!input.value) {
      showError({ input, label, msg: "Estado é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  const isValidBairro: () => boolean = () => {
    const input = bairro;
    const label = bairroErro;

    if (!input.value) {
      showError({ input, label, msg: "Bairro é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };

  // REGRAS GERAIS
  const isValidPercentual: () => boolean = () => {
    const input = percentualInput;
    const label = percentualError;

    if (input.value === "") {
      showError({ input, label, msg: "Percentual é obrigatório" });
      return true;
    }

    if (parseFloat(input.value.replace(',', '.')) < 0.01) {
      showError({
        input,
        label,
        msg: "Necessário preencher com valor acima de 0,01%",
      });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  const isValidValorMaximoPorDia: () => boolean = () => {
    const input = valorMaximoPorDiaInput;
    const label = valorMaximoPorDiaError;

    if (input.value === "") {
      showError({ input, label, msg: "Valor máximo por dia é obrigatório" });
      return true;
    }

    if (input.value === "R$ 0,00" || input.value === "R$ 0.00") {
      showError({ input, label, msg: "Valor máximo por dia é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };
  const isValidValidadeEmDia: () => boolean = () => {
    const input = validadeEmDiaInput;
    const label = validadeEmDiaError;

    if (input.value === "") {
      showError({ input, label, msg: "Validade em dias é obrigatório" });
      return true;
    }

    if (parseFloat(input.value) < 1) {
      showError({ input, label, msg: "Validade em dia é obrigatório" });
      return true;
    }

    hideError({ input, label });
    return false;
  };

  const handleSubmit = () => {
    
    const value_inputs = {
      ...values,
      dadosEmpresa: {
        cnpj: replaceEspecials(values.dadosEmpresa.cnpj),
        razaoSocial: nomeEmpresa.value,
        nomeFantasia: nomeApp.value,
        descricao: descricao.value,
      },
      dadosDiretor: {
        ...values.dadosDiretor,
        cpf: replaceEspecials(values.dadosDiretor.cpf),
        telefone: replaceEspecials(values.dadosDiretor.telefone).substring(2),
        ddd: replaceEspecials(values.dadosDiretor.telefone).substring(0, 2),
      },
      dadosUnidadePrincipal: {
        cnpj: replaceEspecials(values.dadosEmpresa.cnpj),
        nomeUnidade: nomeUnidade.value,
        endereco: {
          cep: replaceEspecials(cep.value),
          endereco: logradouro.value,
          numero: numero.value,
          bairro: bairro.value,
          complemento: complemento.value,
          cidade: cidade.value,
          uf: estado.value,
        },
      },
      campanha: {
        multiplicador: parseFloat((percentualInput.value).replace(',', '.')),
        ativo: true,
        observacoes: observacaoInput.value,
      },
      regrasGerais: {
        valorMaximoDia: parseFloat(valorMaximoPorDiaInput.value.replace("R$ ", "").replace(",", ".")),
        quantidadeVigencia: parseFloat(validadeEmDiaInput.value),
      },
    }
    // console.log('teste', value_inputs)

    const inputs = [
      isValidTipoPlano(),
      isValidNomeEmpresa(),
      isValidCNPJ(),
      isValidNome(),
      isValidCPF(),
      isValidCelular(),
      isValidEmail(),
      isValidEmailConfirm(),
      isValidNomeApp(),
      isValidLogo(),
      isValidNomeUnidade(),
      isValidCep(),
      isValidLogradouro(),
      isValidCidade(),
      isValidNumero(),
      isValidEstado(),
      isValidBairro(),
      isValidPercentual(),
      isValidValorMaximoPorDia(),
      isValidValidadeEmDia(),
    ];

    if (inputs.includes(true)) {
      setErrorMessage("Ops... verifique os dados sinalizados para prosseguir com o cadastro da empresa");
      setErrorForm(true);
      scrollToTop({});
      return;
    }

    const data = new Blob(
      [ JSON.stringify(value_inputs) ],
      { type: "application/json", }
    );

    const formdata = new FormData();

    formdata.append("dados", data);
    if (fileImageArquive) {
      formdata.append("logo", fileImageArquive);
    }

    enterpriseSalve(formdata);
  };

  const enterpriseSalve = async (e: FormData) => {
    setLoading(true);
    try {
      await api.post("/empresas", e);
      validationCancel();
      validationCancelEnd();

      handleCancel();
      setAcceptedForm(true);
    } catch (error: any) {

      error?.response?.data
      ? setErrorMessage(`Ops ... ${error?.response?.data?.message}`)
      : setErrorMessage(`Ops ... erro desconhecido!`)

      setErrorForm(true);
      scrollToTop({ elemento: "#app-body" });
    }
    setLoading(false);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();

    emailConfirmar.style.borderColor = theme.COLORS.ERROR_800;
    emailConfirmarErro.innerHTML = "Necessário digitar o email de confirmação";

    setTimeout(() => {
      emailConfirmar.style.borderColor = "";
      emailConfirmarErro.innerHTML = "";
    }, 1000 * 10); // 10 seg
  };

  return (
    <>
      <AppBody
        id="app-body-sucess"
        style={{ display: acceptedForm ? "block" : "none" }}
      >
        <Modal
          text="Deseja realmente sair? Os dados não salvos serão perdidos"
          open={open}
          onClose={handleModal}
          onYes={handleCancel}
        />

        <TitlePage title="Empresas" />

        <TabEnterprise activeIndexTab={0} />

        <DataWrapperEnterprise>
          <Spacing margin={8} />

          <Label size="18" bold>
            Empresa cadastrada com sucesso!
          </Label>
          <Spacing />
          <Label size="18">
            Um e-mail será enviado ao responsável, com uma senha para o primeiro
            acesso à plataforma.
          </Label>
        </DataWrapperEnterprise>

        <Spacing margin={24} />

        <Row style={{ justifyContent: "center" }}>
          <Row>
            <Button
              loginSubmit
              text="Ok"
              onClick={() => handleFinish()}
              style={{ minWidth: 150 }}
            />
          </Row>
        </Row>
      </AppBody>

      <AppBody
        id="app-body"
        style={{ display: acceptedForm ? "none" : "block" }}
      >
        <TitlePage title="Empresas" />

        {errorForm && <ErrorAlert message={errorMessage} />}

        <TabEnterprise activeIndexTab={0} />

        <DataWrapperEnterprise>
          <Spacing margin={8} />

          <Label size="18" bold>
            TIPO DE PLANO
          </Label>

          <FormControl
            selectServiceType
            id="tipo-plano"
            text=""
            options={listServiceType}
            value={values.cdPlanoSelecionado}
            placeholder="Selecione"
            style={{
              width: 300,
            }}
            onChange={({ target }) =>
              setValues({
                ...values,
                cdPlanoSelecionado: target.value,
              })
            }
          />

          <Spacing margin={2} />

          <Label size="12" id="tipo-plano__erro" color={theme.COLORS.ERROR_800} />

          <Spacing margin={18} />

          <Label size="18" bold>
            DADOS DA EMPRESA
          </Label>

          <Spacing margin={18} />

          <RowLine1>
            <div>
              <FormControl
                id="nome-empresa"
                text="Nome da empresa"
                value={values.dadosEmpresa.razaoSocial}
                size={28}
                onChange={({ target }) =>
                  setValues({
                    ...values,
                    dadosEmpresa: {
                      ...values.dadosEmpresa,
                      razaoSocial: target.value,
                    },
                  })
                }
                maxLength={40}
              />
              <Label
                size="12"
                id="nome-empresa__erro"
                color={theme.COLORS.ERROR_800}
              />
            </div>

            <div>
              <FormControl
                id="cnpj-empresa"
                text="CNPJ da empresa"
                value={values.dadosEmpresa.cnpj}
                size={18}
                onChange={({ target }) =>
                  setValues({
                    ...values,
                    dadosEmpresa: {
                      ...values.dadosEmpresa,
                      cnpj: cnpjMask(target.value),
                    },
                  })
                }
                maxLength={18}
              />
              <Label
                size="12"
                id="cnpj-empresa__erro"
                color={theme.COLORS.ERROR_800}
              />
            </div>
          </RowLine1>

          <Spacing margin={18} />

          <Label size="18" bold>
            DADOS DO RESPONSÁVEL
          </Label>

          <Spacing margin={18} />

          <RowLine2>
            <div>
              <FormControl
                id="nome"
                text="Nome"
                value={values.dadosDiretor.nome}
                size={48}
                onChange={({ target }) =>
                  setValues({
                    ...values,
                    dadosDiretor: {
                      ...values.dadosDiretor,
                      nome: name(target.value),
                    },
                  })
                }
                maxLength={40}
              />
              <Label size="12" id="nome__erro" color={theme.COLORS.ERROR_800} />
            </div>

            <div>
              <FormControl
                id="cpf"
                text="CPF"
                value={values.dadosDiretor.cpf}
                size={14}
                onChange={({ target }) =>
                  setValues({
                    ...values,
                    dadosDiretor: {
                      ...values.dadosDiretor,
                      cpf: cpfMask(target.value),
                    },
                  })
                }
              />

              <Label size="12" id="cpf__erro" color={theme.COLORS.ERROR_800} />
            </div>

            <div>
              <FormControl
                id="celular"
                text="Celular"
                value={values.dadosDiretor.telefone}
                size={12}
                onChange={({ target }) =>
                  setValues({
                    ...values,
                    dadosDiretor: {
                      ...values.dadosDiretor,
                      telefone: phoneMask(target.value),
                    },
                  })
                }
                maxLength={15}
              />

              <Label size="12" id="celular__erro" color={theme.COLORS.ERROR_800} />
            </div>
          </RowLine2>

          <Spacing margin={18} />
       
          <RowLine2a>
            <div>
              <FormControl
                id="email"
                text="E-mail"
                value={values.dadosDiretor.email}
                size={32}
                onChange={({ target }) => 
                  setValues({
                    ...values,
                    dadosDiretor: {
                      ...values.dadosDiretor,
                      email: removeAccents(target.value),
                    },
                  })
                }
                maxLength={50}
              />
              <Label size="12" id="email__erro" color={theme.COLORS.ERROR_800} />
            </div>
            <div>
              <FormControl
                id="emailConfirmar"
                text="Confirmar o e-mail"
                value={emailConfirmation}
                size={32}
                onChange={({ target }) => setEmailConfirmation(removeAccents(target.value))}
                maxLength={50}
                autoComplete="off"
                onPaste={handlePaste}
              />
              <Label
                size="12"
                id="emailConfirmar__erro"
                color={theme.COLORS.ERROR_800}
              />
            </div>
          </RowLine2a>
          
          
          <Spacing margin={36} />
          <Label size="18" bold>DADOS PARA O APP MONY</Label>
          <Spacing margin={18} />

          {/* DADIS APP  */}
          <DataMony 
            hasEdit={false} 
            hasControl={false}
            data={dataApp} 
            hasUploadBlob={(e : any) => setFileImageArquive(e)}
            />
          <Spacing margin={36} />


          <Label size="18" bold>DADOS DA LOJA</Label>

          <Spacing margin={18} />

          {/* ENDEREÇOS */}
          <UnitForm 
            // data={values.dadosUnidadePrincipal}
            data={endereco}
            hasControl={false} />
          
          <Spacing margin={18} />
          
          {/* REGRAS GERAIS */}
          <InstanceManageRules
            values={resetGeneralRules}
            valueInstanceActive={true} />

          <Row>
            <Row>
              <Button text="Cancelar" onClick={handleModal} />

              <Spacing margin={6} />

              <Button
                loginSubmit
                text="Cadastrar Empresa"
                loading={loading}
                disabled={loading}
                onClick={() => handleSubmit()} />
            </Row>
          </Row>
        </DataWrapperEnterprise>

        <Spacing margin={24} />
      </AppBody>
    </>
  );
};

export default Enterprise;
