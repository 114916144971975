import { useEffect, useState } from "react"
import { BoxCkbSwitch } from "./style"

interface ICheckboxSwitch {
  value?: boolean,
  onClick: any
}
const CheckboxSwitch = ({value = true, onClick }: ICheckboxSwitch) => {
  const [active, setActive] = useState(value)

  useEffect(() => {
    setActive(value)

    return () => setActive(true)
  },[value])

  const handleCleck = () => {
    setActive(!active)
    onClick(!active)
  }
  const styleCss = `active-${active ? 'on' : 'off'}`

  return <BoxCkbSwitch className={styleCss} onClick={handleCleck}>
    <div></div>
    {active ? <span>on</span> : <span>off</span>}
  </BoxCkbSwitch>
}
export { CheckboxSwitch } 