import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "styles/theme"

interface ITabs{
  name: string,
  url: string,
  isActive?: boolean
}
interface ITab {
  labels?: ITabs[],
}
const Tab = ({ labels }: ITab) => {
  return <BoxTab>
    {labels?.map((i, idx) => {
      return <Link to={i.url} key={idx} className={i.isActive ? 'active' : ''}>
        {i.name}
      </Link>
    })}
  </BoxTab>
}

const BoxTab = styled.div`
  display:flex;
  gap:10px;
  padding-bottom: 14px;
  
  & > a{
    border-radius:6px;
    font-size:16px;
    font-weight:700;
    color:${theme.COLORS.PRIM_800};
    padding:10px 28px 10px;
    background:${theme.COLORS.WHITE};
    text-decoration: none;
    position:relative;
    
    &.active:after{
      content:'';
      background:${theme.COLORS.WHITE};
      position:absolute;
      top:calc(100% - 5px);
      left:0px;
      width:100%;
      height:20px;
    }
  }
`

export { Tab }