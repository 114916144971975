import { BoxSearch, BoxSuccess, ButtonClose, ButtonSave, Search, TextFieldBox } from './style'
import { CircularProgress, MenuItem } from '@mui/material';
import Button from 'components/Button';
import { cnpjMask, cpfMask } from 'util/masks';
import { TableGrid } from 'components/TableGrid/TableGrid';
import { FormUpdate } from '../Form/FormUpdate';
import { ReactComponent as IconClose } from 'assets/icons/close.svg'
import { useList } from './useList';
import CheckIcon from '@mui/icons-material/Check';
import { useEffect } from 'react';
import { scrollToTop } from 'util/utils';

interface IInputMaskSearch {
  value?: any,
  setValue?: any,
  handleClean?: any
  tipo?: string,
  disabled?: boolean,
  onKeyUp?: any
}
const InputMaskSearch = ({ value, setValue, handleClean, tipo, onKeyUp, disabled }: IInputMaskSearch) => {

  const handleType = (e: any) => {
    if (tipo === 'CPF') {
      setValue(cpfMask(e))
    } else if (tipo === 'CNPJ') {
      setValue(cnpjMask(e))
    } else {
      setValue(e)
    }
  }

  return <div>
    <TextFieldBox
      autoComplete='off'
      value={value}
      inputProps={{ maxLength: tipo === 'CNPJ' ? 18 : 50 }}
      onChange={(e: any) => handleType(e.target.value)}
      label="Pesquisar"
      variant='standard'
      disabled={disabled}
      onKeyUp={(e) => onKeyUp(e)}
    />
    {value.length >= 2 && <ButtonClose onClick={handleClean}><IconClose /></ButtonClose>}
  </div>

}

const EnterpriseList = () => {

  const { listTypeSearch, listEnterprise, columns, rowTableFilter,
    success, values, load, type, typeInput, pageCurrent, handlePage,
    setSuccess, setValues, setType, setTypeInput, setLoad, setFilter,
    handleFilter, handleView, handleCancel, handlePageSucess, handleConfirm, handleEnterFilter } = useList()

  useEffect(() => scrollToTop({}), [pageCurrent])

  return !success
    ? <>
      {!values && <>
        <BoxSearch> 
          <h2>Quantidade de empresas: <span>{listEnterprise?.count}</span></h2>

          <Search>
            <TextFieldBox
              label="Selecione"
              select
              style={{ width: 150 }}
              defaultValue=""
              value={type}
              onChange={(e) => {
                setType(e.target.value)
                setTypeInput('')
              }}
            >
              <MenuItem key={'empty'} value="" selected>Selecione</MenuItem>
              {listTypeSearch.map((i, idx) => (
                <MenuItem key={idx} value={i}>{i}</MenuItem>
              ))}
            </TextFieldBox>

            <InputMaskSearch
              value={typeInput}
              setValue={setTypeInput}
              tipo={type}
              handleClean={() => {
                setFilter('')
                setType('')
                setTypeInput('')
              }}
              onKeyUp={(e : any) => handleEnterFilter(e.key)}
              disabled={!type ? true : false}
            />

            <ButtonSave><Button onClick={handleFilter} text='Buscar' disabled={load} /></ButtonSave>
          </Search>
        </BoxSearch>

        <div id="app-body"></div>
        <TableGrid
            columns={columns}
            rows={rowTableFilter}
            // handleEdit={handleEdit}
            // handleModal={handleModal}
            loading={load}
            goTo={handlePage}
            totalRows={listEnterprise?.count}
            currentPg={pageCurrent}
          />

        {/* {load
          ? <CircularProgress />
          : <TableGrid
            columns={columns}
            rows={rowTableFilter}
            // handleEdit={handleEdit}
            // handleModal={handleModal}
            goTo={handlePage}
            totalRows={listEnterprise?.count}
            currentPg={pageCurrent}
          />} */}
      </>}

      {!!values && <FormUpdate onSuccess={handlePageSucess} onCancel={handleCancel} dadosUpdate={values} />}
    </>
    : <BoxSuccess> <h2>Dados atualizados com sucesso <CheckIcon /></h2> <ButtonSave><Button onClick={handleConfirm} text="ok" /></ButtonSave></BoxSuccess>
}
export { EnterpriseList }