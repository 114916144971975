import styled from 'styled-components';
import { DataWrapper, Row } from 'styles/base';
import theme from 'styles/theme';

export const FileContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid ${theme.COLORS.BLACK_200};
    height: 160px;
    width: 160px;
    margin-right: 30px;

    img {
        width: 110px;
        height: 110px;
        margin-bottom: 5px;
    }

    span {
        display: block;
    }
`;

export const TabButton = styled.div`
    border-radius: 6px 6px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 32px 24px;
    background-color: ${theme.COLORS.WHITE};
`;

export const DataWrapperEnterprise = styled(DataWrapper)`
    border-radius: 0 6px 6px 6px;
`;


export const RowLine1 = styled(Row)({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, auto) minmax(200px, 270px)',
  gap: 16,

  ['@media (max-width:1200px)']: {
    gridTemplateColumns: '1fr',
  }
});
export const RowLine2 = styled(Row)({
  display: 'grid',
  gridTemplateColumns: '1fr 210px 210px',
  gap: 16,
  "& > div > div *": {
    width: '100%',
  },
  ['@media (max-width:1200px)']: {
    gridTemplateColumns: '1fr',
  }
});

export const RowLine2a = styled(Row)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 16,

  "& > div > div *": {
    width: '100%',
  },
  ['@media (max-width:1200px)']: {
    gridTemplateColumns: '1fr',
  }
});
export const RowLine3 = styled(Row)({
  display: 'grid',
  gridTemplateColumns: 'minmax(230px, 1fr) 150px minmax(150px, 300px) minmax(100px, 180px)',
  gap: 16,
  ['@media (max-width:1200px)']: {
    gridTemplateColumns: '1fr',
  }
});
export const RowLine4 = styled(Row)({
  display: 'grid',
  gridTemplateColumns: '1fr minmax(150px, 1fr) minmax(150px, 1fr) 1fr',
  gap: 16,
  ['@media (max-width:1200px)']: {
    gridTemplateColumns: '1fr',
  }
});

export const RowDadosApp = styled.div`
  padding: 40px 0 20px;
  margin:40px 0;
  border-top:1px solid ${theme.COLORS.BLACK_200};
  border-bottom:1px solid ${theme.COLORS.BLACK_200};
`
export const GridDadosApp = styled.div`
  display:grid;
  grid-template-columns: 1fr auto;
  gap:40px;
  padding:32px 0 0;

  .desc{
    display:grid;
    gap:15px;
  }
`