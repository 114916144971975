import styled from "styled-components";
import theme from "styles/theme";

export const SmallError = styled.small`
  font-size:12px;
  color:${theme.COLORS.ERROR_800};
`
export const BoxCep = styled.div`
  position: relative;
`
export const LoadCep = styled.div`
  position: absolute;
  top:25px;
  right:10px;

  & > span{
    width:20px !important;
    height:20px !important;
  }
`