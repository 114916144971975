import TitlePage from "components/TitlePage"
import { useHistory } from "react-router-dom"
import { AppBody } from "styles/base"
import Success from "../sucess/Success"


export const sucessManageRules = () => {
  const navigate = useHistory()

  const handleBack = () => navigate.push('/home/regras-gerais')


  return <AppBody id="topo" >
    <TitlePage title="Regras Gerais" />
    
    <Success onSuccess={() => handleBack()} label={"<b>Regras gerais salva com sucesso!</b>"} iconSuccess={true} />
  </AppBody>
}