import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { moneyMask } from "./masks";

export const normalizeResponsibility = (office: string) => {
  return office === 'MASTER' ||  office === 'master' ? 'DIRETOR' : office
}

export const normalizeMoneyNumber = (e: string) => {
  const value = moneyMask(e)
  const valorNumber = value.replace('R$ ', '')
  return parseFloat( valorNumber.replace(/\D/g, '') ) / 100
}

export const capitalize = (e: string) => {
  return e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
}

export const isOnlyString = (value: any) => {
  const regex = /[0-9]/
  return regex.test(value)
}

export const useQueryString = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const scrollToTop = ({ elemento } : { elemento?: string }) => {
  const body = (document.querySelector(elemento || '#app-body') as HTMLInputElement);
  window.scrollTo({ top: body.offsetTop, behavior: 'smooth' });
};

export const hideTel = (tel: string) => {

  const __toFillInStart = (item : string) => {
    const size = item.length
    return "*".repeat(size - 4)
  }
  const __toFillInEnd = (item : string) => {
    const size = item.length
    return "*".repeat(size) 
  }

  const valueHide = tel.length > 8
    ? `${__toFillInStart(tel)}-${tel.substring(5, 9)}` 
    : `${__toFillInStart(tel)}-${tel.substring(4, 8)}`;

  return valueHide
}

export const hideEmail = (email : string) => {
  const arrayEmail = email.split("@");
  const name = arrayEmail[0];
  const host = arrayEmail[1];

  const __toFillInEnd = (item : string) => {
    const size = item.length
    return "*".repeat(size - 3) 
  }

  const nameHide = `${name.substring(0, 3)}${__toFillInEnd(name)}`; //+ name.substring(name.length - 2);
  // const hostHide = `***${host.substring(host.length - 12)}`;
 
  return nameHide + "@" + host;
}
export const encodeJsonBase64 = (json : any) => {
  var jsonStr = JSON.stringify(json);
  var base64 = btoa(jsonStr);
  return base64;
}
export const decodeJsonBase64 = (base64: any) => {
  var jsonStr = atob(base64);
  var json = JSON.parse(jsonStr);
  return json;
}