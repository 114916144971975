import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

export const config = {
  Referer: process.env.REACT_APP_REFERER,
  UrlS3: process.env.REACT_APP_S3_URL,
  // environment: env.environment,
};

export const Axios = axios;

export function setupInterceptor(signOut: any, history: any) {
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        !originalRequest?._retry
        // (!originalRequest._retry || originalRequest.url !== '/refresh')
      ) {
        // console.log('Nao autorizado!');

        const token = localStorage.getItem("@mony:token");

        if (token) {
          // console.log('Tem token!');

          const configuration = {
            headers: {
              // Referer: config.RefererMaster,
            },
          };

          originalRequest._retry = true;

          try {
            const response = await api.post(
              "/refresh",
              { token },
              configuration
            );

            // console.log(0, response);

            if (response.data.erroCode === 401) {
              console.log("Erro ao renovar token");

              await signOut();
              history.push.push("/");
            }

            const { newToken } = response.data;

            localStorage.setItem("@mony:token", newToken);

            api.defaults.headers.common.Authorization = `Bearer ${newToken}`;

            originalRequest.headers.authorization = `Bearer ${newToken}`;

            return api(originalRequest);
          } catch (e) {
            console.log("Erro ao renovar token");
            await signOut();
            history.push("/");
          }
        } else {
          console.log("Nao tem token!");
          originalRequest._retry = true;

          await signOut();
          history.push("/");
        }

        const requestConfig = error.config;

        return api(requestConfig);
      }

      throw error;
    }
  );
}

export default api;
