import api from "api";
import { useEffect, useState } from "react";
import { TableContainer, TableLine } from "styles/base";
import { cnpjMask } from "util//masks";
import theme from "styles/theme";
import styled from "styled-components";
import { assinanteGetApi } from "./services";
import { default as Modal } from 'components/modal/modal'
import { ModalConfirm } from "./ModalConfirm";
import { CircularProgress } from '@mui/material';
import { ModalCancel } from "./ModalCancel";
import { useAuth } from "hooks/auth";
import { IAccession } from "../services/types";

const defaultData = {
    cnpj: '',
    logo: '',
    nomeFantasia: '',
    razaoSocial: '',
}

const defaultValues = {
    nomeFantasia: '',
}

interface DataSubscribarProps{ 
    setDeleteAccount: Function, 
    error: Function, 
    terms: boolean,
    planCurrent?: IAccession
}

const DataSubscriber = ({ setDeleteAccount, error, terms, planCurrent } : DataSubscribarProps ) => {
    const { signOut } = useAuth();

    const [dataSubscriber, setDataSubscriber] = useState(defaultData);
    const [load, setLoad] = useState(true)

    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
    const [msgDowngrade, setMsgDowngrade] = useState({ status: 0, msg: ''})

    async function initData() {
        const response = await assinanteGetApi().finally(() => setLoad(false))
        setDataSubscriber(response)
    }

    useEffect(() => {
        initData();
    }, []);

    const handleModal = () => setOpenModal(!openModal)

    const handleCotrolModal = (e: boolean) => setOpenModal(e)
    
    const handleConfirmDowngrade = () => {
        setMsgDowngrade({ status: 200, 
            msg: 'Sua conta foi excluída com sucesso!'
        })
        setOpenConfirmModal(!openConfirmModal)
    }
     
    return !dataSubscriber 
    ?  <CircularProgress /> 
    : (
        <>
            <Modal
                width="auto"
                component={<ModalCancel
                    planCurrent={planCurrent}
                    onclick={handleCotrolModal} 
                    onSuccess={handleConfirmDowngrade}
                    closeModal={() => setOpenModal(false)}
                />}
                open={openModal}
                onClose={() => null}
                onYes={() => null} 
                />

            <ModalConfirm 
                open={openConfirmModal}
                // onClose={signOut} // FAZ O LOGOFF
                onClose={handleConfirmDowngrade}
                text={msgDowngrade?.msg}
                />


            {load ? <CircularProgress /> 
            : <TableContainer>
                <thead><tr>
                    <th>Nome da empresa</th>
                    <th>CNPJ</th>
                    <th>Assinatura</th>
                </tr></thead>
                <tbody>
                    <TableLine>
                        <th>
                            {dataSubscriber.razaoSocial}
                        </th>
                        <th>{cnpjMask(dataSubscriber.cnpj)}</th>
                        <th>
                            <Btn onClick={() => handleModal()}>Excluir conta</Btn>
                            {/* handleDelete */}
                        </th>
                    </TableLine>
                </tbody>
            </TableContainer>}
        </>
    )
}

export default DataSubscriber;

const Btn = styled.button`
    height:100% !important;
    width:100% !important;
    border:1px solid ${theme.COLORS.BLACK_800} !important;
`