import { Box, Modal, styled } from "@mui/material";
import theme from "styles/theme";
import { Label } from "../../layout/Home/screens/campaings/styles";
import Button from "components/Button";
import { ReactElement } from "react";

interface ModalDialogProps{
    open: any, 
    onClose: any, 
    onYes: any, 
    text?: string, 
    component?: ReactElement,
    textClose?: string | null, 
    textConfirm?: string | null,
    width?: string,
}

const ModalDialog: React.FC<ModalDialogProps> = 
    ({ open, onClose, onYes, text, component, textClose, textConfirm, width = "350px" }) => {
    const handleClickYes = () => {
        onYes()
        onClose()
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
        >
            <ModalCont width={width}>
                {component 
                ? <>{component}</> 
                : <>
                    <Label dangerouslySetInnerHTML={{ __html: `${text}` }}></Label>
                    <ModalContBts>
                        <Button onClick={onClose} text={textClose || "Não"} />
                        <Button confirm onClick={handleClickYes} text={textConfirm || "Sim"} />
                    </ModalContBts>
                </>
                }
            </ModalCont>
        </Modal>
    )
}


const ModalContainer = styled(Box)({
    width:"90%",
    display: 'flex',
    padding: '1.5rem',
    backgroundColor: `${theme.COLORS.WHITE}`,
    flexDirection: 'column',
});
const ModalCont = styled(ModalContainer)((props : any) => ({
    width: props.width,
}));

const ModalContBts = styled(Box)({
    display: 'flex',
    gap:'1rem',
    width: '100%',
    marginTop: '20px',
    "& > button": {
        width: '220px',
    }
})


export default ModalDialog;