import { useEffect, useState } from "react"
import { CircularProgress } from "@mui/material"
import Button from "components/Button"
import TitlePage from "components/TitlePage"
import { useHistory } from "react-router-dom"
import { AppBody, DataWrapper, Label } from "styles/base"
import { moneyMask } from "util/masks"
import { TitleH2 } from "../../Enterprise/Form/style"
import { DataPayment } from "../dataPayment"
import { getAccessionCurrent, getPlans } from "../services"
import { IAccession, ICards, IPlans, IPlansList } from "../services/types"

import { BoxCards, BoxEditCard, BoxMigrationInfo, BoxMigrationPlan, 
  BoxMock, BoxObs, BoxRelatorios, BoxValor, FlexCards, LoadCard } from "./styles"
import { ErrorAlert } from "components/ErrorAlert"
import { IconArrowRight } from "components/Icons/IconArrowRight"


const Card = ({ id, plano, nome, valor, quantidade, transacao, planoNomeSelecionado,
  relatorios = [], ativo = false, disabled = false, onClick }: ICards) => {

  return <BoxCards>
    <h3>{nome}</h3>
    <BoxValor>
      {valor && moneyMask(valor)}
      {valor !== '0,00' && <small>por mês</small>}
    </BoxValor>

    <div>
      <small>Quantidade de transações</small>{quantidade}
    </div>
    <div>
      <small>Transação adicional</small>{transacao}
    </div>

    {relatorios.length > 0 
    ? <BoxRelatorios>
        {relatorios.length > 0
          && <> <small>Relatórios</small> {relatorios?.map((i, idx) => <div key={idx}>{i}</div>)} </>}
      </BoxRelatorios>
    : <br/> }
        
    <Button 
      onClick={() => onClick({ id, plano, nome, valor, quantidade, transacao })} 
      disabled={disabled || (nome == planoNomeSelecionado)} 
      text={ativo ? "Plano atual" : "Escolher"} 
      confirm={!ativo} 
      />
  </BoxCards>
}

const MockDefault = ({ height, width, limit } : { height?: number, width?: number, limit?: number }) => {
  return <>
    {[...Array(limit)].map((_, i) => {
      return <BoxMock key={i} style={{ height, minWidth: width }}><CircularProgress /></BoxMock>
    })}
  </>
}

const TableMigration = ({ tipo, nome, valor } : { tipo?:String, nome?:String, valor?:string }) => {
  return <table>
    <thead>
      <tr>
      <td>Plano <span>{tipo}</span></td>
      <td>Mensalidade</td>
      </tr>
    </thead>
    <tbody>
      <tr>
      <td>{nome}</td>
      <td>{valor}</td>
      </tr>
    </tbody>
  </table>
}

const Plans = () => {
  let history = useHistory()
  const handleBack = () => history.goBack();
  
  const [activeForm, setActiveForm] = useState<boolean>(false);
  const [dataPlanList, setDataPlanList] = useState<any>([]);
  const [planSelected, setPlanSelected] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [migrationPlan, setMigrationPlan] = useState<ICards | null>(null)
  
  const [load, setLoad] = useState<boolean>(false)

  const userId = localStorage.getItem('@mony:usuarioId')

  const { data: getDataPlanUser, isFetching: loadPlanUser, } : 
  { data: IAccession, isFetching: boolean } = getAccessionCurrent({ userId: `${userId}` })

  const { data: getDataPlanlist, isFetching: loadPlanList } : 
  { data: IPlans[], isFetching: boolean } = getPlans({ userId: `listaPlanos-${userId}` }) // pde ser geral ${userId}
  
  const initial = async () => {
    const planUserCurrent = getDataPlanUser?.plano.codigoPlano
    
    const list : IPlansList[] = []
    for (let i = 0; i < getDataPlanlist?.length; i++) {
      list.push({         
        id: getDataPlanlist[i]?.id,
        plano: getDataPlanlist[i]?.cdPlano,
        nome: `${getDataPlanlist[i]?.nome}`, 
        valor: `${moneyMask( `${getDataPlanlist[i]?.valor * 100}` )}`,
        quantidade: `${getDataPlanlist[i]?.quantidadeTransacoesMes} transações/mês`, 
        transacao: `${moneyMask((getDataPlanlist[i]?.custoTransacaoAdicional  * 100).toString())}/transação`, 
        relatorios: [], // ["créditos utilizados", "créditos distribuídos", "créditos a expirar"],
        disabled: planUserCurrent === getDataPlanlist[i]?.cdPlano ? true : false,
        ativo: planUserCurrent === getDataPlanlist[i]?.cdPlano ? true : false, //false
      })      
    }
    const listOrder = list.sort((a, b) => a.id - b.id)

    setDataPlanList(listOrder)    
  }
  const activePlano = ({ list, itemActive } : { list: IPlansList[],  itemActive: string | boolean }) => {
    
    const arrayList : IPlansList[] = []
    list.map((i : IPlansList) => {
      arrayList.push({ ...i, ativo: i.plano === itemActive })
    })
    const listOrd = arrayList.sort((a, b) =>  a.id - b.id)
    return listOrd
  }
  const handleMigrationPlan = (e : ICards) => {
    setMigrationPlan(e)
  }
  const handlePlanSelected = (e : ICards) => {
    setDataPlanList( activePlano({ 
      list:dataPlanList, 
      itemActive: e.plano || ''
    }) )
    
    if(getDataPlanUser?.plano.codigoPlano === 'PL00'
    && getDataPlanUser.cartao === null) {
      setActiveForm(true) 
    }else{
      setLoad(true)
      setActiveForm(false)  
    }
    setPlanSelected(e)
  }

  useEffect(() => {
    initial()
  },[getDataPlanlist, getDataPlanUser])

  return <>
    <AppBody id="app-body">
      <TitlePage title="Minha Conta" />

      {errorMessage && <><ErrorAlert message={errorMessage} /> <br/></>}

      <DataWrapper>
        <Label size="18" bold>ESCOLHA O SEU PLANO</Label>

        <FlexCards>
          {load ? <LoadCard><CircularProgress /></LoadCard> : null}
          {(loadPlanUser || loadPlanList) && <MockDefault width={150} height={420} limit={3} /> }

          {(!loadPlanUser && !loadPlanList) && dataPlanList?.map((i : any, idx : number)  => {
            if (i.nome === "Plano Corporativo") {
              return null;
            }
            
            return <Card
              key={idx}
              id={i.id}
              plano={i.plano}
              nome={i.nome}
              valor={i.valor}
              quantidade={i.quantidade}
              transacao={i.transacao}
              relatorios={i.relatorios}
              ativo={i.ativo}  
              disabled={i.disabled}
              planoNomeSelecionado={migrationPlan?.nome}
              onClick={(e: ICards) => {
                handleMigrationPlan(e)
                // handlePlanSelected(e)
              }}
            />
          })}
        </FlexCards>
        
        { migrationPlan?.nome != null 
         ? <BoxMigrationPlan>
            <h4>Alteração de Plano</h4>
            
            <BoxMigrationInfo>
              <TableMigration 
                tipo={"atual"} 
                nome={getDataPlanUser?.plano.nome}
                valor={moneyMask((getDataPlanUser?.plano.valor * 100).toString())} />
              
              <IconArrowRight />
              
              <TableMigration 
                tipo={"desejado"} 
                nome={migrationPlan?.nome}
                valor={migrationPlan?.valor} />
            </BoxMigrationInfo>

            <div className="box-btns">
              <p>Atenção: O início de vigência do novo plano será a partir do próximo pagamento</p>
              <div>
                <Button 
                  onClick={() => setMigrationPlan(null)} 
                  text="Cancelar" />
                <Button 
                  confirm
                  onClick={() => handlePlanSelected(migrationPlan)} 
                  text="Confirmar" />  
              </div>
            </div>
         </BoxMigrationPlan> 
         : <></>
        } 


        <BoxObs>
          <p>(*) quantidade promocional passível de mudanças conforme a estratégia comercial do Mony.</p>

          <b>Condição comercial:</b>
          <ul>
            <li>O valor do novo plano e a disponibilização de seus benefícios entrarão em vigor apenas na próxima cobrança.</li>
            <li>Ao atingir o total de transações da franquia, você continurá realizando transações dentro do mês. As transações adicionais serão incluídas automaticamente na sua próxima mensalidade. </li>
            <li>As transações não utilizadas expirarão no final do mês.</li>
          </ul>
        </BoxObs>

          {(activeForm 
          || getDataPlanUser?.plano?.codigoPlano
          && getDataPlanUser?.cartao?.bandeira !== undefined)
          && <>
            <TitleH2>Dados de pagamento</TitleH2>
            <br/>
            <BoxEditCard>
              <DataPayment 
                planSelected={planSelected} 
                planCurrent={getDataPlanUser} 
                isEdit={activeForm} 
                onClick={() =>  handleBack()}
                isSuccess={(e: boolean) => console.log(e)}
                isError={(e : string) => setErrorMessage(e)}
                isUpdateMigrate={true}
                />
            </BoxEditCard>
          </>}

        {!activeForm && <Button 
          onClick={() => handleBack()} 
          text="Voltar" />}

      </DataWrapper>      
    </AppBody>
  </> 
}
export { Plans }
