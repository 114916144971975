import * as ReactDOM from 'react-dom';

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as LeftArrow } from 'assets/icons/arrowLeft.svg';
import { ReactComponent as RigthArrow } from 'assets/icons/arrowRight.svg';
import { scrollToTop } from 'util/utils';
import theme from 'styles/theme';

interface PaginationProps {
  data: any
  pageNumberLimit: number
  itemsPerPage: number
  onPageChange(page: number): void
  currentPage: number
}

const Pagination = ({
  data,
  pageNumberLimit,
  itemsPerPage,
  onPageChange,
  currentPage,
}: PaginationProps) => {
  const [maxPageNumberLimit, setMaxPageNumberLimit] =
    useState<number>(pageNumberLimit)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)
  
  const [elemTable, setElemTable] = useState<any>()

  const pages = []

  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage);) {
    pages.push(i)
    i += 1
  }

  const handlepageClick = (event: any) => {
    setElemTable(event.currentTarget)
    onPageChange(Number(event.target.id))
  }

  const renderPageNumbers = pages.map(page => {
    if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
      return (
        <li
          key={page}
          id={page.toString()}
          onClick={handlepageClick}
          className={currentPage === page ? 'active' : undefined}
        >
          {page}
        </li>
      )
    }
    return null
  })

  const handlePrevious = () => {
    onPageChange(currentPage - 1)
    if (currentPage < minPageNumberLimit + 2) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }
  const handleNext = () => {
    onPageChange(currentPage + 1)
    if (currentPage + 1 > maxPageNumberLimit) {
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
    }
  }

  useEffect(() => {
    if (currentPage + 1 > maxPageNumberLimit) {
      setMinPageNumberLimit(currentPage - 9)
      setMaxPageNumberLimit(currentPage + 1)
    }
    if (currentPage === 1) {
      setMaxPageNumberLimit(10)
      setMinPageNumberLimit(0)
    }
  }, [data])

  // IDENTIFICAR A POSIÇÃO DA TABELA E FAZER O SCROLL
  // useEffect(() => {
  //   const divTable = ReactDOM.findDOMNode(elemTable)?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
  //   const table = divTable?.children[0].getBoundingClientRect().top || 0
  //   window.scrollTo({ top: 175/*Math.abs(table)*/, behavior: 'smooth' });
  //   console.log(Math.abs(table), table, divTable?.children[0].getBoundingClientRect())
  // }, [elemTable])

  return (
    <PaginationContainer>
      <ul className="pageNumbers">
        <li className="arrow">
          <button
            type="submit"
            disabled={currentPage === pages[0]}
            onClick={handlePrevious}
          >
            <LeftArrow />
          </button>
        </li>
        <li>
          <ul className="pages">
            {renderPageNumbers}
          </ul>
        </li>
        <li className="arrow">
          <button
            type="submit"
            disabled={currentPage === pages[pages.length - 1]}
            onClick={handleNext}
          >
            <RigthArrow />
          </button>
        </li>
      </ul>
    </PaginationContainer>
  )
}



const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;

  & > ul{ gap:15px; }

  ul{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pageNumbers li{
    display:flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    border-radius: 50px;
    height: 33px;
    display: flex;
    justify-content: center;
    color:  ${theme.COLORS.BLACK_800};
  }
  
  .pageNumbers li.active {
    background: ${theme.COLORS.SEC_800};
  }

  .pages {
    list-style: none;
    display: flex;
    justify-content: center;
    background:${theme.COLORS.BLACK_100A};
    border-radius:50px;
    padding:3px 5px; 
    font-size:18px;

    li{
      width:33px;
    }
  }
  
  .pageNumbers li.arrow{
    padding:0px;

    button{
      all: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color:${theme.COLORS.BLACK_800};
      background:${theme.COLORS.BLACK_100A};
      width:33px;
      height: 33px;

      svg{
        display:flex;
        width:12px;
        height:12px;
      }
      svg, 
      svg path{        
        fill: currentColor;
      }
    }
  }


`
export default Pagination