import { useEffect, useMemo, useState } from "react"
import { cancelarPlanProps, deleteMigrateOrCancel, deleteDisableInstance, getVerificationAccession } from "./services"
import { BoxModalBtns, BoxModalCancel, BoxModalCard, BoxModalLoad, HeadModalCancel } from "./styles/styleConfirm"
import { CircularProgress } from "@mui/material"

import Button from "components/Button"
import { DataPayment } from "../dataPayment"
import { getAccessionCurrentData } from "../services"
import { IAccession } from "../services/types"
import { IconClose } from "components/Icons/iconClose"
import { IconInfo } from "components/Icons/iconInfo"
import moment from "moment"
import { moneyMask } from "util/masks"
import { getGeneralRulesCampaings } from "../../ManageRules/service"
import { useAuth } from "hooks/auth"

interface StepProps { 
  type?: 'migrate' | 'delete' | 'disabled' | null,
  idCampaing?: number,
  data?: cancelarPlanProps | null,
  txtCancel?: string,
  txtConfirm?: string,
  onCancel: () => void, 
  onConfirm: () => void,
  isSuccess?: (e: boolean) => void 
  isError?: (e: boolean) => void 
} 

const StepAction = ({ txtCancel, txtConfirm, onCancel, onConfirm } : StepProps) => {
  return <BoxModalBtns>
    <Button onClick={() => onCancel()} text={txtCancel || "Cancelar"} />
    <Button confirm onClick={() => onConfirm()} text={txtConfirm || "Confirmar"} />
  </BoxModalBtns>
}

const Step1 = ({ onCancel, onConfirm } : StepProps) => {
  return <div style={{width:'410px'}} data-step={1} >
    <HeadModalCancel>
      <h3>Atenção, essa operação não poderá ser desfeita!</h3>
      <div onClick={() => onCancel()}><IconClose /></div>
    </HeadModalCancel> 
    Ao confirmar, sua conta no <b>Adm Mony</b> e <b>App <br/>Mony Empresa</b> será excluída definitivamente.
    <StepAction 
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()} />
  </div>
}
const Step2 = ({ data, onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '410px'}}  data-step={2}>
      <HeadModalCancel>
        <h3>Atenção, seus clientes ainda possuem cashback para utilizar na sua loja!</h3>
        <div onClick={() => onCancel()}><IconClose /></div>
      </HeadModalCancel> 
      <div>
        Você poderá excluir sua conta, a partir do dia 
        {data?.dataExpiracaoCredito 
        ? <> {moment(data?.dataExpiracaoCredito).format('DD/MM/YYYY')}
        </> : <></>}.

        <br /><br />
        Ao confirmar, a funcionalidade "Lançar Compra" será desativada.
      </div>

      <StepAction 
        onCancel={() => onCancel()}
        onConfirm={() => onConfirm()} />
  </div>
}
const Step3 = ({ onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '370px'}} data-step={3}>
    <HeadModalCancel>
      <h3>Deseja seguir com o Plano Gratuito?</h3>
    </HeadModalCancel>
    
    Você pode continuar sem nenhum custo no plano Mony Grátis.

    <StepAction 
      txtCancel="Excluir conta"
      txtConfirm="Plano Gratuito"
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()} />
  </div>
}
const Step4 = ({ data, onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '450px'}}  data-step={4}>
    <HeadModalCancel>
      <h3>Deseja seguir com o Plano Gratuito?</h3>
      <div onClick={() => onCancel()}><IconClose /></div>
    </HeadModalCancel> 

    Continue sem custo no plano MONY Grátis, no entanto, 
    observe que saldo de {data?.saldoTransacaoDisponivel && <b>({data?.saldoTransacaoDisponivel}) </b>} 
    transações restantes no plano pago 
    não serão transferidas para o plano gratuito.

    <StepAction 
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()} />
  </div>
}
const Step5 = ({ type, idCampaing, data, onCancel, onConfirm, isSuccess, isError } : StepProps) => {
  const [load, setLoad] = useState(true)
  const [dataPlanUser, setDataPlanUser] = useState()

  const handleSubmitCard = () => {
    const ele  = document.querySelector("#save-card-single") as HTMLButtonElement
    ele.click()
  } 
  
  const paymentSuccess = (e: boolean) => {
    isSuccess?.(e)
  }

  const paymentError = (e: boolean) => {
    isError?.(e)
  }

  const init = () => {
    try {
      getAccessionCurrentData()
        .then(resp => setDataPlanUser(resp.data))
        .catch(e => console.log('ERRO api', e))
        .finally(() => setLoad(false))
    }catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    init()
  },[])

  return  load 
  ? <BoxModalLoad>
      <CircularProgress />
    </BoxModalLoad> 
  : <div style={{width: '500px'}} data-step={[5, type].join('-')}>
    <HeadModalCancel>
      <h3>Atenção!</h3>
      <div onClick={() => onCancel()}><IconClose /></div>
    </HeadModalCancel> 
    Identificamos que o pagamento das transações adicionais feitas entre 
    {' '}{moment(data?.dataInicio).format('DD/MM')} e {moment(data?.dataFim).format('DD/MM')}{' '}
    ainda não foi realizado. <br/><br/>
    Realize o pagamento para prosseguir com a alteração de plano.
    Valor devido: {moneyMask(`${data?.valorTotalTransacoesAdicionais && data?.valorTotalTransacoesAdicionais * 100}` || '0.00') } <br/><br/> 
    Informe os dados do seu cartão para prosseguir com o pagamento:
    <br/><br/>

    <BoxModalCard>
      <DataPayment 
        planCurrent={dataPlanUser} 
        isSuccess={(e : boolean) => paymentSuccess(e) } 
        isError={(e : boolean) => paymentError(e) } 
        info={false}
        isControl={false}
        isEdit={true}
        hasSinglePayment={true}
        isMigrateFree={type === 'migrate' ? true : false }
        />
    </BoxModalCard>

    <StepAction 
      onCancel={() => onCancel()}
      onConfirm={() => handleSubmitCard()} />
  </div>
}
const Step6 = ({ onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '270px'}} data-step={6}>
    <HeadModalCancel notIcon><h3>Pagamento realizado com sucesso!</h3></HeadModalCancel> 
    Parabéns, você acaba de mudar para o plano Mony Grátis!
    <BoxModalBtns>
      <Button confirm onClick={() => onConfirm()} text="Ok"/>
    </BoxModalBtns> 
  </div>
}
const Step7 = ({ onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '270px'}} data-step={7}>
    <HeadModalCancel>
      <h3><IconInfo />Erro de pagamento</h3>
      <div onClick={() => onCancel()}><IconClose /></div>
    </HeadModalCancel> 
    Verifique os dados do seu cartão e tente novamente ou informe outro cartão para pagamento.

    <BoxModalBtns>
      <Button confirm onClick={() => onConfirm()} text="Ok"/>
    </BoxModalBtns> 
  </div>
}
const Step8 = ({ onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '250px'}} data-step={8}>
    <HeadModalCancel notIcon><h3>Você acaba de mudar para o plano Mony Grátis!</h3></HeadModalCancel>
    <BoxModalBtns>
      <Button confirm onClick={() => onConfirm()} text="Ok"/>
    </BoxModalBtns> 
  </div>
}
const Step9 = ({ onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '340px'}} data-step={9}>
    <HeadModalCancel notIcon><h3>Sua conta foi desativada com sucesso!</h3></HeadModalCancel>
    <BoxModalBtns>
      <Button confirm onClick={() => onConfirm()} text="Ok"/>
    </BoxModalBtns> 
  </div>
}
const Step10 = ({ onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '340px'}} data-step={9}>
    <HeadModalCancel><h3><IconInfo />Ops!</h3></HeadModalCancel>
    Ocorreu algum erro desconhecido!<br/>
    Tente novamente mais tarde.
    <BoxModalBtns>
      <Button confirm onClick={() => onConfirm()} text="Ok"/>
    </BoxModalBtns> 
  </div>
}
const Step11 = ({ data, onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '450px'}}  data-step={11}>
    <HeadModalCancel>
      <h3>Deseja seguir com a exclusão?</h3>
      <div onClick={() => onCancel()}><IconClose /></div>
    </HeadModalCancel> 
    
    {(data?.saldoTransacaoDisponivel !== null) ? <>
      Você possui <b>{data?.saldoTransacaoDisponivel} transações</b>.<br/>
      Ao confirmar, as transações serão perdidas.
    </> : null}
    
    <StepAction 
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()} />
  </div>
}
const Step12 = ({ onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '450px'}}  data-step={12}>
  <HeadModalCancel>
    <h3>Sua conta foi excluída com sucesso!</h3>
    {/* <div onClick={() => onCancel()}><IconClose /></div> */}
  </HeadModalCancel> 

  Verifique seu e-mail para mais informações.
    
  <BoxModalBtns>
      <Button confirm onClick={() => onConfirm()} text="Ok"/>
    </BoxModalBtns> 
  {/* <StepAction 
    onCancel={() => onCancel()}
    onConfirm={() => onConfirm()} /> */}
</div>
}
const Step13 = ({ data, onCancel, onConfirm } : StepProps) => {
  return <div style={{width: '410px'}}  data-step={13}>
      <HeadModalCancel>
        <h3>Atenção, seus clientes ainda possuem cashback para utilizar na sua loja!</h3>
        {/* <div onClick={() => onCancel()}><IconClose /></div> */}
      </HeadModalCancel> 
      <div>
        Você poderá excluir sua conta, a partir do dia 
        {data?.dataExpiracaoCredito 
        ? <> {moment(data?.dataExpiracaoCredito).format('DD/MM/YYYY')}
        </> : <></>}.

       
      </div>

      <StepAction 
        txtCancel="Plano Gratuito"
        txtConfirm="Entendi"
        onCancel={() => onCancel()}
        onConfirm={() => onConfirm()} 
        />
  </div>
}
export const ModalCancel = ({ onclick, onSuccess, planCurrent, closeModal }
  : { onclick: (e: boolean) => void, onSuccess: () => void, planCurrent: IAccession | undefined, closeModal: () => void }) => {

  const { signOut } = useAuth()  
  const [load, setLoad] = useState<boolean>(true)
  const [step, setStep] = useState(1)
  const [typeMethod, setTypeMethod] = useState<'migrate' | 'delete' | 'disabled' | null>(null)

  const [idCampaing, setIdCampaing] = useState<number>(0)

  const [hasCashBack, setHasCashBack] = useState(false)
  const [respDateCashBack, setRespDateCashBack] = useState<cancelarPlanProps | null>({})
  const [hasCashBackAdd, setHasCashBackAdd] = useState(false)

  const handleStep = (e : number) => setStep(e)

  const isPlanFreeCurrent = useMemo(() => (planCurrent?.plano.codigoPlano === "PL00"), 
    [planCurrent?.plano.codigoPlano])
  
  // Cencelar Plano - Adesão
  const handleCencelPlan = () => {
    setLoad(true)
    // true = migração | false = exclusão
    deleteMigrateOrCancel(false) 
      .then((resp) => {
        // console.log(resp)
        handleStep(12)
      })
      .catch((error) => {
        // console.log(error)
        handleStep(10)
      })
      .finally(() => setLoad(false))
  }

  // migrar plano grátis
  const handleMigratePlanFree = () => {
    // true = migração | false = exclusão
    setLoad(true)
    deleteMigrateOrCancel(true)
      .then((resp : any) => {
        // console.log(resp)
        handleStep(8)
      })
      .catch((error) => { 
        handleStep(10)
      })
      .finally(() => setLoad(false))
  }
  
  // desabilitar instancia
  const handleDesabledInstance = () => {
    setLoad(true)
    deleteDisableInstance(idCampaing)
      .then((resp) => {
          handleStep(9)
      })
      .catch((error) => { 
        handleStep(10)
      })
      .finally(() => setLoad(false))
  }

  // verificar - isMigrageFree = True
  const handleVerificationAccess = async (isMigreteFree : boolean = true) => {

   return getVerificationAccession(isMigreteFree)
      .then((res : cancelarPlanProps) => {
        //console.log(res)
        setRespDateCashBack(res)

        const expirationDate =  !!res.dataExpiracaoCredito
        setHasCashBack(expirationDate || false)

        const cashbackAdd = (res.transacoesAdicionaisUtilizadas !== undefined) 
          && res.transacoesAdicionaisUtilizadas > 0 
        setHasCashBackAdd(cashbackAdd || false)

        return res
      })
      .finally(() => setLoad(false))
  }
  
  const checkMigrateFree = () => {
    setLoad(true)
      handleVerificationAccess(true)
        .then(resp => {
          setTypeMethod('migrate')
          
          if(resp?.valorTotalTransacoesAdicionais){
            // transações adicionais
            return handleStep(5);
          }else if(resp?.saldoTransacaoDisponivel){
            // transações disponíveis
            return handleStep(4);
          }else{
            // plano pago - sem cashback - sem cashback add
            return handleMigratePlanFree();
          }
        })
        .finally(() => setLoad(false))
  }

  const checkDeleteAccession = () => {
    setLoad(true)
      handleVerificationAccess(false)
        .then(resp => {
          setTypeMethod('delete')
          if(resp?.saldoTransacaoDisponivel){
            // transações disponviel
            return handleStep(11)
          }else if(resp?.valorTotalTransacoesAdicionais){
            // tranações add
            return handleStep(5)
          }else if(resp?.dataExpiracaoCredito){
            // transações distribuidas
            return handleStep(13)
          }else{
            // não tem impeditivo
            return handleCencelPlan()
          }
        })
        .finally(() => setLoad(false))
  }

  const handleStep1 = {
    confirm: () => {
      if(isPlanFreeCurrent){
        // plano gratis
        setLoad(true)
        handleVerificationAccess()
          .then(res => {            
            if(res?.hasImpeditivo){
              if(res?.dataExpiracaoCredito){
                // tem créditos distribuidos?
                // tem saldo de transações disponivel?
                return handleStep(2);
              }else{
                return handleDesabledInstance();
              }
            }else{
              // não tem impeditivo
              return handleCencelPlan();
            }
          })
          .catch(() => handleStep(10))
          .finally(() => setLoad(false))
      }else{
        // plano pago
        setLoad(true)
        handleVerificationAccess(false)
        .then(resp => {
          if(resp?.dataExpiracaoCredito){
            return handleStep(13)
          }else{
            return handleStep(3)
          }
        })
        .catch(() => handleStep(10))
        .finally(() => setLoad(false))
      }
    }
  }
  
  const handleStep3 = {
    cancel: () => {
      // EXCLUIR CONTA
      return checkDeleteAccession()
    },
    confirm: () => {
      // MIGRANDO PARA O GRATUITO
      return checkMigrateFree()
    }
  }
  
  const handleStep4 = {
    confirm: () => {
      if(hasCashBackAdd){
        return handleStep(5)
      }else{
        handleMigratePlanFree()
      }
    }
  }

  const handleStep5 = {
    success: (e : boolean, type: 'migrate' | 'delete' | 'disabled' | null ) => {
      if(e){
        if(type === 'migrate'){
          return handleStep(8)
        }else if(type === 'delete'){
          return handleStep(12)
        }
      }
    }
  }

  const handleStep11 = {
    confirm: () => {
      return handleCencelPlan()
    }
  }

  const handleStep13 = {
    cancel: () => {
      checkMigrateFree()
    },
    confirm: () => {
      return  closeModal()
    }
  }

  const stepComponent : any = {
    1: <Step1 // alerta : 1º 
      onCancel={() => onclick(false)}
      onConfirm={() => handleStep1.confirm()} />,
    2: <Step2 // cachback : existe cashback - plano gratis
      data={{ dataExpiracaoCredito : respDateCashBack?.dataExpiracaoCredito || "" }}
      onCancel={() => onclick(false)}  
      onConfirm={() => handleDesabledInstance()} />,
    3: <Step3 // plano pago : excluir  ou migrar
      onCancel={() => handleStep3.cancel()}  
      onConfirm={() => handleStep3.confirm()} />,
    13: <Step13 // cachback : existe cashback - plano pago
      data={{ dataExpiracaoCredito : respDateCashBack?.dataExpiracaoCredito || "" }}
      onCancel={() => handleStep13.cancel()}  // migrar para o plano gratis
      onConfirm={() => handleStep13.confirm()} />,  
    4: <Step4 // confimração para migrar plano gratis
      data={respDateCashBack}
      onCancel={() => onclick(false)}  
      //  handleStep(8) ... verifiar se tem add ??
      onConfirm={() => handleStep4.confirm()} />, 
    5: <Step5 // pagamento
      type={typeMethod}
      idCampaing={idCampaing}
      data={respDateCashBack}
      onCancel={() => onclick(false)}  
      onConfirm={() => null} 
      // isError={(e) => e && handleStep(7)}
      isSuccess={(e) => {
        // e && handleStep(6)
        handleStep5.success(e, typeMethod)
      }} />,
    6: <Step6 // pagamento : sucesso 
      onCancel={() => null}  
      onConfirm={() => onclick(false)} />,
    7: <Step7 // pagamento : erro 
      onCancel={() => onclick(false)}  
      onConfirm={() => handleStep(5)} />,
    8: <Step8 // plano gratis : sucesso
      onCancel={() => null}  
      onConfirm={() => {
        window.location.reload();
        onclick(false)
      }} />,
    9: <Step9 // Conta desativada : sucesso
      onCancel={() => null}  
      onConfirm={() => {
        // window.location.reload();
        onclick(false)
      }} />,
    10: <Step10 // Ocorreu algum erro
      onCancel={() => null}  
      onConfirm={() => onclick(false)} />,
    11: <Step11 // confirmação para exlcuir com saldo disponível
      data={respDateCashBack}
      onCancel={() => onclick(false)}  
      onConfirm={() => handleStep11.confirm()} />,
    12: <Step12 // Confirmação de Exclusão + logoff
      onCancel={() => null}  
      onConfirm={() => signOut()} />,
    
  }

  const init = () => {
    getGeneralRulesCampaings()
      .then(resp => {
        const res = resp[1].data
        setIdCampaing(res.id)
      })
      .catch(error => console.log(error))
      .finally(() => setLoad(false))
  }

  useEffect(() => {
    init()
  },[])

  return load
    ? <BoxModalLoad>
      <CircularProgress />
    </BoxModalLoad>
    : <BoxModalCancel>
      <>
      {stepComponent[step]}

      <small style={{ display:"none", fontSize: '11px', marginTop: "20px" }}>
        Pl: <b>{isPlanFreeCurrent ? 'Gratis' : 'Pago' }</b> 
        {' '}- Cashback: <b>{ hasCashBack ? 'Sim' : 'Não'}</b>
        {' '}- Add: <b>{ hasCashBackAdd ? 'Sim' : 'Não' }</b>
        {' '}- Type: <b>{ typeMethod !== null ? typeMethod : "desconhecido" }</b>
      </small>
      </>
    </BoxModalCancel>
}