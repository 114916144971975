import TitlePage from "components/TitlePage"
import { useHistory } from "react-router-dom"
import { AppBody } from "styles/base"
import Success from "./sucess/Success"

const LaunchPurchaseSuccess = () => {
  const navigate = useHistory()

  const onSuccess = () => navigate.push('/home/')

  return <AppBody>
    <TitlePage title="LANÇAR COMPRA" />

    <Success onSuccess={onSuccess} label={"<b>Cashback gerado com sucesso</b>"} iconSuccess={true} />
  </AppBody>
}
export { LaunchPurchaseSuccess }