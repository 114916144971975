import styled from "styled-components";

export const BoxImagemDefault = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 90%;
    height:90%;
    font-size: 0.9rem;
    font-weight: 400;
    text-transform: uppercase;
    & > img{
        width:60%;
        height: auto;
    }
`