import api from "api";
import { useQueryFetch } from "../../Reports/services";

const getServiceUnid = () => {
  try {
    return api.get(`/unidades`)
  } catch (e) {
    console.log('error: ', e)
  }
}
const getServiceUserCpf = (cpf : number) => {
  try {
    return api.get(`/usuarios/funcionarios?cpf=${cpf}`)
  } catch (e) {
    console.log('error: ', e)
  }
}
const getServiceUser = (totalRows = 100, page = 0) => {
  try {
    return api.get(`/usuarios/funcionarios?size=${totalRows}&page=${page}`)
  } catch (e) {
    console.log('error: ', e)
  }
}

export const getServiceUser_temp = (totalRows = 100, page = 0, id = '') => {
  const { isFetching, isLoading, error, data } = useQueryFetch({ 
    key: ['listUser', 'user'+ id], 
    url: `/usuarios/funcionarios?size=${totalRows}&page=${page}`, 
    revalidate:0, //(60*1000) * 5, // 5 min
    cache:0, // (60*1000) * 30, // 30 min
  })
  return { isFetching, isLoading, error, data }
}

const postServiceUser = (body : any) => {
  try {
    return api.post(`/usuarios/funcionarios`, body)
  } catch (e) {
    console.log('error: ', e)
  } 
}
const putServiceUser = async (body : any) => {
  try {
    return api.put(`/usuarios/funcionarios`, body)
  } catch (e) {
    console.log('error: ', e)
  } 
}
const deleteServiceUser = (id : number) => {
  try {
    return api.delete(`/usuarios/funcionarios/${id}`)
  } catch (e) {
    console.log('error: ', e)
  } 
}

export { getServiceUnid, getServiceUserCpf, getServiceUser, postServiceUser, putServiceUser, deleteServiceUser }