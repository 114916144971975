import React, { createContext, ReactNode, useEffect, useState } from "react";

interface IItens {
  idInstancia: number,
  cdCaixa: string,
  idUsuario: number,
  nomeCliente: string,
  dataLeituraQrCode: string,
  valor: number,
  idUnidade: number,
  idPedido: number
}
interface ISomaPorCodigoDoCaixa{
  cdCaixa: string,
  totalPorCaixa: number
}
interface IReports {
  valorTotalPeriodo: number,
  itens: IItens[],
  page: number,
  size: number,
  totalOfElements: number,
  somaPorCodigoDoCaixa: ISomaPorCodigoDoCaixa[]
}
export interface IFilterReports{
  idInstancia: string, 
  idUnidade: string,
  periodo: string,
  dataInicio: Date, 
  dataFim: Date,
  tipo: "utilizados" | "distribuidos" | "expirados" | "expirar",
}

interface IContext{
  errorReports: any, 
  setErrorReports(errorReports: string): void, 
  valuesReports: IReports | null, 
  setValuesReports(valuesReports: IReports): void, 
  filterReports: IFilterReports | null, 
  setFilterReports(filterReports: IFilterReports): void,
  nameUnity:string,
  setNameUnity(nameUnity: string): void,
  typeConsult: "utilizados" | "distribuidos" | "expirados" | "expirar", 
  setTypeConsult(e: "utilizados" | "distribuidos" | "expirados" | "expirar"): void,
  hashConsult: "utilizados" | "distribuidos" | "expirados" | "expirar", 
  setHashConsult(e: "utilizados" | "distribuidos" | "expirados" | "expirar"): void,
}

export const ReportsContext = createContext<IContext>({} as IContext)

export const ReportsProvider: React.FC<{ children: any }> = ({ children }) => {
  const [nameUnity, setNameUnity] = useState<string>('');

  const [typeConsult, setTypeConsult] = useState<"utilizados" | "distribuidos" | "expirados" | "expirar">('utilizados')
  const [hashConsult, setHashConsult] = useState<"utilizados" | "distribuidos" | "expirados" | "expirar">('utilizados')

  const [filterReports, setFilterReports] = useState<IFilterReports | null>(null);
  const [valuesReports, setValuesReports] = useState<IReports | null>(null);
  const [errorReports, setErrorReports] = useState<string>('');

  return (
    <ReportsContext.Provider value={{ 
      errorReports, setErrorReports, 
      valuesReports, setValuesReports, 
      filterReports, setFilterReports,
      nameUnity, setNameUnity,
      typeConsult, setTypeConsult,
      hashConsult, setHashConsult,
      }}>
      {children}
    </ReportsContext.Provider >
  )
}