import { useEffect } from "react"
import { AppBody, DataWrapper, Spacing } from "styles/base"
import { IUser, useUser } from "./useUser"
import { cpfMask } from "util/masks"
import { default as Modal } from 'components/modal/modal'
import { BarSearch } from "./BarSearch/BarSearch"
import moment from "moment"
import { normalizeResponsibility, capitalize } from "util/utils"
import { CircularProgress } from "@mui/material"
import { TableGrid } from "components/TableGrid/TableGrid"
import { ErrorAlert } from "components/ErrorAlert"
import TitlePage from "components/TitlePage"

const User = () => {
  const { usersFilter, cpf, modal, userFilter, clearFilter, load, errors,
    handleEdit, handleDelete, handleModal, setUsersFilter } = useUser()

  const clear = clearFilter

  useEffect(() => {
    clear()

    return () => setUsersFilter([])
  }, [])

  const columns = [
    { nome: 'Nome' },
    { nome: 'CPF' },
    { nome: 'Perfil' },
    { nome: 'Cadastro' }
  ]
  const rowsTable = usersFilter.map((row: IUser) => {
    return {
      id: row.id,
      nome: row.nome,
      cpf: cpfMask(row.cpf),
      perfil: capitalize(normalizeResponsibility(row.funcao)),
      cadastro: moment(row.dataCadastro).format('DD/MM/YYYY')
    }
  })

  return <AppBody>
    <Modal
      text={modal.msg}
      open={modal.open}
      onClose={handleModal}
      onYes={handleDelete}
    />

    <TitlePage title="Gerenciar usuários" />

    {errors && <><ErrorAlert message={errors} /><br/></>}
    
    <DataWrapper>
      <BarSearch
        value={cpf}
        onChange={(e: string) => userFilter(e)}
        clear={() => clear()}
      />

      {load
        ? <CircularProgress />
        : <TableGrid
          columns={columns}
          rows={rowsTable}
          handleEdit={handleEdit}
          handleModal={handleModal}
          perPage={10}
        />}
    </DataWrapper>
  </AppBody>
}

export default User