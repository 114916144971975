import styled from 'styled-components';

export const Form = styled.form`
  display: flex;

  > div {
    margin-right: 25px;
  }

  > div:last-child {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

export const DataWrapper = styled.div`
    background-color: #FFF;
    border: 0 solid #5E3CA8;
    border-radius: 6px;
    width: 95%;
    padding: 24px;
`;

export const ErrorAlert = styled.div`
    border: 1px solid #FF5757;
    border-radius: 6px;
    background-color: rgba(255,87,87,0.1);
    box-shadow: inset 0 0 4px 1px rgba(0,0,0,0.5);
    padding: 8px 30px;
    width: 95%;

    > span {
        letter-spacing: 0;
        line-height: 24px;
        font-weight: bold;
        color: #FFF;
        font-size: 1.1em;
    }
`;

export const CreditWrapper = styled.div`
    background-color: #2C2A59;
    display: flex;
    align-items: center;
    border-radius: 2px;
    padding: 4px 18px;
`;
