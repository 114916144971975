import { useEffect } from "react";

import { useCredits } from "./useCredits";
import { CircularProgress } from "@mui/material";
import { TableGrid } from "components/TableGrid/TableGrid";
import { BarSearch } from "../BarSearch";
import { BoxInfo } from "../styles";
import { useQueryString } from "util/utils"

const Credits = ({ tipo = "utilizados" } : {tipo?: "utilizados" | "distribuidos" | "expirados" | "expirar"}) => {
  const { isFetching, values, filterValues, setFilterValues, tableAll, relatorioList, 
    handleFilter, handlePeriod, setTypeConsult } = useCredits()

  const query = useQueryString()
  const query_string : any = {
    idInstancia: query.get('idInstancia'), 
    idUnidade: query.get('idUnidade'),
    periodo: query.get('periodo'),
    dataInicio: query.get('dataInicio'), 
    dataFim: query.get('dataFim'),
    tipo: query.get('tipo'),
  }

  useEffect(() => {
    setTypeConsult(tipo)

    if(query_string.tipo === tipo){
      setFilterValues({
        idInstancia: query_string.idInstancia || '',
        idUnidade: query_string.idUnidade  || '',
        periodo: query_string.periodo || 'Hoje', 
        dataInicio: query_string.dataInicio,
        dataFim: query_string.dataFim,
        tipo: query_string.tipo || "utilizados"
      })
    }
  },[])

  return <>
    <div id={`relatorio-${tipo}`}>
    <BarSearch onClick={handleFilter} valueDefault={filterValues} tipo={tipo}/>
    </div>

    {isFetching && <CircularProgress />}

    {!isFetching && <>
      <TableGrid
        columns={tableAll.head}
        rows={tableAll.rows}
        // loading={load}
        // handleEdit={handleEdit}
        // hasIconLoad={false}
      />

      {/* (filterValues.idInstancia !== '' && filterValues.idUnidade !== '') && */}
      {relatorioList?.rows?.length > 0
        && <TableGrid
          columns={relatorioList.head}
          rows={relatorioList.rows}
          // loading={load}
          // hasIconLoad={false}
          />
      }
    </>}

    <BoxInfo>O valor acima refere-se {handlePeriod({}).length > 10 ? `ao periodo de` : `ao dia`} {handlePeriod({})}. Para mais opções, altere o filtro período.</BoxInfo>
  </>
}
export { Credits }