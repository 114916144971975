import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { UserProvider } from 'layout/Home/screens/User/useUser';
import AppProvider from './hooks';
import Routes from './routes';

import './App.css';
import './index.css';
import "react-datepicker/dist/react-datepicker.css";

const queryClient = new QueryClient()

const App = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AppProvider>
        <UserProvider>
          <Routes />
        </UserProvider>
      </AppProvider>
    </BrowserRouter>
  </QueryClientProvider>
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);