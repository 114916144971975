import axios from 'axios';
import api from '../../../api';
import { TransacoesVendaModel, UsarCashbackModel, UsuarioModel, VendedorModel } from '../Models';

const configMultipart = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export function usuarioTel(ddd: string, tel: string) {
  return api.get(`/usuarios/consulta/ddd/${ddd}/telefone/${tel}`);
}

export function sendPin(ddd: string, tel: string) {
  const formData = new FormData();
  formData.append('ddd', ddd);
  formData.append('telefone', tel);

  return api.post('/usuarios/enviar-pin', formData, configMultipart);
}

export function postRegister(
  login: string,
  ddd: string,
  telefone: string,
  nome: string,
  pin: string,
) {
  const formData = new FormData();
  formData.append('login', login);
  formData.append('ddd', ddd);
  formData.append('telefone', telefone);
  formData.append('nome', nome);
  formData.append('pin', pin);

  return api.post('/usuarios/pre-cadastrar', formData, configMultipart);
}

export function getVendedores(value: VendedorModel) {
  return api.get('/usuarios/funcao/VENDEDOR', {
    data: value,
  });
}

export function getUsuarios() {
  return api.get('/usuarios/funcionarios?page=0&size=100');
}
export function getUnidadesAdm(idInstancia: number) {
  const url = `/unidades?page=0&size=10&idInstancia=${idInstancia}`;
  const url1 = '"/unidades"';
  return api.get(url);
}


export function getCashback(value: number) {
  return api.get(`/transacoes-venda/previa-cashback?valor=${value}`);
}

export function postFinalizarTransacao(data: UsarCashbackModel){
  return api.post(`/autorizador/finaliza-transacao`, data)
}

export function launchPurchase(data: TransacoesVendaModel) {
  return api.post('/transacoes-venda', data);
}

export function getUnidade() {
  return api.get('/unidades/usuario-logado');
}

export function fetchIP() {
  return axios.get('https://api.ipify.org');
}
