import { Route, useRouteMatch, Switch } from 'react-router-dom';
import Account from '.';
import { Plans } from './Plans';
import { PlansSuccess } from './Plans/success';

const RouteAccount = () => {
  const { path } = useRouteMatch();

  return <Switch>
    <Route exact path={`${path}/`} component={Account} />
    <Route exact path={`${path}/planos`} component={Plans} />
    <Route exact path={`${path}/planos/sucesso`} component={PlansSuccess} />
  </Switch>
}
export { RouteAccount }
