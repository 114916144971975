import Button from "components/Button";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Column, Label, Row, Spacing } from "styles/base";
import theme from "styles/theme";

import Logo from '../../../../../assets/icons/logo.png'

const AccountValidated = () => {
  const navigate = useHistory();

  const handleConfirm = () => navigate.push('/home/');

  return (
    <BoxContainer>
      <div>
        <img src={Logo} alt="Mony pra você ganhar o dia, todo dia." />
        <h2>Parabéns sua conta<br /> Mony está ativa</h2>

        <Button loginSubmit text="Iniciar" onClick={() => handleConfirm()} />
      </div>
    </BoxContainer>
  )
}

export default AccountValidated;

const BoxContainer = styled(Column)`
  height:100vh;
  justify-content: center;
  align-items: center;
  text-align: center;

  img{
    max-width:350px;
    margin:auto;
  }
  h2{
    font-size:2.0rem;
    line-height: 120%;
    color:${theme.COLORS.WHITE};
    text-transform: uppercase;
    padding:50px 0 25px;
  }
  button{
    width:220px;
    margin:auto;
    border:0px;
  }
`