import { useEffect, useState, createContext, useContext, useMemo } from "react"
import { useHistory } from "react-router-dom";
import { cpfMask, replaceEspecials } from "util/masks"
import { isCpfValid } from "util/validations";
import { deleteServiceUser, getServiceUser, getServiceUserCpf, getServiceUser_temp } from "./service/ServiceUser";

export interface IUser {
  id: string,
  funcao: string,
  nome: string,
  cpf: string,
  ddd: string,
  telefone: string,
  email: string,
  unidade: string,
  dataCadastro?: string,
}
const valueDefault = [{
  id: '',
  funcao: '',
  nome: '',
  cpf: '',
  ddd: '',
  telefone: '',
  email: '',
  unidade: '',
  dataCadastro: ''
}]


const UserContext = createContext<any>({} as any)

const UserProvider: React.FC<{ children: any }> = ({ children }) => {
  const [user, setUser] = useState<IUser[]>(valueDefault)
  const [userEdit, setUserEdit] = useState()

  return <UserContext.Provider value={{ user, setUser, userEdit, setUserEdit }}>
    {children}
  </UserContext.Provider>
}

const useUser = () => {
  const navigate = useHistory();

  const { user, setUser, setUserEdit } = useContext(UserContext)

  const [cpf, setCpf] = useState('')
  const [modal, setModal] = useState({ open: false, msg: '', id: 0 })
  const [usersFilter, setUsersFilter] = useState<IUser[]>([])
  const [load,setLoad] = useState(false)

  const [totalRows, setTotalRows] = useState(100)
  const [pageCurrent, setPageCurrent] = useState(0)

  const [errors, setErrors] = useState('')

  const orderUser = (list : IUser[]) => {
    const listOrderDesc = list.sort((x : any, y : any) => {
      return x.dataCadastro - y.dataCadastro
    })
    return listOrderDesc.reverse()
  }

  const getListUserInit = () => {
    setLoad(true)   
    // totalRows = 100 e paginação = 0
    getServiceUser(totalRows, pageCurrent)?.then(i => {
      setUser(i.data.page)
      setUsersFilter(orderUser(i.data.page))
    }).finally(() => setLoad(false))

    return () => {
      setUser(valueDefault)
      setUserEdit([])
    }
  }

  useEffect(() => {
    getListUserInit()
  }, [totalRows])

  const userFilter = (ele: any) => {
    setErrors('')
    const value = replaceEspecials(ele.target.value)
    setCpf(cpfMask(value))

    if (value.length === 11) {
      if(!isCpfValid(value)){
        setErrors('CPF inválido')
        return
      }

      setLoad(true)
      getServiceUserCpf(parseInt(value))
        ?.then(res => {
          const items = res.data.page

          if(items.length === 0){
            setErrors("CPF não cadastrado. Verifique e faça uma nova consulta.")
            return
          }
          setUsersFilter(items)
        })
        .catch(error => console.log(error))
        .finally(() => setLoad(false))
    }
  }
  const clearFilter = () => {
    setUsersFilter(user)
    setCpf('')
    setErrors('')
  }

  const handleEdit = (idx: number) => {
    const editUser = user.filter((e: any) => e.id === idx)
    setUserEdit(editUser[0])
    navigate.push(`/home/usuarios/salvar/${idx}`)
  }
  const handleModal = (idx: number) => {
    setModal({ open: !modal.open, msg: 'Deseja excluir este item? Os dados não deletados serão perdidos ', id: idx })
  }
  const deleteUserFilter = (id : number) => {
    const editUser = usersFilter.filter((e: any) => e.id !== id)
    setUsersFilter(editUser)
  }
  const handleDelete = () => {
    deleteServiceUser(modal.id)
      ?.then(() => {
        deleteUserFilter(modal.id)
      })
      .catch(error => 
        console.log(error.response.data)
      )
  }

  return { user, usersFilter, cpf, modal, setUser, userFilter, clearFilter, load, 
    setUsersFilter, handleEdit, handleDelete, setModal, handleModal, setTotalRows, errors }
}

export { useUser, UserProvider, UserContext }