import React, { useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';

import Route from './Route';

import Login from '../layout/Login';
import Home from '../layout/Home';
import { setupInterceptor } from '../api';
import { useAuth } from '../hooks/auth';
import AccountValidated from 'layout/Home/screens/Account/accountValidated/AccountValidated';
import { rememberPassword } from 'layout/Login/rememberPassword/rememberPassword';

const Routes: React.FC = () => {
  const history = useHistory();
  const { signOut } = useAuth();

  useEffect(() => {
    setupInterceptor(signOut, history);
  }, []);

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/esqueci-minha-senha" component={rememberPassword} />

      <Route path="/home" component={Home} isPrivate />
      <Route path="/conta-validada" component={AccountValidated} isPrivate />
    </Switch>
  );
};

export default Routes;
