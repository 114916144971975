import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import { moneyMask } from "util/masks"
import { scrollToTop } from "util/utils";
import { getGeneralCampaings, getGeneralRulesCampaings, getGeneralRulesQuery, getMasterPinQuery, updateActiveGeneralRulesQuery, updateGeneralRulesQuery } from "./service";


const initialGeneralRules = {
  idCampaingActive: 0,
  isActiveInstance: true,
  valorMaximoDia: 0,
  valorMaximoDiaMostrar: `R$ 0.00`,
  percentualCreditos: 0,
  quantidadeVigencia: 0,
  observacao: '',
}

const errorInitialGeneralRules = {
  percentualCreditos: '',
  observacao: '',
  valorMaximoDiaMostrar: '',
  quantidadeVigencia: '',
}
const valueDefaultRules ={
  valorMaximoDia: 0,
  quantidadeVigencia: 0
}

const valueDefaultCampaing = {
  id: 0,
  nome: "",
  dataInicioVigencia: "",
  dataFimVigencia: "",
  multiplicador: 0,
  ativo: false,
  observacoes: "",
  vigente: false
}

export const useManageRules = () => {
  const navigate = useHistory()

  const [instanceActive, setInstanceActive] = useState(false)
  const [open, setOpen] = useState(false)
  const [pin, setPin] = useState(null)
  const [errorGerenalRules, setErroGereralRules] = useState(errorInitialGeneralRules)
  const [generalRules, setGeneralRules] = useState<any>(initialGeneralRules)

  const [isLoadRules, setIsLoadRules] = useState(true)
  const [dataGeneralRules, setDataGeneralRules] = useState(valueDefaultRules)
  const [dataGeneralCampaingRules, setDataGeneralCampaingRules] = useState(valueDefaultCampaing)

  const idUser = localStorage.getItem('@mony:usuarioId')
  const { data: dataCodigoPin } = getMasterPinQuery({ id: "user"+ idUser })
  
  const initial = () => {
    getGeneralRulesCampaings()
    .then(res => {
      setDataGeneralRules(res[0].data)
      setDataGeneralCampaingRules(res[1].data)
    }).finally(() => setIsLoadRules(false))
  }

  const normalizeMultiplyZero = (value: any) => {
    const number = value ? value.toFixed(2) : 0
    return number && number.toString().replace('.', ',')
  }

  useEffect(() => {
    initial()
  },[])


  const rules = {
    idCampaingActive: dataGeneralCampaingRules?.id,
    isActiveInstance: dataGeneralCampaingRules?.ativo,
    valorMaximoDia: dataGeneralRules?.valorMaximoDia,
    quantidadeVigencia: dataGeneralRules?.quantidadeVigencia,
    valorMaximoDiaMostrar: moneyMask(`${normalizeMultiplyZero(dataGeneralRules?.valorMaximoDia)}`),
    percentualCreditos: dataGeneralCampaingRules?.multiplicador ? dataGeneralCampaingRules?.multiplicador : 0,
    percentualCreditosMostra: dataGeneralCampaingRules?.multiplicador ? normalizeMultiplyZero(dataGeneralCampaingRules?.multiplicador) : 0,
    observacao: dataGeneralCampaingRules?.observacoes,
  }
  

  useEffect(() => {
    setInstanceActive(rules.isActiveInstance)

    dataGeneralRules !== undefined 
    dataGeneralCampaingRules !== undefined 
    ? setGeneralRules(rules) 
    : null
        
    dataCodigoPin?.codigoPin !== undefined
    ? setPin(dataCodigoPin?.codigoPin)
    : null
  
  }, [pin, dataGeneralRules,dataGeneralCampaingRules])
  
  const handleModal = () => setOpen(!open);
  
  const handleActiveInstance = (e: boolean) => {
    setInstanceActive(e)

    updateActiveGeneralRulesQuery({ ...rules, isActiveInstance: e })
      //.then(res => console.log(res))
  }

  const resetValues = () => setGeneralRules(initialGeneralRules)

  const isValid = (e: any) => {
    const { valorMaximoDia, valorMaximoDiaMostrar, percentualCreditos, quantidadeVigencia, observacao, instanceActive } = e

    const validacao = {
      percentualVazio: percentualCreditos === undefined || percentualCreditos === '',
      percentualMenor: parseFloat(percentualCreditos) < 0.01,
      valorMaximoVazio: valorMaximoDiaMostrar === 'R$ 0,00',
      quantidade: !(parseFloat(quantidadeVigencia) >= 1),
    }

    if(validacao.percentualVazio){
      setErroGereralRules((prevState) => ({...prevState, percentualCreditos: 'Campo obrigatório'}))
    }
    if(validacao.percentualMenor){
      setErroGereralRules((prevState) => ({...prevState, percentualCreditos: 'Necessário preencher com valor acima de 0,01%'}))
    }
    if(!validacao.percentualVazio && !validacao.percentualMenor){
      setErroGereralRules((prevState) => ({...prevState, percentualCreditos: ''}))
    }

    if(validacao.valorMaximoVazio){
      setErroGereralRules((prevState) => ({...prevState, valorMaximoDiaMostrar: 'Campo obrigatório'}))
    }else{
      setErroGereralRules((prevState) => ({...prevState, valorMaximoDiaMostrar: ''}))
    }

    if(validacao.quantidade){
      setErroGereralRules((prevState) => ({...prevState, quantidadeVigencia: 'Campo obrigatório'}))
    }else{
      setErroGereralRules((prevState) => ({...prevState, quantidadeVigencia: ''}))
    }

    if(validacao.percentualVazio 
      || validacao.percentualMenor 
      || validacao.valorMaximoVazio
      || validacao.quantidade
      ){
      return false
    }else{
      return true
    }

  }
  
  const handleUpdate = (e: any) => {

    if (!isValid(e)) {
      scrollToTop({ elemento: "#topo"})
      return
    }

    updateGeneralRulesQuery(e)
      .then(res => {
        const isSucesso = res.map(i => i.status === 200 ? true : false )
        
        if(!isSucesso.includes(false)){
          navigate.push('/home/regras-gerais/sucesso')
        }else{
          console.log('ocorreu algum erro', { error: res.map(i => console.log(i))})
        }
      }).catch(error => console.log('Error', { error }))
    
  }

  return {
    generalRules, isLoadRules, open, pin, instanceActive, errorGerenalRules,
    handleActiveInstance, handleUpdate, handleModal, resetValues
  }
}