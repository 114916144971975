import React, { createContext, useContext, useEffect, useState } from "react"

export const EnterpriseContext = createContext<any>({} as any)

interface IEnterpriseContext {
  errorGeral: string,
  setErrorGeral: any
}

const EnterpriseProvider: React.FC<{ children: any }> = ({ children }) => {

  const [errorGeral, setErrorGeral] = useState<any>('')

  return <EnterpriseContext.Provider value={{ errorGeral, setErrorGeral }}>
    {children}
  </EnterpriseContext.Provider>
}
const useEnterpriseContext = () => {
  const context = useContext(EnterpriseContext)
  const { errorGeral, setErrorGeral } = context


  const clearError = setTimeout(() => {
    setErrorGeral('')
  }, 1000 * 30); // 15s
  const clearTime = () => clearTimeout(clearError);

  useEffect(() => {
    clearTime()
    if(!errorGeral){
      clearError
    }
    return () => clearTime();
  },[errorGeral])

  
  return { errorGeral, setErrorGeral, ...context } as IEnterpriseContext
}

export { useEnterpriseContext, EnterpriseProvider }

