import TitlePage from "components/TitlePage";
import { AppBody } from "styles/base";
import { useManageRules } from "./useManageRules";
import { Container } from "./style";
import { CircularProgress } from "@mui/material"

import { StatefulPinInput } from 'react-input-pin-code';
import { ErrorAlert } from "components/ErrorAlert";
import { InstanceManageRules } from "components/InstanceManageRules";
import theme from "styles/theme";

interface IManageRules {
}
const ManageRules = ({ }: IManageRules) => {
  const { generalRules, isLoadRules,
    pin, instanceActive, errorGerenalRules,
    handleActiveInstance, handleUpdate,
    open, handleModal, resetValues } = useManageRules()

  if((isLoadRules) || (generalRules.idCampaingActive == 0)){
    return <AppBody id="topo" >
      <TitlePage title="Regras Gerais" />
      <Container><CircularProgress /></Container>
    </AppBody>
  }

  return <AppBody id="topo" >
    <TitlePage title="Regras Gerais" />
    
    { !instanceActive && <><ErrorAlert message='A funcionalidade "Lançar compras" foi desativada. Sua loja não está mais visível no aplicativo.' /><br/></> }

    <Container>

      <InstanceManageRules
        values={generalRules}
        isCkdSwitch
        valueInstanceActive={instanceActive}
        handleActiveInstance={handleActiveInstance}
        onClick={handleUpdate}
        errors={errorGerenalRules}
      />

    </Container>

    <Container>
      <h3>PIN Mestre</h3>
      {pin && <StatefulPinInput
        length={6}
        initialValue={pin}
        showState={false}
        disabled
        validBorderColor="white"
        containerStyle={{ marginTop: '10px' }}
        inputStyle={{ fontFamily: 'Montserrat', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 'unset', borderBottom: `1px solid ${theme.COLORS.PRIM_800}`, color: theme.COLORS.PRIM_800 }}
      />}
    </Container>

  </AppBody>
}
export { ManageRules }