import { Box, Modal, styled } from "@mui/material";
import theme from "styles/theme";
import { Label } from "../../campaings/styles";
import Button from "components/Button";

interface ModalProps{
    open: boolean,
    onClose: () => void,
    text: string
}

export const ModalConfirm : React.FC<ModalProps> = ({ open = false, onClose, text }) => {
    
    const handleClickYes = () => {
        onClose()
    }
    return <Modal 
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex',
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            outline: '0' }}
    >
        <ModalContainer>
            <div style={{ textAlign:"center"}}>
                <Label dangerouslySetInnerHTML={{ __html: `${text}` }}></Label>
            </div>
            <ModalContBts>
                <Button confirm onClick={handleClickYes} text={"Ok"} />
            </ModalContBts>
        </ModalContainer>
    </Modal>
}
const ModalContainer = styled(Box)({
    maxWidth: '350px',
    width: '100%',
    display: 'flex',
    padding: '1.5rem',
    backgroundColor: `${theme.COLORS.WHITE}`,
    flexDirection: 'column',
});
const ModalContBts = styled(Box)({
    display: 'flex',
    gap:'1rem',
    width: '100%',
    marginTop: '20px',
    "& > button": {
        width: '100%',
    }
})
