import api from "api";
import { useEffect, useState } from "react";
import { Spacing, TableContainer, TableLine } from "styles/base";
import UnitContent from "../Components/unitContent/UnitContent";

import theme from "styles/theme";

import { ReactComponent as PencilSvg } from 'assets/icons/pencilIcon.svg';
import { ReactComponent as RemoveSvg } from 'assets/icons/remove.svg';
import UnitForm from "components/UnitForm/UnitForm";

const defaultData = {
    page: [{
        idUnidade: 0,
        nomeUnidade: '',
        endereco: {
            cep: '',
            endereco: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            uf: '',
        },
        cnpj: '',
        codigoEstabelecimento: '',
        principal: true,
    }],
    count: 0,
    currentPage: 0,
}

const defaultUnit = {
    idUnidade: 0,
    nomeUnidade: '',
    endereco: {
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',
    },
    cnpj: '',
    codigoEstabelecimento: '',
    principal: true,
}

const ParticipatingUnits = ({ cnpj, error }: { cnpj: string, error: Function }) => {
    const [dataUnits, setDataUnits] = useState(defaultData);
    const [unitSelected, setUnitSelected] = useState(defaultUnit);
    const [deleteUser, setDeleteUser] = useState(false);

    useEffect(() => {
        async function initData() {
            try {
                const response = await api.get("/unidades");
                return setDataUnits(response.data);
            } catch (e) {
                console.log(e);
            }
        }

        initData();
        return () => setDataUnits(defaultData)
    }, [unitSelected, deleteUser]);

    const resetUnitSelected = () => {
        setUnitSelected(defaultUnit);
    }

    const handleRemove = async (idUnidade: number) => {
        try {
            const response = await api.get(`/unidades/has-users?idUnidade=${idUnidade}`)

            if (response.data.hasUsers) {
                return error(true, 'Você possui funcionários dentro desta unidade')
            }

            error(false);

            if (!response.data.hasUsers) {
                try {
                    await api.delete(`/unidades?idUnidade=${idUnidade}`)
                } catch (error: any) {
                    console.log(error);
                }

                setDeleteUser(!deleteUser);
            }

        } catch (error: any) {
            console.log(error);
        }
    }

    return (
        <>
        
            {/* 
            DataWrapper
            <Label size="18" bold>UNIDADES PARTICIPANTES</Label> */}

            {(unitSelected.idUnidade === 0 && dataUnits) && (
                <TableContainer>
                    <thead><tr>
                        <th>Código do Estabelecimento</th>
                        <th>Unidade</th>
                        <th>Editar</th>
                        <th>Remover</th>
                    </tr></thead>
                    <tbody>
                        {dataUnits.page.map((item: any, idx: number) => (
                            <UnitContent key={idx}
                                unit={item} dataUnits={dataUnits} setUnitSelected={setUnitSelected} remove={handleRemove}
                            />
                        ))}
                        <TableLine>
                            <th>-</th>
                            <th>
                                <button onClick={() => setUnitSelected({
                                    ...unitSelected,
                                    idUnidade: 1.1,
                                })}>
                                    <span style={{ textDecoration: 'underline' }}>Adicionar Unidade</span>
                                </button>
                            </th>
                            <th>
                                <button>
                                    <PencilSvg style={{
                                        width: 24, height: 24, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
                                        opacity: 0.5,
                                    }}
                                    />
                                </button>
                            </th>
                            <th>
                                <button>
                                    <RemoveSvg style={{
                                        width: 24, height: 24, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
                                        opacity: 0.5,
                                    }}
                                    />
                                </button>
                            </th>
                        </TableLine>
                    </tbody>
                </TableContainer>
            )}
            {unitSelected.idUnidade !== 0 && (
                <>
                    {unitSelected.idUnidade !== 1.1 && (
                        <TableContainer>
                            <thead><tr>
                                <th>Código do Estabelecimento</th>
                                <th>Unidade</th>
                                <th>Editar</th>
                                <th>Remover</th>
                            </tr></thead>
                            <tbody>
                                <TableLine>
                                    <th>{unitSelected.codigoEstabelecimento || cnpj}</th>
                                    <th>{unitSelected.nomeUnidade}</th>
                                    <th>
                                        <button >
                                            <PencilSvg style={{
                                                width: 24, height: 24, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
                                                opacity: 0.5,
                                            }}
                                            />
                                        </button>
                                    </th>
                                    <th>
                                        <button disabled>
                                            <RemoveSvg style={{
                                                width: 24, height: 24, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
                                                opacity: 0.5,
                                            }}
                                            />
                                        </button>
                                    </th>
                                </TableLine>
                            </tbody>
                        </TableContainer>
                    )}
                    <Spacing margin={24} />

                    <UnitForm data={unitSelected} reset={resetUnitSelected} cnpj={cnpj} />
                </>
            )}
        </>
    )
}

export default ParticipatingUnits;
