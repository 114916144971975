import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styles/theme';
import Button from '../../components/Button';


export const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ResetMessage = styled.span`
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 19px;
    text-align: center;
    color: #fff;
`;

export const BoxInput = styled.label`
    display:block;
    
    input{
        width:100%
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    textarea:-webkit-autofill:active,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    select:-webkit-autofill:active {
        -webkit-animation-name: autofill;
        -webkit-animation-fill-mode: both;
        -webkit-transition-delay: 9999s;
        -webkit-transition-property: background-color, color;
    } 
`


export const BoxBtnLogin = styled.div`
    button{
        width:100%;

        :disabled{
            cursor:initial;
        }
    }
    /* :disabled{        
        cursor:initial
    } */
`
  

export const BoxLink = styled(Link)`
    display:flex;
    margin-left: auto;
    margin-top:10px;
    color:${theme.COLORS.WHITE};
    opacity:.7;
    font-size:14px;
    text-decoration: none;
`

export const BoxForm = styled.form`
  display:grid;
  gap:25px;
`