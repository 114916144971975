import styled from 'styled-components';
import theme from 'styles/theme';

interface Props {
  margin?: number;
}

export const ContainerErrorAlert = styled.div`
    border: 1px solid ${theme.COLORS.PRIM_100};
    border-radius: 6px;
    background: ${theme.COLORS.BG_005};
    box-shadow: inset 0 0 4px 1px ${theme.COLORS.BLACK_800A};
    padding: 10px 30px;
    width: 100%;

    > span {
        letter-spacing: 0;
        line-height: 24px;
        font-weight: bold;
        color: ${theme.COLORS.WHITE};
        font-size: 1.1em;
    }
`;

export const Spacing = styled.div<Props>`
    margin: ${(props) => (props.margin ? `${props.margin}px` : '8px')};
`;
