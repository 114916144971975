import { useContext, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom";
import { moneyMask, NormalizeMoney, phoneMask } from "util/masks";
import { getReports, getReportsDetails, getReportsDetailsFile } from "../services";
import moment from "moment";
import { ReportsContext } from "../context";
import { dateInitial, defaultValuesFilter } from "../BarSearch/useBoxSearch";


const zeroLeft = (e: number) => ('0' + e).slice(-2)

const datePeriod = ({ dtInicio, dtFinal }: { dtInicio?: Date, dtFinal?: Date }) => {
  const dtInitial = moment(dtInicio || dateInitial).format('DD/MM/YYYY')
  const dtEnd = moment(dtFinal || dateInitial).format('DD/MM/YYYY')

  if (dtInitial == dtEnd) {
    return dtInitial
  }

  return `${dtInitial} a ${dtEnd}`
}

export const useCredits = () => {
  // const { errorReports, setErrorReports, 
  //   valuesReports, setValuesReports, 
  //   filterReports, setFilterReports,
  // } = useContext(ReportsContext)

  const [typeConsult, setTypeConsult] = useState<"utilizados" | "distribuidos" | "expirados" | "expirar">('utilizados')
  const [filterValues, setFilterValues] = useState(defaultValuesFilter)
  const [values, setValues] = useState<any>()

  const { data: valueList, isFetching } = getReports({
    idInstancia: filterValues.idInstancia,
    idUnidade: filterValues.idUnidade,
    dataInicio: filterValues.dataInicio,
    dataFim: filterValues.dataFim,
    tipo: typeConsult
  })
  
  const init = useMemo(() => {
    valueList !== undefined ? setValues(valueList) : {}
  }, [valueList, filterValues])
  init

  const handlePeriod = ({ dtInicio, dtFinal }: { dtInicio?: Date, dtFinal?: Date }) => {
    return datePeriod({ dtInicio: dtInicio || filterValues.dataInicio, dtFinal: dtFinal || filterValues.dataFim })
  }

  const handleDetails = () => {
    // 28-03-23 - não esta sendo utilizado o contexto
    // Passo na url com alguns dados e no contexto também
  }


  const handleFilter = (value: any) => {
    const { idInstancia, idUnidade, periodo, dataInicio, dataFim, tipo } = value
    setFilterValues({
      idInstancia: idInstancia,
      idUnidade: idUnidade,
      periodo: periodo, 
      dataInicio: dataInicio,
      dataFim: dataFim,
      tipo: tipo
    })
  }
  
  const tableAll2 : any ={ 
    "utilizados": {
        head: [
        { nome: 'Período', style: { width: "33.333%" } },
        { nome: 'Total do cashback utilizados', style: { width: "33.333%", } },
        { nome: 'Ação', style: { width: "33.333%" } },
      ],
      rows: [{
        periodo: handlePeriod({ dtInicio: filterValues.dataInicio, dtFinal: filterValues.dataFim }),
        creditos: values?.valorTotalPeriodo ? moneyMask(NormalizeMoney(values?.valorTotalPeriodo)) : 0,
        acao: <Link onClick={handleDetails} to={`/home/relatorios/utilizados/detalhe?idInstancia=${filterValues.idInstancia}&idUnidade=${filterValues.idUnidade}&periodo=${filterValues.periodo}&dataInicio=${moment(filterValues.dataInicio).format('YYYY-MM-DD')}&dataFim=${moment(filterValues.dataFim).format('YYYY-MM-DD')}&page=0`}>Veja detalhe</Link>,
      }]
    },
    "distribuidos": {
      head: [
        { nome: 'Período', style: { width: "33.333%" } },
        { nome: 'Valor do cashback', style: { width: "33.333%", } },
        { nome: 'Ação', style: { width: "33.333%" } },
      ],
      rows: [{
        periodo: handlePeriod({ dtInicio: filterValues.dataInicio, dtFinal: filterValues.dataFim }),
        creditos: values?.valorTotalPeriodo ? moneyMask(NormalizeMoney(values?.valorTotalPeriodo)) : 0,
        acao: <Link onClick={handleDetails} to={`/home/relatorios/distribuidos/detalhe?idInstancia=${filterValues.idInstancia}&idUnidade=${filterValues.idUnidade}&periodo=${filterValues.periodo}&dataInicio=${moment(filterValues.dataInicio).format('YYYY-MM-DD')}&dataFim=${moment(filterValues.dataFim).format('YYYY-MM-DD')}&page=0`}>Veja detalhe</Link>,
      }]
    },
    "expirar": {
      head: [
        { nome: 'Período', style: { width: "33.333%" } },
        { nome: 'Valor do cashback', style: { width: "33.333%", } },
        { nome: 'Ação', style: { width: "33.333%" } },
      ],
      rows: [{
        periodo: handlePeriod({ dtInicio: filterValues.dataInicio, dtFinal: filterValues.dataFim }),
        creditos: values?.valorTotalPeriodo ? moneyMask(NormalizeMoney(values?.valorTotalPeriodo)) : 0,
        acao: <Link onClick={handleDetails} to={`/home/relatorios/expirar/detalhe?idInstancia=${filterValues.idInstancia}&idUnidade=${filterValues.idUnidade}&periodo=${filterValues.periodo}&dataInicio=${moment(filterValues.dataInicio).format('YYYY-MM-DD')}&dataFim=${moment(filterValues.dataFim).format('YYYY-MM-DD')}&page=0`}>Veja detalhe</Link>,
      }]
    },
    "expirados": {
      head: [
        { nome: 'Período', style: { width: "33.333%" } },
        { nome: 'Valor do cashback', style: { width: "33.333%", } },
        { nome: 'Ação', style: { width: "33.333%" } },
      ],
      rows: [{
        periodo: handlePeriod({ dtInicio: filterValues.dataInicio, dtFinal: filterValues.dataFim }),
        creditos: values?.valorTotalPeriodo ? moneyMask(NormalizeMoney(values?.valorTotalPeriodo)) : 0,
        acao: <Link onClick={handleDetails} to={`/home/relatorios/expirados/detalhe?idInstancia=${filterValues.idInstancia}&idUnidade=${filterValues.idUnidade}&periodo=${filterValues.periodo}&dataInicio=${moment(filterValues.dataInicio).format('YYYY-MM-DD')}&dataFim=${moment(filterValues.dataFim).format('YYYY-MM-DD')}&page=0`}>Veja detalhe</Link>,
      }]
    },
  }
  
  const relatorioList2 : any = {
    "utilizados": {
      head: [
        { nome: 'Caixa', style: { width: "50%" } },
        { nome: 'Total de cashback', style: { width: "50%" } },
      ],
      rows: values !== undefined || values?.somaPorCodigoDoCaixa?.length > 0
        ? values?.somaPorCodigoDoCaixa?.map((i: any) => {
          return { caixa: zeroLeft(i.cdCaixa), total: moneyMask(NormalizeMoney(i.totalPorCaixa)) }
        })
        : [],
    }, 
    "distribuidos": {
      head: [
        { nome: 'Vendedor', style: { width: "50%" } },
        { nome: 'Total de cashback', style: { width: "50%" } },
      ],
      rows: values !== undefined || values?.somaPorVendedor?.length > 0
        ? values?.somaPorVendedor?.map((i: any) => {
          return { caixa: i.vendedor, total: moneyMask(NormalizeMoney(i.totalCredito)) }
        })
        : [],
    },
    "expirar": {
      head: [
        { nome: 'Vendedor', style: { width: "50%" } },
        { nome: 'Total de cashback', style: { width: "50%" } },
      ],
      rows: values !== undefined || values?.somaPorVendedor?.length > 0
        ? values?.somaPorVendedor?.map((i: any) => {
          return { caixa: i.vendedor, total: moneyMask(NormalizeMoney(i.totalCredito)) }
        })
        : [],
    },
    "expirados": {
      head: [
        { nome: 'Vendedor', style: { width: "50%" } },
        { nome: 'Total de cashback', style: { width: "50%" } },
      ],
      rows: values !== undefined || values?.somaPorVendedor?.length > 0
        ? values?.somaPorVendedor?.map((i: any) => {
          return { caixa: i.vendedor, total: moneyMask(NormalizeMoney(i.totalCredito)) }
        })
        : [],
    },
  }

  const tableAll = tableAll2[typeConsult]
  const relatorioList = relatorioList2[typeConsult]

  return {
    isFetching,
    values,
    filterValues,
    setFilterValues,
    tableAll,
    relatorioList,
    handleFilter,
    handlePeriod,
    setTypeConsult
  }
}

export const useCreditsDetails = () => {
  const { errorReports, setErrorReports, 
    valuesReports, setValuesReports, setFilterReports,
    nameUnity, setNameUnity } = useContext(ReportsContext)
  
  const [typeConsult, setTypeConsult] = useState<"utilizados" | "distribuidos" | "expirados" | "expirar">("distribuidos")
  
  const [pageCurrent, setPageCurrent] = useState<number>(0)
  const [filterValues, setFilterValues] = useState<any>()
  const [values, setValues] = useState<any>()

  const { data: valuesDetails, isLoading: loadDetails, isFetching: FetchingDetails } : 
  { data:any, isLoading: boolean, isFetching: boolean } = getReportsDetails({
    idInstancia: filterValues?.idInstancia,
    idUnidade: filterValues?.idUnidade,
    dataInicio: filterValues?.dataInicio || dateInitial,
    dataFim: filterValues?.dataFim || dateInitial,
    page: pageCurrent || 0,
    tipo: typeConsult
  })
  
  const init = useMemo(() => {
    valuesDetails !== undefined ? setValues(valuesDetails[0]) : {}
  }, [valuesDetails, filterValues])
  init

  const allDetails : any = {
    "utilizados": {
      head: [
        { nome: 'Unidade', style: { width: "33.333%" } },
        { nome: 'Período', style: { width: "33.333%", } },
        { nome: 'Cashback utilizados', style: { width: "33.333%" } },
      ],
      rows: [{
        unidade: nameUnity || '', // nome da unidade esta no CONTEXTO
        periodo: datePeriod({ dtInicio: filterValues?.dataInicio, dtFinal: filterValues?.dataFim }), //'01/01/2022 a 15/01/2022',
        creditos: values?.valorTotalPeriodo ? moneyMask(NormalizeMoney(values?.valorTotalPeriodo || 0)) : 0,
      }]
    },
    "distribuidos": {
      head: [
        { nome: 'Unidade', style: { width: "33.333%" } },
        { nome: 'Período', style: { width: "33.333%", } },
        { nome: 'Cashback distribuídos', style: { width: "33.333%" } },
      ],
      rows: [{
        unidade: nameUnity || '', // nome da unidade esta no CONTEXTO
        periodo: datePeriod({ dtInicio: filterValues?.dataInicio, dtFinal: filterValues?.dataFim }), //'01/01/2022 a 15/01/2022',
        creditos: values?.valorTotalPeriodo ? moneyMask(NormalizeMoney(values?.valorTotalPeriodo || 0)) : 0,
      }]
    },
    "expirar": {
      head: [
        { nome: 'Unidade', style: { width: "33.333%" } },
        { nome: 'Período', style: { width: "33.333%", } },
        { nome: 'Cashback a expirar', style: { width: "33.333%" } },
      ],
      rows: [{
        unidade: nameUnity || '', // nome da unidade esta no CONTEXTO
        periodo: datePeriod({ dtInicio: filterValues?.dataInicio, dtFinal: filterValues?.dataFim }), //'01/01/2022 a 15/01/2022',
        creditos: values?.valorTotalPeriodo ? moneyMask(NormalizeMoney(values?.valorTotalPeriodo || 0)) : 0,
      }]
    },
    "expirados": {
      head: [
        { nome: 'Unidade', style: { width: "33.333%" } },
        { nome: 'Período', style: { width: "33.333%", } },
        { nome: 'Cashback expirado', style: { width: "33.333%" } },
      ],
      rows: [{
        unidade: nameUnity || '', // nome da unidade esta no CONTEXTO
        periodo: datePeriod({ dtInicio: filterValues?.dataInicio, dtFinal: filterValues?.dataFim }), //'01/01/2022 a 15/01/2022',
        creditos: values?.valorTotalPeriodo ? moneyMask(NormalizeMoney(values?.valorTotalPeriodo || 0)) : 0,
      }]
    },
  }

  const ListDetails : any = {
    "utilizados": {
      head: [
        { nome: 'Data', style: { width: "25%" } },
        { nome: 'Cliente', style: { width: "25%" } },
        { nome: 'Celular', style: { width: "25%" } },
        { nome: 'Valor do cashback', style: { width: "25%" } },
      ],
      rows: values !== undefined && values?.itens?.length > 0
        ? values?.itens.map((i: any) => {
          return { 
            data: moment(i.dataAprovacao).format('DD/MM/YYYY HH:mm'), 
            cliente: i.nomeCliente, 
            celular: phoneMask(i.dddCliente + i.telefoneCliente),
            total: moneyMask(NormalizeMoney(i.valor || 0)) }
          //[{ data: '16/11/22 10:00', cliente: 'Nome do Clientes', total: moneyMask(NormalizeMoney(110.00)), caixa: '01' },] 
        })
      : [],
      totalRows: values?.totalOfElements
    },
    "distribuidos": {
      head: [
        { nome: 'Data', style: { width: "16%" } },
        { nome: 'Cliente', style: { width: "16%" } },
        { nome: 'Celular', style: { width: "16%" } },
        { nome: 'Valor da compra', style: { width: "16%" } },
        { nome: 'Valor do cashback', style: { width: "16%" } },
        { nome: 'Vendedor', style: { width: "16%" } },
      ],
      rows: values !== undefined && values?.itens?.length > 0
        ? values?.itens.map((i: any) => {
          return { 
            data: moment(i.dataTransacaoVenda).format('DD/MM/YYYY HH:mm'), 
            cliente: i.cliente, 
            celular: phoneMask(i.dddCliente + i.telefoneCliente),
            compra: moneyMask(NormalizeMoney(i.valorCompra || 0)), 
            credito: moneyMask(NormalizeMoney(i.valorCredito || 0)), 
            vendedor: i.vendedor }
        })
        : [],
      totalRows: values?.totalOfElements //values.valorTotalPeriodo
    },
    "expirar": {
      head: [
        { nome: 'Data da expiração', style: { width: "25%" } },
        { nome: 'Cliente', style: { width: "25%" } },
        { nome: 'Celular', style: { width: "25%" } },
        { nome: 'Valor do cashback', style: { width: "25%" } }
      ],
      rows: values !== undefined && values?.itens?.length > 0
        ? values?.itens.map((i: any) => {
          return { 
            data: moment(i.dataAExpirar).format('DD/MM/YYYY'), 
            cliente: i.cliente, 
            celular: phoneMask(i.dddCliente + i.telefoneCliente), 
            total: moneyMask(NormalizeMoney(i.valorCredito || 0)) }
        })
        : [],
      totalRows: values?.totalOfElements //values.valorTotalPeriodo
    },
    "expirados": {
      head: [
        { nome: 'Data da expiração', style: { width: "25%" } },
        { nome: 'Cliente', style: { width: "25%" } },
        { nome: 'Celular', style: { width: "25%" } },
        { nome: 'Valor do cashback', style: { width: "25%" } }
      ],
      rows: values !== undefined && values?.itens?.length > 0
        ? values?.itens.map((i: any) => {
          return { 
            data: moment(i.dataExpirado).format('DD/MM/YYYY'), 
            cliente: i.cliente, 
            celular: phoneMask(i.dddCliente + i.telefoneCliente), 
            total: moneyMask(NormalizeMoney(i.valorCredito || 0)) }
        })
        : [],
      totalRows: values?.totalOfElements //values.valorTotalPeriodo
    }
  }

  const handleClick = () => getReportsDetailsFile({ idInstancia: filterValues?.idInstancia,
    idUnidade: filterValues?.idUnidade,
    dataInicio: filterValues?.dataInicio || dateInitial,
    dataFim: filterValues?.dataFim || dateInitial,
    page: pageCurrent || 0,
    tipo: typeConsult
  }) //console.log('enviar relatorio')


  const tableAllDetails = allDetails[typeConsult]
  const relatorioListDetails = ListDetails[typeConsult]

  return {
    loadDetails,
    FetchingDetails,
    setFilterValues,
    tableAllDetails,
    relatorioListDetails,
    pageCurrent,
    setPageCurrent,
    setTypeConsult,
    handleClick
  }
}