import { useEffect, useMemo, useRef, useState } from "react"
import { CircularProgress } from "@mui/material"
import { BoxIcon, TableLine } from "styles/base"
import Pagination from './usePagination'

import { ReactComponent as PencilIcon } from 'assets/icons/pencilIcon.svg';
import { ReactComponent as RemoveSvg } from 'assets/icons/remove.svg';
import styled from "styled-components"
import theme from "styles/theme";
import { PaginationAPI } from "./PaginationAPI";

interface ITableGrid {
  columns?: any,
  rows?: any,
  handleEdit?: any,
  handleModal?: any,
  loading?: boolean,
  limit?: number,
  perPage?: number,
  hasIconLoad?: boolean,
  totalRows?:number,
  goTo?: ((pag: number) => number) | any,
  currentPg?: number
}

const TableGrid = ({ columns, rows, handleEdit, handleModal, loading = false, limit = 10, perPage = 10, hasIconLoad = true, totalRows = 0, goTo, currentPg = 1 }: ITableGrid) => {

  const [pageLimit, setPageLimit] = useState(limit)
  const [itemsPerPage, setItemsPerPage] = useState(perPage)
  const [currentPage, setCurrentPage] = useState(1)

  const handleOnPageChange = (page: number) => setCurrentPage(page)

  const handleOnPageChangeApi = (page: number) => goTo(page) 
    
  const currentTableData = useMemo(() => {
    const firstPageIdx = (currentPage - 1) * itemsPerPage
    const lastPageIdx = firstPageIdx + itemsPerPage
    return rows.slice(firstPageIdx, lastPageIdx)
  }, [currentPage, rows, totalRows])

  const TableBody = () => {
    return currentTableData.length > 0 ? <>
      {currentTableData?.map((row: any, idx: number) => {
        const itemRow = Object.keys(row)
        itemRow.includes('id') && itemRow.shift()

        return <TableLine key={idx}>
          {itemRow.map((i, index) => <th key={index}>{row[i]}</th>)}
          {handleEdit && <th><button onClick={() => handleEdit(row.id)}><BoxIcon><PencilIcon /></BoxIcon></button></th>}
          {handleModal && <th><button onClick={() => handleModal(row.id)}><BoxIcon><RemoveSvg /></BoxIcon></button></th>}
        </TableLine>
      })}
    </> : null
  }
  
  return (
    <div>
      {loading ? hasIconLoad && <BoxProgress><CircularProgress /></BoxProgress> : null}
      <>
        {(rows.length === 0 && !loading)
          ? <H3>Não há registro</H3>
          : <TableContainer>
            <thead>
              <tr>
                {columns.map((i: any, idx: number) => {
                  return <th key={idx} style={i.style}>
                    {i.nome}
                  </th>})
                }
                {handleEdit && <th style={{ width: 80 }}>Editar</th>}
                {handleModal && <th style={{ width: 80 }}>Excluir</th>}
              </tr>
            </thead>
            <tbody><TableBody /></tbody>
          </TableContainer>
        }
        {totalRows > 0 ? 
          <PaginationAPI
            count={totalRows}
            onPageChange={handleOnPageChangeApi}
            currentPage={currentPg}
          /> : null}

        { totalRows === 0 
        && rows.length >= itemsPerPage
          && <Pagination
            data={rows}
            pageNumberLimit={pageLimit}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handleOnPageChange}
          />
        }
      </>
    </div>
  )
}

const H3 = styled.h3`
  padding:40px 0;
  text-align:center;
`

const TableContainer = styled.table`
  border-collapse: collapse;
  font-size: 16px;
  width: 100%;
  color: #2C2A59;

  th {
      font-size: 16;
      height: 54px ;
      font-family: "MontSerrat"; 
  }

  svg{
    fill:currentColor;
  }
  
  a{
    color:${theme.COLORS.PRIM_800};
  }

`
const BoxProgress = styled.div`
  & > svg{ 
    width:18px;
    height:18px;
  }
`
export { TableGrid }