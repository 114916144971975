import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import FormControl from 'components/FormControl';
import { getServiceType } from '../service';
import { Row } from 'styles/base';
import { default as Modal } from 'components/modal/modal';
import Button from 'components/Button';
import { useFormEnterprise, usePlanos } from './useForm';
import { alpha, cepMask, cnpjMaskNew, cpfMask, name, number, phoneMask, replaceEspecials } from 'util/masks';

import { UploadFile } from 'components/UploadFile';
import { ButtonCancel, GridForm, TitleH2 } from './style';
import { MenuItem, TextField } from '@mui/material';

interface IPlanos {
  values?: any,
  setValues?: any,
  errorLabel?: any,
  disabled?: boolean,
  register?: any,
}
export const Planos = ({ values, setValues, errorLabel, disabled, register }: IPlanos) => {
  
  const { isLoading, error, data } = useQuery(['planosData'], () => 
    getServiceType(false)
      .then(res => res.data)
      .catch(error => console.log(error))
    , { staleTime: ((60 * 1000) * 60) * 24, cacheTime: 10 })

  const dadosSelect = isLoading ? [{ idPlanoMensalidade:0, nome: 'aguarde ...' }] : data

  return <>
    <TitleH2 start="true">TIPO DE PLANO</TitleH2>
    <FormControl
      className='required'
      title='Selecione um plano'
      selectServiceType
      id="tipo-plano"
      text=""
      options={dadosSelect}
      value={values}
      placeholder="Selecione"
      style={{ width: 300 }}
      onChange={({ target }) => setValues(target.value)
        // setValues((prevState: any) => ({
        //   ...prevState,
        //   idPlanoSelecionado: target.value
        // }))
      }
      disabled={disabled}
    />
    {/* <LabelError>{errorLabel}</LabelError> */}
  </>
}

interface IEnterpriseForm {
}
const EnterpriseForm = ({ }: IEnterpriseForm) => {

  const { register, handleSubmit, errors,
    values, setValues, defaultValues, defaultValuesError, open,
    errorValues, setErrorValues, cepInput, setCepInput,
    emailConfirmation, setEmailConfirmation, disabledEnd,
    handleModal, handleCancel, handleSave, checkCep, resetEnd } = useFormEnterprise()

  const { isLoading, listPlanos } = usePlanos()

  useEffect(() => {
    setValues(defaultValues)
    setErrorValues(defaultValuesError)
  }, [])

  return <>
    <Modal
      text="Deseja realmente sair? Os dados não salvos serão perdidos"
      open={open}
      onClose={handleModal}
      onYes={handleCancel}
    />
    <form onSubmit={handleSubmit(handleSave)}>
      {/* <Planos values={values} setValues={setValues} errorLabel={errorValues.idPlanoSelecionado} /> */}

      <TitleH2 start='true'>TIPO DE PLANO</TitleH2>
      <GridForm columns={1}>
        <TextField
          label=""
          // variant='standard'
          select
          inputProps={{ ...register("planos") }}
          style={{ width: 180, }}
          defaultValue={values.idPlanoSelecionado}
          value={values.idPlanoSelecionado}
          onChange={({ target }) => setValues((prevState: any) => ({
            ...prevState,
            idPlanoSelecionado: target.value
          })
          )}
          error={!!errors.planos}
          helperText={!!errors.planos && errors.planos?.message}
        >
          <MenuItem value={0}>Selecione</MenuItem>
          {listPlanos && listPlanos.map((i: any) => (
            <MenuItem key={i.nome} value={i.idPlanoMensalidade}>
              {i.nome}
            </MenuItem>
          ))}
        </TextField>
      </GridForm>

      <TitleH2>DADOS DA EMPRESA</TitleH2>
      <GridForm columns={3}>
        <TextField
          {...register("nomeEmpresa")}
          autoComplete='off'
          value={values.dadosEmpresa.razaoSocial}
          onChange={({ target }) => setValues({
            ...values,
            dadosEmpresa: {
              ...values.dadosEmpresa,
              razaoSocial: target.value,
            },
          })}
          label="Nome da empresa"
          variant='standard'
          error={!!errors.nomeEmpresa}
          helperText={!!errors.nomeEmpresa && errors.nomeEmpresa?.message}
        />

        <TextField
          {...register("cnpj")}
          autoComplete='off'
          value={values.dadosEmpresa.cnpj}
          onChange={({ target }) => setValues({
            ...values,
            dadosEmpresa: {
              ...values.dadosEmpresa,
              cnpj: cnpjMaskNew(target.value),
            },
          })}
          inputProps={{ maxLength: 18 }}
          label="CNPJ"
          variant='standard'
          error={!!errors.cnpj}
          helperText={!!errors.cnpj && errors.cnpj?.message}
        />

        <TextField
          {...register("nomeEmpresaAPP")}
          autoComplete='off'
          label="Nome da empresa (APP)"
          variant='standard'
          value={values.dadosEmpresa.nomeFantasia}
          onChange={({ target }) => setValues({
            ...values,
            dadosEmpresa: {
              ...values.dadosEmpresa,
              nomeFantasia: target.value,
            },
          })}
          error={!!errors.nomeEmpresaAPP}
          helperText={!!errors.nomeEmpresaAPP && errors.nomeEmpresaAPP?.message}
        />
      </GridForm>

      <TitleH2>DADOS DO RESPONSÁVEL</TitleH2>
      <GridForm columns={3}>
        <TextField
          {...register("nomeDiretor")}
          autoComplete='off'
          label="Nome"
          variant='standard'
          value={values.dadosDiretor.nome}
          onChange={({ target }) => setValues({
            ...values,
            dadosDiretor: {
              ...values.dadosDiretor,
              nome: name(target.value),
            },
          })}
          error={!!errors.nomeDiretor}
          helperText={!!errors.nomeDiretor && errors.nomeDiretor?.message}
        />

        <TextField
          {...register("cpfDiretor")}
          autoComplete='off'
          label="CPF"
          variant='standard'
          value={values.dadosDiretor.cpf}
          onChange={({ target }) => setValues({
            ...values,
            dadosDiretor: {
              ...values.dadosDiretor,
              cpf: cpfMask(target.value),
            },
          })}
          error={!!errors.cpfDiretor}
          helperText={!!errors.cpfDiretor && errors.cpfDiretor?.message}
        />

        <TextField
          {...register("telDiretor")}
          autoComplete='off'
          label="Telefone/Celular"
          placeholder='(99) 9999-9999'
          variant='standard'
          value={values.dadosDiretor.telefone}
          onChange={({ target }) => setValues({
            ...values,
            dadosDiretor: {
              ...values.dadosDiretor,
              telefone: phoneMask(target.value),
            },
          })}
          error={!!errors.telDiretor}
          helperText={!!errors.telDiretor && errors.telDiretor?.message}
        />
      </GridForm>
      <GridForm columns={2}>
        <TextField
          {...register("emailDiretor")}
          // autoComplete='off'
          label="E-mail"
          variant='standard'
          inputProps={{ maxLength: 50 }}
          value={values.dadosDiretor.email}
          onChange={({ target }) => setValues({
            ...values,
            dadosDiretor: {
              ...values.dadosDiretor,
              email: target.value,
            },
          })}
          error={!!errors.emailDiretor}
          helperText={!!errors.emailDiretor && errors.emailDiretor?.message}
        />
        <TextField
          {...register("emailConfirmacao")}
          autoComplete='off'
          label="Confirmar o e-mail"
          variant='standard'
          inputProps={{ maxLength: 50 }}
          value={emailConfirmation}
          onChange={({ target }) => setEmailConfirmation(target.value)}
          error={!!errors.emailConfirmacao}
          helperText={!!errors.emailConfirmacao && errors.emailConfirmacao?.message}
        />
      </GridForm>


      <TitleH2>LOGO PARA O APLICATIVO</TitleH2>
      <Row>
        <UploadFile 
        values={values} 
        setValues={setValues} 
        // errorLabel={errorValues.logo} 
        />
      </Row>

      <TitleH2>DADOS DA LOJA</TitleH2>
      <GridForm columns={4}>
        <TextField
          {...register("nomeUnidade")}
          autoComplete='off'
          label="Nome da unidade"
          variant='standard'
          inputProps={{ maxLength: 50 }}
          value={values.dadosUnidadePrincipal.nomeUnidade}
          onChange={({ target }) => setValues({
            ...values,
            dadosUnidadePrincipal: {
              ...values.dadosUnidadePrincipal,
              nomeUnidade: alpha(target.value),
            },
          })}
          error={!!errors.nomeUnidade}
          helperText={!!errors.nomeUnidade && errors.nomeUnidade?.message}
        />

        <TextField
          {...register("cep")}
          autoComplete='off'
          label="CEP"
          variant='standard'
          inputProps={{ maxLength: 9 }}
          value={cepInput}
          onChange={({ target }) => {
            setCepInput(cepMask(target.value))
            if (target.value.length === 8 || target.value.length === 9) {
              checkCep(replaceEspecials(target.value));
            } else {
              resetEnd()
            }
          }}
          error={!!errors.cep || !!errorValues.dadosUnidadePrincipal.endereco.cep}
          helperText={errorValues.dadosUnidadePrincipal.endereco.cep || (!!errors.cep && errors.cep?.message)}
        />

        <TextField
          {...register("logradouro")}
          autoComplete='off'
          label="Logradouro"
          variant='standard'
          inputProps={{ maxLength: 50 }}
          value={values.dadosUnidadePrincipal.endereco.endereco}
          onChange={({ target }) => setValues({
            ...values,
            dadosUnidadePrincipal: {
              ...values.dadosUnidadePrincipal,
              endereco: {
                ...values.dadosUnidadePrincipal.endereco,
                endereco: alpha(target.value),
              },
            },
          })}
          error={!!errors.logradouro}
          helperText={!!errors.logradouro && errors.logradouro?.message}
          disabled={disabledEnd.endereco}
        />

        <TextField
          {...register("numero")}
          autoComplete='off'
          label="Número"
          variant='standard'
          inputProps={{ maxLength: 50 }}
          value={values.dadosUnidadePrincipal.endereco.numero}
          onChange={({ target }) => setValues({
            ...values,
            dadosUnidadePrincipal: {
              ...values.dadosUnidadePrincipal,
              endereco: {
                ...values.dadosUnidadePrincipal.endereco,
                numero: number(target.value),
              },
            },
          })}
          error={!!errors.numero}
          helperText={!!errors.numero && errors.numero?.message}
        />
      </GridForm>
      <GridForm columns={4}>
        <TextField
          {...register("complemento")}
          autoComplete='off'
          label="Complemento"
          variant='standard'
          inputProps={{ maxLength: 50 }}
          value={values.dadosUnidadePrincipal.endereco.complemento}
          onChange={({ target }) => setValues({
            ...values,
            dadosUnidadePrincipal: {
              ...values.dadosUnidadePrincipal,
              endereco: {
                ...values.dadosUnidadePrincipal.endereco,
                complemento: alpha(target.value),
              },
            },
          })}
          error={!!errors.complemento}
          helperText={!!errors.complemento && errors.complemento?.message}
        />

        <TextField
          {...register("bairro")}
          autoComplete='off'
          label="Bairro"
          variant='standard'
          inputProps={{ maxLength: 50 }}
          value={values.dadosUnidadePrincipal.endereco.bairro}
          onChange={({ target }) => setValues({
            ...values,
            dadosUnidadePrincipal: {
              ...values.dadosUnidadePrincipal,
              endereco: {
                ...values.dadosUnidadePrincipal.endereco,
                bairro: alpha(target.value),
              },
            },
          })}
          error={!!errors.bairro}
          helperText={!!errors.bairro && errors.bairro?.message}
          disabled={disabledEnd.bairro}
        />

        <TextField
          {...register("cidade")}
          autoComplete='off'
          label="Cidade"
          variant='standard'
          inputProps={{ maxLength: 50 }}
          value={values.dadosUnidadePrincipal.endereco.cidade}
          onChange={({ target }) => setValues({
            ...values,
            dadosUnidadePrincipal: {
              ...values.dadosUnidadePrincipal,
              endereco: {
                ...values.dadosUnidadePrincipal.endereco,
                cidade: alpha(target.value),
              },
            },
          })}
          error={!!errors.cidade}
          helperText={!!errors.cidade && errors.cidade?.message}
          disabled={disabledEnd.cidade}
        />

        <TextField
          {...register("estado")}
          autoComplete='off'
          label="Estado"
          variant='standard'
          inputProps={{ maxLength: 50 }}
          value={values.dadosUnidadePrincipal.endereco.uf}
          onChange={({ target }) => setValues({
            ...values,
            dadosUnidadePrincipal: {
              ...values.dadosUnidadePrincipal,
              endereco: {
                ...values.dadosUnidadePrincipal.endereco,
                uf: alpha(target.value),
              },
            },
          })}
          error={!!errors.estado}
          helperText={!!errors.estado && errors.estado?.message}
          disabled={disabledEnd.uf}
        />
      </GridForm>

      <GridForm end="true" buttons>
        <ButtonCancel
          type="reset"
          onClick={() => handleModal()} >
          Cancelar
        </ButtonCancel>

        <Button
          loginSubmit
          text="Cadastrar Empresa"
        // loading={loading}
        // onClick={() => handleSave()}
        />
        <div>
        </div>
      </GridForm>
    </form>
  </>
}

export { EnterpriseForm }