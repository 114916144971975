import Button from "components/Button"
import FormControl from "components/FormControl"
import styled from "styled-components"
import { usePayment } from "./usePayment"
import ImgFlag from 'assets/images/flags.jpg';
import ModalDialog from "components/modal/modal"
import { useEffect } from "react"
import { moneyMask } from "util/masks";
import { IAccession, IPlansList } from "../services/types";
import theme from "styles/theme";
import { CircularProgress } from "@mui/material";
import { Label } from "styles/base";

const BoxInfo = () => {
  return <BoxPaymentInfos>
    Ops! Não existe dados de cartão de crédito
  </BoxPaymentInfos>
}

interface IDataPayment{
  planSelected?: IPlansList, 
  planCurrent?: IAccession, 
  isEdit?: boolean, 
  onClick?: any, 
  isControl?: boolean,
  isSuccess?: any,
  isError?: any,
  info?: boolean,
  isUpdateMigrate?: boolean,
  hasSinglePayment?: boolean,
  isMigrateFree?: boolean,
}

export const DataPayment = ({ 
  planSelected, planCurrent, isEdit = false, 
  onClick, isControl = true, isSuccess, hasSinglePayment = false,
  isError, info = true, isUpdateMigrate = false, isMigrateFree = true}: IDataPayment) => {

  const { values, load, loadCard, edit, setEdit, error, handleSubmit, handleReset, styleFlag,
    handleNomeCartao, handleNumeroCartao, handleValidadeCartao, handleCvvCartao,
    modal, handleModal, handleCancel, numberCard, limit, handleUpdate, isBoxSuccess, setIsBoxSuccess,
    valuesData, setvaluesData, handleMigratePlans, setValuesSelect, singlePayment } = usePayment()
  
  useEffect(() => {
    isEdit ? setEdit(true) : setEdit(false)
    
    //PLANCURRENT - certifica que não existe adesão 
    if(planCurrent !== undefined 
      && (planSelected !== undefined 
      && planCurrent?.plano?.codigoPlano !== 'PL00')){
      handleMigratePlans({ planCurrent, planSelected })
    }

    setvaluesData(planCurrent)
    setValuesSelect(planSelected)
  }, [isEdit, planSelected, planCurrent])


  useEffect(() => {
    if(planCurrent?.id === undefined){
      if(values.nome !== ''
        && values.numero  !== ''
        && values.vencimento  !== ''
        && values.cvv !== ''){
          isSuccess(true)
        }else{
          isSuccess(false)
        }
    }
  },[values])

  
  useEffect(() => {
    if(error.geral){
      const clearError = setTimeout(() => {
        isError('')
      }, 3000)
    
      isError(error.geral)
      clearError

      return  () => clearTimeout(clearError)
    }
  },[error])

  const handleSinglePayment = async (isMigrateFree : boolean) => {
    const resp = await singlePayment(isMigrateFree)

    if(resp?.isSuccess){
      isSuccess(true)
    }else{
      isError(true)
    }
  }

  return isBoxSuccess ? <RowSuccess>
    <Label size="16" bold>Atualização realizada com sucesso!</Label>
    <Button confirm text="ok" onClick={() => setIsBoxSuccess(!isBoxSuccess)} />
  </RowSuccess>
  :<>
    <ModalDialog
      text={modal.msg}
      open={modal.open}
      onClose={handleModal}
      onYes={() => {
        //!planCurrent?.id && isEdit ? onClick() : handleCancel()
        handleCancel()
        onClick()
      }}
    />

    {!edit
      ? <>
        {!valuesData?.id 
        ? <BoxInfo />
        : <BoxPaymentInfos>
          <div>
            <b>Tipo:</b> Cartão de Crédito<br />
            <b>Bandeira:</b> {valuesData?.cartao?.bandeira} com final {numberCard(valuesData?.cartao?.numeroCartao || '')}<br />
            <b>Validade:</b> {`${("0" + valuesData?.cartao?.mesValidade).slice(-2)}/${valuesData?.cartao?.anoValidade}`} <br />
            <b>Mensalidade:</b> {moneyMask(`${(valuesData?.plano?.valor || 0) * 100}`)}
          </div>
        </BoxPaymentInfos>
        }
      </>
      : <>
        {info && <>
          <h4>Será cobrado neste cartão:</h4>
          <p>{planSelected?.valor} + {planSelected?.transacao.replace('/', ' por ')} adicional (quando utilizado)</p><br />

          <p>As transações excedentes a franquia (soma das transações de todas as lojas cadastradas na empresa) utilizadas no mês, serão cobradas somadas ao valor da mensalidade do mês seguinte.</p>
        </>}

        <BosPaymentCard>
          <div>
            <FormControl
              id="nome"
              text="Nome impresso no cartão"
              size={28}
              maxLength={40}
              value={values.nome}
              onChange={({ target }: any) => handleNomeCartao(target.value)}
            />
            {error.nome && <small>{error.nome}</small>}
          </div>

          <BoxNumberCard>
            <FormControl
              id="nome"
              text="Número do cartão"
              size={28}
              maxLength={limit.numero}
              value={values.numero}
              onChange={({ target }: any) => handleNumeroCartao(target.value)}
            />
            {loadCard && <BoxLoad><CircularProgress /></BoxLoad>}
            <Flags className={styleFlag} />
            {error.numero && <small>{error.numero}</small>}
          </BoxNumberCard>

          <div>
            <FormControl
              id="validade"
              text="Validade"
              size={7}
              maxLength={7}
              value={values.vencimento}
              onChange={({ target }: any) => handleValidadeCartao(target.value)}
            />
            {error.vencimento && <small>{error.vencimento}</small>}
          </div>

          <div>
            <FormControl
              id="cvv"
              text="CVV"
              size={4}
              maxLength={limit.cvv}
              value={values.cvv}
              onChange={({ target }: any) => handleCvvCartao(target.value)}
            />
            {error.cvv && <small>{error.cvv}</small>}
          </div>
        </BosPaymentCard>
        {(load && !isControl) && <CircularProgress />}

        <BoxPaymentButtons style={{ display: !isControl ? 'none' : 'flex'  }}>
          <Button
            text="Cancelar"
            disabled={load}
            onClick={() => handleReset()} />

          {hasSinglePayment 
            ? <>
              <Button 
                id="save-card-single"
                confirm
                text="Salvar"
                loading={load}
                disabled={load}
                onClick={() => handleSinglePayment(isMigrateFree)} />
            </> 
            : <>
            { !planCurrent?.id 
              ? <Button 
                id="save-card"
                confirm
                text="Salvar"
                loading={load}
                disabled={load}
                onClick={() => handleSubmit()} />
              : <Button 
                id="save-card"
                confirm
                text="Atualizar"
                loading={load}
                disabled={load}
                onClick={() =>
                  isUpdateMigrate ? handleMigratePlans({ planCurrent, planSelected }) : handleUpdate()
                } />
            }
          </>}

          {/* <Button 
            id="save-card"
            confirm
            text="sucesso"
            onClick={() => setIsBoxSuccess(true)} /> */}
        </BoxPaymentButtons>
      </>}

      {(isControl && !edit)? <><br/><Button id="edit-card" text="Editar" onClick={() => setEdit(!edit)} /></>: null }
  </>
}

const RowSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
`

const BoxPaymentInfos = styled.div`
  display:flex;
  align-items: center;
  gap:34px;
  line-height: 160%;
`
const BosPaymentCard = styled.div`
  display:flex;
  padding:20px 0;
  gap:20px;

  small{
    font-size:0.7rem;
    color:${theme.COLORS.ERROR_700};
  }
`
const BoxPaymentButtons = styled.div`
  display:flex;
  gap:10px;
`

const BoxNumberCard = styled.div`
  position:relative;
`
const BoxLoad = styled.div`
  position: absolute;
  top: 55px;
  right: 90px;
  zoom: .5;
`
const Flags = styled.div`
  display:flex;
  width:71px;
  height:52px;
  background:url(${ImgFlag});
  background-position: 1px 30px;
  margin-top:30px;
  position: absolute;
  top: 30px;
  right: 10px;
  zoom: 0.45;

  &.visa{
    background-position: -7px -31px;
  }
  &.mastercard{
    background-position: -101px -31px;
  }
  &.hiper{
    background-position: -199px -31px;
  }
  &.elo{
    background-position: -292px -31px;
  }
  &.diners{
    background-position: -386px -31px;
  }
  &.hipercard{
    background-position: -479px -31px;
  }
  &.amex{
    background-position: -6px -96px;
  }
`