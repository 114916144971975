import styled from "styled-components";
import theme from "styles/theme";

type Props = {
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  width?: number | string;
  weight?: number;
};

export const Button = styled.button<Props>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor || theme.COLORS.SEC_800};
  outline: none;
  width: ${(props) => props.width || "unset"};
  color: ${theme.COLORS.PRIM_800};
  font-family: "MontSerrat" !important;
  font-size: 16px;
  font-weight: ${(props) => props.weight || "normal"};
  border: ${(props) =>
    props.backgroundColor ? `1px solid ${theme.COLORS.PRIM_800}` : "transparent"};
  border-radius: 4px;
  padding: 8px 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font: inherit;

  > span {
    line-height: 19px;
    font-size: 0.8em;
    text-align: center;
    color: ${theme.COLORS.PRIM_800};
    font-weight: bold;
  }

  :disabled{
    cursor:initial;
    opacity:.5;
  }
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 24;
  font-family: "MontSerrat";
  color: ${theme.COLORS.PRIM_800};
  line-height: 20px;
`;
