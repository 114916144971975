import Button from "components/Button";
import {
  AppBody,
  Column,
  DataWrapper,
  FileContainer,
  Label,
  Row,
  Spacing,
} from "styles/base";
import theme from "styles/theme";
import { useEffect, useState } from "react";
import FilePicker from "assets/icons/file-picker.svg";
import { ReactComponent as Drop } from "assets/icons/drop-arrow.svg";
import { ReactComponent as Check } from "assets/icons/check.svg";
import DataSubscriber from "./dataSubscriber/DataSubscriber";
import DataResponsible from "./dataResponsible/DataResponsible";
import DataSignature from "./dataSignature/DataSignature";
import api from "api";
import ParticipatingUnits from "./participatingUnits/ParticipatingUnits";
import { ErrorAlert } from "components/ErrorAlert";
import { ResponseUsuarioAtual } from "./model/ResponseUsuarioAtual";
import DigitalTerms from "./digitalTerms/DigitalTerms";
import TitlePage from "components/TitlePage";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Accordion } from "components/Accordion";
import { UploadFile } from "components/UploadFile";
import { DataMony } from "./dataMony";
import { DataPayment } from "./dataPayment";
import { BannerFirstAccess } from "./banner/BannerFirstAccess";
import { BannerExchangeOfPlan } from "./banner/BannerExchangeOfPlan";
import {
  getAccessionCurrent,
  getAccessionCurrentData,
  getPlanSeleced,
} from "./services";
import { scrollToTop } from "util/utils";
import { IAccession, IPlans } from "./services/types";
import { moneyMask } from "util/masks";
import { usePayment } from "./dataPayment/usePayment";
import styled from "styled-components";

const Account = () => {
  const [cnpj, setCpnj] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [deleteAccount, setDeleteAccount] = useState(false);

  const [userData, setUserData] = useState<ResponseUsuarioAtual.RootObject>(
    {} as ResponseUsuarioAtual.RootObject
  );

  const [load, setLoad] = useState(true);
  const [dataPlanUser, setDataPlanUser] = useState<any>();

  const navigate = useHistory();

  const userId = localStorage.getItem("@mony:usuarioId");
  // const { data: dataPlanUser, isLoading: loadPlanUser } :
  // { data: IAccession, isLoading: boolean } = getAccessionCurrent({ userId: `${userId}` })

  const {
    data: dataPlanSelected,
    isLoading: loadPlanSelected,
  }: { data: IPlans; isLoading: boolean } = getPlanSeleced({
    userId: `${userId}`,
  });

  const initData = async () => {
    try {
      // const respAccession = await
      getAccessionCurrentData()
        .then((resp) => setDataPlanUser(resp.data))
        .catch((e) => console.log("ERRO api", e))
        .finally(() => setLoad(false));

      const response = await api.get("/empresas/assinante");
      setCpnj(response.data.cnpj);

      const responseUserData = await api.get("/usuarios/atual");
      setUserData(responseUserData.data);
      return;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    initData();
  }, [deleteAccount]);

  const handleActiveTerm = async () => {
    if (dataPlanSelected?.cdPlano == "PL00") {
      setBtnDisabled(!btnDisabled);

      const formdata = new FormData();
      formdata.append("aceiteTermos", "true");
      try {
        await api.post("/adesao", formdata);
      } catch (error: any) {
        setBtnDisabled(!btnDisabled);
        console.log(error.response.data);
      }
      setBtnDisabled(!btnDisabled);
      navigate.push("/conta-validada");

      //@ts-ignore
      return document.querySelector("#save-card").click();
    }

    //@ts-ignore
    return document.querySelector("#save-card").click();
  };

  const handleError = (bool: boolean, message: string) => {
    if (bool) {
      scrollToTop({});
    }

    setError(bool);
    setErrorMessage(message);
  };

  // INICIO DAS RENDERIÇÃO CONDICIONAL
  // LOADING - CARREGANDO DADOS
  if (!userData.body || load) {
    return (
      <AppBody id="app-body">
        <TitlePage title="Minha Conta" />
        <DataWrapper>
          <CircularProgress />
        </DataWrapper>
      </AppBody>
    );
  }

  // DELETAR CONTA
  if (deleteAccount) {
    return (
      <AppBody id="app-body">
        <Spacing margin={8} />

        <TitlePage title={"Minha Conta"} />

        <DataWrapper center backgroundColor={`${theme.COLORS.PRIM_700}`}>
          <Row justify="center">
            <Label color={theme.COLORS.WHITE} bold>
              Você acaba de mudar para o plano Mony Grátis!
              {/* Um e-mail foi enviado à equipe responsável. Em breve entraremos em contato para entendermos melhor sua solicitação. */}
            </Label>
            <Check
              style={{
                width: 20,
                height: 20,
                alignSelf: "center",
                marginLeft: 5,
              }}
            />
          </Row>
        </DataWrapper>

        <Spacing margin={8} />

        <Row style={{ width: "95%", justifyContent: "center" }}>
          <Button confirm text="Ok" onClick={() => setDeleteAccount(false)} />
        </Row>
      </AppBody>
    );
  }

  // QUANDO O TERMO JÁ FOI ACEITO
  if (userData.body.termo) {
    return (
      <AppBody id="app-body">
        <TitlePage title={"Minha Conta"} />

        {(errorMessage || error) && (
          <>
            <ErrorAlert message={errorMessage} />
            <br />
          </>
        )}

        {(dataPlanUser?.plano.codigoPlano === undefined ||
          dataPlanUser?.planoVigente?.planoGratuito) && (
          <BannerExchangeOfPlan />
        )}

        <Accordion
          titulo="Dados de assinatura"
          isActive={true}
          isControl={false}
        >
          <DataSignature planCurrent={dataPlanUser} />
        </Accordion>

        {!dataPlanUser?.planoVigente?.planoGratuito && (
          <Accordion titulo="Dados de pagamento">
            <DataPayment
              planCurrent={dataPlanUser}
              isSuccess={(e: boolean) => null}
              isError={(e: string) => setErrorMessage(e)}
              info={false}
            />
          </Accordion>
        )}

        <Accordion titulo="Dados para o app Mony">
          <DataMony />
        </Accordion>

        <Accordion titulo="UNIDADES PARTICIPANTES">
          <ParticipatingUnits cnpj={cnpj} error={handleError} />
        </Accordion>

        <Accordion titulo="DADOS DO RESPONSÁVEL">
          <DataResponsible error={handleError} terms />
        </Accordion>

        <Accordion titulo="DADOS DO ASSINANTE">
          <DataSubscriber
            planCurrent={dataPlanUser}
            setDeleteAccount={setDeleteAccount}
            error={handleError}
            terms
          />
        </Accordion>

        <Accordion titulo="TERMOS DO ACEITE DIGITAL">
          <DigitalTerms terms />
        </Accordion>
      </AppBody>
    );
  }

  // PRIMEIRO ACESSO
  return (
    <AppBody id="app-body">
      <TitlePage title="Minha Conta" />

      {errorMessage && (
        <>
          <ErrorAlert message={errorMessage} /> <br />
        </>
      )}

      {dataPlanSelected?.cdPlano == "PL00" ? null : (
        <Accordion
          titulo="Dados de pagamento"
          isActive={true}
          isControl={false}
        >
          <DataPayment
            planSelected={{
              id: dataPlanSelected?.idPlanoMensalidade,
              nome: dataPlanSelected?.nome,
              transacao: `${moneyMask(
                `${dataPlanSelected?.custoTransacaoAdicional * 100}`
              )} transações/mês`,
              valor: `${moneyMask(`${dataPlanSelected?.valor * 100}`)}`,
              plano: "",
              quantidade: "",
              ativo: true,
              relatorios: [],
            }}
            isEdit={true}
            isControl={false}
            isSuccess={(e: boolean) => setBtnDisabled(e)}
            isError={(e: string) => setErrorMessage(e)}
          />
        </Accordion>
      )}

      <Accordion
        titulo="TERMOS DO ACEITE DIGITAL"
        isActive={true}
        isControl={false}
      >
        <>
          <DigitalTerms terms={true} />

          <BoxBtnAceite>
            <Button
              confirm
              text="Aceitar os termos e ativar conta"
              onClick={() => handleActiveTerm()}
              disabled={
                dataPlanSelected?.cdPlano == "PL00" ? btnDisabled : !btnDisabled
              }
            />
          </BoxBtnAceite>
        </>
      </Accordion>
    </AppBody>
  );
};

export default Account;

const BoxBtnAceite = styled.div`
  padding: 20px 0 0;
`;
