import React, { useEffect, useState } from 'react';
import { NumberFormatProps, NumberFormatValues, SourceInfo } from 'react-number-format';
import theme from 'styles/theme';

import { ReactComponent as EyeCrossed } from '../../assets/icons/eye-crossed.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';

import { Column } from '../../styles/base';
import { ShowPasswordButton } from '../Button/styles';

import {
  Box, FormLabel, ReactNumberFormat, ConatinerEye,
} from './styles';

interface Props extends NumberFormatProps {
  label: string;
  onValueChange(values: NumberFormatValues, sourceInfo: SourceInfo): void;
  placeholder?: string;
  constract?: boolean;
  lineInput?: boolean;
  light?: boolean;
  id?: string;
  disabled?:boolean;
}

export const InputNumberFormat = ({
  label, id, onValueChange, placeholder, format, constract, lineInput, value, light, defaultValue, type, maxLength, disabled, ...rest
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [defaultValueState, setDefaultValue] = useState(defaultValue)
  const [isFocused, setIsFocused] = useState(false);

  function handleInputFocus() {
    setIsFocused(true);
  }

  function handleInputBlur() {
    setIsFocused(false);
  }

  if (type === 'password') {
    return (
      <Column>
        <FormLabel light={light} isFocused={isFocused} lineInput={lineInput}>{label}</FormLabel>
        <Box>
          <ReactNumberFormat
            lineInput={lineInput}
            placeholder={placeholder}
            format={format}
            onFocus={() => handleInputFocus()}
            onBlurCapture={() => handleInputBlur()}
            onValueChange={onValueChange}
            type={!showPassword ? 'password' : 'text'}
            maxLength={maxLength}
            disabled={disabled}
          />

          {type === 'password' && (
            <ConatinerEye>
              <ShowPasswordButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword
                  ? (<EyeCrossed style={{ width: 22, height: 22, fill: theme.COLORS.WHITE }} />)
                  : (<Eye style={{ width: 22, height: 22, fill: theme.COLORS.WHITE }} />)}
              </ShowPasswordButton>
            </ConatinerEye>
          )}
        </Box>
      </Column>

    );
  }

  useEffect(() => {
    // console.log(value)
    if (defaultValue && defaultValue > 0) setDefaultValue(defaultValue)
  }, [defaultValue])

  return (
    <Column>
      <FormLabel isFocused={isFocused} lineInput={lineInput}>{label}</FormLabel>
      <Box>
        <ReactNumberFormat
          id={id}
          lineInput={lineInput}
          placeholder={placeholder}
          format={format}
          value={value}
          onFocus={() => handleInputFocus()}
          onBlurCapture={() => handleInputBlur()}
          onValueChange={onValueChange}
          type={type}
          style={{ fontFamily: 'Montserrat' }}
          maxLength={maxLength}
          disabled={disabled}
        />
      </Box>
    </Column>

  );
};
