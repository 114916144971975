import { useEffect, useMemo } from "react"
import moment from "moment";

import { AppBody, DataWrapper, TituloH2 } from "styles/base"
import TitlePage from "components/TitlePage"
import { useCreditsDetails } from "./useCredits"
import { TableGrid } from "components/TableGrid/TableGrid"
import { BoxBtns, BoxBtnsRelatorios, LinksBack } from "../styles"
import { scrollToTop, useQueryString } from "util/utils"
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom"
import Button from "components/Button";

interface IReportsDetails {

}

interface RouteParams {
  slug: "utilizados" | "distribuidos" | "expirados" | "expirar"
}

const ReportsDetails = ({ }: IReportsDetails) => {
  const { slug: slug_type } : RouteParams = useParams()
  const slug = slug_type

  const { loadDetails, FetchingDetails, setFilterValues,
  tableAllDetails, relatorioListDetails, pageCurrent, 
  setPageCurrent, setTypeConsult, handleClick } = useCreditsDetails()
  
  const query = useQueryString()
  
  const slugTitle : any = {
    "utilizados" : "Cashback Utilizados",
    "distribuidos" : "Cashback Distribuídos",
    "expridados" : "Cashback Expirados",
    "expirar" : "Cashback a Expirar",
  }

  const init = useMemo(() => {
    setTypeConsult(slug)

    setFilterValues({ 
      idInstancia: query.get('idInstancia'), 
      idUnidade: query.get('idUnidade'),
      periodo: query.get('periodo'),
      dataInicio: query.get('dataInicio'), 
      dataFim: query.get('dataFim'),
    })

    const pagina = parseInt(query.get('page') || "0") - 1
    setPageCurrent(pagina > 0 ? pagina : 0)
  }, [query])
  init

  useEffect(() => scrollToTop({}), [pageCurrent])

  const handlePage = (e : number) => setPageCurrent(e)

  const urlBack = `/home/relatorios?tipo=${slug}&idInstancia=${query.get('idInstancia')}&idUnidade=${query.get('idUnidade')}&periodo=${query.get("periodo")}&dataInicio=${moment(query.get('dataInicio')).format('YYYY-MM-DD')}&dataFim=${moment(query.get('dataFim')).format('YYYY-MM-DD')}&page=0`

  return <>
  <AppBody>
    
    <TitlePage title="Relatórios" />
    <DataWrapper>
      <TituloH2>{slugTitle[slug]}</TituloH2>

      {(loadDetails && relatorioListDetails.rows.length === 0) && <CircularProgress />}

      <TableGrid
        columns={tableAllDetails.head}
        rows={tableAllDetails.rows}
        // handleEdit={handleEdit}
        // loading={load}
        // hasIconLoad={false}
        />

      <div id="app-body"></div>  
      <TableGrid
        columns={relatorioListDetails.head}
        rows={relatorioListDetails.rows}
        // handleEdit={handleEdit}
        // hasIconLoad={false}
        loading={FetchingDetails}
        totalRows={relatorioListDetails.totalRows}
        goTo={handlePage}
        currentPg={pageCurrent}
        />

      <BoxBtnsRelatorios style={{ marginTop: relatorioListDetails.totalRows <= 10 ? '' : '-60px' }}>
        <LinksBack to={urlBack}>Voltar</LinksBack>

        <Button confirm text="Gerar relatório" 
          onClick={handleClick} 
          disabled={relatorioListDetails.totalRows < 1} />
      </BoxBtnsRelatorios>

    </DataWrapper>
  </AppBody>
  </>
}
export { ReportsDetails }