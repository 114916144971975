import React from 'react';

export function cep(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 9;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  e.currentTarget.value = value;
  return e;
}

export function currency(e: React.FormEvent<HTMLInputElement>, prefix = '') {
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

  e.currentTarget.value = value ? `${prefix} ${value}` : value;
  return e;
}

export function cpf(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 14;
  let { value } = e.currentTarget;
  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
    e.currentTarget.value = value;
  }
  return e;
}

export function telMask(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 10;
  let { value } = e.currentTarget;

  if (!value.match(/^(\d{5})-(\d{4})$/)) {
    value = value.replace(/\D/g, '');

    if (value && value.length === 10) {
      value = value.substring(0, value.length - 1);
    }

    value = value.replace(/(\d{5})(\d{4})/, '$1-$2');
    e.currentTarget.value = value;
  }
  return e;
}

export function numberOnly(e : any){
  const reg = /^[0-9\b]+$/
  let preval=e.target.value
  if (e.target.value === '' || reg.test(e.target.value)) return true
  else e.target.value = preval.substring(0,(preval.length-1))
}