import api from "api";
import Button from "components/Button";
import FormControl from "components/FormControl";
import { useEffect, useState } from "react";
import { Label, Row, Spacing } from "styles/base";
import theme from "styles/theme";
import { alpha, cepMask, replaceEspecials, number } from "util/masks";

import { styled } from '@mui/system';
import { CircularProgress } from "@mui/material"
import { BoxCep, LoadCep, SmallError } from "./style";

const defaultValues = {
  idUnidade: 0,
  nomeUnidade: '',
  cnpj: '',
  endereco: {
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
  }
};

const UnitForm = ({ data, reset, cnpj, hasControl = true }: 
  { data: any, reset?: any, cnpj?: string, hasControl?: boolean }) => {
  // Campos
  const nomeUnidade = (document.querySelector('#nomeUnidade') as HTMLInputElement);
  const cep = (document.querySelector('#cep') as HTMLInputElement);
  const logradouro = (document.querySelector('#logradouro') as HTMLInputElement);
  const complemento = (document.querySelector('#complemento') as HTMLInputElement);
  const cidade = (document.querySelector('#cidade') as HTMLInputElement);
  const numero = (document.querySelector('#numero') as HTMLInputElement);
  const estado = (document.querySelector('#estado') as HTMLInputElement);
  const bairro = (document.querySelector('#bairro') as HTMLInputElement);

  // Erros
  const nomeUnidadeErro = (document.querySelector('#nomeUnidade__erro') as HTMLInputElement);
  const cepErro = (document.querySelector('#cep__erro') as HTMLInputElement);
  const logradouroErro = (document.querySelector('#logradouro__erro') as HTMLInputElement);
  const cidadeErro = (document.querySelector('#cidade__erro') as HTMLInputElement);
  const numeroErro = (document.querySelector('#numero__erro') as HTMLInputElement);
  const estadoErro = (document.querySelector('#estado__erro') as HTMLInputElement);
  const bairroErro = (document.querySelector('#bairro__erro') as HTMLInputElement);

  
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCep, setIsLoadingCep] = useState(false)
  const [cepInput, setCepInput] = useState(data.endereco.cep || '')
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    setValues({
      idUnidade: data.idUnidade,
      nomeUnidade: data.nomeUnidade,
      cnpj: data.cnpj,
      endereco: {
        cep: data.endereco.cep ? cepMask(data.endereco.cep) : '',
        endereco: data.endereco.endereco,
        numero: data.endereco.numero,
        complemento: data.endereco.complemento || '',
        bairro: data.endereco.bairro,
        cidade: data.endereco.cidade,
        uf: data.endereco.uf,
      }
    });

    if( data.endereco.cep === undefined ||  data.endereco.cep === '' ){
      setCepInput('')
    }
  }, [data]);

  const checkCep = async (data: string) => {
    try {
      setIsLoadingCep(true)
      const response = await api.get(`/endereco/${data}`).finally(() => setIsLoadingCep(false));

      setValues({
        ...values,
        endereco: {
          ...values.endereco,
          cep: cepMask(response.data.exploded.zip),
          endereco: response.data.exploded.fullAddress,
          cidade: response.data.exploded.city.name,
          uf: response.data.exploded.state.shortName,
          bairro: response.data.recovered.recoveredAddress[0].quarterA,
        },
      })

      cep.style.borderColor = theme.COLORS.PRIM_800;
      cepErro.innerHTML = '';

      if (logradouro.value) {
        logradouro.setAttribute('disabled', 'true');
      } else {
        logradouro.removeAttribute('disabled')
      }

      if (bairro.value) {
        bairro.setAttribute('disabled', 'true');
      } else {
        bairro.removeAttribute('disabled')
      }

      if (cidade.value) {
        cidade.setAttribute('disabled', 'disabled');
      }

      if (estado.value) {
        estado.setAttribute('disabled', 'disabled');
      }

      numero.removeAttribute('disabled');
      complemento.removeAttribute('disabled');

      setIsLoadingCep(false)
      validationCancelEnd()
    } catch (error) {
      cep.style.borderColor = theme.COLORS.ERROR_800;
      cepErro.innerHTML = 'CEP inválido';

      logradouro.setAttribute('disabled', 'true');
      complemento.setAttribute('disabled', 'true');
      bairro.setAttribute('disabled', 'true');
      numero.setAttribute('disabled', 'true');
      cidade.setAttribute('disabled', 'true');
      estado.setAttribute('disabled', 'true');
    }
  };


  const validationCancelEnd = () => {
    cep.style.borderColor = theme.COLORS.PRIM_800;
    logradouro.style.borderColor = theme.COLORS.PRIM_800;
    cidade.style.borderColor = theme.COLORS.PRIM_800;
    numero.style.borderColor = theme.COLORS.PRIM_800;
    estado.style.borderColor = theme.COLORS.PRIM_800;
    bairro.style.borderColor = theme.COLORS.PRIM_800;

    cepErro.innerHTML = '';
    logradouroErro.innerHTML = '';
    cidadeErro.innerHTML = '';
    numeroErro.innerHTML = '';
    estadoErro.innerHTML = '';
    bairroErro.innerHTML = '';
  }
  const resetEnd = () => {
    setValues({
      ...values,
      endereco: {
        ...values.endereco,
        cep: '',
        endereco: '',
        numero: '',
        bairro: '',
        complemento: '',
        cidade: '',
        uf: '',
      },
    },
    );
  }

  const handleCancel = () => {
    nomeUnidade.style.borderColor = theme.COLORS.PRIM_800;
    cep.style.borderColor = theme.COLORS.PRIM_800;
    logradouro.style.borderColor = theme.COLORS.PRIM_800;
    cidade.style.borderColor = theme.COLORS.PRIM_800;
    numero.style.borderColor = theme.COLORS.PRIM_800;
    estado.style.borderColor = theme.COLORS.PRIM_800;
    bairro.style.borderColor = theme.COLORS.PRIM_800;

    nomeUnidadeErro.innerHTML = '';
    cepErro.innerHTML = ''
    logradouroErro.innerHTML = ''
    cidadeErro.innerHTML = ''
    numeroErro.innerHTML = ''
    estadoErro.innerHTML = ''
    bairroErro.innerHTML = ''

    logradouro.removeAttribute('disabled');
    cidade.removeAttribute('disabled');
    estado.removeAttribute('disabled');
    bairro.removeAttribute('disabled');

    setValues({
      idUnidade: data.idUnidade,
      nomeUnidade: data.nomeUnidade,
      cnpj: data.cnpj,
      endereco: {
        cep: data.endereco.cep && cepMask(data.endereco.cep),
        endereco: data.endereco.endereco,
        numero: data.endereco.numerro,
        complemento: data.endereco.complemento || '',
        bairro: data.endereco.bairro,
        cidade: data.endereco.cidade,
        uf: data.endereco.uf,
      }
    });

    reset();
  }

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!nomeUnidade.value) {
      nomeUnidade.style.borderColor = theme.COLORS.ERROR_800;
      nomeUnidadeErro.innerHTML = 'Nome da unidade é obrigatório';
    }

    if (cep.value.length !== 9) {
      cep.style.borderColor = theme.COLORS.ERROR_800;
      cepErro.innerHTML = 'CEP inválido';
    }

    if (!cep.value) {
      cep.style.borderColor = theme.COLORS.ERROR_800;
      cepErro.innerHTML = 'CEP é obrigatório';
    }

    if (!logradouro.value) {
      logradouro.style.borderColor = theme.COLORS.ERROR_800;
      logradouroErro.innerHTML = 'Logradouro é obrigatório';
    }

    if (!cidade.value) {
      cidade.style.borderColor = theme.COLORS.ERROR_800;
      cidadeErro.innerHTML = 'Cidade é obrigatório';
    }

    if (!numero.value) {
      numero.style.borderColor = theme.COLORS.ERROR_800;
      numeroErro.innerHTML = 'Número é obrigatório';
    } else {
      numero.style.borderColor = '';
      numeroErro.innerHTML = '';
    }

    if (!estado.value) {
      estado.style.borderColor = theme.COLORS.ERROR_800;
      estadoErro.innerHTML = 'Estado é obrigatório';
    }

    if (!bairro.value) {
      bairro.style.borderColor = theme.COLORS.ERROR_800;
      bairroErro.innerHTML = 'Bairro é obrigatório';
    }

    if (!numero.value) {
      numero.style.borderColor = theme.COLORS.ERROR_800;
      numeroErro.innerHTML = 'Número é obrigatório';
    }

    console.log('bairro', bairro.value)

    if (!nomeUnidade.value
      || !cep.value
      || !logradouro.value
      || !cidade.value
      || !estado.value
      || !bairro.value
      || !numero.value
    ) {
      setIsLoading(false);
      return;
    }

    if (values.idUnidade === 1.1) {
      try {
        await api.post('/unidades', {
          nomeUnidade: values.nomeUnidade,
          cnpj: cnpj,
          endereco: {
            cep: values.endereco.cep,
            endereco: values.endereco.endereco,
            numero: values.endereco.numero,
            complemento: values.endereco.complemento,
            bairro: values.endereco.bairro,
            cidade: values.endereco.cidade,
            uf: values.endereco.uf,
          }
        });

      } catch (error: any) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      try {
        await api.put('/unidades', values);

      } catch (error: any) {
        console.log(error);
        setIsLoading(false);
      }
    }

    setIsLoading(false);

    reset();
  }

  return (
    <>
      <RowLine3>
        <div>
          <FormControl
            id="nomeUnidade"
            text="Nome da unidade"
            value={values.nomeUnidade}
            size={32}
            onChange={({ target }) => setValues({
              ...values,
              nomeUnidade: target.value,
            })}
            maxLength={40}
          />

          <SmallError id="nomeUnidade__erro"></SmallError>
        </div>

        <BoxCep>
          <FormControl
            id="cep"
            text="CEP"
            value={cepInput}
            size={10}
            onChange={({ target }) => {
              setCepInput(cepMask(target.value))

              if (target.value.length === 8 || target.value.length === 9) {
                checkCep(replaceEspecials(target.value));
              } else {
                resetEnd()
              }
            }}
            maxLength={9}
          />
          {isLoadingCep && <LoadCep><CircularProgress /></LoadCep>}
          <SmallError id="cep__erro"></SmallError>
        </BoxCep>

        <div>
          <FormControl
            id="logradouro"
            text="Logradouro"
            value={values.endereco.endereco}
            size={50}
            onChange={({ target }) => setValues({
              ...values,
              endereco: {
                ...values.endereco,
                endereco: alpha(target.value),
              },
            })}
            maxLength={50}
          />

          <SmallError id="logradouro__erro"></SmallError>
        </div>

        <div>
          <FormControl
            id="numero"
            text="Número"
            value={values.endereco.numero}
            size={6}
            onChange={({ target }) => setValues({
              ...values,
              endereco: {
                ...values.endereco,
                numero: number(target.value),
              },
            },
            )}
            maxLength={6}
          />

          <SmallError id="numero__erro"></SmallError>
        </div>
      </RowLine3>

      <Spacing margin={18} />

      <RowLine4>
        <div>
          <FormControl
            id="complemento"
            text="Complemento*"
            value={values.endereco.complemento}
            size={12}
            onChange={({ target }) => setValues({
              ...values,
              endereco: {
                ...values.endereco,
                complemento: alpha(target.value),
              },
            })}
            maxLength={15}
          />
        </div>

        <div>
          <FormControl
            id="bairro"
            text="Bairro"
            value={values.endereco.bairro}
            size={36}
            onChange={({ target }) => setValues({
              ...values,
              endereco: {
                ...values.endereco,
                bairro: alpha(target.value),
              },
            })}
          />

          <SmallError id="bairro__erro"></SmallError>
        </div>

        <div>
          <FormControl
            id="cidade"
            text="Cidade"
            value={values.endereco.cidade}
            size={12}
            onChange={({ target }) => setValues({
              ...values,
              endereco: {
                ...values.endereco,
                cidade: alpha(target.value),
              },
            })}
          />

          <SmallError id="cidade__erro"></SmallError>
        </div>

        <div>
          <FormControl
            id="estado"
            text="Estado"
            value={values.endereco.uf}
            size={12}
            onChange={({ target }) => setValues({
              ...values,
              endereco: {
                ...values.endereco,
                uf: alpha(target.value),
              },
            },
            )}
          />

          <SmallError id="estado__erro"></SmallError>
        </div>
      </RowLine4>

      <Spacing margin={8} />

      <Label>*opcional</Label>

      {hasControl && <>
        <Spacing margin={18} />
        <Row>
          <Row>
            <Button text="Cancelar" onClick={() => handleCancel()} />

            <Spacing margin={6} />

            <Button confirm text="Salvar" loading={isLoading} onClick={() => handleSubmit()} />
          </Row>
        </Row>
      </>}
    </>
  )
};

const RowLine1 = styled(Row)({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, auto) minmax(200px, 270px) minmax(210px, 220px)',
  gap: 16,

  ['@media (max-width:1200px)']: {
    gridTemplateColumns: '1fr',
  }
});
const RowLine2 = styled(Row)({
  display: 'grid',
  gridTemplateColumns: '1fr 210px 210px',
  gap: 16,
  "& > div > div *": {
    width: '100%',
  },
  ['@media (max-width:1200px)']: {
    gridTemplateColumns: '1fr',
  }
});
const RowLine3 = styled(Row)({
  display: 'grid',
  gridTemplateColumns: 'minmax(230px, 1fr) 150px minmax(150px, 300px) minmax(100px, 180px)',
  gap: 16,
  ['@media (max-width:1200px)']: {
    gridTemplateColumns: '1fr',
  }
});
const RowLine4 = styled(Row)({
  display: 'grid',
  gridTemplateColumns: '1fr minmax(150px, 1fr) minmax(150px, 1fr) 1fr',
  gap: 16,
  ['@media (max-width:1200px)']: {
    gridTemplateColumns: '1fr',
  }
});

export default UnitForm;
