import { CircularProgress, MenuItem, TextField } from '@mui/material';
import Button from 'components/Button';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppBody, DataWrapper, Label, Spacing } from "styles/base"
import theme from 'styles/theme';
import { IUnidSelect, useFormUser, useQueryString } from './useFormUser';
import { default as Modal } from 'components/modal/modal'
import { cpfMask, phoneMask } from 'util/masks';
import { UserContext } from '../useUser';
import { useContext, useEffect } from 'react';
import { normalizeResponsibility,capitalize } from 'util/utils';
import { ErrorAlert } from 'components/ErrorAlert';
import TitlePage from 'components/TitlePage';

interface IParans {
  id?: string,
  funcao?: string,
}

const FormUser = () => {
  const { unidades, unid, modal, values, setValues, setEditUser, setFuncao,
    handleUnidade, handleModal, handleCancel, handleSave,
    register, handleSubmit, errors, load, errorForm
  } = useFormUser()

  const query = useQueryString()
  const parans = useParams<IParans>();

  const id = parans?.id
  const funcao = query.get('funcao')?.toLocaleLowerCase()

  const { userEdit } = useContext(UserContext)
  
  useEffect(() => {
    !funcao && setEditUser(userEdit)

    !!funcao && setFuncao(funcao.toUpperCase()) // Função a ser cadastrada, serve para definir qual schema será usado
  }, [userEdit])

  const usePhoneFuncao = () => {
    return funcao?.toUpperCase() === 'DIRETOR'
      || userEdit?.funcao.toUpperCase() === 'DIRETOR'
      || values?.funcao.toUpperCase() === 'DIRETOR'
      || values?.funcao.toUpperCase() === 'MASTER'
  }

  return <AppBody>
    <div id="app-body" ></div>
    <Modal
      text={modal.msg}
      open={modal.open}
      onClose={handleModal}
      onYes={handleCancel}
    />

    <TitlePage title="Gerenciar Usuários" />

    {!!values.id
      ? <Label size="24" bold color="white">Alteração dos dados do {capitalize(normalizeResponsibility(funcao || values.funcao))}</Label>
      : <Label size="24" bold color="white">Insira dos dados do {capitalize(normalizeResponsibility(funcao || values.funcao))}</Label>
    }
    <Spacing margin={16} />

    {errorForm.msg !== '' && <><ErrorAlert message={errorForm.msg} error={errorForm.error}/> <br/></>}
    
    <DataWrapper>
      <Form onSubmit={handleSubmit(handleSave)}>
        <Row>
          <TextField
            autoComplete='off'
            {...register("nome")} 
            value={values.nome}
            onChange={(e) => setValues({ ...values, nome: e.target.value })}
            label="Nome"
            variant='standard'
            error={!!errors.nome}
            helperText={!!errors.nome && errors.nome?.message}
          />

          <TextField
            autoComplete='off'
            {...register("cpf")}
            value={values.cpf}
            onChange={(e) => setValues({ ...values, cpf: cpfMask(e.target.value) })}
            label="CPF"
            className='cpf'
            variant='standard'
            error={!!errors.cpf}
            helperText={!!errors.cpf && errors.cpf?.message}
          />

          <TextField
            autoComplete='off'
            {...register("email")}
            value={values.email}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
            label="E-mail"
            variant='standard'
            error={!!errors.email}
            helperText={!!errors.email && errors.email?.message}
          />
        </Row>

        <RowLine>
          <TextField
            autoComplete='off'
            {...register("telefone")}
            value={values.telefone}
            onChange={(e) => setValues({ ...values, telefone: phoneMask(e.target.value) })}
            label="Telefone/Celular"
            variant='standard'
            // disabled={userEdit?.id !== undefined ? true : false}
            error={!!errors.telefone}
            helperText={!!errors.telefone && errors.telefone?.message}
          />

          {unidades.length > 0 ? <>
            <TextField
              label="Unidade" variant='standard'
              select
              inputProps={{ ...register("unidade") }}
              defaultValue=""
              value={values.idUnidade || unid}
              onChange={handleUnidade}
              error={!!errors.unidade}
              helperText={!!errors.unidade && errors.unidade?.message}
            >
              <MenuItem key={'empty'} value="">Selecione</MenuItem>
              {unidades && unidades.map((i : IUnidSelect) => (
                <MenuItem key={i.id} value={`${i.id}`}>
                  {i.nome}
                </MenuItem>
              ))}
            </TextField>
          </> : null}
        </RowLine>

        <RowActions>
          <ButtonCancel onClick={handleModal}>Cancelar</ButtonCancel>
          <ButtonSave><Button text='Salvar' disabled={load} /></ButtonSave>
          {load && <CircularProgress style={{ width:35, height:35 }} />}
        </RowActions>
      </Form>

    </DataWrapper>
  </AppBody>
}
const Form = styled.form`
  div,
  label,
  input{
    font-family:${theme.FONTS.MONTSERRAT} !important;
  }
  div,
  input{
    font-weight:600;
    color:${theme.COLORS.PRIM_800}
  }
`
const Row = styled.div`
  display:flex;
  gap:10px;
  padding:15px 0;

  & > div{
    display: flex;
    flex:auto;

    &.cpf{
      min-width:300px;
      flex:1;
    }
  }
`
const RowLine = styled(Row)`
  & > div{
    width:300px;
    flex:initial;
  }
`
const RowActions = styled(Row)`
  display:grid;
  grid-template-columns: auto auto 1fr ;
`
const ButtonSave = styled.div`
  display:flex;

  & > button{
    background: ${theme.COLORS.SEC_800};
    border:1px solid ${theme.COLORS.SEC_800};
  }

`
const ButtonCancel = styled.div`
    background: transparent;
    display:flex;
    outline: none;
    font-size:.8rem;
    font-weight:bold;
    color:${theme.COLORS.PRIM_800};
    font-family: ${theme.FONTS.MONTSERRAT};
    border: 1px solid ${theme.COLORS.PRIM_800};
    border-radius: 6px;
    padding: 8px 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default FormUser