import { useRef } from 'react';
import { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import Button from '../../../../components/Button';
import { InputMask } from '../../../../components/InputMask';
import { Form } from './style';

export interface IFormInput {
  ddd: string;
  telefone: string;
}

type Props = {
  onSubmit(data: IFormInput): void;
  register: UseFormRegister<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  loading: boolean;
  disabledForm?: boolean;
  clearErrors(): void;
}

export function FormClient({
  onSubmit, handleSubmit, register, loading, disabledForm, clearErrors
}: Props) {
  const ref = useRef(null);

  const jumpEvent = (fieldOut: string, fieldIn: string) => {
    if ((document.querySelector(fieldOut) as HTMLInputElement)
      .value.length >= (document.querySelector(fieldOut) as HTMLInputElement).maxLength) {
      (document.querySelector(fieldIn) as HTMLInputElement).focus();
    }
  };

  const handleEnter = (e : any) => {
    if(e.key === 'Enter'){
      jumpEvent('#celular', '#buscar')
    }
  }
  const handleDDD = (e : any) => {
    const value = e.target.value
   
    if(value.length === 2){
      jumpEvent('#input-ddd', '#celular')
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => handleEnter(e)}>
      <div ref={ref}>
        <InputMask disabled={disabledForm} label="DDD" clearErrors={clearErrors} id="input-ddd" maxLength={2} placeholder="99" mask="num" onChange={handleDDD} size={1} style={{ textAlign: 'right' }} register={register} registerField="ddd" />
      </div>
      <InputMask disabled={disabledForm} label="Celular" clearErrors={clearErrors} id="celular" maxLength={10} placeholder="99999-9999" mask="cel" register={register} registerField="telefone" />
      <div>
        <Button disabled={disabledForm} text="Buscar" id="buscar" loading={loading} />
      </div>
    </Form>
  );
}
