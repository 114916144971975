/* eslint-disable-next-line react/require-default-props */

import ReactLoading from 'react-loading';
import {  ConfirmButton, FormButton, LinkButton, LoginButton, MenuButton, } from './styles';
import { ReactComponent as EyeSvg } from '../../assets/icons/eye.svg';
import { ReactComponent as DropSvg } from '../../assets/icons/drop.svg';
import { ReactComponent as PencilSvg } from '../../assets/icons/pencilIcon.svg';
import { Spacing } from '../../styles/base';
import theme from 'styles/theme';

interface Props {
    loginSubmit?: boolean,
    confirm?: boolean,
    account?: boolean,
    menu?: boolean,
    text: string,
    disabled?: boolean,
    link?: boolean,
    onClick?(): any, // Trocar any type
    loading?: boolean;
    style?: {};
    id?: string,
}

const defaultProps = {
  loginSubmit: false,
  confirm: false,
  account: false,
  menu: false,
  disabled: false,
  link: false,
  loading: false,
};

const Button = ({
  loginSubmit, confirm, account, text, onClick, disabled, menu, loading, link, style, id
}: Props) => {
  if (link) {
    return (
      <LinkButton type="submit" onClick={onClick} style={style} id={id}>
        <span>{text}</span>
        <DropSvg style={{
          width: 7, height: 7, fill: theme.COLORS.PRIM_800, verticalAlign: 'middle',
        }}
        />
      </LinkButton>
    );
  }

  if (menu) {
    return (
      <MenuButton type="submit" onClick={onClick} disabled={disabled}  id={id}>
        <EyeSvg style={{ width: 22, height: 22, fill: theme.COLORS.WHITE }} />
        <Spacing margin={8} />
        {loading ? (<ReactLoading color={theme.COLORS.WHITE} type="spin" width="1em" height="1em" />) : (<span style={{ color: theme.COLORS.WHITE }}>{text}</span>)}
      </MenuButton>
    );
  }

  if (loginSubmit) {
    return (
      <LoginButton type="submit" onClick={onClick} disabled={disabled} style={style}  id={id}>
        {loading ? (<ReactLoading color={theme.COLORS.PRIM_800} type="spin" width="1em" height="1em" />) : (<span>{text}</span>)}
      </LoginButton>
    );
  }

  if (confirm) {
    return (
      <ConfirmButton type="submit" onClick={onClick} disabled={disabled || loading} style={style}  id={id}>
        {loading ? (<ReactLoading color={theme.COLORS.PRIM_800} type="spin" width="1em" height="1em" />) : (<span>{text}</span>)}
      </ConfirmButton>
    );
  }

  if (account) {
    return (
      <LinkButton type="submit" onClick={onClick} style={style}  id={id}>
        <span style={{ color: theme.COLORS.PRIM_700}}>{text}</span>
        <PencilSvg style={{
          width: 24, height: 24, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
        }}
        />
      </LinkButton>
    )
  }

  return (
    <FormButton type="submit" onClick={onClick} disabled={disabled || loading} style={style}  id={id}>
      {loading ? (<ReactLoading color={theme.COLORS.PRIM_800} type="spin" width="1em" height="1em" />) : (<span>{text}</span>)}
    </FormButton>
  );
};

// Button.defaultProps = defaultProps;

export default Button;
