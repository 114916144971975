import api from "api";
import Button from "components/Button";
import { useEffect, useState } from "react";
import { Row, Spacing, TableContainer, TableLine } from "styles/base";
import { ReactComponent as SaveSvg } from 'assets/icons/save.svg';
import { ReactComponent as UndoSvg } from 'assets/icons/undo.svg';
import { cpfMask, name, phoneMask, replaceEspecials } from "util/masks";
import { InputTable } from "../style";
import { isCellPhoneDDDValid, isEmailValid, removeAccents, } from "util/validations";
import theme from "styles/theme";

const defaultData = {
    nome: '',
    cpf: '',
    ddd: '',
    telefone: '',
    email: '',
}

const defaultValues = {
    nome: '',
    ddd: '',
    telefone: '',
    email: '',
}

const DataResponsible = ({ error, terms }: { error: Function, terms: boolean }) => {
    const [dataResponsible, setDataResponsible] = useState(defaultData);
    const [values, setValues] = useState(defaultValues);
    const [editNome, setEditNome] = useState(false);
    const [editTelefone, setEditTelefone] = useState(false);
    const [editEmail, setEditEmail] = useState(false);

    const [show, setShow] = useState(terms ? false : true);

    useEffect(() => {
        async function initData() {
            try {
                const response = await api.get("/usuarios/responsavel-instancia");
                return setDataResponsible(response.data);
                } catch (e) {
                console.log(e);
            }
        }
    
        initData();
    }, [editNome, editTelefone, editEmail]);

    if (!dataResponsible) {
        return null;
    }

    const cancelNome = () => {
        error(false);
        setEditNome(false);
        setValues({
            ...values,
           nome: '',
        })
    }

    const cancelTelefone = () => {
        error(false);
        setEditTelefone(false);
        setValues({
            ...values,
           telefone: '',
        })
    }

    const cancelEmail = () => {
        error(false);
        setEditEmail(false);
        setValues({
            ...values,
           email: '',
        })
    }

    const handleNome = async () => {
        if (values.nome.length < 3) {
            return error(true, 'O nome do responsável possui menos que 3 caracteres')
        }

        error(false);

        try {
            await api.put('/usuarios/responsavel-instancia', {
                nome: values.nome
            });
  
          } catch (error: any) {
            console.log(error);
        }

        setValues({
            ...values,
           nome: '',
        })
        setEditNome(false);
    }   

    const handleTelefone = async () => {
        const ddd = replaceEspecials(values.telefone).substring(0, 2)
        const telefone = replaceEspecials(values.telefone).substring(2)

        try {
            if (isCellPhoneDDDValid(replaceEspecials(ddd +""+ telefone))) {
                await api.put('/usuarios/responsavel-instancia', {
                    ddd,
                    telefone,
                });
                error(false);
            }else{
                return error(true, 'Celular inválido');
            }
        } catch (error: any) {
            console.log(error);
        }

        setValues({
            ...values,
           telefone: '',
        })
        setEditTelefone(false);
    }

    const handleEmail = async () => {
        if (!isEmailValid(values.email)) {
            return error(true, 'E-mail inválido');
        }
        
        error(false);

        try {
            await api.put('/usuarios/responsavel-instancia', {
                email: values.email
            });
  
          } catch (error: any) {
            console.log(error);
        }

        setValues({
            ...values,
           email: '',
        })
        setEditEmail(false);
    }

    return (
        <>
            <TableContainer>
                <thead><tr>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>Telefone/Celular</th>
                    <th>E-mail</th>
                </tr></thead>
                <tbody>
                    <TableLine>
                        <th>
                            {editNome ? (
                                <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <InputTable 
                                        value={values.nome}
                                        onChange={({ target }) => setValues({
                                            ...values,
                                            nome: name(target.value),
                                        })}
                                        maxLength={40}
                                    />

                                    <Spacing margin={2} />
                                    
                                    <button onClick={() => cancelNome()}>
                                        <UndoSvg style={{
                                            width: 20, height: 20, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle'
                                            }}
                                        />
                                    </button>
                                    
                                    <Spacing margin={2} />

                                    <button onClick={() => handleNome()}>
                                        <SaveSvg style={{
                                            width: 20, height: 20, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
                                        }}
                                        />
                                    </button>
                                </Row>
                            ) : (
                                <Button 
                                    account
                                    text={dataResponsible.nome}
                                    onClick={() => setEditNome(true)}
                                />
                            )}
                        </th>
                        <th>{cpfMask(dataResponsible.cpf)}</th>
                        <th>
                            {editTelefone ? (
                                <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <InputTable 
                                        value={values.telefone}
                                        onChange={({ target }) => setValues({
                                            ...values,
                                            telefone: phoneMask(target.value),
                                        })}
                                    />

                                    <Spacing margin={2} />
                                    
                                    <button onClick={() => cancelTelefone()}>
                                        <UndoSvg style={{
                                            width: 20, height: 20, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle'
                                            }}
                                        />
                                    </button>
                                    
                                    <Spacing margin={2} />

                                    <button onClick={() => handleTelefone()}>
                                        <SaveSvg style={{
                                                width: 20, height: 20, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
                                        }}
                                        />
                                    </button>
                                </Row>
                            ) : (
                                <Button 
                                    account
                                    text={phoneMask(dataResponsible.ddd + dataResponsible.telefone)}
                                    onClick={() => setEditTelefone(true)}
                                />
                            )}
                        </th>
                        <th>
                            {editEmail ? (
                                <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <InputTable 
                                        value={values.email}
                                        onChange={({ target }) => setValues({
                                            ...values,
                                            email: removeAccents(target.value),
                                        })}
                                        maxLength={50}
                                    />

                                    <Spacing margin={2} />
                                    
                                    <button onClick={() => cancelEmail()}>
                                        <UndoSvg style={{
                                            width: 20, height: 20, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle'
                                        }}
                                        />
                                    </button>
                                    
                                    <Spacing margin={2} />

                                    <button onClick={() => handleEmail()}>
                                        <SaveSvg style={{
                                            width: 20, height: 20, fill: theme.COLORS.PRIM_700, verticalAlign: 'middle',
                                        }}
                                        />
                                    </button>
                                </Row>
                            ) : (
                                <Button 
                                    account
                                    text={dataResponsible.email}
                                    onClick={() => setEditEmail(true)}
                                />
                            )}
                        </th>
                    </TableLine>
                </tbody>
            </TableContainer>
        </>
    )
}

export default DataResponsible;