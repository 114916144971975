import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LogoPng from '../../assets/icons/logo.png';
import { useAuth } from '../../hooks/auth';
import { Column, Label, Spacing } from '../../styles/base';
import { consoleStringify } from '../../util/console';
import { AuthorityEnum } from './model/Authority';
import { ResponseUsuarioAtual } from './model/ResponseUsuarioAtual';
import { getListMenu, getUserData } from './service';
import { BoxLoadPage, Divisor, Header, Menu, MenuBtn } from './styles';

import { ReactComponent as Empresas } from 'assets/icons/menu/empresas.svg';

import { ReactComponent as LancarCompra } from 'assets/icons/menu/lancar-compra.svg';
import { ReactComponent as Pagar } from 'assets/icons/menu/pagar.svg';
import { ReactComponent as ConsultarCliente } from 'assets/icons/menu/consultar-cliente.svg';
import { ReactComponent as Relatorios } from 'assets/icons/menu/relatorios.svg';
import { ReactComponent as Campanhas } from 'assets/icons/menu/campanhas.svg';
import { ReactComponent as Groups } from 'assets/icons/menu/gerenciar-usuarios.svg';
import { ReactComponent as ControleFinanceiro } from 'assets/icons/menu/controle-financeiro.svg';

import { ReactComponent as MinhaConta } from 'assets/icons/menu/minha-conta.svg';
import { ReactComponent as Sair } from 'assets/icons/menu/sair.svg';


import { normalizeResponsibility } from 'util/utils';
import { getAccessionCurrent } from 'layout/Home/screens/Account/services';
import { IAccession } from 'layout/Home/screens/Account/services/types';
import theme from 'styles/theme';
import { CircularProgress } from '@mui/material';

const AppHeader = () => {

  const navigate = useHistory();
  const { updateUser, signOut } = useAuth();
  const [ load, setLoad] = useState(true)

  const [dadosUsuario, setDadosUsuario] = useState<ResponseUsuarioAtual.RootObject>({} as ResponseUsuarioAtual.RootObject,);
  
  function handleVerifyAuthorities(authority: ResponseUsuarioAtual.Authority[]) {
    if (authority?.some((item) => item.authority === AuthorityEnum.ADMINISTRADOR)) {
      signOut();
    }
  }

  async function initData() {
    try {

      const response = await getUserData();
      setDadosUsuario(response.data);
      updateUser(response.data.body);
      handleVerifyAuthorities(response.data.body.authorities);

      const perfil = response.data.body.authorities[0].authority
      localStorage.setItem("@mony:usuarioPerfil", perfil === 'MASTER' ? 'DIRETOR' : perfil )
    } catch (error) {
      consoleStringify(error);
    }
    setLoad(false)
  }

  
  const userId = localStorage.getItem('@mony:usuarioId')
  const { data: dataPlanUser, isLoading: loadPlanUser } : 
  { data: IAccession, isLoading: boolean } = getAccessionCurrent({ userId: `${userId}`})

  useEffect(() => {
    initData()
    listMenu()
  }, []);
 
  if (!dadosUsuario) {
    return null;
  }

  const pathRoutes = (value: string) => {
    const route = (navigate.location.pathname).split('/')
    const urlRoute = route[2] || route[1]
    return value === urlRoute || ""
  }

  const [menuList, setMenuList] = useState([])
  const [loadMenu, setLoadMenu] = useState(true)
  const listMenu = () => {
    getListMenu()
      .then(res => {
        setMenuList(res.data)
      })
      .catch(e => console.log(e))
      .finally(() => setLoadMenu(false))
  }
  const isMenuActive = (e: any) => {
    const list = menuList?.map((i: any) => i.item.toLowerCase())
    return list.includes(e.toLowerCase())
  }

  const mountListMenu = [
    { nome: 'Empresas', icon: <Empresas />, route: '/home/empresas', active: isMenuActive('Empresas') && dadosUsuario?.body?.authorities[0].authority === 'ADMINISTRADOR GOPOINTS', menuAtivo: pathRoutes('empresas') || pathRoutes('consultar-empresas'), disabled: !dadosUsuario?.body?.termo, divisor: true },
    { nome: 'Lançar Compra', icon: <LancarCompra />, route: '/home/', active: isMenuActive('Lançar Compra'), menuAtivo: pathRoutes('home'), disabled: !dadosUsuario?.body?.termo },
    { nome: 'Usar Cashback', icon: <Pagar style={{ width: 15 }} />, route: '/home/pagar', active: isMenuActive('Pagar com o Mony'), menuAtivo: pathRoutes('pagar'), disabled: !dadosUsuario?.body?.termo },
    { nome: 'Consultar Cliente', icon: <ConsultarCliente />, route: '/home/consultar-cliente', active: isMenuActive('Consultar Cliente'), menuAtivo: pathRoutes('consultar-cliente'), disabled: !dadosUsuario?.body?.termo },
    { nome: 'Relatórios', icon: <Relatorios />, route: '/home/relatorios', active: isMenuActive('Relatórios'), menuAtivo: pathRoutes('relatorios'), disabled: !dadosUsuario?.body?.termo },
    { nome: 'Regras Gerais', icon: <Campanhas />, route: '/home/regras-gerais', active: isMenuActive('Regras Gerais'), menuAtivo: pathRoutes('regras-gerais'), disabled: !dadosUsuario?.body?.termo },
    { nome: 'Gerenciar Usuários', icon: <Groups style={{ width: 22 }} />, route: '/home/usuarios', active: isMenuActive('Gerenciar Usuários'), menuAtivo: pathRoutes('usuarios'), disabled: !dadosUsuario?.body?.termo },
    { nome: 'Controle Financeiro', icon: <ControleFinanceiro style={{ width: 22 }} />, route: '/home/controle-financeiro', active: isMenuActive('Controle Financeiro'), menuAtivo: pathRoutes('controle-financeiro'), disabled: !dadosUsuario?.body?.termo },
    { nome: 'Minha Conta', icon: <MinhaConta />, route: '/home/conta', active: isMenuActive('Minha Conta'), menuAtivo: pathRoutes('conta'), disabled: !dadosUsuario?.body?.termo },
    // && dadosUsuario?.body?.authorities[0].authority === 'MASTER'
  ]
  const firstName = (value: string) => value//?.split(' ')[0]
  
  const initial = () => {
    const perfilAdmin = ['MASTER', 'DIRETOR']; //'ADMINISTRADOR GOPOINTS'

    if(!loadPlanUser){  
      (!dadosUsuario?.body?.termo 
      && dataPlanUser?.status !== 'ATIVA'
      && perfilAdmin.includes(dadosUsuario?.body?.authorities[0].authority))
      && navigate.push('/home/conta')
    }
  }

  useEffect(() => {
    initial()
  }, [dataPlanUser, dadosUsuario, loadPlanUser])
  
  return (
    <Header>
      {loadMenu && <BoxLoadPage> <CircularProgress /> Carregando... </BoxLoadPage>}
      <nav>
        <Menu>
          <Spacing margin={50} />
          <img src={LogoPng} alt="Logo" style={{ width: '100%' }} />

          <Spacing margin={50} />
          <Column>
            <Label bold size="24" color={theme.COLORS.WHITE}>
              {`Olá ${firstName(dadosUsuario?.body?.nome) || ''}`}
            </Label>
            <Spacing margin={4} />
            <Label size="14" color={theme.COLORS.WHITE}>
              {normalizeResponsibility(dadosUsuario?.body?.authorities[0].authority)}
            </Label>
          </Column>

          <Spacing margin={10} />
          {mountListMenu.map((item, idx) => {

            return item.active && <div key={idx}>
              <MenuBtn
                className={item.menuAtivo && 'ativo'}
                onClick={() => navigate.push(item?.route)}
                disabled={item.disabled}
              >
                {item.icon}
                <span>{item.nome}</span>
              </MenuBtn>

              {item.divisor && <>
                <Divisor />
                <Spacing margin={10} />
              </>}
            </div>
          })}

          <MenuBtn type="button" onClick={signOut}>
            <Sair />
            <span>Sair</span>
          </MenuBtn>
        </Menu>
      </nav>
    </Header>
  );
};

export default AppHeader;
