import Button from "components/Button"
import { InputNumberFormat } from "components/PasswordFormat"
import { LabelError } from "layout/Login/rememberPassword/rememberPassword"
import { BoxInput } from "layout/Login/style"
import { useEffect, useState } from "react"
import styled from "styled-components"
import theme from "styles/theme"

const BoxResetPassord = ({ onClick, load = false }: { onClick?: any, load?:boolean }) => {
  const [values, setValues] = useState({ newPassword: '', confirmPassword: '' })

  const [isValid, setIsValid] = useState(false)
  const [error, setError] = useState('')

  const validConfirmPassword = () => {
    const value = values.confirmPassword
    if (value.length === 6) {
      values.newPassword !== value && setError('Senha de confirmação não confere')
      if (values.newPassword === value) {
        setIsValid(true)
        setError('')
      } else {
        setIsValid(false)
      }
    } else {
      setError('')
      setIsValid(false)
    }
  }
  useEffect(() => {
    validConfirmPassword()
  }, [values])

  const onClickInt = () => {
    onClick(values || "")
  }
  return <BoxReset onSubmit={(e) => e.preventDefault()}>
    <h3>Insira uma senha de 6 digitos numéricos</h3>

    <BoxInput>
      <InputNumberFormat
        label="Nova Senha"
        lineInput
        type="password"
        format="######"
        autoComplete="new-password"
        value={values.newPassword}
        onValueChange={(data: any) => {
          setValues((prevState) => ({ ...prevState, newPassword: data }))
        }}
      />
    </BoxInput>

    <BoxInput>
      <InputNumberFormat
        label="Confirmar Senha"
        lineInput
        type="password"
        format="######"
        autoComplete="new-password"
        value={values.confirmPassword}
        onValueChange={(data: any) => {
          setValues((prevState) => ({ ...prevState, confirmPassword: data }))
        }}
      />
      <LabelError>{error}</LabelError>
    </BoxInput>

    <Button
      loginSubmit
      text="Redefenir senha"
      disabled={!isValid}
      onClick={() => onClickInt()}
      loading={load}
    />

  </BoxReset>
}

const BoxReset = styled.form`
  display:grid;
  gap:25px;

  & > h3 {
    font-size:18px;
    color:${theme.COLORS.WHITE};
    margin:50px 0 5px;
  }

  & > button{
    margin-top:20px;
  }
`

export { BoxResetPassord }