import { useHistory } from "react-router-dom"

import TitlePage from "components/TitlePage"
import { AppBody } from "styles/base"
import Success from "../../sucess/Success"

export const PlansSuccess = () => {
  const navigate = useHistory()

  const onSuccess = () =>  navigate.push('/home/conta/')

  return <>
    <AppBody id="app-body">
      <TitlePage title="Minha Conta" />
      <Success onSuccess={onSuccess} label={'Plano atualizado com sucesso!!'} iconSuccess={false} />
    </AppBody>
  </>
  }